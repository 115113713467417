import React, { useEffect, useRef, useState } from "react";
import ActiveButton from "../ActiveButton";
import InactiveButton from "../InactiveButton";
import placeholder from "../../../assets/images/customplaceholder.png";
import { defaultCollections } from "./defaultCollections";
import searchCollections from "src/apis/searchCollections";
import getAlchemyMetadata from "src/apis/getAlchemyMetadata";
import { isValidEthereumAddress } from "src/helpers/isValidEthereumAddress";
import { getContractMetadata } from "src/apis";

const SearchByCollection = ({
	active,
	setActive,
	selected,
	setSelected,
}: ISearchByCollectionProps) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [openDropDown, setOpenDropDown] = useState(false);
	const [collections, setCollections] = useState<any>(defaultCollections);
	const [tokenId, setTokenId] = useState<string | null>("");
	const [nftImage, setNftImage] = useState<string | undefined>(undefined);

	const collectionDropdownRef = useRef<any>(null);

	const filteredCollections = isValidEthereumAddress(searchTerm)
		? collections
		: collections.filter((item: any) =>
				item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
		  );

	async function findCollections(value: string) {
		const isValidAddress = isValidEthereumAddress(value);
		try {
			if (isValidAddress) {
				const { data: contractMetadata } = await getContractMetadata(value);

				const foundCollection = [
					{
						_id: contractMetadata.data.address,
						name: contractMetadata.data.name,
						imageUrl: contractMetadata.data.openSeaMetadata.imageUrl,
					},
				];
				setCollections(foundCollection);
			} else if (!isValidAddress && value.trim().length > 2) {
				const { data } = await searchCollections(value.trim());
				const collections = data.data;
				setCollections(collections);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function findContract(address: string) {
		const isValidAddress = isValidEthereumAddress(address);
		try {
			if (isValidAddress) {
				const { data: contractMetadata } = await getContractMetadata(address);

				const foundCollection = [
					{
						_id: contractMetadata.data.address,
						name: contractMetadata.data.name,
						imageUrl: contractMetadata.data.openSeaMetadata.imageUrl,
					},
				];
				setCollections(foundCollection);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				collectionDropdownRef.current &&
				!collectionDropdownRef.current.contains(event.target)
			) {
				setOpenDropDown(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		async function fetchNFTImage() {
			try {
				if (selected && selected.contractAddress && tokenId !== "" && tokenId) {
					const { data } = await getAlchemyMetadata(
						selected.contractAddress,
						tokenId
					);
					const image = data.data.media[0].gateway;
					setNftImage(image);
				}
			} catch (error) {
				setNftImage(undefined);
				console.log(error);
			}
		}

		fetchNFTImage();
	}, [selected, selected?._id, tokenId]);

	useEffect(() => {
		setSelected((prev: any) => ({ ...prev, tokenId: tokenId }));
	}, [setSelected, tokenId]);

	return (
		<div ref={collectionDropdownRef}>
			<div
				className='custom-radio-button'
				onClick={() => {
					setActive("COLLECTIONS");
					setSelected(null);
					setSearchTerm("");
				}}>
				{active === "COLLECTIONS" ? <ActiveButton /> : <InactiveButton />}
				<div>
					<h4>Collection</h4>
					<p>Search by collection name</p>
				</div>
			</div>
			{active === "COLLECTIONS" ? (
				<div className='select-recipient-container'>
					<div className='input-with-image'>
						{selected && selected.contractAddress ? (
							<img
								src={
									selected && selected.imageUrl
										? selected.imageUrl
										: placeholder
								}
								alt='collection-img'
								className='input-image'
							/>
						) : null}
						<input
							type='text'
							className='select-recipient-input cutom-contract-input'
							placeholder='COLLECTION NAME / CONTRACT ADDRESS'
							onFocus={() => {
								setOpenDropDown(true);
								setSearchTerm("");
								setSelected(null);
								setTokenId("");
							}}
							onChange={(e) => setSearchTerm(e.target.value)}
							value={searchTerm}
							onKeyUp={() => findCollections(searchTerm)}
							onPaste={(event) => {
								findContract(event.clipboardData.getData("text"));
							}}
						/>
					</div>

					<input
						type='text'
						className='select-recipient-input token-id'
						placeholder='ID'
						onChange={(e) => setTokenId(e.target.value)}
						value={tokenId?.toString()}
					/>
					{selected &&
					selected.contractAddress &&
					tokenId !== "" &&
					nftImage ? (
						<img src={nftImage} alt='NFT' />
					) : selected &&
					  selected.contractAddress &&
					  tokenId !== "" &&
					  !nftImage ? (
						<img src={placeholder} alt='NFT' />
					) : null}
				</div>
			) : null}

			{openDropDown && active === "COLLECTIONS" ? (
				<div className='search-result'>
					<ul>
						{filteredCollections.map((item: any, index: number) => (
							<li
								key={index}
								onClick={() => {
									setSearchTerm(item.name);
									setSelected((prev: any) => ({
										...prev,
										contractAddress: item._id,
										imageUrl: item.imageUrl,
									}));
									setOpenDropDown(false);
								}}>
								<img
									src={
										item.imageUrl && item.imageUrl.includes("http")
											? item.imageUrl
											: placeholder
									}
									alt='placeholder'
								/>
								<span>{item.name}</span>
							</li>
						))}
					</ul>
				</div>
			) : null}
		</div>
	);
};

export default SearchByCollection;

interface ISearchByCollectionProps {
	active: string;
	setActive: React.Dispatch<React.SetStateAction<string>>;
	selected: any | null;
	setSelected: React.Dispatch<React.SetStateAction<any | null>>;
}
