import { useEffect } from 'react'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './rmntoken.css'

const RnmToken = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <>
      <section className="rnm-token">
        <Container>
          <Row>
            <Col>
              <div className="rnm-token-content position-relative">
                <Image src="./img/svg/dragon.svg" className="wow fadeIn" />
                <div className="nftr-text-box wow fadeInLeft">
                  <h2>
                    NFTR <strong>PROFILES</strong> & DOMAINS
                  </h2>
                  <Image className="pink-bar" src="./img/pink-bar.png" />
                  <p>
                    Some individual collections permit naming NFTs in silos.
                    <br />
                    NFTR creates a universal name space with a single instance
                    <br />
                    of each name across all ERC-721s. This makes referring to
                    <br />
                    NFTs simple, enables reachability, and unlocks innovative
                    <br />
                    utilities such as NFT domains, profile pages, messaging,
                    <br />
                    token bound account (ERC-6551) naming, heterogenous 
                    <br />
                    groupings for DAOs, branding, and more. As soon as  
                    <br />
                    you name your NFT it gets a reachable profile page 
                    <br />
                    on the internet.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default RnmToken
