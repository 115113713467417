const ActiveButton = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<circle cx='12' cy='12' r='12' fill='white' />
			<circle cx='12' cy='12' r='7.5' fill='white' stroke='#FC4BA3' />
			<circle cx='12' cy='12' r='2' fill='#FC4BA3' />
		</svg>
	);
};

export default ActiveButton;
