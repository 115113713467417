import Routes from "./Routes";
//import { useEffect, useState } from "react";

import {
  LoadedContractsDataProvider,
  WalletContentsDataProvider,
  NamesDataProvider,
  Web3ContextProvider,
} from "./contexts";
import Web3OnboardContextProvider from "./contexts/Web3OnboardContext";
import WalletBalancesProvider from "./contexts/WalletBalancesContext";
import OnboardStyles from "./OnboardStyles";
import ListedNamesContextProvider from "./contexts/ListedNamesContext";
import SocketContextProvider from "./contexts/SocketContext";
import { initDB } from "react-indexed-db-hook";
import { DBConfig } from "./DBConfig";
import { useEffect } from "react";

initDB(DBConfig);

const App = () => {
  // eslint-disable-next-line no-unused-vars
  // const [route, setRoute] = useState();

  // useEffect(() => {
  //   setRoute(window.location.pathname);
  // }, [setRoute]);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      try {
        const parsedAuthToken = JSON.parse(authToken);

        if (typeof parsedAuthToken !== 'object') {
          localStorage.removeItem('authToken');
          console.log('authToken in localStorage was not an object. Removed.');
        }
      } catch (error) {
        localStorage.removeItem('authToken');
        console.error('Error parsing authToken from localStorage:', error);
      }
    }
  }, []);

  return (
    <div className="App">
      <OnboardStyles />
      <Web3ContextProvider>
        <SocketContextProvider>
          <ListedNamesContextProvider>
            <Web3OnboardContextProvider>
              <NamesDataProvider>
                <LoadedContractsDataProvider>
                  <WalletContentsDataProvider>
                    <WalletBalancesProvider>
                      <Routes />
                    </WalletBalancesProvider>
                  </WalletContentsDataProvider>
                </LoadedContractsDataProvider>
              </NamesDataProvider>
            </Web3OnboardContextProvider>
          </ListedNamesContextProvider>
        </SocketContextProvider>
      </Web3ContextProvider>
    </div>
  );
};

export default App;
