import { useRef, useState } from "react";
import { Image } from "react-bootstrap";
import placeholder from "../../../../assets/images/customplaceholder.png";
import { useTokenBoundAccount } from "src/hooks/useTokenBoundAccount";
import Loader from "../../Loader";
import { ISelectedNFT, ITokenBoundAccountCollectibles } from "./interface";
import LiveName from "src/components/LiveName";
import "./TokenBoundAccount.css";
import { Send } from "react-bootstrap-icons";
import TBATransferModal from "./TBATransferModal";

const TokenBoundAccountCollectibles = ({
	contractAddress,
	tokenId,
	owned,
	tokenBoundNFTs,
}: ITokenBoundAccountCollectibles) => {
	const [selectedNFT, setSelectedNFT] = useState<ISelectedNFT | undefined>();
	const renderRef = useRef(0);
	const { loading, accountDeployed, account } = useTokenBoundAccount(
		contractAddress,
		tokenId
	);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div>
			{selectedNFT && (
				<TBATransferModal
					isOpen={isModalOpen}
					onClose={closeModal}
					account={account}
					accountDeployed={accountDeployed}
					selectedNFT={selectedNFT}
				/>
			)}
			{!loading ? (
				<div>
					<div className='token-bound-collectibles'>
						{tokenBoundNFTs.map((item, index) => (
							<div className='token-bound-collectibles-asset-container'>
								<div className='image-with-btn'>
									<Image
										onClick={() => {
											if (
												item.contractAddress.toLowerCase() ===
													selectedNFT?.contractAddress.toLowerCase() &&
												+selectedNFT.tokenId === +item.tokenId
											) {
												setSelectedNFT(undefined);
											} else {
												setSelectedNFT({
													nftrName: "",
													contractAddress: item.contractAddress,
													tokenId: item.tokenId.toString(),
													collectionName: item.collectionName,
												});
											}
										}}
										key={index}
										className={`token-bound-collectibles-asset ${
											item.contractAddress.toLowerCase() ===
												selectedNFT?.contractAddress.toLowerCase() &&
											+selectedNFT.tokenId === +item.tokenId
												? "selected"
												: ""
										}`}
										src={item.image1}
										onError={function (error: any) {
											error.target.src = placeholder;
										}}
									/>

									<button
										className='tba-nft-send-btn'
										onClick={() => {
											setSelectedNFT({
												nftrName: "",
												contractAddress: item.contractAddress,
												tokenId: item.tokenId.toString(),
												collectionName: item.collectionName,
											});
											openModal();
										}}>
										{accountDeployed && owned ? (
											<Send color='#fff' width={30} height={30} />
										) : null}
									</button>
								</div>

								<a
									href={`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${item.contractAddress}/${item.tokenId}`}
									target='_blank'
									rel='noreferrer'
									className='asset-link'>
									<h3 className='tba-name'>
										<LiveName
											updateInterval={5000}
											contractAddress={item.contractAddress}
											tokenId={item.tokenId}
										/>
									</h3>
									<p className='token-bound-collectibles-asset-name'>
										{item.collectionName}
									</p>
									<p className='token-bound-collectibles-asset-name id'>
										ID {item.tokenId}
									</p>
								</a>
							</div>
						))}
					</div>
				</div>
			) : (
				<Loader color={"#fc4ba3"} />
			)}
		</div>
	);
};

export default TokenBoundAccountCollectibles;
