import axios from "axios";
import axiosRetry from "axios-retry";

const nftrAxios = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL_BASE,
  timeout: 1200000,
});
axiosRetry(nftrAxios, { retries: 5 });

export { nftrAxios };
