import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { TwitterTweetEmbed } from "react-twitter-embed";

const TwitterModal = ({
  tweetId,
  currentTweetId,
}: {
  tweetId: string;
  currentTweetId: any;
}) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    currentTweetId.current = null;
  };
  return (
    <Modal
      className="modal-naming"
      show={open}
      onHide={() => handleClose()}
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="modal-header-naming"
        closeButton
        style={{ backgroundColor: "#1D9CEF" }}
      >
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-naming">
        <TwitterTweetEmbed tweetId={tweetId} />
      </Modal.Body>
      <Modal.Footer
        className="modal-footer-naming"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <a
          href={`https://twitter.com/intent/retweet?tweet_id=${tweetId}`}
          className="modal-button-naming-no retweet-button"
          target={"_blank"}
          onClick={handleClose}
          rel="noreferrer"
          style={{ backgroundColor: "#1D9CEF" }}
        >
          Retweet
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default TwitterModal;
