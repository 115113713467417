import { useState } from 'react'
import { Col, Row, Accordion, Tabs, Tab, Nav } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import DiamondImage from './images/diamond.svg'
import IconGreen from './images/logo-green.svg'
import Switch from '../../Switch/Switch'
import FormGroupInput from '../../FormGroupInput/FormGroupInput'

const StakeGtk = () => {
  const [error, setError] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)

  const rnmNumber = 50000

  // handle RNM form
  const handleRnm = (e) => {
    // handle error state
    if (parseInt(e.target.value) < rnmNumber) {
      setError(true)
    } else {
      setError(false)
    }

    // handle button state
    if (
      parseInt(e.target.value) < rnmNumber ||
      parseInt(e.target.value.length) === 0
    ) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }
  }
  return (
    <div>
      <Row>
        <Col lg={5}>
          <div className="tab-left-content">
            <h4>STAKE GTKs</h4>
            <p className="para-medium text-uppercase">
              Earn share of 10% of WETH platform revenue
            </p>
            <p className="para-small">Total GTKs staked or spent: 0 ($0.00)</p>
            <p className="para-large">
              APY 0%
              <span
                style={{ marginLeft: '8px' }}
                data-tip="APR calculated from current WETH revenue dollar value per staked/spent GTK vs. current GTK mint dollar value"
              >
                <i className="far fa-question-circle"></i>
              </span>
            </p>
          </div>
        </Col>
        <Col lg={7}>
          <div className="tab-right-content">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordion-header-left">
                    <h4>
                      <span>Your STAKE</span>{' '}
                      <span className="accordion-header-right">0 GTKs</span>
                    </h4>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tab-content-dark">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="stake"
                    >
                      <Nav variant="pills" className="accordion-navigation">
                        <Nav.Item>
                          <Nav.Link eventKey="stake">Stake</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="unstake">Unstake</Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane eventKey="stake">
                          <div className="form-group">
                            <input
                              onChange={handleRnm}
                              type="number"
                              placeholder="GTK Balance"
                              className={`w-100 ${
                                error === true ? 'show-error' : ''
                              }`}
                            />
                            <button disabled={btnDisable}>Max</button>
                            <button disabled={btnDisable}>Stake</button>
                          </div>
                          {error && (
                            <div className="notification" role="alert">
                              {error === true ? 'Insufficient RNM balance' : ''}
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="unstake">
                          <div className="form-group">
                            <input
                              onChange={handleRnm}
                              type="number"
                              placeholder="GTK Balance"
                              className={`w-100 ${
                                error === true ? 'show-error' : ''
                              }`}
                            />
                            <button disabled={btnDisable}>Max</button>
                            <button disabled={btnDisable}>Unstake</button>
                          </div>
                          {error && (
                            <div className="notification" role="alert">
                              {error === true ? 'Insufficient RNM balance' : ''}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                    <div className="statistics">
                      <div className="statistics-row">
                        <span>GTKs in wallet:</span>
                        <span>0</span>
                      </div>
                      <span className="bar"></span>
                      <div className="statistics-row">
                        <span>
                          Collect 0 WETH rewards?{' '}
                          <span
                            style={{ marginLeft: '8px' }}
                            data-tip="Hello from the tooltip"
                          >
                            <i className="far fa-question-circle"></i>
                          </span>
                        </span>
                        <Switch />
                      </div>
                      <div className="statistics-row mt-30">
                        <h6>WETH rewards distributed every block</h6>
                        <p>
                          What's this{' '}
                          <span
                            style={{ marginLeft: '8px' }}
                            data-tip="Hello from the tooltip"
                          >
                            <i className="far fa-question-circle"></i>
                          </span>
                        </p>
                      </div>
                      <FormGroupInput
                        placeholderText={['Quantity ', <span>($0.00)</span>]}
                      />
                      <div className="statistics-row mt-30">
                        <span>LAST COLLECTED:</span>
                        <p>00:00, Jan 1, 1979</p>
                      </div>
                      <div className="statistics-row mt-10">
                        <span>COLLECTED TO DATE:</span>
                        <p>
                          0 <span>($0.00)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
      </Row>
      <ReactTooltip />
    </div>
  )
}

export default StakeGtk
