import { Link } from 'react-router-dom'

const Project = ({ title, url }) => {
  return (
    <div
      className="project-box"
      style={{ backgroundImage: `url(./img/project-bg.png)` }}
    >
      <div className="project-box-bg">
        <div className="project-colored-bg">
          <h4>{title}</h4>
          <span>so</span> <br />
          <a target="_blank" href={url} className="primary-btn">
            GO VOTE
          </a>
        </div>
      </div>
    </div>
  )
}

export default Project
