import { useContext, createContext, useEffect, useState } from "react";
import unWrappedGetNamingBalances from "./getNamingBalances";
import { Web3OnboardContext } from "./Web3OnboardContext";

export const initialState = {
  eth: { decimal: 0, original: null },
  namingPrice: { decimal: 0, original: null },
  weth: { decimal: 0, original: null },
  wethAllow: { decimal: 0, original: null },
  gtk: { decimal: 0, original: null },
  namingCredits: { decimal: 0, original: null },
};

export const WalletBalancesContext = createContext(initialState);

// let a = 0;
// let b = 0;

const WalletBalancesProvider = ({ children }) => {
  const { readContracts, address, userSigner } = useContext(Web3OnboardContext);

  const [values, setValues] = useState(initialState);
  const { eth, weth, namingPrice, wethAllow, gtk, namingCredits } = values;

  const getNamingBalances = () =>
    unWrappedGetNamingBalances(readContracts, userSigner, address);

  const updateBalances = async (timer) => {
    try {
      const balances = await unWrappedGetNamingBalances(
        readContracts,
        userSigner,
        address
      );

      if (!balances) {
        timer && setTimeout(() => updateBalances(timer), timer);
        return;
      }
      const {
        eth: newEth,
        weth: newWeth,
        namingPrice: newNamingPrice,
        wethAllow: newWethAllow,
        gtk: newGTK,
        namingCredits: newNamingCredits,
      } = balances;
      if (
        eth.decimal !== newEth.decimal ||
        weth.decimal !== newWeth.decimal ||
        namingPrice.decimal !== newNamingPrice.decimal ||
        wethAllow.decimal !== newWethAllow.decimal ||
        gtk.decimal !== newGTK.decimal ||
        namingCredits.decimal !== newNamingCredits.decimal
      ) {
        setValues(balances);
      }
    } catch (error) {
      console.error(error);
    }

    timer && setTimeout(() => updateBalances(timer), timer);
  };

  useEffect(() => {
    updateBalances();
  }, [readContracts, address, userSigner]);

  useEffect(() => {
    updateBalances(15000);
  }, []);

  const valuesObject = {
    getNamingBalances,
    updateBalances,
    eth,
    weth,
    namingPrice,
    wethAllow,
    gtk,
    namingCredits,
  };

  return (
    <WalletBalancesContext.Provider value={valuesObject}>
      {children}
    </WalletBalancesContext.Provider>
  );
};

export default WalletBalancesProvider;
