/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { WOW } from "wowjs";
import { Container, Row, Col, Image } from "react-bootstrap";
import { GOLDEN_TICKET_ADDRESS } from "src/contracts/external_contracts";

const GoldenTicket = ({ contractAddress }) => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);
  return (
    <section className="golden-ticket-section">
      <div className="golden-ticket-shapes position-relative">
        <Image
          className="gt-shape-left position-absolute"
          src="./img/golden-ticket-shape-left.png"
        />
        <Image
          className="gt-shape-right position-absolute"
          src="./img/golden-ticket-shape-right.png"
        />
      </div>
      <Container style={{ marginTop: "50px" }}>
        <Row>
          <Col lg={12} className="text-center wow fadeInUp">
            <div className="golden-ticket-content">
              <Image src="./img/golden-ticket.png" />
              <div className="earn-content">
                <div className="gtk-buttons">
                  <a
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      const ethereum = window.ethereum;
                      ethereum
                        .request(addGoldenTicketRequest)
                        .then((success) => {
                          if (success) {
                          } else {
                            throw new Error("Something went wrong.");
                          }
                        })
                        .catch(console.error);
                    }}
                  >
                    ADD TO WALLET <i className="fas fa-plus-circle"></i>
                  </a>
                </div>
              </div>
              <p>
                We have preselected a list of 1,000 coveted names that require
                spending a Golden Ticket in addition to 0.05 ETH or 1,000 RNM in
                order to name your NFT with one of the special names. This list
                includes names such as Vitalik, 0x0, Steve Jobs, Bill Gates, and
                Danny. GTKs have alternative uses, including 1) Staking for
                platform ETH revenue; 2) Spending it for ETH revenue and RNM
                farming.
              </p>
              <a
                href="https://cloudflare-ipfs.com/ipfs/QmfDuTeF5FMp6gUrHrSEF2trUR4rLqHEvjYfsat43qTvFh"
                target="_blank"
                className="bordered-button"
                rel="noreferrer"
              >
                Special Names List
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const addGoldenTicketRequest = {
  method: "wallet_watchAsset",
  params: {
    type: "ERC20",
    options: {
      address: GOLDEN_TICKET_ADDRESS,
      symbol: "GTK",
      decimals: 0,
      image: "https://i.postimg.cc/cCQxpHYg/gtk-logo.png",
    },
  },
};

export default GoldenTicket;
