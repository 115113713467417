import { useState } from "react";
import { Col, Row, Accordion, Tabs, Tab, Nav } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import DiamondImage from "./images/diamond.svg";
import IconGreen from "./images/logo-green.svg";
import Switch from "../../Switch/Switch";
import FormGroupInput from "../../FormGroupInput/FormGroupInput";

const RnmStaking = () => {
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);

  const rnmNumber = 50000;

  // handle RNM form
  const handleRnm = (e) => {
    // handle error state
    if (parseInt(e.target.value) < rnmNumber) {
      setError(true);
    } else {
      setError(false);
    }

    // handle button state
    if (
      parseInt(e.target.value) < rnmNumber ||
      parseInt(e.target.value.length) === 0
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };

  return (
    <div>
      <Row>
        <Col lg={5}>
          <div className="tab-left-content">
            <h4>RNM STAKING</h4>
            <p className="para-medium">STAKE RNM. EARN RNM &amp; WETH</p>
            <p className="para-small">TOTAL RNM STAKED: 0 ($0.00)</p>
            <p className="para-large">
              0% APY
              <span
                style={{ marginLeft: "8px" }}
                data-tip="APR is calculated from WETH and RNM dollar value vs instantaneous RNM dollar value"
              >
                <i className="far fa-question-circle"></i>
              </span>
            </p>
          </div>
        </Col>
        <Col lg={7}>
          <div className="tab-right-content">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordion-header-left">
                    <h4>
                      <span>Your STAKE</span>{" "}
                      <span className="accordion-header-right">0 RNM</span>
                    </h4>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tab-content-dark">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="stake"
                    >
                      <Nav variant="pills" className="accordion-navigation">
                        <Nav.Item>
                          <span data-tip="Staking and yield functionality will be enabled upon NFTR phase 2 (RNM) launch">
                            <Nav.Link eventKey="stake">Stake</Nav.Link>
                          </span>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="unstake">Unstake</Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane eventKey="stake">
                          <div className="form-group">
                            <input
                              onChange={handleRnm}
                              type="number"
                              placeholder="RNM Balance"
                              className={`w-100 ${
                                error === true ? "show-error" : ""
                              }`}
                            />
                            <button disabled={btnDisable}>Max</button>
                            <button disabled={btnDisable}>Stake</button>
                          </div>
                          {error && (
                            <div className="notification" role="alert">
                              {error === true ? "Insufficient RNM balance" : ""}
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="unstake">
                          <div className="form-group">
                            <input
                              onChange={handleRnm}
                              type="number"
                              placeholder="RNM Balance"
                              className={`w-100 ${
                                error === true ? "show-error" : ""
                              }`}
                            />
                            <button disabled={btnDisable}>Max</button>
                            <button disabled={btnDisable}>Unstake</button>
                          </div>
                          {error && (
                            <div className="notification" role="alert">
                              {error === true ? "Insufficient RNM balance" : ""}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                    <div className="statistics">
                      <div className="statistics-row">
                        <span>RNM in wallet:</span>
                        <span>0</span>
                      </div>
                      <div className="statistics-row">
                        <span>Your Stake (Compounding):</span>
                        <span>0 ($0)</span>
                      </div>
                      <div className="statistics-bottom">
                        <div className="statistics-bottom-inner d-flex justify-content-between align-items-center">
                          <h4>
                            Collect 0 WETH rewards?{" "}
                            <span data-tip="Collect rewards in the same staking/unstaking transaction">
                              <i className="fas fa-info-circle"></i>
                            </span>
                          </h4>
                          <Switch />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="accordion-header-left">
                    <h4>
                      <span>Rewards to Collect</span>{" "}
                      <span className="accordion-header-right">0</span>
                    </h4>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="textbox-space-between mb-20">
                    <h5>WETH rewards distributed every block</h5>
                    <p>
                      What's this
                      <span data-tip="Hello from the tooltip">
                        <i className="far fa-question-circle"></i>
                      </span>
                    </p>
                  </div>
                  <FormGroupInput
                    placeholderText={[
                      <img src={DiamondImage} alt="" key={"itemA"} />,
                      " 0 WETH ",
                      <span key={"itemB"}>($0.00)</span>,
                    ]}
                  />
                  <div className="statistics-row mt-30">
                    <span>LAST COLLECTED:</span>
                    <span>00:00, Jan 1, 1979</span>
                  </div>
                  <div className="statistics-row">
                    <span>COLLECTED TO DATE:</span>
                    <span>
                      0 <span>($0.00)</span>
                    </span>
                  </div>
                  <span className="bar mb-20 mt-20"></span>
                  <h6 className="mb-30">
                    RNM rewards are automatically compounded. No need to
                    collect.
                  </h6>
                  <div className="statistics-row">
                    <h3>
                      <img src={IconGreen} alt="" /> RNM
                    </h3>
                    <p>
                      Compounding{" "}
                      <span data-tip="Hello from the tooltip">
                        <i className="far fa-question-circle"></i>
                      </span>
                    </p>
                  </div>
                  <div className="statistics-row">
                    <p>Earned to date:</p>
                    <p>
                      <span>
                        0 <span>($)</span>
                      </span>
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
      </Row>
      <ReactTooltip />
    </div>
  );
};

export default RnmStaking;
