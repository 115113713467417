import { Container, Row, Col, Image } from 'react-bootstrap'
import EarnYieldTab from '../components/EarnYieldTab/EarnYieldTab'

//import EarnBg from '../assets/images/earn-bg.png'
import EarnShapeLeft from '../assets/images/earn-shape-left.png'
import EarnShapeRight from '../assets/images/earn-shape-right.png'

const EarnYield = (props) => {
  return (
    <>
      <section className="earn-section position-relative">
        <div className="earn-shapes">
          <Image
            src={EarnShapeLeft}
            className="earn-shape-left position-absolute"
          />
          <Image
            src={EarnShapeRight}
            className="earn-shape-right position-absolute"
          />
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="earn-content text-center wow fadeInUp">
                <h1>EARN YIELD ON RNM</h1>
                <p>Stake RNM, earn ETH and RNM</p>
                <p className="earn-content-desc">Wen Token</p>
                <div className="earn-buttons">
                  <span data-tip="Staking and yield functionality will be enabled upon NFTR phase 2 (RNM) launch">
                    <a href="">
                      BUY RNM <i className="fas fa-external-link-alt"></i>
                    </a>
                  </span>
                  <span data-tip="Staking and yield functionality will be enabled upon NFTR phase 2 (RNM) launch">
                    <a href="#">
                      ADD TO WALLET <i className="fas fa-plus-circle"></i>
                    </a>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <EarnYieldTab />
      </section>
    </>
  )
}

export default EarnYield
