// MY INFURA_ID, SWAP IN YOURS FROM https://infura.io/dashboard/ethereum
export const INFURA_ID = "359c5140734c47e990cf43bb7052527b";

// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
export const ETHERSCAN_KEY = "QQ9N41ZPXT7P3P5QMV15IY1QK7TG3PFZSF";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = "7aa11af8-6bab-40f0-8be9-efce89085cb1";

export const NETWORKS = {
	localhost: {
		name: "localhost",
		color: "#666666",
		chainId: 31337,
		blockExplorer: "",
		rpcUrl: "http://" + window.location.hostname + ":8545",
	},
	mainnet: {
		name: "mainnet",
		color: "#ff8b9e",
		chainId: 1,
		rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
		blockExplorer: "https://etherscan.io/",
	},
	kovan: {
		name: "kovan",
		color: "#7003DD",
		chainId: 42,
		rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
		blockExplorer: "https://kovan.etherscan.io/",
		faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
	},
	rinkeby: {
		name: "rinkeby",
		color: "#e0d068",
		chainId: 4,
		rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
		faucet: "https://faucet.rinkeby.io/",
		blockExplorer: "https://rinkeby.etherscan.io/",
	},
	ropsten: {
		name: "ropsten",
		color: "#F60D09",
		chainId: 3,
		faucet: "https://faucet.ropsten.be/",
		blockExplorer: "https://ropsten.etherscan.io/",
		rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
	},
	goerli: {
		name: "goerli",
		color: "#0975F6",
		chainId: 5,
		faucet: "https://goerli-faucet.slock.it/",
		blockExplorer: "https://goerli.etherscan.io/",
		rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
	},
	sepolia: {
		name: "sepolia",
		color: "#FFC300",
		chainId: 11155111,
		faucet: "https://sepoliafaucet.com/",
		blockExplorer: "https://sepolia.etherscan.io/",
		rpcUrl: `https://sepolia.infura.io/v3/${INFURA_ID}`,
	},
	xdai: {
		name: "xdai",
		color: "#48a9a6",
		chainId: 100,
		price: 1,
		gasPrice: 1000000000,
		rpcUrl: "https://dai.poa.network",
		faucet: "https://xdai-faucet.top/",
		blockExplorer: "https://blockscout.com/poa/xdai/",
	},
	matic: {
		name: "matic",
		color: "#2bbdf7",
		chainId: 137,
		price: 1,
		gasPrice: 1000000000,
		rpcUrl: "https://rpc-mainnet.maticvigil.com",
		faucet: "https://faucet.matic.network/",
		blockExplorer: "https://explorer-mainnet.maticvigil.com//",
	},
	mumbai: {
		name: "mumbai",
		color: "#92D9FA",
		chainId: 80001,
		price: 1,
		gasPrice: 1000000000,
		rpcUrl: "https://rpc-mumbai.maticvigil.com",
		faucet: "https://faucet.matic.network/",
		blockExplorer: "https://mumbai-explorer.matic.today/",
	},
	localArbitrum: {
		name: "localArbitrum",
		color: "#50a0ea",
		chainId: 153869338190755,
		blockExplorer: "",
		rpcUrl: `http://localhost:8547`,
	},
	localArbitrumL1: {
		name: "localArbitrumL1",
		color: "#50a0ea",
		chainId: 44010,
		blockExplorer: "",
		rpcUrl: `http://localhost:7545`,
	},
	rinkebyArbitrum: {
		name: "Arbitrum Testnet",
		color: "#50a0ea",
		chainId: 421611,
		blockExplorer: "https://rinkeby-explorer.arbitrum.io/#/",
		rpcUrl: `https://rinkeby.arbitrum.io/rpc`,
	},
	arbitrum: {
		name: "Arbitrum",
		color: "#50a0ea",
		chainId: 42161,
		blockExplorer: "https://explorer.arbitrum.io/#/",
		rpcUrl: `https://arb1.arbitrum.io/rpc`,
		gasPrice: 0,
	},
	localOptimismL1: {
		name: "localOptimismL1",
		color: "#f01a37",
		chainId: 31337,
		blockExplorer: "",
		rpcUrl: "http://" + window.location.hostpwename + ":9545",
	},
	localOptimism: {
		name: "localOptimism",
		color: "#f01a37",
		chainId: 420,
		blockExplorer: "",
		rpcUrl: "http://" + window.location.hostname + ":8545",
		gasPrice: 0,
	},
	kovanOptimism: {
		name: "kovanOptimism",
		color: "#f01a37",
		chainId: 69,
		blockExplorer: "https://kovan-optimistic.etherscan.io/",
		rpcUrl: `https://kovan.optimism.io`,
		gasPrice: 0,
	},
	optimism: {
		name: "optimism",
		color: "#f01a37",
		chainId: 10,
		blockExplorer: "https://optimistic.etherscan.io/",
		rpcUrl: `https://mainnet.optimism.io`,
	},
};

export const NETWORK = (chainId) => {
	for (const n in NETWORKS) {
		if (NETWORKS[n].chainId === chainId) {
			return NETWORKS[n];
		}
	}
};

const sepoliaUrls = [
	"https://develop--suspicious-babbage-5fa6bd.netlify.app",
	// "localhost",
];

const isDev =
	sepoliaUrls.findIndex((e) => window.location.href.indexOf(e) > -1) > -1;

const variableToUse = isDev
	? process.env.REACT_APP_DEV_NETWORK_NAME
	: process.env.REACT_APP_PROD_NETWORK_NAME;

export const DEFAULT_NETWORK = NETWORKS[variableToUse];

let useOverride = isDev
	? process.env.REACT_APP_DEV_OVERRIDE_ETH_CONTRACTS
	: process.env.REACT_APP_PROD_OVERRIDE_ETH_CONTRACTS;

let defaultNetworkName = isDev
	? process.env.REACT_APP_DEV_NETWORK_NAME
	: process.env.REACT_APP_PROD_NETWORK_NAME;

export const IS_DEV = isDev;

export const USE_OVERRRIDE = useOverride;

export const DEFAULT_NETWORK_NAME = defaultNetworkName;
