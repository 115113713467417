import { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { SocketContext } from "src/contexts/SocketContext";

const CreateOfferModal = ({
	openCreateOfferModal,
	handleCloseOfferModal,
	name,
	NameMarketplace,
	currentBid,
	approval,
	approveTransfer,
	awaitingApproval,
	activeOffer,
	setActiveOffer,
}: any) => {
	const [offerAmount, setOfferAmount] = useState("0.01");
	const [offerToAddress, setOfferToAddress] = useState(false);
	const [offerAddress, setOfferAddress] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [, forceUpdate] = useState<any>({});

	const addressRegex = /^0x[a-fA-F0-9]{40}$/;

	const validAddress = addressRegex.test(offerAddress);

	const { socket } = useContext(SocketContext);

	async function offerNameForSale() {
		try {
			setIsLoading(true);
			const feePerc = await NameMarketplace.feePerc();
			const minSalePriceInWei = Number(offerAmount) * 10 ** 18;
			const res = await NameMarketplace.offerNameForSale(
				name,
				minSalePriceInWei.toString(),
				feePerc.toString()
			);
			setIsLoading(false);
			handleCloseOfferModal();

			await res.wait();

			setActiveOffer({
				collectionFrom: "",
				tokenFrom: "",
				name: name,
				value: Number(minSalePriceInWei.toString()) / 10 ** 18,
				status: "active",
				timestamp: Date.now(),
				blockNumber: 0,
				transactionHash: "",
				onlyTo: "0x0000000000000000000000000000000000000000",
			});
			forceUpdate({});
			socket.emit("OfferCreated");
		} catch (error) {
			setIsLoading(false);

			console.log(error);
		}
	}

	async function offerNameForSaleToAddress() {
		try {
			setIsLoading(true);
			const feePerc = await NameMarketplace.feePerc();
			const minSalePriceInWei = Number(offerAmount) * 10 ** 18;

			if (validAddress) {
				const res = await NameMarketplace.offerNameForSaleToAddress(
					name,
					minSalePriceInWei.toString(),
					offerAddress,
					feePerc.toString()
				);

				setIsLoading(false);
				handleCloseOfferModal();

				await res.wait();

				setActiveOffer({
					collectionFrom: "",
					tokenFrom: "",
					name: name,
					value: Number(minSalePriceInWei.toString()) / 10 ** 18,
					status: "active",
					timestamp: Date.now(),
					blockNumber: 0,
					transactionHash: "",
					onlyTo: offerAddress,
				});
				forceUpdate({});
				socket.emit("OfferCreated");
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	}

	const handleClick = () => {
		setOfferToAddress(!offerToAddress);
	};
	return (
		<>
			<Modal
				show={openCreateOfferModal}
				onHide={handleCloseOfferModal}
				className='my-5'>
				<Modal.Header closeButton>
					<Modal.Title>MAKE AN OFFER</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: "0px" }}>
					<div style={{ width: "100%" }}>
						<p
							className='text-center'
							style={{ marginTop: "12px", marginBottom: "0" }}>
							You're about to list <span className='text-pink'>{name}</span> for
							sale
						</p>
					</div>

					<p className='input-label'>Offer Amount</p>

					<div className='bid-input'>
						<input
							step='any'
							type='number'
							min={typeof currentBid === "number" ? currentBid : "0.01"}
							value={offerAmount}
							onChange={(e) => {
								setOfferAmount(e.target.value);
							}}
							className='select-token-wrapper deposit'
						/>
						<label className='text-pink input-label'>ETH</label>
					</div>

					<div className='flex'>
						<label className='input-label'>Offer to Address</label>
					</div>

					<>
						<label
							className='switch'
							style={{
								marginBottom: "16px",
							}}>
							<input
								type='checkbox'
								checked={offerToAddress}
								onClick={handleClick}
							/>
							<span className='slider round'></span>
						</label>
					</>

					<div>
						{offerToAddress ? (
							<>
								<label className='input-label'>Address</label>

								<input
									placeholder='Wallet Address'
									type='string'
									value={offerAddress}
									onChange={(e) => {
										setOfferAddress(e.target.value);
									}}
									className='select-token-wrapper offer-address'
									style={{
										color: "#fc4ba3",
										fontSize: "20px",
										backgroundColor: "#fff",
									}}
								/>
							</>
						) : null}
					</div>
				</Modal.Body>
				<Modal.Footer>
					{approval ? (
						<button
							className='offer-btn'
							style={{
								padding: "6px 24px",
								width: "208px",
								fontWeight: "300",
								fontSize: "1rem",
							}}
							disabled={
								offerToAddress
									? !validAddress
									: +offerAmount <= 0
									? true
									: false
							}
							onClick={
								offerToAddress ? offerNameForSaleToAddress : offerNameForSale
							}>
							{isLoading ? (
								<Spinner animation='border' role='status'></Spinner>
							) : (
								"CREATE OFFER"
							)}
						</button>
					) : (
						<button
							onClick={approveTransfer}
							className='offer-btn'
							style={{
								padding: "6px 24px",
								width: "208px",
								fontWeight: "300",
								fontSize: "1rem",
							}}
							disabled={
								offerToAddress
									? !validAddress
									: +offerAmount <= 0
									? true
									: false
							}>
							{awaitingApproval ? (
								<Spinner animation='border' role='status'></Spinner>
							) : (
								"APPROVE"
							)}
						</button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CreateOfferModal;
