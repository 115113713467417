import Header from '../components/Header/Header'
import TokenBanner from '../components/TokenBanner/TokenBanner'
import RnmToken from '../components/RnmToken/RnmToken'
import WhyNameNft from '../components/WhyNameNFT/WhyNameNft'
import RnmTokenUsed from '../components/RnmTokenUsed/RnmTokenUsed'
import EarnBoxList from '../components/Earn/EarnBoxList'
import Featured from '../components/Featured/Featured'
import BecomeProvider from '../components/BecomeProvider/BecomeProvider'
import Projects from '../components/Projects/Projects'
import Cta from '../components/Cta/Cta'
import Footer from '../components/CallToAction/CallToAction'
import { useState, useEffect } from 'react'

const HomeScreen = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 500
    let heightToShowFrom = 4500
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    /*	
		if (winScroll < heightToHideFrom || winScroll > heightToShowFrom) { 
			 setIsVisible(false);
		} else {
			 setIsVisible(true);
		} 
		*/
  }

  return (
    <>
      {isVisible && <Footer />}
      <Header />
      <TokenBanner />
      <RnmTokenUsed />
      <EarnBoxList />
      <Featured />
      <BecomeProvider />
      <Projects />
      <Cta
        title="FOLLOW US ON MEDIUM"
        url="https://medium.com/nft-registry"
        classN="cta-section"
      />
    </>
  )
}

export default HomeScreen
