import { nftrAxios } from "./axiosInstance";
import { DataEntity, FetchNamesResponse } from "./Names";
import { IS_DEV } from "../constants";

async function fetchNames(): Promise<DataEntity[]> {
  let results: DataEntity[] = [];
  for (let i = 0; i < 100000; i = i + 1000) {
    const body = {
      skip: i,
      take: 1000,
      chainId: IS_DEV ? 11155111 : 1,
    };
    const { data } = await nftrAxios.post<FetchNamesResponse>(
      "/fetch-names",
      body
    );
    results = [...results, ...data.data];
    if (data.data.length < 1000) {
      break;
    }
  }
  return results;
}

export default fetchNames;
