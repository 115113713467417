import { nftrAxios } from "./axiosInstance";

async function fetchNFTImages(
  contractAddress: string,
  page?: number,
  tokenIds?: number[]
) {
  const body = {
    page: page,
    address: contractAddress,
    tokenIds: tokenIds,
  };
  return nftrAxios.post("/fetch-nfts", body).then((res) => res.data);
}

export default fetchNFTImages;
