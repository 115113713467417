import React, { useState, ReactNode } from "react";
import "./Tabs.css";

export const Tab: React.FC<TabProps> = ({ label, children }) => {
	return <div className='tab-content'>{children}</div>;
};

interface TabsProps {
	children: React.ReactNode;
	defaultLabel?: String;
	className?: string;
}

const Tabs: React.FC<TabsProps> = ({
	children,
	defaultLabel = "Assets",
	className = "",
}) => {
	const [activeTab, setActiveTab] = useState(defaultLabel);

	const handleTabClick = (label: string) => {
		setActiveTab(label);
	};

	return (
		<div className='tabs-custom'>
			<ul className={`tab-list-custom text-green ${className}`}>
				{React.Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						return (
							<li
								className={`tab-custom  ${className} ${
									child.props.label === activeTab ? "active-tab-custom" : ""
								}`}
								onClick={() => handleTabClick(child.props.label)}>
								{child.props.label}
							</li>
						);
					}
					// return null;
				})}
			</ul>
			<div className='tab-contents-custom'>
				{React.Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						return (
							<Tab label={child.props.label}>
								{child.props.label === activeTab && child.props.children}
							</Tab>
						);
					}
					// return null;
				})}
			</div>
		</div>
	);
};

export default Tabs;

interface TabProps {
	label: string;
	children: ReactNode;
}
