import { createContext, useContext, useState, useEffect } from "react";
import { fetchWalletContents } from "src/apis";
import { mapWalletData } from "./mapWalletData";
import { IS_DEV } from "../constants";
import { Web3OnboardContext } from "./Web3OnboardContext";

const initialState = {};

export const WalletContentsDataContext = createContext(initialState);

// const getOverrideData = function () {
//   if (!IS_DEV) {
//     return undefined;
//   } else {
//     return JSON.parse(process.env.REACT_APP_OWNERSHIP_OVERRIDE || null);
//   }
// };

const WalletContentsDataProvider = ({ children }) => {
  const { address } = useContext(Web3OnboardContext);


  const [data, setData] = useState({ ...initialState });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (address) {
      setIsLoading(true)
      //fetch stuff and update state
      fetchWalletContents(address.toLowerCase()) //0xAEC4075e4fc8cE829BC4f0cFD5e2fA10dd2b12D1
        .then((res) => {
          // const nftData = mapWalletData(res.data.data, getOverrideData());
          setData({ nfts: res.data.data });
          setIsLoading(false)
        })
        .catch((error) => {
          setIsError(true);
          console.log(error);
          setIsLoading(false)
        });
    }
  }, [address]);

  return (
    <WalletContentsDataContext.Provider
      value={{ data, isLoading, isError }}
    >
      {/* {isError || (address && !data.nfts) ? (
        <input
          className="loading-text"
          style={{ postion: "fixed", top: "0px", left: "0px", zIndex: 3000 }}
          readOnly
          value={
            isError ? "Error Loading Wallet" : "Loading Wallet Contents..."
          }
        ></input>
      ) : null} */}
      {children}
    </WalletContentsDataContext.Provider>
  );
};

export default WalletContentsDataProvider;
