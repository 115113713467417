import React, { useContext, useEffect, useMemo, useState } from "react";
import { ISelectedNFT, IToken } from "./interface";
import SearchNFTOptions from "src/components/Common/SelectReceipient";
import { ISelectNamedNFT } from "src/components/Common/SearchNFTOptions/SearchByName";
import { TokenboundClient } from "@tokenbound/sdk";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { getTokenBoundAccount, transfer, transferNFT } from "./utils";
import { useMessageStore } from "src/store/message.store";

const TBATransferModal: React.FC<TBATransferModalProps> = ({
	isOpen,
	onClose,
	tokenToTransfer,
	account,
	accountDeployed,
	selectedNFT,
	action,
	senderId,
}) => {
	const modalDisplay = isOpen ? "block" : "none";
	const [amount, setAmount] = useState("");
	const [receiverAddress, setReceiverAddress] = useState<string | undefined>();
	const [selected, setSelected] = useState<ISelectNamedNFT | null>(null);
	const { userSigner } = useContext(Web3OnboardContext);
	const [address, setAddress] = useState("");
	const { setNftToMessage, updateName, setConversation } = useMessageStore(
		(state) => ({
			setNftToMessage: state.actions.setNftToMessage,
			updateName: state.actions.updateName,
			setConversation: state.actions.setConversation,
		})
	);

	let authToken = localStorage.getItem("authToken");
	if (authToken !== null) {
		authToken = JSON.parse(authToken)[address];
	} else {
		authToken = "authToken";
	}

	const tokenboundClient = useMemo(() => {
		return new TokenboundClient({
			signer: userSigner,
			chainId: 1,
		});
	}, [userSigner]);

	useEffect(() => {
		(async function () {
			if (!selected && !address) {
				setReceiverAddress(undefined);
			}
			if (selected) {
				const receiverAddress = await getTokenBoundAccount(
					selected,
					tokenboundClient
				);
				setReceiverAddress(receiverAddress);
			}
			if (address && !selected) {
				setReceiverAddress(address);
			}
		})();
	}, [address, selected, tokenboundClient]);

	async function sendToken() {
		if (receiverAddress && account && accountDeployed && tokenToTransfer) {
			await transfer(
				account as `0x${string}`,
				receiverAddress as `0x${string}`,
				tokenToTransfer,
				tokenboundClient,
				amount.toString()
			);
			onClose();
		}
	}

	async function sendNFT() {
		if (selectedNFT && account && receiverAddress) {
			await transferNFT(
				selectedNFT,
				receiverAddress as `0x${string}`,
				tokenboundClient,
				account
			);
			onClose();
		}
	}

	async function messageNFT() {
		try {
			if (action === "MESSAGING" && selected) {
				const updated = {
					contractAddress: selected.contractAddress,
					tokenId: selected.tokenId,
					name: "",
					imageUrl: selected.imageUrl,
					timestamp: "",
					_id: selected.contractAddress,
				};

				setNftToMessage(updated);
				await updateName(updated.contractAddress, updated.tokenId);

				if (senderId) {
					await setConversation(senderId);
				}
			}
		} catch (error) {
			console.log(error);
		}
		onClose();
	}
	return (
		<div className='modal' style={{ display: modalDisplay }}>
			<div className='tba-modal-content'>
				{tokenToTransfer ? (
					<>
						<div className='tba-modal-heading'>
							<h4>Transfer {tokenToTransfer.symbol}</h4>
							<span className='close-button' onClick={onClose}>
								&times;
							</span>
						</div>
						<h5>Balance</h5>
						<div className='token-balance'>
							<div className='flex-row-center'>
								<img
									src={tokenToTransfer.logoURI}
									className='token-images'
									alt={tokenToTransfer.symbol}
								/>
								<p>{tokenToTransfer.symbol}</p>
							</div>
							<p>{tokenToTransfer.balance.toFixed(4)}</p>
						</div>

						<div className='input-with-btn'>
							<input
								type='text'
								className='select-recipient-input cutom-contract-input tba-amount-input'
								placeholder='0.00'
								onChange={(e) => setAmount(e.target.value)}
								value={amount}
							/>
							<button
								className='input-btn'
								onClick={() => setAmount(tokenToTransfer.balance.toString())}>
								MAX
							</button>
						</div>
					</>
				) : selectedNFT ? (
					<>
						<div className='tba-modal-heading'>
							<h4>
								Transfer {selectedNFT.collectionName} #{selectedNFT.tokenId}
							</h4>
							<span className='close-button' onClick={onClose}>
								&times;
							</span>
						</div>
					</>
				) : null}

				{action === "MESSAGING" ? (
					<div className='select-recipient'>
						<div className='d-flex align-items-center justify-content-between'>
							<h5>Select Recipient</h5>
							<span className='close-button' onClick={onClose}>
								&times;
							</span>
						</div>
						<SearchNFTOptions selected={selected} setSelected={setSelected} />
					</div>
				) : (
					<div className='select-recipient'>
						<h5>Select Recipient</h5>
						<SearchNFTOptions
							selected={selected}
							setSelected={setSelected}
							setAddress={setAddress}
							address={address}
						/>
					</div>
				)}
				{/* <div className='select-recipient'>
					<h5>Select Recipient</h5>
					<SearchNFTOptions
						selected={selected}
						setSelected={setSelected}
						setAddress={setAddress}
						address={address}
					/>
				</div> */}
				<button
					className='tba-transfer-btn'
					onClick={
						tokenToTransfer
							? sendToken
							: selectedNFT
							? sendNFT
							: action === "MESSAGING"
							? messageNFT
							: onClose
					}>
					{tokenToTransfer || selectedNFT ? "SEND" : "MESSAGE"}
				</button>
			</div>
		</div>
	);
};

export default TBATransferModal;

interface TBATransferModalProps {
	isOpen: boolean;
	onClose: () => void;
	tokenToTransfer?: IToken;
	account: `0x${string}` | null;
	accountDeployed: boolean;
	selectedNFT?: ISelectedNFT;
	nftToMessage?: any;
	action?: string;
	senderId?: string;
}

export interface SelectMessagingRecipient {
	contractAddress: string;
	imageUrl: string;
	name: string;
	timestamp: string;
	tokenId: string;
	_id: string;
}
