import axios from "axios";
import React, { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
const baseURL = process.env.REACT_APP_API_URL_BASE;

const ConnectEmailModal = ({
	openEmailModal,
	setOpenEmailModal,
	name = null,
	contractAddress,
	tokenId,
}: any) => {
	const [email, setEmail] = useState("");
	const [error, setError] = useState<string | null>(null);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const { address } = useContext(Web3OnboardContext);

	async function submitEmail() {
		let authToken = JSON.parse(localStorage.getItem("authToken") as string);

		authToken = authToken[address];

		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authToken}`,
		};
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (emailPattern.test(email)) {
			try {
				if (contractAddress && tokenId) {
					setLoading(true);
					const res = await axios.post(
						`${baseURL}name/email`,
						{
							name,
							contractAddress,
							tokenId,
							email,
							address,
						},
						{
							headers: headers,
						}
					);
					if (res.status === 200) {
						setSubmitted(true);
						setLoading(false);

						setTimeout(() => {
							setOpenEmailModal(false);
						}, 3000);
					}
				} else {
					const res = await axios.post(
						`${baseURL}wallet/email`,
						{
							email,
							address,
						},
						{
							headers: headers,
						}
					);

					if (res.status === 200) {
						setSubmitted(true);
						setLoading(false);

						setTimeout(() => {
							setOpenEmailModal(false);
						}, 3000);
					}
				}
			} catch (error) {
				setSubmitted(false);
				setLoading(false);
				console.log(error);
				setOpenEmailModal(false);
			}
		} else {
			console.log("invalid email");
			setError("invalid email");
		}
	}

	return (
		<>
			<Modal
				style={{ top: "150px" }}
				show={openEmailModal}
				onHide={() => {
					setOpenEmailModal(false);
				}}>
				<Modal.Header closeButton>
					<Modal.Title>LINK EMAIL</Modal.Title>
				</Modal.Header>

				<Modal.Body style={{ padding: "0px", marginBottom: "32px" }}>
					<div
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							marginTop: "1rem",
						}}>
						{/* email input */}

						{submitted ? (
							<p
								className='text-pink'
								style={{ margin: "1rem auto 0 auto", textAlign: "center" }}>
								Please check your email for a verification link
							</p>
						) : (
							<>
								<input
									placeholder='Email'
									style={{ marginBottom: "0px", marginRight: "48px" }}
									type='email'
									className='select-token-search'
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>

								<button
									onClick={submitEmail}
									className='offer-btn'
									style={{
										padding: "6px 24px",
										width: "208px",
										fontWeight: "300",
										fontSize: "1rem",
										marginTop: "0",
									}}>
									{loading ? (
										<Spinner animation='border' role='status'></Spinner>
									) : (
										"SUBMIT"
									)}
								</button>
							</>
						)}
					</div>

					{error && (
						<p
							className='text-white'
							style={{ marginTop: "1rem", marginBottom: "0px" }}>
							{error}
						</p>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ConnectEmailModal;
