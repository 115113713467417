import { useEffect } from 'react'
import { WOW } from 'wowjs'
import { Container, Row, Col, Form, Image } from 'react-bootstrap'
import airdrop from '../../assets/data/airdrop.json'

const InviteComponent = (props) => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])

  function check_eligibility(address) {
    var address_array = JSON.stringify(airdrop)
    if (address_array.includes(address.toLowerCase())) {
      return true
    } else {
      return false
    }
  }

  const address = props.address
  var image_tag = '<Image className="pink-bar" src="./img/pink-bar.png" />'

  return (
    <section className="invite-section">
      <div className="invite-shapes position-relative">
        <Image
          src="./img/invite-shape-left.png"
          className="invite-shape-left position-absolute"
        />
        <Image
          src="./img/invite-shape-right.png"
          className="invite-shape-right position-absolute"
        />
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="earn-content text-center wow fadeInUp">
              <h1>AIRDROP</h1>
              <p style={{ marginTop: 40 }}>
                2,050 RNM for 60,000 NFTR participants
              </p>
              <p>claimable upon NFTR phase 2 (RNM) launch</p>
              {/*
              <div
                className="nftr-text-box wow fadeInLeft"
                style={{ marginTop: 50 }}
              >
                {address ? (
                  check_eligibility(address) ? (
                    <Image
                      className="pink-bar-2"
                      src="./img/pink-bar.png"
                      style={{ marginBottom: 0 }}
                    />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {address ? (
                  <h2>
                    <strong>
                      {check_eligibility(address)
                        ? 'YOU ARE ELIGIBLE'
                        : 'YOU ARE NOT ELIGIBLE :('}
                    </strong>
                  </h2>
                ) : (
                  <h2>Connect above to see eligibility</h2>
                )}

                {address ? (
                  check_eligibility(address) ? (
                    <Image className="pink-bar-2" src="./img/pink-bar.png" />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
                */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default InviteComponent
