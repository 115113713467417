import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import '../Banner/banner.css'

const Banner = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <>
      <section className="banner h-100">
        <div className="banner-shapes">
          <Image
            className="banner-shape-left banner-shape position-absolute wow fadeIn"
            src="./img/left-banner-shape.png"
            data-wow-delay="0.7s"
          />
          <Image
            className="banner-shape-right banner-shape position-absolute wow fadeIn"
            src="./img/right-banner-shape.png"
            data-wow-delay="0.7s"
          />
        </div>
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg={10}>
              <div className="banner-text text-center">
                <h1 className="text-green wow fadeInUp">
                  THE DECENTRALIZED <span className="text-pink">NFT</span>{' '}
                  <span className="text-white">NAME</span>{' '}
                  <span className="text-pink">R</span>egistry
                </h1>
                <Image className="wow fadeInUp" src="./img/range-bar.png" />
                <div className="banner-button-group">
                  <Link
                    to="/lets-browse"
                    className="bordered-button wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{ fontSize: 30 }}
                  >
                    NAME YOUR NFT
                  </Link>
                </div>
                <h1 className="text-blue wow fadeInUp">↓ TO UNLOCK ↓</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Banner
