import { format } from "timeago.js";
import "./RobotDetails.css"
import placeholder from "../../../assets/images/customplaceholder.png";
import { Image } from 'react-bootstrap';

const Message = ({ message, own, senderImage, recipientImage, selected, names, recipientAddress, senderAddress }) => {

  const { contractAddress, tokenId } = selected
  const nftName = names.find(item => item.contractAddress.toLowerCase() === contractAddress.toLowerCase() && item.tokenId.toString() === tokenId.toString())
  const name = nftName ? nftName.name : null


  const blur = message.members && !message.members?.some(item => item.toLowerCase() === senderAddress.toLowerCase()) ? true : false
  const imageUrl =
    (selected && contractAddress && tokenId) &&
    `${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${contractAddress}/${tokenId}`;

  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        {
          own ?
            <>
              <p className={`messageText ${blur ? "message-blur" : ""}`}>
                {blur ? btoa(message.text) : message.text}</p>
              <Image

                className="messageImg"
                src={own ? senderImage.image2 : recipientImage?.image2 === null ? placeholder : recipientImage?.image2}
                alt=""

                onError={function (error) {
                  error.target.src = placeholder;
                }} />
            </>
            :
            <>
              {/* <Tooltip
                content={name}
                direction="top"
                id="opensea-tooltip"
                marginLeft="0px"
              > */}
              <a href={imageUrl} target='_blank' rel='noreferrer'>

                <img
                  className="messageImg"
                  src={own ? senderImage.image1 : recipientImage?.image2 === null ? placeholder : recipientImage?.image2}

                  onError={function (error) {
                    error.target.src = placeholder;
                  }}
                  alt=""
                  style={{ marginRight: "10px", marginLeft: "0px" }}
                />
              </a>
              <p className={`messageText ${blur ? "message-blur" : ""}`}>
                {message.text}</p>
            </>
        }
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div >
  )
}

export default Message