import { useContext, useState, useEffect, useCallback } from "react";
import { IS_DEV } from "src/constants";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";

let intervalIds = {};

function useGetName(contractAddress, tokenId, updateInterval) {
  const [name, setName] = useState("");
  const { readContracts } = useContext(Web3OnboardContext);
  //const [loop, setLoop] = useState(null);

  const updateName = () => {
    let contractAddressToUse = contractAddress;
    if (
      !contractAddress ||
      tokenId === undefined ||
      !readContracts ||
      !readContracts.NFTRegistry
    ) {
      return;
    }
    if (IS_DEV) {
      if (
        contractAddress.toLowerCase() ===
        "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb"
      ) {
        contractAddressToUse = "0xc8492446fB574447a9a2134f67D26b082bc1272f";
      } else if (
        contractAddress.toLowerCase() ===
        "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d"
      ) {
        contractAddressToUse = "0x388d7bd34dd59F62bC93C5066ff6c468e68eaaA7";
      }
    }

    if ((contractAddressToUse, tokenId)) {
      readContracts.NFTRegistry.tokenName(contractAddressToUse, tokenId)
        .then((res) => {
          setName(res);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    updateName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, tokenId, readContracts]);

  useEffect(() => {
    if (updateInterval) {
      intervalIds[contractAddress + tokenId] = setInterval(() => {
        updateName();
      }, updateInterval);
    }
    return () => {
      clearInterval(intervalIds[contractAddress + tokenId]);
    };
  }, []);

  return { name, updateName, setName };
}

export default useGetName;
