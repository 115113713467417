import { ChangeEvent, useRef } from "react";

interface IProps {
  updateNFTs: (index: any) => void;
  updateSelectedContractAndFetchImages: (contract: string) => void;
  contract: string;
  walletTokenIdLookup: any;
}

let timeoutId: NodeJS.Timeout;

function IdSearch({
  updateNFTs,
  updateSelectedContractAndFetchImages,
  contract,
  walletTokenIdLookup,
}: IProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    walletTokenIdLookup.current = event.target.value;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      // updateNFTs(Number(event.target.value));
      updateNFTs(event.target.value);

      // setTimeout(() => {
      //   if (inputRef.current) {
      //     inputRef.current.value = "";
      //   }
      // }, 500);
    }, 1000);
  };

  const clearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      walletTokenIdLookup.current = null;
      sessionStorage.removeItem("lastPageNumber");
      updateSelectedContractAndFetchImages(contract);
    }
  };

  return (
    <>
      <label htmlFor="id-search">
        ID:
        <input
          ref={inputRef}
          onChange={onChange}
          placeholder="TOKEN ID"
          type="text"
          className="id-search"
          id="id-search"
        ></input>
      </label>
      <span onClick={clearSearch}>
        {inputRef.current?.value ? (
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        ) : null}
      </span>
    </>
  );
}

export default IdSearch;
