import { useState } from "react";
import { Image } from "react-bootstrap";
import { useTokenBoundAccount } from "src/hooks/useTokenBoundAccount";
import { IToken, ITokenBoundAccountAssets } from "./interface";
import TBATransferModal from "./TBATransferModal";
import "src/components/Common/SelectReceipient.css";

const TokenBoundAccountAssets = ({
	contractAddress,
	tokenId,
	owned,
	tokens,
}: ITokenBoundAccountAssets) => {
	const [tokenToTransfer, setTokenToTransfer] = useState<IToken | null>(null);

	const { account, loading, accountDeployed } = useTokenBoundAccount(
		contractAddress,
		tokenId
	);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<div>
			{tokenToTransfer && (
				<TBATransferModal
					isOpen={isModalOpen}
					onClose={closeModal}
					tokenToTransfer={tokenToTransfer}
					account={account}
					accountDeployed={accountDeployed}
				/>
			)}
			{!loading ? (
				<>
					<div className='d-flex flex-column asset-list'>
						{tokens.map((item: IToken, index: number) => (
							<>
								{item.address &&
								item.balance &&
								item.decimal &&
								item.logoURI &&
								item.logoURI ? (
									<div key={index} className='d-flex asset-details'>
										<div className='flex-row-center'>
											{item.logoURI && item.balance > 0 ? (
												<>
													<Image src={item.logoURI} className='token-images' />
													<div>
														{item.symbol
															? item.symbol
															: "Nameless".toUpperCase()}
													</div>
												</>
											) : null}
										</div>

										<div className='flex-row-center'>
											<p>{item.balance.toFixed(4)}</p>

											{owned && accountDeployed ? (
												<button
													className='tba-send-button'
													onClick={() => {
														setTokenToTransfer(item);
														openModal();
													}}>
													Transfer
												</button>
											) : null}
										</div>
									</div>
								) : null}
							</>
						))}
					</div>
				</>
			) : null}
		</div>
	);
};

export default TokenBoundAccountAssets;
