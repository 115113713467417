import { ReactNode, createContext } from "react";
import { io } from "socket.io-client";

const baseURL = process.env.REACT_APP_API_URL_BASE as string;

export const SocketContext = createContext<any>(undefined);

const SocketContextProvider = ({ children }: { children: ReactNode }) => {
  const socket = io(baseURL);

  const valuesObject = { socket };

  return (
    <SocketContext.Provider value={valuesObject}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
