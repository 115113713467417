import { Circles } from "react-loader-spinner";

const Loader = ({ color }: { color: string }) => (
  <div className="screen-blocker-div">
    <div className="loader-div">
      <Circles color={color} ariaLabel="loading-indicator" />
    </div>
  </div>
);

export default Loader;
