import { Tooltip, OverlayTrigger } from "react-bootstrap";

import { QuestionCircle } from "react-bootstrap-icons";
interface IProps {
  namingPrice: number | undefined;
  eth: number;
  weth: number;
  wethAllowance: number;
  className: string | undefined;
  isSpecial: boolean;
  namingCurrency: number;
}

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    WETH will be used if your ETH balance is less than the cost of the
    transaction.
    <br />
    <br />
    Some ETH is always reserved for gas fees.
  </Tooltip>
);
function getNamingCurrency(currency: number): "ETH" | "NamingCredit" | "RNM" {
  if (currency === 1) return "ETH";
  if (currency === 2) return "NamingCredit";
  else return "RNM";
}

function NamePrice({
  namingPrice,
  eth,
  weth,
  wethAllowance,
  className,
  isSpecial,
  namingCurrency,
}: IProps) {
  if (!namingPrice) {
    return ``;
  }
  const totalPrice = Number(namingPrice.toFixed(3));
  const allCrypto = eth + weth;
  const currencyType = getNamingCurrency(namingCurrency);
  let result = "";
  if (currencyType === "NamingCredit") {
    result = "1 Naming Credit";
  } else if (eth > totalPrice) {
    result = `0${formatNumber(namingPrice)} ETH`;
  } else if (
    allCrypto > totalPrice &&
    allCrypto < wethAllowance &&
    eth <= 0.01
  ) {
    result = `0${formatNumber(totalPrice)} WETH`;
  } else if (
    allCrypto > totalPrice &&
    allCrypto < wethAllowance &&
    eth > 0.01
  ) {
    const ethValue = formatNumber(eth - 0.01);
    result = `0${ethValue} ETH + 0${formatNumber(
      totalPrice - Number(ethValue)
    )} WETH`;
  } else if (allCrypto > totalPrice && wethAllowance < totalPrice) {
    result = `Approve WETH`;
  }
  if (isSpecial && result !== "Approve WETH" && result !== "") {
    result = result + ` + 1 GTK`;
  }
  if (!result) {
    return null;
  }
  return (
    <>
      <h4 className={className}>
        <span>{result}</span>
        {currencyType === "ETH" ? (
          <OverlayTrigger
            placement="bottom-start"
            delay={{ show: 100, hide: 100 }}
            overlay={renderTooltip}
          >
            <QuestionCircle
              color="#3eecc6"
              style={{ paddingLeft: "10px", paddingBottom: "5px" }}
            />
          </OverlayTrigger>
        ) : null}
      </h4>
    </>
  );
}
export default NamePrice;

function formatNumber(num: number) {
  const num3 = Number(num.toFixed(3));
  const num2 = Number(num.toFixed(2));
  if (num3 !== num2) {
    return num3.toFixed(3).slice(1);
  }
  return num2.toFixed(2).slice(1);
}
