import { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import SelectOwnNFT from "./SelectOwnNFT";
import { ethers } from "ethers";
import useGetName from "src/hooks/useGetName";
import { SocketContext } from "src/contexts/SocketContext";

const BuyNameModal = ({
  openBuyNameModal,
  setOpenBuyNameModal,
  selected,
  setSelected,
  name,
  activeOffer,
  NameMarketplace,
  address,
  setUpdateNameInput,
}: any) => {
  const { name: nftName } = useGetName(
    selected.contractAddress,
    selected.tokenId,
    1000
  );

  const [loading, setLoading] = useState(false);

  const { socket } = useContext(SocketContext);

  const allow =
    activeOffer &&
    activeOffer.onlyTo === "0x0000000000000000000000000000000000000000"
      ? true
      : activeOffer &&
        activeOffer.onlyTo !== "0x0000000000000000000000000000000000000000" &&
        activeOffer.onlyTo?.toLowerCase() === address.toLowerCase()
      ? true
      : false;
  async function buyName() {
    try {
      setLoading(true);
      const res = await NameMarketplace.buyName(
        name,
        selected.contractAddress,
        selected.tokenId.toString(),
        {
          value: ethers.utils.parseEther(activeOffer.value.toString()),
          gasLimit: 500000,
        }
      );

      setLoading(false);
      setOpenBuyNameModal(false);
      await res.wait();

      socket.emit("NameTransfer");
      socket.emit("NameBought", activeOffer);
      setUpdateNameInput(true);
    } catch (error) {
      console.log(error);
      setOpenBuyNameModal(false);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        show={openBuyNameModal}
        onHide={() => {
          setOpenBuyNameModal(false);
        }}
        className="my-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>BUY NAME</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div style={{ width: "100%" }}>
            {activeOffer && name ? (
              <p
                className="text-center"
                style={{ marginTop: "12px", marginBottom: "6px" }}
              >
                You're about to accept an offer to buy
                <span className="text-pink">&nbsp;{name}&nbsp;</span>
                for
                <span className="text-pink">
                  &nbsp;{activeOffer.value}&nbsp;ETH
                </span>
              </p>
            ) : null}

            <p className="input-label" style={{ marginTop: "24px" }}>
              Recipient NFT{" "}
            </p>
            <SelectOwnNFT
              selected={selected}
              setSelected={setSelected}
              top=""
              backgroundColor="#3fecc6"
              position="absolute"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {nftName ? (
            <p style={{ color: "#ffffff" }}>cannot bid with a named NFT</p>
          ) : null}
          <button
            onClick={buyName}
            className="offer-btn"
            style={{
              padding: "6px 24px",
              width: "208px",
              fontWeight: "300",
              fontSize: "1rem",
            }}
            disabled={
              !allow ||
              !!nftName ||
              !selected.contractAddress ||
              !selected.tokenId
            }
          >
            {loading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              "BUY NAME"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyNameModal;
