import { ethers } from "ethers";
import { useContext, useEffect, useState, useCallback } from "react";
import { IS_DEV } from "src/constants";
import { SocketContext } from "src/contexts/SocketContext";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import contracts from "src/contracts/external_contracts";
import isSpecialName from "src/helpers/isSpecialName";


let intervalIds = {};


function LiveName({ contractAddress, tokenId, updateInterval }) {
  const { readContracts } = useContext(Web3OnboardContext);
  const [name, setName] = useState("");
  //const [loop, setLoop] = useState(null);

  const updateName = useCallback(async () => {
    let contractAddressToUse = contractAddress;
    if (
      !contractAddress ||
      tokenId === undefined

    ) {
      return;
    }
    if (IS_DEV) {
      if (
        contractAddress.toLowerCase() ===
        "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb"
      ) {
        contractAddressToUse = "0xc8492446fB574447a9a2134f67D26b082bc1272f";
      } else if (
        contractAddress.toLowerCase() ===
        "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d"
      ) {
        contractAddressToUse = "0x388d7bd34dd59F62bC93C5066ff6c468e68eaaA7";
      }
    }
    // readContracts.NFTRegistry.tokenName(contractAddressToUse, tokenId)
    //   .then((res) => {
    //     setName(res);
    //   })
    //   .catch(console.error);


    const address = IS_DEV ? "0xCAAd09E013630697de8252505618c1619eb048f5" : "0xc662a8Ce36392d4B9489D0B59605a1874711BFc6"

    const network = IS_DEV ? "sepolia" : "mainnet"

    const baseProvider = new ethers.providers.InfuraProvider(
      network,
      "d34a8d5f505a4dfcafacadd4e019e771"
    );
    const NFTRegistry = new ethers.Contract(address, contracts[1].contracts.NFTRegistry.abi, baseProvider)


    NFTRegistry.tokenName(contractAddressToUse, tokenId).then(tokenName => {
      setName(tokenName);
    }).catch(error => console.log(error));




  }, [contractAddress, readContracts, tokenId])




  const { socket } = useContext(SocketContext)
  // name events
  useEffect(() => {
    socket.on("UpdateRobotDetails", () => {
      console.log("update live name");
      updateName();
    });
  }, [socket, updateName]);

  useEffect(() => {
    updateName();
  }, [contractAddress, tokenId, readContracts]);



  useEffect(() => {
    if (updateInterval) {
      intervalIds[contractAddress + tokenId] = setInterval(() => {
        updateName();
      }, updateInterval);
    }
    return () => {
      clearInterval(intervalIds[contractAddress + tokenId]);
    };
  }, []);

  const isSpecial = isSpecialName(name);

  let style = {
    opacity: name ? "1.0" : "0.6",
    color: "gold",
  };
  if (!isSpecial) delete style.color;

  return <p style={style}>{name || "UNNAMED"}</p>;
}
export default LiveName;
