
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { correct, cross } from 'src/assets/images'
import jwt from "jsonwebtoken"
import Loader from 'src/components/LetsBrowseScreen/Loader'
import axios from 'axios'
import { SocketContext } from 'src/contexts/SocketContext'
import { Web3OnboardContext } from 'src/contexts/Web3OnboardContext'

const baseURL = process.env.REACT_APP_API_URL_BASE;


const EmailConfirmationScreen = () => {
	const [tokenInValid, setTokenInValid] = useState(false)
	const [loading, setLoading] = useState(false)
	const [verified, setVerified] = useState(false)
	const [message, setMessage] = useState("verifying email ...")

	const { socket } = useContext(SocketContext);
	const { address } = useContext(Web3OnboardContext);
	const url = window.location.href;

	function getQueryParameters(url) {
		const queryString = url.split('?')[1];
		const params = new URLSearchParams(queryString);
		const parameters = {};

		for (let [key, value] of params) {
			parameters[key] = value;
		}

		return parameters;
	}

	const queryString = getQueryParameters(url);

	const { token } = queryString


	useEffect(() => {
		async function verifyEmail() {
			if (token) {
				try {
					const decoded = jwt.decode(token)
					console.log({ decoded });
					if (decoded) {
						const exp = decoded.exp
						const email = decoded.data.email
						const present = Math.floor(Date.now() / 1000)

						// if data.
						if (exp > present) {
							try {
								const { data } = await axios.post(`${baseURL}email/verify`, {
									token,
									email
								});
								if (data.verified) {
									setLoading(false)
									setVerified(true)
									setMessage("email verification successful")

									socket.emit("refreshemail", token);
									setTimeout(() => {
										window.close();
									}, 2000);
								}

								else {
									setLoading(false)
									setVerified(false)
									setMessage("email verification failed, please try again")
								}

								setLoading(false)

							} catch (error) {
								setLoading(false)
								console.log(error);
							}

						} else {
							setMessage("verification email expired")
							setTokenInValid(true)
							setLoading(false)
						}
						console.log({ decoded, exp, email });
					}

				} catch (error) {
					setLoading(false)
					setTokenInValid(true)
					setMessage("email verification failed, please try again")
					console.log(error);
				}

			}
		}

		verifyEmail()

	}, [address, socket, token])


	// check
	return (
		<section className="invite-section">
			<div className="invite-shapes position-relative">
				<Image
					src="./img/invite-shape-left.png"
					className="invite-shape-left position-absolute"
				/>
				<Image
					src="./img/invite-shape-right.png"
					className="invite-shape-right position-absolute"
				/>
			</div>
			<Container>
				<Row>
					<Col lg={12}>
						<div className="earn-content text-center wow fadeInUp">
							<p className='text-green'>
								{
									loading ? "verifying email ..." : message
								}
							</p>
							<div>
								{
									loading ? <Loader color={"#fc4ba3"} /> :
										!tokenInValid ? <Image style={{
										}} src={correct} alt='check mark' /> :
											verified ? <Image style={{
											}} src={correct} alt='check mark' /> : tokenInValid ? <Image style={{
											}} src={cross} alt='check mark' /> : null
								}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

// #3fecc6
export default EmailConfirmationScreen