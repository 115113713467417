import { useCallback, useEffect, useRef } from 'react';

const usePollAsync = <R = unknown>(fn: () => Promise<R>, interval: number, enabled: boolean = true) => {
  const runningCount = useRef(0);
  const timeout = useRef<number>();
  const mountedRef = useRef(false);
  const counter = useRef(0)

  const next = useCallback(
    (handler: TimerHandler) => {
      if (mountedRef.current && runningCount.current === 0) {
        timeout.current = window.setTimeout(handler, interval);
      }
    },
    [interval],
  );

  const run = useCallback(async () => {
    runningCount.current += 1;
    counter.current += 1;
    let result;

    try {
      result = await fn();
    } catch (error) {
      console.log(error);

    }
    runningCount.current -= 1;

    if (counter.current < 15) {
      next(run);
    }

    return result;
  }, [fn, next]);

  useEffect(() => {

    if (enabled) {
      mountedRef.current = true;
      run();
    }

    return () => {
      mountedRef.current = false;
      window.clearTimeout(timeout.current);
    };
  }, [enabled, run]);

  const flush = useCallback(() => {
    window.clearTimeout(timeout.current);
    return run();
  }, [run]);
  return flush;
};

export default usePollAsync;