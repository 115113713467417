import { useEffect } from "react";
import { WOW } from "wowjs";
import { Container, Row, Col, Image } from "react-bootstrap";

const PageNotFoundComponent = (props) => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);

  return (
    <section className="invite-section">
      <div className="invite-shapes position-relative">
        <Image
          src="./img/invite-shape-left.png"
          className="invite-shape-left position-absolute"
        />
        <Image
          src="./img/invite-shape-right.png"
          className="invite-shape-right position-absolute"
        />
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="earn-content text-center wow fadeInUp">
              <h1>404 ERROR</h1>
              <p>The page you were looking for doesn't exist :(</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PageNotFoundComponent;
