import { nftrAxios } from "./axiosInstance";
import { IS_DEV } from "../constants";

function fetchWalletContents(address: string) {
  const body = {
    address: address,
    chainId: IS_DEV ? 11155111 : 1,
  };
  return nftrAxios.post("/fetch-wallet-contents", body);
}

export default fetchWalletContents;
