import { Image } from "react-bootstrap";

const EarnBox = ({ title, img, desc }) => {
  return (
    <div className='service-box text-center'>
      <Image src={img} />
      <h3>{title}</h3>
      <p>{desc}</p>
    </div>
  );
};

export default EarnBox;
