import { useEffect } from "react";
import { WOW } from "wowjs";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./nftbanking.css";

const NFTBanking = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);
  return (
    <section className="nftbanking-section position-relative">
      <div className="pink-shape-wrapper">
        <Image
          className="pink-shape position-absolute"
          src="./img/pink-shape.png"
        />
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="rnm-token-used-content">
              <div className="nftr-text-box wow fadeInUp">
                <h2>
                  TOKEN BOUND <strong>ACCOUNTS</strong>
                </h2>
                <Image className="pink-bar" src="./img/pink-bar.png" />
                <p>
                  Since NFTs have become the basis of our web3 identity, 
                  it only makes sense for them to own wallets.
                  <br />
                  <br />
                  Enter ERC-6551, a new standard that allows NFTs to own accounts.
                  These accounts can be used for arbitrary on-chain actions, equivalent
                  to what Externally Owned Accounts (EOAs) can do. This means that
                  NFTs can now own tokens, send transactions, and even own other NFTs.
                  <br />
                  <br />
                  Soon, you'll be able to deploy ERC-6551 Token Bound Accounts (TBAs)
                  on NFTR on behalf of your NFTs and name each account by creating subdomains
                  to your NFTs' names; thus making TBAs easily reachable.
                  <br />
                </p>
              </div>
              <div className="nftbanking-thumbnail-wrapper">
                <Image
                  className="nftbanking-thumbnail wow fadeInRight"
                  src="./img/rnm-token-used.png"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NFTBanking;
