import axios from 'axios';
import { ethers } from 'ethers';
import getAlchemyMetadata from 'src/apis/getAlchemyMetadata';
import getNFTTokenIdOwners from 'src/apis/getNFTTokenIdOwners';
import contracts from 'src/contracts/external_contracts';
import formatIPFS from 'src/helpers/formatIPFS';
import { create } from 'zustand';

interface MessageState {
  selected: ISelected
  messages: IMessages[],
  allUserMessages: IMessages[],
  conversation: IConversation | null;
  recipientAddress: string;
  senderImage: IRecipient;
  recipientImage: IRecipient;
  actions: {
    updateName: (contractAddress: string, tokenId: string | number) => Promise<void>
    setNftToMessage: (selected: ISelected | null) => Promise<void>
    setConversation: (senderId: string) => Promise<void>
    addNewMessage: (message: IMessages) => Promise<void>
    setUserMessages: (userConversations: IConversation[]) => Promise<void>
  }
}

const baseURL = process.env.REACT_APP_API_URL_BASE;

export const useMessageStore = create<MessageState>(
  (set, get) => ({
    selected: {
      contractAddress: "",
      tokenId: "",
      name: "",
      imageUrl: "",
      timestamp: "",
      _id: ""
    },
    messages: [],
    allUserMessages: [],
    recipientImage: { image1: "", image2: "" },
    recipientAddress: "",
    senderImage: { image1: "", image2: "" },
    conversation: null,
    actions: {
      updateName: async (contractAddress: string, tokenId: string | number) => {
        try {
          const network = "mainnet"
          const address = "0xc662a8Ce36392d4B9489D0B59605a1874711BFc6"
          const baseProvider = new ethers.providers.InfuraProvider(
            network,
            "d34a8d5f505a4dfcafacadd4e019e771"
          );

          const NFTRegistry = new ethers.Contract(address, contracts[1].contracts.NFTRegistry.abi, baseProvider)
          const { selected } = get()
          const name = await NFTRegistry.tokenName(contractAddress, tokenId)

          console.log({ name });

          if (name) {
            const updated = { ...selected, name: name }
            set({ selected: updated })
          } else {
            const NFTContract = new ethers.Contract(
              contractAddress,
              ["function name() external view returns (string _name)"],
              baseProvider
            );
            const collectionName = await NFTContract.name();
            const name = `${collectionName} #${tokenId}`
            const updated = { ...selected, name: name }
            set({ selected: updated })
          }
        } catch (error) {
          console.log(error);
        }
      },
      setNftToMessage: async (selected) => {
        try {
          if (!selected) {
            const defaultSelected = {
              contractAddress: "",
              imageUrl: "",
              name: "",
              timestamp: "",
              tokenId: "",
              _id: ""
            }
            set({ selected: defaultSelected })

          } else {

            const { contractAddress, tokenId, imageUrl, timestamp, _id, name } = selected
            const updated = { contractAddress, tokenId, imageUrl, timestamp, _id, name }
            set({ selected: updated })

            const { data: metadata } = await getAlchemyMetadata(
              contractAddress,
              tokenId
            );
            const image1 = formatIPFS(metadata?.data?.media[0].raw);
            const image2 = formatIPFS(metadata?.data?.media[0].gateway);
            const updateImageUrl = { contractAddress, tokenId, imageUrl: image1, timestamp, _id, name }

            set({ selected: updateImageUrl })
            const images = { image1, image2 }
            set({ recipientImage: images })

            const { data } = await getNFTTokenIdOwners(
              contractAddress,
              tokenId
            );
            set({
              recipientAddress: data.data
            })
          }
        } catch (error) {
          console.log(error);
        }
      },
      setConversation: async (senderId) => {

        try {
          const { selected } = get()
          const receiverId = `${selected.contractAddress}${selected.tokenId}`.toLowerCase()

          console.log({ setConversation: "data", senderId, receiverId });

          const { data } = await axios.get<IConversation>(`${baseURL}conversations/find/${senderId}/${receiverId}`)

          set({ conversation: data })

          const { data: messages } = await axios.get(`${baseURL}messages/${data._id}`);

          set({ messages })
          const contractAddress = senderId.substring(0, 42);
          const tokenId = senderId.substring(42)

          const { data: imageData } = await getAlchemyMetadata(
            contractAddress,
            tokenId
          );
          if (imageData && imageData.data && imageData.data.media) {
            const image1 = formatIPFS(imageData?.data?.media[0].raw);
            const image2 = formatIPFS(imageData?.data?.media[0].gateway);

            const images = { image1, image2 }
            set({ senderImage: images })
          }
        } catch (error) {
          console.log(error);
        }
      },

      addNewMessage: async (message: IMessages) => {
        try {
          const { messages, allUserMessages } = get()
          const updated = [...messages, message]
          const allMessages = [...allUserMessages, message]

          set({ messages: updated })
          set({ allUserMessages: allMessages })
        } catch (error) {
          console.log(error);
        }
      },

      setUserMessages: async (conversations: IConversation[]) => {
        try {
          const userMessages: IMessages[] = []
          for (const conversation of conversations) {
            const { data: messages } = await axios.get(`${baseURL}messages/${conversation._id}`)

            for (const message of messages) {
              userMessages.push(message)
            }
          }

          set({ allUserMessages: userMessages })
        } catch (error) {
          console.log(error);
        }
      }
    },
  }),
);

interface ISelected {
  contractAddress: string;
  imageUrl: string;
  name: string;
  timestamp: string;
  tokenId: string;
  _id: string;
}

interface IConversation {
  _id: string;
  members: string[];
  status: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface IRecipient {
  image1: string;
  image2: string;
}

interface IMessages {
  _id: string;
  conversationId: string;
  sender: string;
  text: string;
  isRead: false;
  members: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}