import Label from "./AsyncSelectOptionLabel";
// the label field is so that the async select shows it properly

const curatedCollections = {
  BAYC: {
    _id: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
    // label field is just here so that the async select can render it as a "selected" option
    label: (
      <Label
        name={"Bored Ape Yacht Club"}
        imageUrl={
          "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s120"
        }
      />
    ),
    updated: 1645590869209,
    collectionLength: 10000,
    slug: "boredapeyachtclub",
    name: "BoredApeYachtClub",
    message: "",
    startingIndex: "0",
    needsUpdate: false,
    txCountYearly: 59438,
    txCountMonthly: 2861,
    txCountWeekly: 552,
    imageUrl:
      "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s120",
    openseaMetadata: {
      banner_image_url:
        "https://lh3.googleusercontent.com/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs=s2500",
      chat_url: null,
      created_date: "2021-04-22T23:14:03.967121",
      default_to_fiat: false,
      description:
        "The Bored Ape Yacht Club is a collection of 10,000 unique Bored Ape NFTs— unique digital collectibles living on the Ethereum blockchain. Your Bored Ape doubles as your Yacht Club membership card, and grants access to members-only benefits, the first of which is access to THE BATHROOM, a collaborative graffiti board. Future areas and perks can be unlocked by the community through roadmap activation. Visit www.BoredApeYachtClub.com for more details.",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "250",
      discord_url: "https://discord.gg/3P5K3dzgdB",
      display_data: { card_display_style: "contain" },
      external_url: "http://www.boredapeyachtclub.com/",
      featured: false,
      featured_image_url:
        "https://lh3.googleusercontent.com/RBX3jwgykdaQO3rjTcKNf5OVwdukKO46oOAV3zZeiaMb8VER6cKxPDTdGZQdfWcDou75A8KtVZWM_fEnHG4d4q6Um8MeZIlw79BpWPA=s300",
      hidden: false,
      safelist_request_status: "verified",
      image_url:
        "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s120",
      is_subject_to_whitelist: false,
      large_image_url:
        "https://lh3.googleusercontent.com/RBX3jwgykdaQO3rjTcKNf5OVwdukKO46oOAV3zZeiaMb8VER6cKxPDTdGZQdfWcDou75A8KtVZWM_fEnHG4d4q6Um8MeZIlw79BpWPA=s300",
      medium_username: null,
      name: "Bored Ape Yacht Club",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: "0xaae7ac476b117bccafe2f05f582906be44bc8ff1",
      require_email: false,
      short_description: null,
      slug: "boredapeyachtclub",
      telegram_url: null,
      twitter_username: "BoredApeYC",
      instagram_username: "boredapeyachtclub",
      wiki_url: null,
    },
    forceUpdate: false,
  },
  cryptopunks: {
    _id: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
    // label field is just here so that the async select can render it as a "selected" option
    label: (
      <Label
        name={"CryptoPunks"}
        imageUrl={
          "https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s130"
        }
      />
    ),
    updated: 1646017250389,
    collectionLength: 10000,
    slug: "cryptopunks",
    name: "CryptoPunks",
    message: "",
    startingIndex: "0",
    needsUpdate: false,
    txCountYearly: 59438,
    txCountMonthly: 2861,
    txCountWeekly: 552,
    imageUrl:
      "https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s130",
    openseaMetadata: { name: "CryptoPunks" },
    forceUpdate: false,
    assetsHavingImages: 10000,
    assetsMissingImages: 0,
    badCollectionLength: false,
  },
};
export default curatedCollections;
export const defaultCollection = curatedCollections["cryptopunks"];

// we show this collection if there was a data loading error
export const errorCollection = {
  _id: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
  // label field is just here so that the async select can render it as a "selected" option
  label: <Label name={"Error Loading Data"} imageUrl={""} />,
  updated: 1646017250389,
  collectionLength: 10000,
  slug: "cryptopunks",
  name: "CryptoPunks",
  message: "",
  startingIndex: "0",
  needsUpdate: false,
  txCountYearly: 59438,
  txCountMonthly: 2861,
  txCountWeekly: 552,
  imageUrl: "",
  openseaMetadata: { name: "CryptoPunks" },
  forceUpdate: false,
  assetsHavingImages: 10000,
  assetsMissingImages: 0,
  badCollectionLength: false,
};
