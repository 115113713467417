import { SelectMessagingRecipient } from "src/components/LetsBrowseScreen/RobotDetails/TokenBoundAccount/TBATransferModal";
import ActiveButton from "../ActiveButton";
import InactiveButton from "../InactiveButton";
import { ISelectNamedNFT } from "./SearchByName";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { useContext, useEffect, useState } from "react";
import { LoadedContractsDataContext } from "src/contexts";
import { useTokenBoundAccount } from "src/hooks/useTokenBoundAccount";

const SearchByWallet = ({
	active,
	setActive,
	setAddress,
	address,
	setSelected,
}: ISearchByWalletProps) => {
	const { injectedProvider } = useContext(Web3OnboardContext);
	const [ensAddress, setEnsAddress] = useState("");
	const [selectedNFT, setSelectedNFT] = useState({
		contractAddress: "",
		imageUrl: "",
		name: "",
		timestamp: 1692398378486,
		tokenId: "",
		_id: "",
	});

	const [data] = useContext<any>(LoadedContractsDataContext);

	const names = data.NamedData;

	const { account } = useTokenBoundAccount(
		selectedNFT.contractAddress as `0x${string}`,
		selectedNFT.tokenId
	);

	useEffect(() => {
		async function resolveName() {
			const ens = await injectedProvider.resolveName(address);
			setEnsAddress(ens);
		}
		if (address.indexOf(".eth") > 0) {
			resolveName();
		} else if (address.indexOf("nftr") > 0) {
			console.log({ tba: address });

			const name = address.split(".")[1];

			const found = names.find(
				(item: any) => item.name.toLowerCase() === name.toLowerCase()
			);

			setSelectedNFT(found);

			if (found && setSelected) {
				setSelected(found);
			}

			if (account) {
				setEnsAddress(account);
			}
		} else {
			setEnsAddress("");
		}
	}, [account, address, injectedProvider, names, setAddress, setSelected]);

	console.log({ account });

	return (
		<div>
			<div
				className='custom-radio-button'
				onClick={() => {
					setActive("ADDRESS");
					setAddress("");
					setEnsAddress("");
					if (setSelected) setSelected(null);
				}}>
				{active === "ADDRESS" ? <ActiveButton /> : <InactiveButton />}
				<div>
					<h4>Wallet Address</h4>
					<p>Wallet address to transfer to</p>
				</div>
			</div>
			{active === "ADDRESS" ? (
				<div className='select-recipient-container flex-col'>
					<input
						type='text'
						className='select-recipient-input'
						placeholder='WALLET ADDRESS'
						onChange={(e) => setAddress(e.target.value)}
						value={address}
					/>
					{ensAddress && (
						<a
							className='ens-address'
							href={`https://etherscan.io/address/${ensAddress}`}
							target='_blank'
							rel='noreferrer'>
							{ensAddress}
						</a>
					)}
				</div>
			) : null}
		</div>
	);
};

export default SearchByWallet;

interface ISearchByWalletProps {
	active: string;
	setActive: React.Dispatch<React.SetStateAction<string>>;
	setAddress: React.Dispatch<React.SetStateAction<string>>;
	address: string;
	setSelected?: React.Dispatch<
		React.SetStateAction<
			ISelectNamedNFT | SelectMessagingRecipient | any | null
		>
	>;
}
