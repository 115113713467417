import { DEFAULT_NETWORK } from "src/constants";

export const ERC20ABI = [
	{
		constant: true,
		inputs: [],
		name: "name",
		outputs: [
			{
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "_spender",
				type: "address",
			},
			{
				name: "_value",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "totalSupply",
		outputs: [
			{
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "_from",
				type: "address",
			},
			{
				name: "_to",
				type: "address",
			},
			{
				name: "_value",
				type: "uint256",
			},
		],
		name: "transferFrom",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "decimals",
		outputs: [
			{
				name: "",
				type: "uint8",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				name: "_owner",
				type: "address",
			},
		],
		name: "balanceOf",
		outputs: [
			{
				name: "balance",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "symbol",
		outputs: [
			{
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "_to",
				type: "address",
			},
			{
				name: "_value",
				type: "uint256",
			},
		],
		name: "transfer",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				name: "_owner",
				type: "address",
			},
			{
				name: "_spender",
				type: "address",
			},
		],
		name: "allowance",
		outputs: [
			{
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		payable: true,
		stateMutability: "payable",
		type: "fallback",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				name: "spender",
				type: "address",
			},
			{
				indexed: false,
				name: "value",
				type: "uint256",
			},
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: "from",
				type: "address",
			},
			{
				indexed: true,
				name: "to",
				type: "address",
			},
			{
				indexed: false,
				name: "value",
				type: "uint256",
			},
		],
		name: "Transfer",
		type: "event",
	},
];
const DAIABI = [
	{
		inputs: [
			{
				internalType: "uint256",
				name: "chainId_",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "src",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "guy",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: true,
		inputs: [
			{
				indexed: true,
				internalType: "bytes4",
				name: "sig",
				type: "bytes4",
			},
			{
				indexed: true,
				internalType: "address",
				name: "usr",
				type: "address",
			},
			{
				indexed: true,
				internalType: "bytes32",
				name: "arg1",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "bytes32",
				name: "arg2",
				type: "bytes32",
			},
			{
				indexed: false,
				internalType: "bytes",
				name: "data",
				type: "bytes",
			},
		],
		name: "LogNote",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "src",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "dst",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "Transfer",
		type: "event",
	},
	{
		constant: true,
		inputs: [],
		name: "DOMAIN_SEPARATOR",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "PERMIT_TYPEHASH",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "allowance",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "usr",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "balanceOf",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "usr",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "burn",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "decimals",
		outputs: [
			{
				internalType: "uint8",
				name: "",
				type: "uint8",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "guy",
				type: "address",
			},
		],
		name: "deny",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "usr",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "mint",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "src",
				type: "address",
			},
			{
				internalType: "address",
				name: "dst",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "move",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "name",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "nonces",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "holder",
				type: "address",
			},
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "nonce",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "expiry",
				type: "uint256",
			},
			{
				internalType: "bool",
				name: "allowed",
				type: "bool",
			},
			{
				internalType: "uint8",
				name: "v",
				type: "uint8",
			},
			{
				internalType: "bytes32",
				name: "r",
				type: "bytes32",
			},
			{
				internalType: "bytes32",
				name: "s",
				type: "bytes32",
			},
		],
		name: "permit",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "usr",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "pull",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "usr",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "push",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "guy",
				type: "address",
			},
		],
		name: "rely",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "symbol",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "totalSupply",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "dst",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "transfer",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				internalType: "address",
				name: "src",
				type: "address",
			},
			{
				internalType: "address",
				name: "dst",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "wad",
				type: "uint256",
			},
		],
		name: "transferFrom",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "version",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "wards",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
];

const GoldenTicketABI = [
	{
		inputs: [
			{
				internalType: "address",
				name: "ownerin",
				type: "address",
			},
			{
				internalType: "string",
				name: "namein",
				type: "string",
			},
			{
				internalType: "string",
				name: "symbolin",
				type: "string",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "numTickets",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "burnProceeds",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "nextMintPrice",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "nextBurnPrice",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "ticketSupply",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "newReserve",
				type: "uint256",
			},
		],
		name: "GoldenTicketsBurned",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "numTickets",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "pricePaid",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "nextMintPrice",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "nextBurnPrice",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "ticketSupply",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "mintFee",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "newReserve",
				type: "uint256",
			},
		],
		name: "GoldenTicketsMinted",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "from",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
		],
		name: "Transfer",
		type: "event",
	},
	{
		inputs: [],
		name: "MAX_SUPPLY",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "accumulatedFees",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "ownerin",
				type: "address",
			},
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
		],
		name: "allowance",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "balanceOf",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "minimumSupply",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "quantity",
				type: "uint256",
			},
		],
		name: "burnTickets",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "quantity",
				type: "uint256",
			},
		],
		name: "currentBurnPrice",
		outputs: [
			{
				internalType: "uint256",
				name: "price",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "quantity",
				type: "uint256",
			},
		],
		name: "currentMintPrice",
		outputs: [
			{
				internalType: "uint256",
				name: "price",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "decimals",
		outputs: [
			{
				internalType: "uint8",
				name: "",
				type: "uint8",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "subtractedValue",
				type: "uint256",
			},
		],
		name: "decreaseAllowance",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "getAccumulatedFees",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getOrphanedTicketFunds",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "getReserve",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "supply",
				type: "uint256",
			},
		],
		name: "getSingleBurnPrice",
		outputs: [
			{
				internalType: "uint256",
				name: "price",
				type: "uint256",
			},
		],
		stateMutability: "pure",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "mintNumber",
				type: "uint256",
			},
		],
		name: "getSingleMintPrice",
		outputs: [
			{
				internalType: "uint256",
				name: "price",
				type: "uint256",
			},
		],
		stateMutability: "pure",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "spender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "addedValue",
				type: "uint256",
			},
		],
		name: "increaseAllowance",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "quantity",
				type: "uint256",
			},
		],
		name: "mintTickets",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [],
		name: "name",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "nftrAddress",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "numberUsedTickets",
		outputs: [
			{
				internalType: "uint256",
				name: "nftrTicketBalance",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "reserve",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_nftrAddress",
				type: "address",
			},
		],
		name: "setNFTRegistryAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "symbol",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "totalSupply",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "recipient",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "transfer",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "sender",
				type: "address",
			},
			{
				internalType: "address",
				name: "recipient",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "transferFrom",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
];
export const NFTRegistryABI = [
	{
		inputs: [
			{
				internalType: "address",
				name: "_punksAddress",
				type: "address",
			},
			{
				internalType: "address",
				name: "_goldenTicketAddress",
				type: "address",
			},
			{
				internalType: "address",
				name: "_WETH",
				type: "address",
			},
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "_minNamingPriceEther",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "_minNamingPriceRNM",
				type: "uint256",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				indexed: true,
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "string",
				name: "newName",
				type: "string",
			},
			{
				indexed: false,
				internalType: "address",
				name: "sender",
				type: "address",
			},
			{
				indexed: false,
				internalType: "enum NFTRegistry.NamingCurrency",
				name: "namingCurrency",
				type: "uint8",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "currencyQuantity",
				type: "uint256",
			},
		],
		name: "NameChange",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "nftAddressFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenIdFrom",
				type: "uint256",
			},
			{
				indexed: true,
				internalType: "address",
				name: "nftAddressTo",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenIdTo",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "string",
				name: "name",
				type: "string",
			},
			{
				indexed: false,
				internalType: "address",
				name: "transferer",
				type: "address",
			},
		],
		name: "NameTransfer",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "namingPriceEther",
				type: "uint256",
			},
		],
		name: "NewNamingPriceEther",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "namingPriceRNM",
				type: "uint256",
			},
		],
		name: "NewNamingPriceRNM",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "protocolFeeRecipient",
				type: "address",
			},
		],
		name: "NewProtocolFeeRecipient",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "rnmNamingStartBlock",
				type: "uint256",
			},
		],
		name: "NewRnmNamingStartBlock",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		name: "TransferAllowed",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "owner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		name: "TransferDisAllowed",
		type: "event",
	},
	{
		inputs: [],
		name: "INFINITE_BLOCK",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MAX_ASSIGNABLE_NAMING_CREDITS",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MAX_NUMBER_CURATED_COLLECTIONS",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MAX_SPECIAL_NAMES_COUNT",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MAX_TOTAL_ASSIGNABLE_NAMING_CREDITS",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MIN_NAMING_PRICE_ETHER",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MIN_NAMING_PRICE_RNM",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "WETH",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		name: "allowTransfer",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "allowUpdatingFeeRecipient",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
			{
				internalType: "address",
				name: "",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		name: "allowances",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "user",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "assignNamingCredits",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
			{
				internalType: "string",
				name: "newName",
				type: "string",
			},
			{
				internalType: "enum NFTRegistry.NamingCurrency",
				name: "namingCurrency",
				type: "uint8",
			},
			{
				internalType: "uint256",
				name: "currencyQuantity",
				type: "uint256",
			},
		],
		name: "changeName",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
		],
		name: "curateCollection",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		name: "disallowTransfer",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		name: "firstNamed",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "string",
				name: "nameString",
				type: "string",
			},
		],
		name: "getTokenByName",
		outputs: [
			{
				components: [
					{
						internalType: "address",
						name: "collectionAddress",
						type: "address",
					},
					{
						internalType: "uint256",
						name: "tokenId",
						type: "uint256",
					},
				],
				internalType: "struct NFTRegistry.Token",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "goldenTicketAddress",
		outputs: [
			{
				internalType: "contract IERC20",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "holdFarmingAddress",
		outputs: [
			{
				internalType: "contract IHoldFarming",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "marketplaceAddress",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "namingCreditsAddress",
		outputs: [
			{
				internalType: "contract INamingCredits",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "namingPriceEther",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "namingPriceRNM",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "namingState",
		outputs: [
			{
				internalType: "enum NFTRegistry.NamingState",
				name: "",
				type: "uint8",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "numberCuratedCollections",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "numberSpecialNames",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "protocolFeeRecipient",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "punksAddress",
		outputs: [
			{
				internalType: "contract IPunks",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "rnmNamingStartBlock",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "rnmToken",
		outputs: [
			{
				internalType: "contract IRNM",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "contract IHoldFarming",
				name: "_holdFarmingAddress",
				type: "address",
			},
		],
		name: "setHoldFarmingAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_marketplaceAddress",
				type: "address",
			},
		],
		name: "setMarketplaceAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "contract INamingCredits",
				name: "_namingCreditsAddress",
				type: "address",
			},
		],
		name: "setNamingCreditsAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "contract IRNM",
				name: "_rnmToken",
				type: "address",
			},
		],
		name: "setRnmTokenAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "string[]",
				name: "_specialNames",
				type: "string[]",
			},
		],
		name: "setSpecialNames",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "shutOffAssignments",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "shutOffFeeRecipientUpdates",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		name: "specialNames",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "string",
				name: "str",
				type: "string",
			},
		],
		name: "toLower",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "pure",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		name: "tokenByName",
		outputs: [
			{
				internalType: "address",
				name: "collectionAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "tokenId",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		name: "tokenName",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "nftAddress",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "index",
				type: "uint256",
			},
		],
		name: "tokenNameByIndex",
		outputs: [
			{
				internalType: "string",
				name: "",
				type: "string",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "totalNumberAssignedCredits",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "nftAddressFrom",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "tokenIdFrom",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "nftAddressTo",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "tokenIdTo",
				type: "uint256",
			},
		],
		name: "transferName",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
		],
		name: "updateNamingCreditsProtocolFeeRecipient",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_namingPriceEther",
				type: "uint256",
			},
		],
		name: "updateNamingPriceEther",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_namingPriceRNM",
				type: "uint256",
			},
		],
		name: "updateNamingPriceRNM",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
		],
		name: "updateProtocolFeeRecipient",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_rnmNamingStartBlock",
				type: "uint256",
			},
		],
		name: "updateRnmNamingStartBlock",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "string",
				name: "str",
				type: "string",
			},
		],
		name: "validateName",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "pure",
		type: "function",
	},
	{
		inputs: [],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "withdrawRNM",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
];

const Weth9ABI = [
	{
		constant: true,
		inputs: [],
		name: "name",
		outputs: [
			{
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "guy",
				type: "address",
			},
			{
				name: "wad",
				type: "uint256",
			},
		],
		name: "approve",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "totalSupply",
		outputs: [
			{
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "src",
				type: "address",
			},
			{
				name: "dst",
				type: "address",
			},
			{
				name: "wad",
				type: "uint256",
			},
		],
		name: "transferFrom",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "wad",
				type: "uint256",
			},
		],
		name: "withdraw",
		outputs: [],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "decimals",
		outputs: [
			{
				name: "",
				type: "uint8",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				name: "",
				type: "address",
			},
		],
		name: "balanceOf",
		outputs: [
			{
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: true,
		inputs: [],
		name: "symbol",
		outputs: [
			{
				name: "",
				type: "string",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		constant: false,
		inputs: [
			{
				name: "dst",
				type: "address",
			},
			{
				name: "wad",
				type: "uint256",
			},
		],
		name: "transfer",
		outputs: [
			{
				name: "",
				type: "bool",
			},
		],
		payable: false,
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		constant: false,
		inputs: [],
		name: "deposit",
		outputs: [],
		payable: true,
		stateMutability: "payable",
		type: "function",
	},
	{
		constant: true,
		inputs: [
			{
				name: "",
				type: "address",
			},
			{
				name: "",
				type: "address",
			},
		],
		name: "allowance",
		outputs: [
			{
				name: "",
				type: "uint256",
			},
		],
		payable: false,
		stateMutability: "view",
		type: "function",
	},
	{
		payable: true,
		stateMutability: "payable",
		type: "fallback",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: "src",
				type: "address",
			},
			{
				indexed: true,
				name: "guy",
				type: "address",
			},
			{
				indexed: false,
				name: "wad",
				type: "uint256",
			},
		],
		name: "Approval",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: "src",
				type: "address",
			},
			{
				indexed: true,
				name: "dst",
				type: "address",
			},
			{
				indexed: false,
				name: "wad",
				type: "uint256",
			},
		],
		name: "Transfer",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: "dst",
				type: "address",
			},
			{
				indexed: false,
				name: "wad",
				type: "uint256",
			},
		],
		name: "Deposit",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: "src",
				type: "address",
			},
			{
				indexed: false,
				name: "wad",
				type: "uint256",
			},
		],
		name: "Withdrawal",
		type: "event",
	},
];
const NamingCreditsABI = [
	{
		inputs: [
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
			{
				internalType: "address",
				name: "_WETH",
				type: "address",
			},
			{
				internalType: "address",
				name: "_nftrAddress",
				type: "address",
			},
		],
		stateMutability: "nonpayable",
		type: "constructor",
	},
	{
		anonymous: false,
		inputs: [],
		name: "AssignerAssignmentsShutOff",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "assigner",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "AssignerCreditsAdded",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [],
		name: "AssignmentsShutOff",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "assigner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "receiver",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "CreditsAssigned",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "enum NamingCredits.BuyWithETH",
				name: "buyWithETH",
				type: "uint8",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "totalCost",
				type: "uint256",
			},
		],
		name: "CreditsBought",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "CreditsConsumed",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "protocolFeeRecipient",
				type: "address",
			},
		],
		name: "NewProtocolFeeRecipient",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "rnmAddress",
				type: "address",
			},
		],
		name: "RNMAddressSet",
		type: "event",
	},
	{
		inputs: [],
		name: "MAX_ASSIGNER_CREDITS",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MAX_BULK_ASSIGNMENT",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MAX_CREDITS_ASSIGNED",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "WETH",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "assigner",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "addAssignerCredits",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "allowUpdatingFeeRecipient",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "user",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "assignNamingCredits",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address[]",
				name: "user",
				type: "address[]",
			},
			{
				internalType: "uint256[]",
				name: "numberOfCredits",
				type: "uint256[]",
			},
		],
		name: "assignNamingCreditsBulk",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "assignerAssignmentsAllowed",
		outputs: [
			{
				internalType: "enum NamingCredits.AssignmentsAllowed",
				name: "",
				type: "uint8",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "assigners",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "assignmentsAllowed",
		outputs: [
			{
				internalType: "enum NamingCredits.AssignmentsAllowed",
				name: "",
				type: "uint8",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
			{
				internalType: "enum NamingCredits.BuyWithETH",
				name: "buyWithETH",
				type: "uint8",
			},
			{
				internalType: "uint256",
				name: "currencyQuantity",
				type: "uint256",
			},
		],
		name: "buyNamingCredits",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "credits",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "nftrAddress",
		outputs: [
			{
				internalType: "contract INFTRegistry",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "assigner",
				type: "address",
			},
		],
		name: "nullAssignerCredits",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "protocolFeeRecipient",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "sender",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "numberOfCredits",
				type: "uint256",
			},
		],
		name: "reduceNamingCredits",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "rnmAddress",
		outputs: [
			{
				internalType: "contract IRNM",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_rnmAddress",
				type: "address",
			},
		],
		name: "setRNMAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "shutOffAssignerAssignments",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "shutOffAssignments",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "shutOffFeeRecipientUpdates",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newAdmin",
				type: "address",
			},
		],
		name: "transferTempAdmin",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
		],
		name: "updateProtocolFeeRecipient",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
];

export const NameMarketplaceABI = [
	{ inputs: [], name: "BidIsTooLow", type: "error" },
	{ inputs: [], name: "BidderIsNotOwner", type: "error" },
	{ inputs: [], name: "BidderIsOwner", type: "error" },
	{ inputs: [], name: "BidderNoLongerOwner", type: "error" },
	{ inputs: [], name: "BuyerNotOwner", type: "error" },
	{ inputs: [], name: "CantOverwriteNFTName", type: "error" },
	{ inputs: [], name: "FailedToSendETHER", type: "error" },
	{ inputs: [], name: "FeeMismatch", type: "error" },
	{ inputs: [], name: "NFTIsNotNamed", type: "error" },
	{ inputs: [], name: "NFTNotAllowedForNameTransfer", type: "error" },
	{ inputs: [], name: "NoNFTWithThatName", type: "error" },
	{ inputs: [], name: "NotBidder", type: "error" },
	{ inputs: [], name: "NotEnoughETH", type: "error" },
	{ inputs: [], name: "NotForSale", type: "error" },
	{ inputs: [], name: "NotForSaleToThisAddress", type: "error" },
	{ inputs: [], name: "NotNameOwner", type: "error" },
	{ inputs: [], name: "SellerNoLongerOwner", type: "error" },
	{ inputs: [], name: "TooEarlyToWithdraw", type: "error" },
	{
		anonymous: false,
		inputs: [
			{ indexed: false, internalType: "uint8", name: "version", type: "uint8" },
		],
		name: "Initialized",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "collectionFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenFrom",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "collectionTo",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenTo",
				type: "uint256",
			},
			{ indexed: false, internalType: "string", name: "name", type: "string" },
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "bidder",
				type: "address",
			},
		],
		name: "NameBidEntered",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "collectionFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenFrom",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "collectionTo",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenTo",
				type: "uint256",
			},
			{ indexed: false, internalType: "string", name: "name", type: "string" },
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "bidder",
				type: "address",
			},
		],
		name: "NameBidWithdrawn",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "collectionFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenFrom",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "collectionTo",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenTo",
				type: "uint256",
			},
			{ indexed: false, internalType: "string", name: "name", type: "string" },
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "seller",
				type: "address",
			},
			{
				indexed: false,
				internalType: "address",
				name: "buyer",
				type: "address",
			},
		],
		name: "NameBought",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "collectionFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenFrom",
				type: "uint256",
			},
			{ indexed: false, internalType: "string", name: "name", type: "string" },
		],
		name: "NameNoLongerForSale",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "collectionFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenFrom",
				type: "uint256",
			},
			{ indexed: false, internalType: "string", name: "name", type: "string" },
			{
				indexed: false,
				internalType: "uint256",
				name: "minSalePriceInWei",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "onlyTo",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "feePerc",
				type: "uint256",
			},
		],
		name: "NameOffered",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "address",
				name: "collectionFrom",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenFrom",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "collectionTo",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "tokenTo",
				type: "uint256",
			},
			{ indexed: false, internalType: "string", name: "name", type: "string" },
		],
		name: "NameTransfer",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "uint256",
				name: "feePerc",
				type: "uint256",
			},
		],
		name: "NewFeePerc",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "protocolFeeRecipient",
				type: "address",
			},
		],
		name: "NewProtocolFeeRecipient",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		inputs: [],
		name: "WETH",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "uint256", name: "minPrice", type: "uint256" },
			{ internalType: "uint256", name: "_feePerc", type: "uint256" },
		],
		name: "acceptBidForName",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		name: "bidBlock",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "address", name: "collectionTo", type: "address" },
			{ internalType: "uint256", name: "tokenTo", type: "uint256" },
		],
		name: "buyName",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "address", name: "collectionTo", type: "address" },
			{ internalType: "uint256", name: "tokenTo", type: "uint256" },
		],
		name: "enterBidForName",
		outputs: [],
		stateMutability: "payable",
		type: "function",
	},
	{
		inputs: [],
		name: "feePerc",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "_nftr", type: "address" },
			{ internalType: "address", name: "_punksAddress", type: "address" },
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
			{ internalType: "address", name: "_WETH", type: "address" },
		],
		name: "initialize",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		name: "nameBids",
		outputs: [
			{ internalType: "bool", name: "hasBid", type: "bool" },
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "address", name: "collectionTo", type: "address" },
			{ internalType: "uint256", name: "tokenTo", type: "uint256" },
			{ internalType: "address", name: "bidder", type: "address" },
			{ internalType: "uint256", name: "value", type: "uint256" },
			{ internalType: "uint256", name: "bidBlock", type: "uint256" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "string", name: "name", type: "string" }],
		name: "nameNoLongerForSale",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
		name: "namesOfferedForSale",
		outputs: [
			{ internalType: "bool", name: "isForSale", type: "bool" },
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "address", name: "seller", type: "address" },
			{ internalType: "uint256", name: "minValue", type: "uint256" },
			{ internalType: "address", name: "onlySellTo", type: "address" },
			{ internalType: "uint256", name: "agreedFee", type: "uint256" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "nftr",
		outputs: [
			{ internalType: "contract INFTRegistry", name: "", type: "address" },
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "uint256", name: "minSalePriceInWei", type: "uint256" },
			{ internalType: "uint256", name: "_feePerc", type: "uint256" },
		],
		name: "offerNameForSale",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "string", name: "name", type: "string" },
			{ internalType: "uint256", name: "minSalePriceInWei", type: "uint256" },
			{ internalType: "address", name: "toAddress", type: "address" },
			{ internalType: "uint256", name: "_feePerc", type: "uint256" },
		],
		name: "offerNameForSaleToAddress",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "", type: "address" }],
		name: "pendingWithdrawals",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "protocolFeeRecipient",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "punksAddress",
		outputs: [{ internalType: "contract IPunks", name: "", type: "address" }],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{ internalType: "address", name: "collectionFrom", type: "address" },
			{ internalType: "uint256", name: "tokenFrom", type: "uint256" },
			{ internalType: "address", name: "collectionTo", type: "address" },
			{ internalType: "uint256", name: "tokenTo", type: "uint256" },
		],
		name: "transferName",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "uint256", name: "_feePerc", type: "uint256" }],
		name: "updateFeePerc",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_protocolFeeRecipient",
				type: "address",
			},
		],
		name: "updateProtocolFeeRecipient",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "withdraw",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [{ internalType: "string", name: "name", type: "string" }],
		name: "withdrawBidForName",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
];
export const ERC721ABI = [
	{
		constant: true,
		inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
		name: "ownerOf",
		outputs: [{ internalType: "address", name: "", type: "address" }],
		payable: false,
		stateMutability: "view",
		type: "function",
	},

	{
		constant: true,
		inputs: [{ name: "", type: "uint256" }],
		name: "punkIndexToAddress",
		outputs: [{ name: "", type: "address" }],
		payable: false,
		type: "function",
	},
];

// Mainnet DAI, Optimism and Arbitrium Rollup Contracts with local addresses
const contracts = {
	1: {
		contracts: {
			DAI: {
				address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
				abi: DAIABI,
			},
			UNI: {
				address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
				abi: ERC20ABI,
			},
			GoldenTicket: {
				address: "0x388d7bd34dd59f62bc93c5066ff6c468e68eaaa7",
				abi: GoldenTicketABI,
			},
			Weth9: {
				address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
				abi: Weth9ABI,
			},
			NFTRegistry: {
				address: "0xc662a8Ce36392d4B9489D0B59605a1874711BFc6",
				abi: NFTRegistryABI,
			},
			NamingCredits: {
				address: "0x6a8e631447e701880e70613514A5A4CAE6a11B13",
				abi: NamingCreditsABI,
			},
			NameMarketplace: {
				address: "0x89C3574E08Ada6C3587447F487e1eeb1A08f618D",
				abi: NameMarketplaceABI,
			},
		},
	},
	4: {
		contracts: {
			GoldenTicket: {
				address: "0xE37C7A3f7E7869537D18622fA76487A0E59Dc014",
				abi: GoldenTicketABI,
			},
			NFTRegistry: {
				address: "0x0C28755676f10961626763c36eb8817E136c764B",
				abi: NFTRegistryABI,
			},
			Weth9: {
				address: "0x7e965da028385D5EDD893f32dd199e2a994c33F0",
				abi: Weth9ABI,
			},
			NamingCredits: {
				address: "0x2ae42d2c43ba7e1787897ec2abc65be71481de38",
				abi: NamingCreditsABI,
			},
			NameMarketplace: {
				address: "0x89C3574E08Ada6C3587447F487e1eeb1A08f618D",
				abi: NameMarketplaceABI,
			},
		},
	},
	5: {
		contracts: {
			GoldenTicket: {
				address: "0x7e965da028385D5EDD893f32dd199e2a994c33F0",
				abi: GoldenTicketABI,
			},
			NFTRegistry: {
				address: "0xEaDD0C0c9493d61518bB7E9F104b57BdD2CA4Aa9",
				abi: NFTRegistryABI,
			},
			Weth9: {
				address: "0xf44745f250a6733798eEd9F259542A6b57089D15",
				abi: Weth9ABI,
			},
			NamingCredits: {
				address: "0xc7c6900E107Db43727b20aB5C7b6dc9188880081",
				abi: NamingCreditsABI,
			},

			NameMarketplace: {
				address: "0xBf1B1ba9CD5803781E97b3C966c9EC1E1677a730",
				abi: NameMarketplaceABI,
			},
		},
	},
	11155111: {
		contracts: {
			GoldenTicket: {
				address: "0x14811762fa5D44c06A0B95c66Cf12B8FFb941dA7",
				abi: GoldenTicketABI,
			},
			NFTRegistry: {
				address: "0xCAAd09E013630697de8252505618c1619eb048f5",
				abi: NFTRegistryABI,
			},
			Weth9: {
				address: "0xE534Ae561B50fdC50a7348635e56033a9D8460E4",
				abi: Weth9ABI,
			},
			NamingCredits: {
				address: "0x906135F37b07b601373AC933a73fDB265bFD7622",
				abi: NamingCreditsABI,
			},

			NameMarketplace: {
				address: "0xF031adBb8f6087961afb862CFe8CFc4c4E809a67",
				abi: NameMarketplaceABI,
			},
		},
	},
};
export default contracts;

export const GOLDEN_TICKET_ADDRESS =
	contracts[DEFAULT_NETWORK.chainId].contracts.GoldenTicket.address;

export const NAME_MARKETPLACE_ADDRESS =
	contracts[DEFAULT_NETWORK.chainId].contracts.NameMarketplace.address;

export const NFTREGISTRY_ADDRESS =
	contracts[DEFAULT_NETWORK.chainId].contracts.NFTRegistry.address;
