/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Component } from "react";
import { SingleValue, components } from "react-select";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";

//import { ERC721s, erc721s } from './erc721s';
import { IContractMapped, IContracts } from "../../../global";
import { IMappedContracts } from "./MappedContract";
import Label from "./AsyncSelectOptionLabel";
import { LoadedContractsDataContext } from "../../contexts";
import { errorCollection } from "./curatedCollections";

interface State {
	readonly inputValue: string;
}

const filterProjects = (
	inputValue: string,
	erc721s: IContracts
): IMappedContracts => {
	if (inputValue && inputValue.length < 2) {
		return [];
	}
	/*
    check the local name, the contract address, and the opensea name(if it exists)
    */

	const ercsToReturn = erc721s
		.filter(
			(i) =>
				i.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
				i._id.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
				(i.openseaMetadata?.name &&
					i.openseaMetadata.name
						.toLowerCase()
						.indexOf(inputValue.toLowerCase()) > -1)
		)
		.sort((a, b) => (b.txCountYearly || 0) - (a.txCountYearly || 0));
	//console.log(ercsToReturn);

	if (ercsToReturn.length === 0 && /^0x[a-fA-F0-9]{40}$/.test(inputValue)) {
		return [
			{
				label: (
					<Label
						contractAddress={inputValue}
						name={inputValue}
						imageUrl={null}></Label>
				),
				value: inputValue,
				startingIndex: null,
				imageUrl: null,
				txCountYearly: null,
				collectionLength: null,
			},
		];
	}

	return ercsToReturn.map((e) => ({
		label: (
			<Label
				contract={e}
				name={
					e && e.openseaMetadata && e.openseaMetadata.name
						? e.openseaMetadata.name
						: e.name
				}
				imageUrl={e.imageUrl}></Label>
		),
		value: e._id,
		startingIndex: e.startingIndex,
		imageUrl: e.imageUrl,
		txCountYearly: e.txCountYearly,
		collectionLength: e.collectionLength,
	}));
};

let timeoutId: NodeJS.Timeout;

const promiseOptions = (inputValue: string, erc721s: IContracts) => {
	return new Promise<IMappedContracts>((resolve) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			resolve(filterProjects(inputValue, erc721s));
		}, 1000);
	});
};

const promiseOptionsNames = (
	inputValue: string,
	searchNames: (value: string) => Name[]
) => {
	return new Promise<Name[]>((resolve) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			const values = searchNames(inputValue);
			if (values.length) {
				resolve(
					values.map((e) => ({
						...e,
						label: (
							<Link
								to={{
									pathname: `/robot-details/${e.contractAddress}/${e.tokenId}`,
									//@ts-ignore
									state: { robot: { ...e, maxId: 0 } },
								}}
								style={{ textDecoration: "none", color: "white" }}>
								<Label
									contract={e.contractAddress}
									name={e.name}
									imageUrl={e.imageUrl}></Label>
							</Link>
						),
					}))
				);
			} else {
				resolve([]);
			}
		}, 1000);
	});
};

const customStyles = {
	menuList: (base: any) => ({
		...base,
		"::-webkit-scrollbar": {
			width: "15px",
			height: "0px",
		},
		"::-webkit-scrollbar-track": {
			background: "rgba(233,91,161,1)",
		},
		"::-webkit-scrollbar-thumb": {
			background: "#fff",
		},
		"::-webkit-scrollbar-thumb:hover": {
			background: "#000",
		},
	}),

	option: (provided: any, state: any) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: "white",
		fontSize: 20,
		cursor: "pointer",
		backgroundColor: state.isFocused
			? "rgba(233,91,161,1)"
			: "rgba(233,91,161,0.6)",
	}),
	control: (base: any, state: any) => ({
		...base,
		height: 50,
		fontSize: 40,
		backgroundColor: "transparent",
		border: "transparent",
		boxShadow: "none",
	}),
	dropdownIndicator: (base: any) => ({
		...base,
		marginTop: -6,
	}),
	indicatorSeparator: (base: any) => ({
		...base,
		marginTop: 10,
		height: "50%",
	}),
	singleValue: (provided: any, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const marginTop = -5;
		return { ...provided, opacity, transition, marginTop };
	},
	placeholder: (base: any) => ({
		...base,
		textAlign: "left",
		opacity: 0.4,
		marginTop: -5,
	}),
	input: (base: any) => ({
		...base,
		color: "white",
		cursor: "pointer",
		marginTop: -9,
		marginLeft: 80,
	}),
	menu: (base: any) => ({
		...base,
		backgroundColor: "rgba(233,91,161,0.6)",
		width: "92%",
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
		cursor: "pointer",
		scrollbars: 0,
	}),
	noOptionsMessage: (base: any) => ({
		fontSize: 30,
	}),
	loadingMessage: (base: any) => ({
		fontSize: 30,
	}),
	clearIndicator: (base: any) => ({
		...base,
		marginTop: -6,
	}),
};

interface Name {
	name: string;
	timestamp: number;
	imageUrl: string;
	contractAddress: string;
	tokenId: number;
}

interface IProps {
	searchType: string;
	updateProject: (newValue: SingleValue<IContractMapped>) => void;
	searchNames: (value: string) => Promise<Name[]>;
}

const ClearIndicator = (props: any) => {
	const clearValue = () => {
		sessionStorage.removeItem("lastCollection");
		props.clearValue();
		props.selectProps.onClear && props.selectProps.onClear();
	};

	const innerProps = {
		...props.innerProps,
		onMouseDown: clearValue,
		onTouchEnd: clearValue,
	};

	return <components.ClearIndicator {...props} innerProps={innerProps} />;
};

export default class WithPromises extends Component<IProps, State> {
	constructor(props: IProps) {
		super(props);
	}

	state: State = { inputValue: "" };

	static contextType = LoadedContractsDataContext;

	handleInputChange = (newValue: string) => {
		const inputValue = newValue.replace(/\W/g, "");
		this.setState({ inputValue });
		return inputValue;
	};

	loadOptions = async (inputValue: string) => {
		//@ts-ignore
		return promiseOptions(inputValue, this.context[0].contracts);
	};
	loadNameOptions = async (inputValue: string) => {
		//@ts-ignore
		return promiseOptionsNames(inputValue, this.props.searchNames);
	};

	render() {
		//@ts-ignore
		const [loadedContractsData] = this.context;

		const isError =
			!loadedContractsData.contracts.length && loadedContractsData.loaded;

		let value = isError
			? errorCollection
			: loadedContractsData.selectedContract;

		if (this.props.searchType === "name") {
			value = "";
		}

		return (
			<AsyncSelect
				className='react-select'
				styles={customStyles}
				cacheOptions
				value={value}
				components={{ ClearIndicator }}
				//isDisabled={isError}
				loadOptions={
					this.props.searchType === "project"
						? this.loadOptions
						: this.loadNameOptions
				}
				isClearable={true}
				placeholder={loadedContractsData ? "NAME" : "Loading..."}
				noOptionsMessage={() =>
					this.props.searchType === "project"
						? "Type a name or paste a contract address"
						: "Type a name"
				}
				loadingMessage={({ inputValue }) => {
					if (
						inputValue &&
						inputValue.length < 3 &&
						this.props.searchType === "project"
					) {
						return "Search must be at least 3 characters";
					}
					return "Searching...";
				}}
				//@ts-ignore
				onChange={this.props.updateProject}
			/>
		);
	}
}
