import { Nfts } from "src/apis/Names";
import { IS_DEV, USE_OVERRRIDE } from "src/constants";
import { RobotData } from "src/contexts/mapWalletData";

function filterNFTs(
  filter: string,
  contractId: string | undefined,
  walletTokenId: number | string | null,
  loadedNFTs: RobotData[],
  namedNfts: RobotData[],
  walletNFTs: RobotData[],
  pageNumber: number,
  checkCollection: (contractAddress: string) => Nfts[],
  checkNFT: (contractAddress: string, tokenId: number) => string | null,
  listed: RobotData[]
): RobotData[] {
  let filteredData: RobotData[] = loadedNFTs;

  if (walletNFTs && filter === "WALLET") {

    if (!contractId) {
      filteredData = walletNFTs
    }
    if (contractId) {
      filteredData = walletNFTs
        .filter((c) => {
          return c.contractAddress === contractId;
        })
        .sort((a, b) => a._id - b._id);
    }


    if (walletTokenId) {
      // let filterById: RobotData[] = []
      // let filterByName: RobotData[] = []

      // if (isNaN(+walletTokenId)) {
      const common = namedNfts.filter(item1 => {
        return walletNFTs.some(item2 => item2.contractAddress.toLowerCase() === item1.contractAddress.toLowerCase() && Number(item2.tokenId) === Number(item1.tokenId));
      });


      const filterByName = common.filter(nft => nft.name?.toLowerCase().includes(walletTokenId.toString().toLowerCase()))

      // } else {
      const filterById = walletNFTs.filter((nft) => nft._id === +walletTokenId)
      // }


      filteredData = [...filterById, ...filterByName]
    }
  } else if (filter === "LISTED") {
    filteredData = listed;

    if (walletTokenId) {
      const filterByName = namedNfts.filter(nft => nft.name?.toLowerCase().includes(walletTokenId.toString().toLowerCase()))

      const filterById = listed.filter((token) => +token.tokenId === +walletTokenId)
      filteredData = [...filterById, ...filterByName]

    }

    else if (contractId) {
      filteredData = listed
        .filter((c) => {
          return c.contractAddress === contractId;
        })
        .sort((a, b) => a._id - b._id);
    }

    else if (contractId && walletTokenId) {
      filteredData = listed
        .filter((c) => {
          return c.contractAddress === contractId;
        })
        .sort((a, b) => a._id - b._id).filter((token) => +token.tokenId === +walletTokenId)
    }
  }

  else if (filter === "WALLET") {
    filteredData = [];
  }
  else if (!contractId && filter === "DEFAULT") {
    filteredData = []
  }


  else if (filter === "NAMED" && contractId) {

    //@ts-ignore

    filteredData = namedNfts.filter((token) => token.contractAddress.toLowerCase() === contractId.toLowerCase())
    if (walletTokenId) {

      const filterByName = filteredData.filter(nft => nft.name?.toLowerCase().includes(walletTokenId.toString().toLowerCase()))

      const filterById = filteredData.filter((token) => +token.tokenId === +walletTokenId)
      filteredData = [...filterById, ...filterByName]
    }
  } else if (filter === "NAMED" && !contractId) {

    if (walletTokenId) {

      const filterByName = namedNfts.filter(nft => nft.name?.toLowerCase().includes(walletTokenId.toString().toLowerCase()))

      const filterById = namedNfts.filter((token) => +token.tokenId === +walletTokenId)
      filteredData = [...filterById, ...filterByName]
    } else {
      filteredData = namedNfts;
    }
  }
  if (filteredData?.length && filteredData.length > 16) {
    // last pagenumber
    let lastPageNumber = sessionStorage.getItem("lastPageNumber") || 1;

    if (IS_DEV) {
      if (USE_OVERRRIDE) {
        //@ts-ignore
        filteredData = filteredData.filter((e) => e.temporary === undefined);
      }
    }
    filteredData = filteredData.slice((+lastPageNumber - 1) * 16, +lastPageNumber * 16);
  }
  if (filteredData?.length && contractId && filter !== "NAMED") {
    filteredData = filteredData.map((e) => {
      const nftName = checkNFT(contractId, e.tokenId);
      if (nftName) {
        return { ...e, name: nftName };
      }
      return e;
    });
  }

  if (IS_DEV) {
    if (USE_OVERRRIDE) {
      //@ts-ignore
      filteredData = filteredData.filter((e) => e.temporary === undefined);
    }
  }

  return filteredData;
}
export default filterNFTs;
