import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { getNameHistory, getNames } from "src/apis";
import "./NameHistory.css";
import { EtherscanEnum, etherscanLink } from "src/helpers/etherscan";
import { SocketContext } from "src/contexts/SocketContext";

const initialState: INameHistory[] = [];
const initialNames: INameTransaction[] = [];

const NameHistory = ({ namingTweet, contractAddress, tokenId }: any) => {
  const [namesHistory, setNamesHistory] = useState<INameHistory[] | null>(
    initialState
  );

  const [names, setNames] = useState<INameTransaction[] | null>(initialNames);

  const { socket } = useContext(SocketContext);
  const [data, setData] = useState<INameHistory[] | null>(initialState);

  useEffect(() => {
    async function fetchNameHistory() {
      const { data }: { data: INameHistory[] } = await getNameHistory(
        contractAddress.toLowerCase(),
        tokenId
      );

      const { data: names }: { data: INameTransaction[] } = await getNames(
        contractAddress.toLowerCase()
      );

      setData(data);
      setNames(names);
      const namesHistory = data.filter((item) => item.bought === false);
      setNamesHistory(namesHistory);
    }

    fetchNameHistory();
  }, [contractAddress, tokenId]);

  useEffect(() => {
    async function fetchNameHistory() {
      const { data }: { data: INameHistory[] } = await getNameHistory(
        contractAddress.toLowerCase(),
        tokenId
      );

      setData(data);
      const namesHistory = data.filter((item) => item.bought === false);
      setNamesHistory(namesHistory);
    }

    socket.on("UpdateTransfers", async () => {
      fetchNameHistory();
    });
  }, [contractAddress, socket, tokenId]);

  function transferOut(
    data: INameHistory,
    contractAddress: string,
    tokenId: string | number
  ): boolean {
    const name =
      namesHistory?.filter(
        (item: INameHistory) =>
          item.collectionTo !== contractAddress && item.tokenTo !== +tokenId
      ) ?? [];

    return name.includes(data);
  }

  function transferFrom(
    data: INameHistory,
    contractAddress: string,
    tokenId: string | number
  ): boolean {
    const name =
      namesHistory?.filter(
        (item: INameHistory) =>
          item.collectionFrom === contractAddress && item.tokenFrom === +tokenId
      ) ?? [];

    return name.includes(data);
  }

  function transferIn(
    data: INameHistory,
    contractAddress: string,
    tokenId: string | number
  ): boolean {
    const name =
      namesHistory?.filter(
        (item: INameHistory) =>
          item.collectionTo === contractAddress &&
          item.tokenTo === +tokenId &&
          item.named === false
      ) ?? [];

    return name.includes(data);
  }

  function named(
    data: INameHistory,
    contractAddress: string,
    tokenId: string | number
  ): boolean {
    const named =
      namesHistory?.filter(
        (item: INameHistory) =>
          item.collectionTo === contractAddress &&
          item.tokenTo === +tokenId &&
          item.named === true
      ) ?? [];

    return named.includes(data);
  }

  function nameSold(
    row: INameHistory,
    contractAddress: string,
    tokenId: string | number
  ) {
    const sold = data?.find(
      (item) =>
        item.bought === true && item.transactionHash === row.transactionHash
    );

    return (
      sold?.collectionFrom === contractAddress && sold.tokenFrom === +tokenId
    );
  }

  function nameBought(
    row: INameHistory,
    contractAddress: string,
    tokenId: string | number
  ) {
    const bought = data?.find(
      (item) =>
        item.bought === true && item.transactionHash === row.transactionHash
    );

    return (
      bought?.collectionTo === contractAddress && bought.tokenTo === +tokenId
    );
  }

  function showName(
    data: INameHistory,
    contractAddress: string,
    tokenId: string
  ): boolean {
    const names =
      namesHistory?.filter(
        (item) =>
          item.collectionTo.toLowerCase() === contractAddress.toLowerCase() &&
          item.tokenTo === +tokenId
      ) ?? [];

    return names.includes(data);
  }

  return (
    <div className="NameHistory">
      {namesHistory ? (
        <div>
          <h2>NAME HISTORY</h2>
          <table>
            <thead>{/* <th>NAMING HISTORY</th> */}</thead>
            <tbody>
              {namesHistory.map((data, index) => (
                <tr key={index}>
                  <td style={{ paddingRight: "42px" }}>
                    {moment(data.timestamp).format("l")}
                  </td>
                  <td style={{ paddingRight: "42px" }}>
                    <a
                      href={etherscanLink(
                        data.transactionHash,
                        EtherscanEnum.HASH
                      )}
                      target="_blank"
                      className="pill"
                      style={{
                        border:
                          transferFrom(data, contractAddress, tokenId) ||
                          transferOut(data, contractAddress, tokenId)
                            ? "1px solid #fc4ba3"
                            : "",

                        background:
                          transferFrom(data, contractAddress, tokenId) ||
                          transferOut(data, contractAddress, tokenId)
                            ? "#FC4BA34D"
                            : "",
                      }}
                      rel="noreferrer"
                    >
                      {nameBought(data, contractAddress, tokenId)
                        ? "BOUGHT"
                        : nameSold(data, contractAddress, tokenId)
                        ? "SOLD"
                        : named(data, contractAddress, tokenId)
                        ? "NAMED"
                        : transferOut(data, contractAddress, tokenId)
                        ? "TRANSFER OUT"
                        : transferFrom(data, contractAddress, tokenId)
                        ? "TRANSFER OUT"
                        : transferIn(data, contractAddress, tokenId)
                        ? "TRANSFER IN"
                        : "NAMED"}
                    </a>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={etherscanLink(
                        data.transactionHash,
                        EtherscanEnum.HASH
                      )}
                      rel="noreferrer"
                    >
                      {showName(data, contractAddress, tokenId)
                        ? data.name
                        : null}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Nothing to show</p>
      )}
    </div>
  );
};

export default NameHistory;

interface INameHistory {
  _id: string;
  transactionHash: string;
  name: string;
  timestamp: number;
  blockNumber: number;
  collectionFrom: string;
  collectionTo: string;
  tokenTo: number;
  tokenFrom: number;
  sold: boolean;
  bought: boolean;
  named: boolean;
  event?: string;
}

export interface INameTransaction {
  transactionHash: string;
  name: string;
  timestamp: number;
  blockNumber: number;
  contractAddress: string;
  tokenId: string;
  wallet: string;
}
