import { useState, useEffect, useContext } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ethers } from "ethers";
import { Radio } from "antd";

import isSpecialName from "src/helpers/isSpecialName";
import { approveWeth } from "../../../helpers/contractInteractions";

import NamePrice from "./NamePrice";
import { WalletBalancesContext } from "src/contexts/WalletBalancesContext";
import { IS_DEV } from "src/constants";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import useGetName from "src/hooks/useGetName";
import { NFTREGISTRY_ADDRESS, NFTRegistryABI } from "src/contracts/external_contracts";
import { SocketContext } from "src/contexts/SocketContext";

let timeoutId = 0;

function getIsTakenObject(contractAddress, tokenId) {
  return {
    contractAddress: contractAddress,
    tokenId: tokenId,
  };
}

const GAS_BUFFER = Number(process.env.REACT_APP_NAMING_GAS_BUFFER);

const initialModalProperties = {
  body: "",
  open: false,
  accept: () => "",
  header: "",
  isSpecial: false,
};

function NameHandler({
  owned,
  contractAddress,
  tokenId,
  providedName,
  updateName,
  setNameUpdated,
  setUpdatedName,
  updateNameInput,
}) {
  const [localName, setLocalName] = useState(null);
  const [isSpecial, setIsSpecial] = useState();
  const [isValid, setIsValid] = useState(true);
  const [isTaken, setIsTaken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalProperties, setModalProperties] = useState(
    initialModalProperties
  );
  const [NFTRegistry, setNFTRegistry] = useState();

  const [namingCurrency, setNamingCurrency] = useState(0);
  const { name } = useGetName(contractAddress, tokenId, 5000)


  const { readContracts, tx, writeContracts, userSigner } = useContext(Web3OnboardContext);
  const {
    eth,
    weth,
    wethAllow,
    gtk,
    namingPrice: namingPriceFull,
    getNamingBalances,
    updateBalances,
    namingCredits,
  } = useContext(WalletBalancesContext);
  const ethBalance = eth.decimal;
  const wethBalance = weth.decimal;
  const wethAllowance = wethAllow.decimal;
  const namingPrice = namingPriceFull.decimal;
  const goldenTicketBalance = gtk.decimal;
  const namingCreditsBalance = namingCredits.decimal;

  let alertMessage = "";

  useEffect(() => {
    if (!userSigner) return;

    const NFTRegistry = new ethers.Contract(
      NFTREGISTRY_ADDRESS,
      NFTRegistryABI,
      userSigner
    );


    setNFTRegistry(NFTRegistry);
  }, [userSigner]);

  useEffect(() => {
    if (providedName && localName === null) {
      setLocalName(providedName);
    }
  }, [providedName, localName]);

  const checkNameProperties = async (value) => {
    try {
      const [isTakenCV, isValidCV] = await Promise.all([
        readContracts.NFTRegistry.getTokenByName(value.toLowerCase()),
        readContracts.NFTRegistry.validateName(value),
      ]);
      if (
        isTakenCV[0] &&
        isTakenCV[0] === "0x0000000000000000000000000000000000000000"
      ) {
        setIsTaken(null);
      } else {
        setIsTaken(getIsTakenObject(isTakenCV[0], isTakenCV[1].toString()));
      }
      setIsValid(isValidCV);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (updateNameInput) {
      setLocalName(providedName)
      setLocalName(null)
    }
  }, [localName, providedName, updateNameInput])
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    socket.on("UpdateRobotDetails", () => {

      updateName();

    });
  }, [socket, updateName]);

  const updateNameProperties = ({ target: { value } }) => {
    setLocalName(value);
    setIsLoading(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (value !== undefined) {
      setIsSpecial(isSpecialName(value.toLowerCase()));
      timeoutId = setTimeout(() => {
        checkNameProperties(value).finally(() => {
          setIsLoading(false);
        });
      }, 500);
    }
  };

  const mintNameCheck = async () => {
    try {
      let {
        namingPrice: namingPriceNow,
        eth: ethNow,
        weth: wethNow,
        wethAllow: wethAllowNow,
      } = await getNamingBalances();
      // approve weth if needed
      if (
        ethNow.decimal <= namingPriceNow.decimal + GAS_BUFFER &&
        ethNow.decimal + wethNow.decimal >
        namingPriceNow.decimal + GAS_BUFFER &&
        wethAllowNow.decimal < namingPriceNow.decimal + GAS_BUFFER
      ) {
        try {
          await approveWeth(tx, writeContracts);
        } catch (error) {
          console.log(error);
          return;
        }
      }
      if (isSpecial) {
        setModalProperties({
          ...goldenTicketModalProperties,
          isSpecial: isSpecialName(providedName),
          handleClose: () =>
            setModalProperties((prev) => ({ ...prev, open: false })),
          accept: () => {
            setModalProperties((prev) => ({ ...prev, open: false }));
            mintName();
          },
        });
        return;
      } else if (providedName) {
        setModalProperties({
          ...nameOverModalProperties,
          isSpecial: isSpecialName(providedName),
          handleClose: () =>
            setModalProperties((prev) => ({ ...prev, open: false })),
          accept: () => {
            setModalProperties((prev) => ({ ...prev, open: false }));
            mintName();
          },
        });
        return;
      } else {
        mintName();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mintName = async () => {
    try {
      let {
        namingPrice: namingPriceNow,
        eth: ethNow,
        weth: wethNow,
        wethAllow: wethAllowNow,
        gtk: gtkNow,
      } = await getNamingBalances();

      let contractAddressToUse = convertContractAddress(contractAddress);
      const args = [
        contractAddressToUse,
        tokenId.toString(),
        localName.toString(),
        namingCurrency.toString(),
        namingCurrency === 0 ? namingPriceNow.original.toString() : "1",
        //{ value: ethers.utils.parseEther((0.05).toString()) },
        namingCurrency === 0
          ? {
            value:
              ethNow.decimal > namingPriceNow.decimal + GAS_BUFFER
                ? namingPriceNow.original.toString()
                : ethNow.decimal > GAS_BUFFER
                  ? ethers.utils.parseEther(
                    (ethNow.decimal - GAS_BUFFER).toFixed(6)
                  )
                  : ethers.utils.parseEther((0).toString()),
          }
          : undefined,
      ];

      // NFTRegistry
      const currencyQuantity = namingCurrency === 0 ? namingPriceNow.original.toString() : "1"
      const value = namingCurrency === 0
        ? {
          value:
            ethNow.decimal > namingPriceNow.decimal + GAS_BUFFER
              ? namingPriceNow.original.toString()
              : ethNow.decimal > GAS_BUFFER
                ? ethers.utils.parseEther(
                  (ethNow.decimal - GAS_BUFFER).toFixed(6)
                )
                : ethers.utils.parseEther((0).toString()),
        }
        : undefined
      let result;

      if (namingCurrency === 0) {
        result = await NFTRegistry.changeName(
          contractAddressToUse,
          tokenId.toString(),
          localName.toString(),
          namingCurrency.toString(),
          currencyQuantity,
          value
        )
      } else if (namingCurrency === 2) {
        result = await NFTRegistry.changeName(
          contractAddressToUse,
          tokenId.toString(),
          localName.toString(),
          namingCurrency.toString(),
          currencyQuantity,
        )
      }
      await result.wait()
      socket.emit("NameTransfer");


      // await tx(
      //   writeContracts.NFTRegistry.changeName(
      //     ...args.filter((e) => e !== undefined)
      //   ),
      //   undefined,
      //   `-------------------------------------------------------- eth: ${ethNow.decimal.toFixed(
      //     4
      //   )} | weth: ${wethNow.decimal.toFixed(
      //     4
      //   )} | weth allowance: ${wethAllowNow.decimal.toString()}`
      // );
      updateBalances();
      updateName();
      setUpdatedName(localName.toString())
      setNameUpdated(true)

    } catch (error) {
      console.log(error);

    }
  };

  if (!isValid) {
    alertMessage = "NFT name is invalid";
  } else if (isTaken !== null) {
    alertMessage = `name is already taken`;
  } else if (
    owned &&
    localName !== providedName &&
    ethBalance &&
    namingPrice &&
    ethBalance + wethBalance < namingPrice + GAS_BUFFER
  ) {
    alertMessage = `low on Funds`;
  } else if (isSpecialName(providedName?.toLowerCase()) && localName !== providedName) {
    alertMessage = `you're about to write over a special name`
  }
  else if (owned && isSpecial && goldenTicketBalance === 0) {
    alertMessage = (
      <>
        a Golden Ticket is needed and you can{" "}
        <a
          href="/#/golden-ticket"
          style={{ textDecoration: "none", color: "#c1c403" }}
          target={"_blank"}
        >
          {" "}
          mint one here
        </a>
      </>
    );
  }

  const isNewNameValid =
    //basic validation, isvalid, is different, etc.
    localName !== providedName &&
    isTaken === null &&
    isValid &&
    localName !== null &&
    //if trying to pay with eth/weth, check the amounts
    ((namingCurrency === 0 &&
      ethBalance &&
      namingPrice &&
      ethBalance + wethBalance > namingPrice + GAS_BUFFER) ||
      //if trying to pay with naming credtis, check balance
      (namingCurrency === 2 && namingCreditsBalance >= 1)) &&
    // if special, check special ticket balance
    (!isSpecial || (isSpecial && goldenTicketBalance > 0));

  return (
    <>
      <SpecialModal
        open={modalProperties.open}
        handleClose={modalProperties.handleClose}
        accept={modalProperties.accept}
        body={modalProperties.body}
        header={modalProperties.header}
        headerStyle={modalProperties.style}
      ></SpecialModal>
      <div className="robot-details-text">
        {/* These classes might not be in the index.css yet */}
        <p className="robot-details-alert text-pink fs-4">{alertMessage}</p>

        <input
          type="text"
          value={localName || ""}
          onChange={updateNameProperties}
          placeholder="Pick a name"
          className="pick-a-name"
          disabled={!owned}
          style={!owned ? { opacity: "50%" } : {}}
        />
        <button
          style={
            !owned
              ? {
                pointerEvents: "none",
                opacity: "50%",
              }
              : {}
          }
          disabled={!isNewNameValid}
          onClick={mintNameCheck}
          className={`bordered-button  ${isSpecial ? " gold" : " pink"}`}
        >
          {isLoading ? (
            <Spinner animation="border" role="status"></Spinner>
          ) : name ? ("RENAME IT") : (
            "NAME IT"
          )}
        </button>

        {owned ? <NamePrice
          className={"naming-price"}
          namingPrice={namingPrice}
          eth={ethBalance}
          weth={wethBalance}
          wethAllowance={wethAllowance}
          isSpecial={isSpecial}
          namingCurrency={namingCurrency}
        /> : null}
        {namingCreditsBalance === 0 ? null : owned ? (
          <Radio.Group
            onChange={(e) => setNamingCurrency(e.target.value)}
            className={"naming-currency-radio-group"}
            value={namingCurrency}
          >
            <Radio.Button
              className={"naming-currency-radio left"} value={0}>
              ETH
            </Radio.Button>
            {/* <Radio.Button className={"naming-currency-radio"} value={1}>
              RNM
            </Radio.Button> */}
            <Radio.Button className={"naming-currency-radio right"} value={2}>
              Naming Credits
            </Radio.Button>
          </Radio.Group>
        ) : null}
      </div>
    </>
  );
}

function convertContractAddress(address) {
  if (IS_DEV) {
    if (address.toLowerCase() === "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb")
      //"0xc8492446fb574447a9a2134f67d26b082bc1272f")
      return "0xc8492446fb574447a9a2134f67d26b082bc1272f";
    if (address.toLowerCase() === "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d")
      return "0x388d7bd34dd59f62bc93c5066ff6c468e68eaaa7";
  }
  return address;
}

export default NameHandler;

const SpecialModal = ({
  open,
  body,
  header,
  handleClose,
  accept,
  headerStyle,
  buttonStyle,
  isSpecial,
}) => {
  return (
    <Modal
      show={open}
      onHide={() => handleClose(false)}
      dialogClassName="custom-modal"
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        {body}
        {isSpecial ? " YOU'RE ABOUT TO OVERRIDE A SPECIAL NAME!" : ""}
      </Modal.Body>
      <Modal.Footer className="modal-footer-naming">
        <div className="d-flex justify-content-center">
          <button
            className="offer-btn"
            // variant="secondary"
            onClick={() => handleClose(false)}
            style={{
              marginRight: "24px",
              padding: "6px 24px",
              borderRadius: "4px",
              outline: "none",
              border: "1px solid #fc4ba3",
              backgroundColor: "#fc4ba3",
              color: "#fff",
              width: "160px",
              fontWeight: "300",
              fontSize: "1rem",
            }}
          >
            NO
          </button>
          <button
            className="offer-btn"
            variant="primary"
            onClick={accept}
            style={{
              padding: "6px 24px",
              borderRadius: "4px",
              outline: "none",
              backgroundColor: "#fff",
              border: "none",
              color: "#fc4ba3",
              width: "160px",
              fontWeight: "300",
              fontSize: "1rem",
            }}          >
            YES
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const goldenTicketModalProperties = {
  open: true,
  header: "Special Name Detected",
  body: "To use a Special Name, a Golden Ticket is required. Do you want to use a Golden Ticket?",
};
const nameOverModalProperties = {
  open: true,
  header: "Name Overwrite Detected",
  body: "Are you sure you want to rename this NFT? Your previous name will be available for anyone to take.",
  // style: { backgroundColor: "#fc4ba3" },
};
