import LetsBrowseSearch from "../components/LetsBrowseScreen/LetsBrowseSearch";
import RobotsList from "../components/LetsBrowseScreen/RobotsList";
import Loader from "../components/LetsBrowseScreen/Loader";
import { useContext, useCallback, useState, useRef, useEffect } from "react";
import { filterNFTs } from "../helpers";
import curatedCollections from "src/components/LetsBrowseScreen/curatedCollections";

import {
	LoadedContractsDataContext,
	NamesDataContext,
	WalletContentsDataContext,
} from "src/contexts";
import { postSearch } from "../apis/postEvents";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { ListedNamesContext } from "src/contexts/ListedNamesContext";

const LetsBrowseScreen = (props) => {
	const {
		loading: loadingWallet,
		wallet,
		address,
	} = useContext(Web3OnboardContext);
	let lastFilter = sessionStorage.getItem("lastFilter");
	const [data, actions] = useContext(LoadedContractsDataContext);

	const { listed } = useContext(ListedNamesContext);
	const { data: walletContents, isLoading } = useContext(
		WalletContentsDataContext
	);
	const { checkCollection, checkNFT, searchNames } =
		useContext(NamesDataContext);

	let connected_wallet =
		localStorage.getItem("onboard.js:last_connected_wallet") || [];
	connected_wallet =
		typeof connected_wallet === "string"
			? JSON.parse(connected_wallet)
			: connected_wallet;

	const DEFAULT = !connected_wallet || !address || !wallet;
	const WALLET =
		connected_wallet.length > 0 || address || wallet ? true : false;

	const [filter, setFilter] = useState(() => {
		if (WALLET && lastFilter) {
			return lastFilter;
		} else if (WALLET) {
			return "WALLET";
		} else if (DEFAULT) {
			return "DEFAULT";
		} else if (lastFilter) {
			return lastFilter;
		} else {
			return "DEFAULT";
		}
	});

	const [pageNumber, setPageNumber] = useState(1);
	const [, setState] = useState({});
	const walletTokenIdLookup = useRef(null);
	const pageNumberRef = useRef(pageNumber);

	let contractId = data.selectedContract?.value || data.selectedContract?._id;

	let collectionSize;
	let namedCollectionLength;

	if (contractId) {
		namedCollectionLength = data?.NamedData?.filter(
			(collection) =>
				collection.contractAddress.toLowerCase() === contractId.toLowerCase()
		).length;
	}

	if (filter === "WALLET") {
		collectionSize = walletContents?.nfts?.length;

		if (contractId) {
			collectionSize = walletContents?.nfts?.filter(
				(item) =>
					item.contractAddress.toLowerCase() === contractId.toLowerCase()
			).length;
		}
	} else if (filter === "NAMED" && !contractId) {
		collectionSize = data.length;
	} else if (filter === "NAMED" && contractId) {
		collectionSize = namedCollectionLength;
	}

	const updateQuickCollection = useCallback((name) => {
		if (curatedCollections[name]) {
			setPageNumber(1);
			pageNumberRef.current = 1;
			sessionStorage.removeItem("lastPageNumber");
			actions.updateSelectedContractAndFetchImages(curatedCollections[name]);
		} else {
			setPageNumber(1);
			pageNumberRef.current = 1;
			sessionStorage.removeItem("lastPageNumber");
			actions.resetContract();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateFilter = useCallback(async (type) => {
		if (["DEFAULT", "WALLET", "NAMED", "LISTED"].includes(type)) {
			lastFilter = type;

			pageNumberRef.current = 1;
			sessionStorage.removeItem("lastPageNumber");
			walletTokenIdLookup.current = null;
			setFilter(type);
			setPageNumber(1);
		}
	}, []);

	const filteredData = filterNFTs(
		filter,
		contractId,
		walletTokenIdLookup.current,
		data.NFTData,
		data.NamedData,
		walletContents.nfts,
		pageNumberRef.current,
		checkCollection,
		checkNFT,
		listed
	);

	const updateProject = useCallback(
		async (contract) => {
			if (contract !== null) {
				if (contract.tokenId !== undefined) {
					return;
				}
				pageNumberRef.current = 1;
				sessionStorage.removeItem("lastPageNumber");
				postSearch(contract.value, contract?.label?.props?.name);
				actions.updateSelectedContractAndFetchImages(contract);
			} else {
				pageNumberRef.current = 1;
				sessionStorage.removeItem("lastPageNumber");
				actions.resetContract();
			}
		},
		[actions]
	);

	const nextPage = () => {
		if (
			filter === "WALLET" &&
			filteredData.length === 16 &&
			walletContents.nfts.length > pageNumber * 16
		) {
			setPageNumber((prev) => {
				pageNumberRef.current = prev + 1;
				sessionStorage.setItem("lastPageNumber", pageNumberRef.current);
				return prev + 1;
			});
		} else {
			pageNumberRef.current = Number(pageNumberRef.current) + 1;
			sessionStorage.setItem("lastPageNumber", pageNumberRef.current);
			actions.updateImagesWithSameContract(Number(pageNumberRef.current));
		}
	};

	const lastPage = () => {
		if (filter === "WALLET" && pageNumber > 1) {
			setPageNumber((prev) => {
				if (prev > 1) {
					pageNumberRef.current = prev - 1;
					sessionStorage.setItem("lastPageNumber", pageNumberRef.current);
					return prev - 1;
				}
			});
		} else {
			if (pageNumberRef.current > 1) {
				pageNumberRef.current = Number(pageNumberRef.current) - 1;
				sessionStorage.setItem("lastPageNumber", pageNumberRef.current);
				actions.updateImagesWithSameContract(Number(pageNumberRef.current));
			}
		}
	};

	const walletSearch = useCallback((tokenId) => {
		// if (!isNaN(Number(tokenId))) {
		walletTokenIdLookup.current = tokenId;
		setState({});
		// }
	}, []);

	return (
		<>
			<LetsBrowseSearch
				updateProject={updateProject}
				contractId={
					data?.selectedContract?.value || data?.selectedContract?._id
				}
				searchNames={searchNames}
			/>
			{/*This section below is the start of adding the contract in */}
			{loadingWallet ? (
				<Loader color={"#fc4ba3"} />
			) : (
				<RobotsList
					robotData={filteredData}
					contract={data.selectedContract}
					updateImagesWithSameContract={(tokenId) => {
						return actions.updateImagesWithSameContract(undefined, tokenId);
					}}
					updateSelectedContractAndFetchImages={(contract) => {
						return actions.updateSelectedContractAndFetchImages(contract);
					}}
					updateQuickCollection={updateQuickCollection}
					updateFilter={updateFilter}
					walletConnected={walletContents.nfts !== undefined}
					walletTokenIdLookup={walletTokenIdLookup}
					filter={filter}
					setFilter={setFilter}
					nextPage={nextPage}
					lastPage={lastPage}
					walletSearch={walletSearch}
					pageNumberRef={pageNumberRef}
					setPageNumber={setPageNumber}
					pageNumber={pageNumber}
					collectionSize={collectionSize}
				/>
			)}
			{data.loading && <Loader color={"#fc4ba3"} />}
		</>
	);
};

export default LetsBrowseScreen;
