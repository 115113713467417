// import { Tab } from 'react-bootstrap';
import NameHandler from './NameHandler';
import NameService from './NameService';
import NFTMessaging from './NFTMessaging';
import TokenBoundAccount from './TokenBoundAccount/TokenBoundAccount';
import Tabs, { Tab } from 'src/components/Tabs/Tabs';
import { useEffect, useState } from 'react';

const ServicesTabs = ({
	contractAddress,
	tokenIdToUse,
	name,
	namingTweet,
	owned,
	updateName,
	setNameUpdated,
	updateNameInput,
	setUpdateNameInput,
	setUpdatedName,
	selectedToken,
	setSelectedToken
}) => {


	const [defaultLabel, setDefaultLabel] = useState("MESSAGING")
	const lastService = localStorage.getItem("service")


	useEffect(() => {
		if (!name) {
			setDefaultLabel("NAMING")
		} else {
			setDefaultLabel(lastService)
		}
	}, [lastService, name])

	return (
		// <Tab.Container
		// 	defaultActiveKey="account"
		// 	id="uncontrolled-tab-example"
		// 	className="services-tabs"
		// >
		// 	<Nav className='services-tabs nav nav-tabs'>
		// 		<TooltipTopNavItem
		// 			title={"NAMING"}
		// 			eventKey={"naming"}
		// 		/>
		// 		<TooltipTopNavItem
		// 			title={"MESSAGING"}
		// 			eventKey={"messaging"}
		// 			className={`messaging unread`}

		// 		/>
		// 		<TooltipTopNavItem
		// 			title={"ACCOUNTS"}
		// 			tooltipMessage={"Token Bound Accounts coming"}
		// 			eventKey={"account"}
		// 		/>

		// 	</Nav>
		// 	<Tab.Content>
		// 		<Tab.Pane eventKey="naming">
		// 			<NameHandler
		// 				owned={owned}
		// 				contractAddress={contractAddress}
		// 				tokenId={tokenIdToUse}
		// 				providedName={name}
		// 				updateName={updateName}
		// 				setNameUpdated={setNameUpdated}
		// 				updateNameInput={updateNameInput}
		// 				setUpdateNameInput={setUpdateNameInput}
		// 				setUpdatedName={setUpdatedName}
		// 			/>
		// 			<NameService
		// 				contractAddress={contractAddress}
		// 				tokenId={tokenIdToUse}
		// 				providedName={name}
		// 				namingTweet={namingTweet}
		// 				owned={owned}
		// 				name={name}
		// 				setUpdateNameInput={setUpdateNameInput}
		// 				updateName={updateName}
		// 			/>
		// 		</Tab.Pane>
		// 	</Tab.Content>
		// 	<Tab.Content>
		// 		<Tab.Pane eventKey="messaging">
		// 			<NFTMessaging
		// 				tokenIdToUse={tokenIdToUse}
		// 				contractAddress={contractAddress}
		// 				owned={owned}
		// 			/>
		// 		</Tab.Pane>
		// 	</Tab.Content>

		// 	<Tab.Content>
		// 		<Tab.Pane eventKey="account">
		// 			<TokenBoundAccount
		// 				contractAddress={contractAddress}
		// 				tokenId={tokenIdToUse}
		// 				name={name}
		// 			/>
		// 		</Tab.Pane>
		// 	</Tab.Content>
		// </Tab.Container>




		<Tabs
			defaultLabel={defaultLabel}
			className="services-tabs"
		>
			<Tab label='NAMING'>
				<NameHandler
					owned={owned}
					contractAddress={contractAddress}
					tokenId={tokenIdToUse}
					providedName={name}
					updateName={updateName}
					setNameUpdated={setNameUpdated}
					updateNameInput={updateNameInput}
					setUpdateNameInput={setUpdateNameInput}
					setUpdatedName={setUpdatedName}
				/>
				<NameService
					contractAddress={contractAddress}
					tokenId={tokenIdToUse}
					providedName={name}
					namingTweet={namingTweet}
					owned={owned}
					name={name}
					setUpdateNameInput={setUpdateNameInput}
					updateName={updateName}
				/>
			</Tab>

			<Tab label='MESSAGING'>
				<NFTMessaging
					tokenIdToUse={tokenIdToUse}
					contractAddress={contractAddress}
					owned={owned}
				/>
			</Tab>
			<Tab label='TOKEN BOUND ACCOUNT'>
				<TokenBoundAccount
					contractAddress={contractAddress}
					tokenId={tokenIdToUse}
					name={name}
					owned={owned}
					setDefaultLabel={setDefaultLabel}
				/>
			</Tab>
		</Tabs>

	);
}

export default ServicesTabs
