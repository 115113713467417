import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DisplayTooltip = ({
	displayAddress,
	owner,
	tokenBoundNFT,
}: {
	displayAddress: string;
	owner: string;
	tokenBoundNFT: any;
}) => {
	const renderTooltip = (props: any) => (
		<Tooltip id='button-tooltip' {...props}>
			{displayAddress}
		</Tooltip>
	);

	return (
		<OverlayTrigger
			placement='top'
			delay={{ show: 250, hide: 400 }}
			overlay={renderTooltip}>
			<p className='text-pink p-0 mb-0 fs-4'>
				{tokenBoundNFT ? (
					<a
						className='text-pink ms-1'
						target='_blank'
						href={`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${tokenBoundNFT.contractAddress}/${tokenBoundNFT.tokenId}`}
						rel='noreferrer'>
						{`${tokenBoundNFT.walletAddress.substring(0, 6)}...`}
					</a>
				) : (
					<a
						className='text-pink ms-1'
						target='_blank'
						href={`https://etherscan.io/address/${owner}`}
						rel='noreferrer'>
						{" "}
						{`${displayAddress.substring(0, 7)}...`}
					</a>
				)}
			</p>
		</OverlayTrigger>
	);
};

export default DisplayTooltip;
