import { IS_DEV } from "src/constants"

export enum EtherscanEnum {
    TOKEN = "token",
    ADDRESS = "address",
    HASH = "hash",
}
function etherscanLink(data: string, category: EtherscanEnum): string {
    let url = ""
    if (IS_DEV) {
        url = "https://sepolia.etherscan.io"
    } else {
        url = "https://etherscan.io"
    }

    switch (category) {
        case EtherscanEnum.ADDRESS:
            return `${url}/address/${data}`

        case EtherscanEnum.TOKEN:
            return `${url}/token/${data}`

        case EtherscanEnum.HASH:
            return `${url}/tx/${data}`
        default:
            return url;
    }
}

export { etherscanLink }


