import { AxiosResponse } from "axios";
import { IContracts } from "global";
import { nftrAxios } from "./axiosInstance";

async function fetchContractAddresses(): Promise<IContracts> {
  let results: IContracts = [];

  try {
    const body = { skip: 0, take: 150000 };
    const {
      data: { data: assets },
    } = await nftrAxios.post<AxiosResponse<IContracts>>(
      "/fetch-contract-addresses",
      body
    );
    return assets;
  } catch (error) {
    console.log(error);
  }

  return results;
}

export default fetchContractAddresses;
