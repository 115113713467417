export const defaultCollections = [
  {
    _id: "0xc1a5fc1d2b17f3a4c592da5d3c533c27f353d8a1",
    updated: 0,
    collectionLength: 1,
    slug: "testladyapesclub",
    name: "OkayApesClub",
    message: "",
    startingIndex: "0",
    needsUpdate: true,
    txCountYearly: 9402,
    txCountMonthly: 5,
    txCountWeekly: 2,
    imageUrl:
      "https://lh3.googleusercontent.com/zQgxnHtWSxqyiurbBSIuKSwX5IWP54Y0hfvAwWoshYM_eAlsYyIcDG_cw4jOQsoLp9-K-xw290qOdEz7CzOhXU7Ty1UC1_xFtPcs=s120",
    openseaMetadata: {
      banner_image_url:
        "https://lh3.googleusercontent.com/moweBH3C_gk6HckZSlyEyT-vtpELzrq2sVPTJuriVdVpNk0hgVYQfZpZtHl4FKF-dC2Ml77aezDG8phl_uehZA742hlT8NeY7NvK=s2500",
      chat_url: null,
      created_date: "2021-12-07T06:23:56.866258",
      default_to_fiat: false,
      description:
        "Welcome to the home of Okay Apes Club,The intention was always set to be a community-driven NFT project full of 15000+ Apes hand drawn",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "600",
      discord_url: "https://discord.gg/W6teedmMxw",
      display_data: { card_display_style: "contain" },
      external_url: null,
      featured: false,
      featured_image_url:
        "https://lh3.googleusercontent.com/SakJjv7PpFlYLVrrbiGEcdUKtGiDenPNUZuARg9psusDURKS5oyn05paj2walehkKWGpWuaOC6khEUv8VZ8DreLgii92GGSBujQaTg=s300",
      hidden: false,
      safelist_request_status: "approved",
      image_url:
        "https://lh3.googleusercontent.com/zQgxnHtWSxqyiurbBSIuKSwX5IWP54Y0hfvAwWoshYM_eAlsYyIcDG_cw4jOQsoLp9-K-xw290qOdEz7CzOhXU7Ty1UC1_xFtPcs=s120",
      is_subject_to_whitelist: false,
      large_image_url:
        "https://lh3.googleusercontent.com/SakJjv7PpFlYLVrrbiGEcdUKtGiDenPNUZuARg9psusDURKS5oyn05paj2walehkKWGpWuaOC6khEUv8VZ8DreLgii92GGSBujQaTg=s300",
      medium_username: null,
      name: "Okay Apes Club",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: "0x61b41394b72198486ab4d4ff512508a55e57fbf1",
      require_email: false,
      short_description: null,
      slug: "okay-apes-club",
      telegram_url: null,
      twitter_username: "tag_Hutton",
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
    },
    forceUpdate: false,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    badCollectionLength: false,
    floorPrice: null,
  },
  {
    _id: "0x40f1084da81d45abfefc65742232fb86db823f5b",
    updated: 0,
    created: 1657533822083,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "thedegenapesrgr",
    name: "TheDegenApes",
    message: "",
    startingIndex: "1",
    needsUpdate: true,
    txCountYearly: 3429,
    txCountMonthly: 3424,
    txCountWeekly: 3423,
    imageUrl:
      "https://openseauserdata.com/files/76127d8c34ad591bcf0ec888f33c38cc.ico",
    openseaMetadata: {
      banner_image_url:
        "https://openseauserdata.com/files/69c6581f5f3f03b1429d79db1323be60.png",
      chat_url: null,
      created_date: "2022-07-10T20:28:01.060194",
      default_to_fiat: false,
      description: "",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "0",
      discord_url: null,
      display_data: { card_display_style: "contain" },
      external_url: null,
      featured: false,
      featured_image_url: null,
      hidden: false,
      safelist_request_status: "not_requested",
      image_url:
        "https://openseauserdata.com/files/76127d8c34ad591bcf0ec888f33c38cc.ico",
      is_subject_to_whitelist: false,
      large_image_url: null,
      medium_username: null,
      name: "TheDegenApesegeg",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: null,
      require_email: false,
      short_description: null,
      slug: "thedegenapesrgr",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0x776d88edfbb990a93931c24c794fdd457996e3a6",
    updated: 0,
    created: 1657706684017,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "qwerweqradsfdasf",
    name: "Subscapes by Matt DesLauriers",
    message: "No Assets Returned From Opensea",
    startingIndex: null,
    needsUpdate: true,
    txCountYearly: null,
    txCountMonthly: 650,
    txCountWeekly: 650,
    imageUrl: "",
    openseaMetadata: null,
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0x004e7e28af6e18a36b749828ae199295e9e6b175",
    updated: 0,
    created: 1661508488500,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "sick-ape-society-official",
    name: "Sick Ape Society",
    message: "",
    startingIndex: "0",
    needsUpdate: true,
    txCountYearly: null,
    txCountMonthly: 1431,
    txCountWeekly: 1431,
    imageUrl:
      "https://openseauserdata.com/files/d2183bf9fc338b8d386fd84f00279e11.jpg",
    openseaMetadata: {
      banner_image_url:
        "https://openseauserdata.com/files/f0be133416029f4102b6c612202ce2ca.jpg",
      chat_url: null,
      created_date: "2022-08-25T12:01:28.093608",
      default_to_fiat: false,
      description:
        "MINT LIVE https://sickapessociety.netlify.app/\nUtility-based metajungle. We’re sick of rug pulls, that’s why we don’t give false promises and prepared everything before our big jump",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "0",
      discord_url: "https://discord.gg/sickapesociety",
      display_data: { card_display_style: "contain" },
      external_url: "https://sickapessociety.netlify.app/",
      featured: false,
      featured_image_url:
        "https://openseauserdata.com/files/d2183bf9fc338b8d386fd84f00279e11.jpg",
      hidden: false,
      safelist_request_status: "not_requested",
      image_url:
        "https://openseauserdata.com/files/d2183bf9fc338b8d386fd84f00279e11.jpg",
      is_subject_to_whitelist: false,
      large_image_url:
        "https://openseauserdata.com/files/d2183bf9fc338b8d386fd84f00279e11.jpg",
      medium_username: null,
      name: "Sick Ape Society Officials",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: null,
      require_email: false,
      short_description: null,
      slug: "sick-ape-society-official",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
      is_rarity_enabled: false,
      fees: {
        opensea_fees: { "0x0000a26b00c1f0df003000390027140000faa719": 250 },
      },
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0xaa8807cbca7a46c0ba0172ff8a026095ad0b0dba",
    updated: 0,
    created: 1661680924563,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "boredapeyogaclubofficial",
    name: "Bored Ape Yoga Club",
    message: "",
    startingIndex: "0",
    needsUpdate: true,
    txCountYearly: 3744,
    txCountMonthly: 3742,
    txCountWeekly: 420,
    imageUrl:
      "https://openseauserdata.com/files/5171d368b878484464b49156007e9711.jpg",
    openseaMetadata: {
      banner_image_url:
        "https://openseauserdata.com/files/f8d1d5723580844212cdf51e3dcd37bd.png",
      chat_url: null,
      created_date: "2022-08-27T20:10:09.987222",
      default_to_fiat: false,
      description: "BORED APE YOGA CLUB BY ENJOYOOR",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "0",
      discord_url: null,
      display_data: { card_display_style: "contain" },
      external_url: "http://boredapeyogacrew.com",
      featured: false,
      featured_image_url:
        "https://openseauserdata.com/files/f8d1d5723580844212cdf51e3dcd37bd.png",
      hidden: false,
      safelist_request_status: "not_requested",
      image_url:
        "https://openseauserdata.com/files/5171d368b878484464b49156007e9711.jpg",
      is_subject_to_whitelist: false,
      large_image_url:
        "https://openseauserdata.com/files/f8d1d5723580844212cdf51e3dcd37bd.png",
      medium_username: null,
      name: "BoredApeYogaClub",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: null,
      require_email: false,
      short_description: null,
      slug: "boredapeyogaclubofficial",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
      fees: {
        opensea_fees: { "0x0000a26b00c1f0df003000390027140000faa719": 250 },
      },
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0x31906358c68c2f77423832c9189ef58cec4598df",
    updated: 0,
    created: 1661940226090,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "gangst-apesnft",
    name: "Gangst Apes",
    message: "Hidden collection or bad contract address",
    startingIndex: "0",
    needsUpdate: true,
    txCountYearly: 2890,
    txCountMonthly: 2890,
    txCountWeekly: 2889,
    imageUrl:
      "https://openseauserdata.com/files/264ab1a1fdb8e4a7e28270a892e09dde.jpg",
    openseaMetadata: {
      banner_image_url:
        "https://openseauserdata.com/files/92b4903dc0341d7d230a20555ab834cc.jpg",
      chat_url: null,
      created_date: "2022-08-28T11:12:09.350440",
      default_to_fiat: false,
      description:
        "Mint live gangstapes.xyz\n\n969 Supply Unique PFP's, Utility and many other features👁\nFirst 1000 WL 1 X Free Mint\n212 Hand Drawn Traits and 3 Exclusive 1/1's\nPublic Price TBA",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "0",
      discord_url: null,
      display_data: { card_display_style: "contain" },
      external_url: "http://gangstapes.xyz",
      featured: false,
      featured_image_url:
        "https://openseauserdata.com/files/264ab1a1fdb8e4a7e28270a892e09dde.jpg",
      hidden: true,
      safelist_request_status: "not_requested",
      image_url:
        "https://openseauserdata.com/files/264ab1a1fdb8e4a7e28270a892e09dde.jpg",
      is_subject_to_whitelist: false,
      large_image_url:
        "https://openseauserdata.com/files/264ab1a1fdb8e4a7e28270a892e09dde.jpg",
      medium_username: null,
      name: "Gangst Apes Official",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: null,
      require_email: false,
      short_description: null,
      slug: "gangst-apesnft",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
      fees: {
        opensea_fees: { "0x0000a26b00c1f0df003000390027140000faa719": 250 },
      },
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0xb152cad96629c464d404225f013215a477ac52a3",
    updated: 0,
    created: 1661940634653,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "",
    name: "Bored Ape Yoga Club ",
    message: "No Assets Returned From Opensea",
    startingIndex: null,
    needsUpdate: true,
    txCountYearly: null,
    txCountMonthly: 657,
    txCountWeekly: 657,
    imageUrl: "",
    openseaMetadata: null,
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0xeae0fb1c4528ee766ae157f99f743206e8759aa7",
    updated: 0,
    created: 1661940769403,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "planet-apes-official",
    name: "Planet Apes Official",
    message: "Hidden collection or bad contract address",
    startingIndex: "1",
    needsUpdate: true,
    txCountYearly: null,
    txCountMonthly: 494,
    txCountWeekly: 494,
    imageUrl:
      "https://openseauserdata.com/files/51bf4f5e8cfd76ad56451c4bded0b978.jpg",
    openseaMetadata: {
      banner_image_url:
        "https://openseauserdata.com/files/961627671a3f00ebd67a788b8e532608.jpg",
      chat_url: null,
      created_date: "2022-08-29T13:54:42.534953",
      default_to_fiat: false,
      description:
        "Planet Apes! Breed, train and battle with apes! 🐒 (Free Mint [Here](https://planetapes.xyz/))  \r\n1000 Beautiful and adorable NFTs living on the Ethereum Blockchain. Mint yours for FREE now at https://planetapes.xyz/",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "0",
      discord_url: null,
      display_data: { card_display_style: "contain" },
      external_url: "https://planetapes.xyz/",
      featured: false,
      featured_image_url: null,
      hidden: true,
      safelist_request_status: "not_requested",
      image_url:
        "https://openseauserdata.com/files/51bf4f5e8cfd76ad56451c4bded0b978.jpg",
      is_subject_to_whitelist: false,
      large_image_url: null,
      medium_username: null,
      name: "Planet Apes Official",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: null,
      require_email: false,
      short_description: null,
      slug: "planet-apes-official",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
      fees: {
        opensea_fees: { "0x0000a26b00c1f0df003000390027140000faa719": 250 },
      },
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0x8de57590887da5b2dc467a1d105be727b42e95a8",
    updated: 0,
    created: 1663668104223,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "interstellar-ape-v2",
    name: "interstellar APE",
    message: "Hidden collection or bad contract address",
    startingIndex: "4208",
    needsUpdate: true,
    txCountYearly: 4224,
    txCountMonthly: 4224,
    txCountWeekly: 4224,
    imageUrl:
      "https://i.seadn.io/gcs/files/89e468471b05a0d3f49b0b14def8879a.gif?w=500&auto=format",
    openseaMetadata: {
      banner_image_url:
        "https://i.seadn.io/gcs/files/a8a10b39894140435fd8ae43516963fc.jpg?w=500&auto=format",
      chat_url: null,
      created_date: "2022-09-19T10:40:57.762372",
      default_to_fiat: false,
      description:
        "interstellar APE is a project built for the community, by the community. The goal of interstellar APE is to create a brand that facilitates a seamless adoption of the web3 space through our community fueled ventures and collaborations.",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "500",
      discord_url: null,
      display_data: { card_display_style: "contain" },
      external_url: null,
      featured: false,
      featured_image_url: null,
      hidden: true,
      safelist_request_status: "not_requested",
      image_url:
        "https://i.seadn.io/gcs/files/89e468471b05a0d3f49b0b14def8879a.gif?w=500&auto=format",
      is_subject_to_whitelist: false,
      large_image_url: null,
      medium_username: null,
      name: "interstellar APEs Club",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: "0x956093242c0c7e1e39284831a22fa598ed1e1a85",
      require_email: false,
      short_description: null,
      slug: "interstellar-ape-v2",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
      fees: {
        seller_fees: { "0x956093242c0c7e1e39284831a22fa598ed1e1a85": 500 },
        opensea_fees: { "0x0000a26b00c1f0df003000390027140000faa719": 250 },
      },
      is_rarity_enabled: false,
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
  {
    _id: "0x4b387d1efc8b67dea554c9f55b660876275b0247",
    updated: 0,
    created: 1665138484332,
    collectionLength: 1,
    manuallyAdded: false,
    slug: "naughty-ape-v2",
    name: "naughty APE",
    message: "Hidden collection or bad contract address",
    startingIndex: "562",
    needsUpdate: true,
    txCountYearly: null,
    txCountMonthly: 580,
    txCountWeekly: 580,
    imageUrl:
      "https://i.seadn.io/gcs/files/cd4caba716aae337eed6d3143effa2db.jpg?w=500&auto=format",
    openseaMetadata: {
      banner_image_url:
        "https://i.seadn.io/gcs/files/99d856fd22e5ea26a95abb503ba9e55f.jpg?w=500&auto=format",
      chat_url: null,
      created_date: "2022-10-07T04:57:42.770132+00:00",
      default_to_fiat: false,
      description:
        "naughty ape. A collection of 5566 Apes that take us back to basics. None of the fluff, all of the value.",
      dev_buyer_fee_basis_points: "0",
      dev_seller_fee_basis_points: "0",
      discord_url: "https://discord.gg/invite/justape",
      display_data: { card_display_style: "contain" },
      external_url: null,
      featured: false,
      featured_image_url: null,
      hidden: true,
      safelist_request_status: "not_requested",
      image_url:
        "https://i.seadn.io/gcs/files/cd4caba716aae337eed6d3143effa2db.jpg?w=500&auto=format",
      is_subject_to_whitelist: false,
      large_image_url: null,
      medium_username: null,
      name: "naughty APE ClUB",
      only_proxied_transfers: false,
      opensea_buyer_fee_basis_points: "0",
      opensea_seller_fee_basis_points: "250",
      payout_address: null,
      require_email: false,
      short_description: null,
      slug: "naughty-ape-v2",
      telegram_url: null,
      twitter_username: null,
      instagram_username: null,
      wiki_url: null,
      is_nsfw: false,
      fees: {
        opensea_fees: { "0x0000a26b00c1f0df003000390027140000faa719": 250 },
      },
      is_rarity_enabled: false,
    },
    forceUpdate: null,
    assetsHavingImages: 0,
    assetsMissingImages: 0,
    assets: null,
    floorPrice: null,
  },
];