import { TokenboundClient } from "@tokenbound/sdk";
import { ISelectedNFT, IToken } from "./interface";
import { ISelectNamedNFT } from "src/components/Common/SearchNFTOptions/SearchByName";

export async function deployAccount(contractAddress: `0x${string}`, tokenId: string, tokenboundClient: TokenboundClient) {
  try {
    await tokenboundClient.createAccount({
      tokenContract: contractAddress,
      tokenId: tokenId,
    });
  } catch (error) {
    console.log(error);
  }
}

export function isNotEmptyString(str: string | null) {
  return str !== "";
}

export async function transfer(account: `0x${string}`, receiverAddress: `0x${string}`, tokenToTransfer: IToken, tokenboundClient: TokenboundClient, amount: string) {

  console.log({ receiverAddress, account, tokenToTransfer });

  try {

    if (tokenToTransfer.symbol === "ETH") {
      await tokenboundClient.transferETH({
        account: account,
        amount: +amount,
        recipientAddress: receiverAddress as `0x${string}`,
      });
    } else {

      if (tokenToTransfer.address) {
        console.log("transferring token");

        await tokenboundClient.transferERC20({
          account: account,
          amount: +amount,
          recipientAddress: receiverAddress as `0x${string}`,
          erc20tokenAddress: tokenToTransfer.address,
          erc20tokenDecimals: tokenToTransfer.decimal,
        });
      }

    }
  } catch (error) {
    console.log("transferTokenBoundToken", error);
  }
}

export async function getTokenBoundAccountAddress(
  contractAddress: `0x${string}`,
  tokenId: string,
  tokenboundClient: TokenboundClient
) {
  try {
    const receiverAddress = tokenboundClient.getAccount({
      tokenContract: contractAddress,
      tokenId: tokenId,
    });

    return receiverAddress;
  } catch (error) {
    console.log(`getTokenBoundAccountAddress Error ${JSON.stringify(error)}`);
  }
}


export async function transferNFT(selectedNFT: ISelectedNFT, receiverAddress: `0x${string}`, tokenboundClient: TokenboundClient, account: `0x${string}`) {
  try {
    if (
      selectedNFT &&
      receiverAddress &&
      isNotEmptyString(selectedNFT.contractAddress) &&
      isNotEmptyString(selectedNFT.tokenId)
    ) {
      const transferNFT = await tokenboundClient.transferNFT({
        account,
        tokenType: "ERC721",
        tokenContract: selectedNFT.contractAddress as `0x${string}`,
        tokenId: selectedNFT.tokenId,
        recipientAddress: receiverAddress as `0x${string}`,
      });
      console.log(transferNFT); //...
    }
  } catch (error) {
    console.log(`transferNFT ${JSON.stringify(error)}`);
  }
}

export async function getTokenBoundAccount(selected: ISelectedNFT | ISelectNamedNFT, tokenboundClient: TokenboundClient) {
  const { contractAddress, tokenId } = selected;

  if (isNotEmptyString(contractAddress) && isNotEmptyString(tokenId)) {
    const receiverAddress = await getTokenBoundAccountAddress(
      contractAddress as `0x${string}`,
      tokenId,
      tokenboundClient
    );
    return receiverAddress;
  }
}