import { Button } from "antd";
import { useCallback, useContext, useEffect } from "react";
import Address from "./Address";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import axios from "axios";
import decodeJwtToken from "src/helpers/decodeJwtToken";

const baseURL = process.env.REACT_APP_API_URL_BASE;

export default function Account() {
  const modalButtons = [];

  const { address, connect, disconnect, wallet, injectedProvider, blockExplorer, userSigner } = useContext(Web3OnboardContext)
  const authToken = JSON.parse(localStorage.getItem("authToken")) || {}

  const authenticate = useCallback(async (msg) => {
    try {
      if (address && msg) {
        const signature = await userSigner.signMessage(msg);
        const { data: token } = await axios.post(`${baseURL}authenticate`, {
          signature,
          publicAddress: address
        })
        authToken[address] = token
        const stringifyToken = JSON.stringify(authToken)
        localStorage.setItem("authToken", stringifyToken)
      }

    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  useEffect(() => {
    async function login() {
      try {

        if (address) {
          const { data: user } = await axios.get(`${baseURL}users?publicAddress=${address}`)
          if (user) {
            const msg = `I am signing my one-time nonce: ${user.nonce}`;

            const userToken = authToken[address]
            const decodedToken = decodeJwtToken(userToken, address)
            if (decodedToken.valid === false) {
              await authenticate(msg)
            }
          }
          else {
            const { data: user } = await axios.post(`${baseURL}users`, { publicAddress: address })
            const msg = `I am signing my one-time nonce: ${user.nonce}`;
            await authenticate(msg)
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])


  const minimized = undefined;
  if (wallet) {
    modalButtons.push(
      <Button
        key="logoutbutton"
        style={{
          verticalAlign: "top",
          marginLeft: 8,
          marginTop: 4,
          backgroundColor: "transparent",
        }}
        onClick={async () => {
          await disconnect(wallet)
          window.localStorage.removeItem('connectedWallets')
          window.sessionStorage.removeItem("lastFilter")
        }}
        className="bordered-button account-button"
      >
        logout
      </Button>
    );
  } else {
    modalButtons.push(
      <Button
        key="loginbutton"
        style={{
          verticalAlign: "top",
          marginLeft: 8,
          marginTop: 4,
          backgroundColor: "transparent",
        }}
        /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
        onClick={async () => {
          await connect()
        }}
        className="bordered-button account-button"
      >
        Connect
      </Button>
    );
  }


  const display = minimized ? (
    ""
  ) : (
    <span>
      {address ? (
        <Address
          address={address}
          ensProvider={injectedProvider}
          blockExplorer={blockExplorer}
        />
      ) : (
        ""
      )}
    </span>
  );

  return (
    <div
      className="account-bar"
      style={{ position: "fixed", right: 0, top: 0, padding: 10, zIndex: 3000 }}
    >
      {display}
      {modalButtons}
    </div>
  );
}
