import { ethers } from "ethers";
import { initialState } from "./WalletBalancesContext";

const getNamingBalances = async (readContracts, userSigner, address) => {
  if (
    readContracts &&
    readContracts.Weth9 &&
    readContracts.GoldenTicket &&
    readContracts.NFTRegistry &&
    userSigner &&
    address
  ) {
    try {
      // var a = readContracts;
      const [namingPrice, eth, weth, wethAllow, gtk, namingCredits] =
        await Promise.all([
          readContracts.NFTRegistry.namingPriceEther(),
          userSigner.getBalance(),
          readContracts.Weth9.balanceOf(address),
          readContracts.Weth9.allowance(
            address,
            readContracts.NFTRegistry.address
          ),
          readContracts.GoldenTicket.balanceOf(address),
          readContracts.NamingCredits.credits(address),
        ]);

      return {
        namingPrice: {
          decimal: Number(ethers.utils.formatUnits(namingPrice, "ether")),
          original: namingPrice,
        },
        eth: { decimal: Number((eth / 10 ** 18).toFixed(6)), original: eth },
        weth: { decimal: Number((weth / 10 ** 18).toFixed(6)), original: weth },
        wethAllow: {
          original: wethAllow,
          decimal: Number(wethAllow) / 10 ** 18,
        },
        gtk: { decimal: Number(gtk.toString()), original: gtk },
        namingCredits: {
          decimal: Number(namingCredits.toString()),
          original: namingCredits,
        },
      };
    } catch (error) {
      console.log("error getting balances", error);
      //debugger;
      return initialState;
    }
  }
};
export default getNamingBalances;
