import { useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { SocketContext } from "src/contexts/SocketContext";

const baseURL = process.env.REACT_APP_API_URL_BASE as string;

const TransferWarningModal = ({
  transferWarningOpen,
  setTransferWarningOpen,
  NameMarketplace,
  setAllowTransfer,
  name,
  setActiveOffer,
  selected,
  NFTRegistry,
}: any) => {
  const { socket } = useContext(SocketContext);

  async function cancelOffer() {
    try {
      setTransferWarningOpen(false);

      const res = await NameMarketplace.nameNoLongerForSale(name);

      await res.wait();

      setActiveOffer(null);
      await axios.post(`${baseURL}name/marketplace/event`, {
        event: "NameNoLongerForSale",
        blockNumber: res.blockNumber,
        transactionHash: res.transactionHash,
      });

      await axios.put(`${baseURL}name/offer`, {
        name,
        collectionFrom: selected.contractAddress,
        tokenFrom: selected.tokenId,
      });

      // completed

      socket.emit("OfferCreated");
      setAllowTransfer(true);
    } catch (error) {
      // warning error

      console.log(error);
      setAllowTransfer(false);
      setTransferWarningOpen(false);
    }
  }

  async function approveTransfer() {
    try {
      setTransferWarningOpen(false);
      const res = await NFTRegistry?.allowTransfer(
        selected.contractAddress,
        selected.tokenId.toString()
      );

      await res.wait();

      setAllowTransfer(true);
    } catch (error) {
      console.log(error);
      setAllowTransfer(false);
      setTransferWarningOpen(false);
    }
  }

  //
  return (
    <>
      <Modal
        show={transferWarningOpen}
        onHide={() => setTransferWarningOpen(false)}
        className="my-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>WARNING</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <p>You're about transfer a name with an active offer</p>
          <p
            style={{
              textAlign: "left",
            }}
          >
            Do you want to approve transfer for the recipient NFT?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <button
              className="offer-btn"
              onClick={cancelOffer}
              style={{
                padding: "6px 24px",
                width: "208px",
                fontWeight: "300",
                fontSize: "1rem",
                backgroundColor: "#fff",
                color: "red",
                marginRight: "16px",
              }}
            >
              CANCEL OFFER
            </button>
            <button
              className="offer-btn"
              onClick={() => {
                approveTransfer();
                setAllowTransfer(true);
                setTransferWarningOpen(false);
              }}
              style={{
                padding: "6px 24px",
                width: "208px",
                fontWeight: "300",
                fontSize: "1rem",
                marginLeft: "16px",
              }}
            >
              APPROVE
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TransferWarningModal;
