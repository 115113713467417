import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { WOW } from 'wowjs'
import { Image, Col, Row } from 'react-bootstrap'

const FeaturedItem = ({ img, title, desc, inverted, url }) => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <div className="featured-item">
      {inverted === 'true' ? (
        <Row className="align-items-center inverted-item">
          <Col
            md={7}
            className="order-xl-1 order-lg-1 order-md-1 order-sm-2 order-last"
          >
            <div className="featured-content wow fadeInLeft">
              <div className="nftr-text-box">
                <h2>{title}</h2>
                <Image src="./img/pink-bar.png" />
                <p>{desc}</p>
                {url && (
                  <div className="button-wrapper">
                    <Link to={url} className="bordered-button pink-border">
                      Staking
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col
            md={5}
            className="order-xl-2 order-lg-2 order-md-2 order-sm-1 order-first"
          >
            <div className="featured-img wow fadeInRight">
              <Image src={img} />
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="align-items-center">
          <Col md={5}>
            <div className="featured-img wow fadeInLeft">
              <Image src={img} />
            </div>
          </Col>
          <Col md={7}>
            <div className="featured-content wow fadeInRight">
              <div className="nftr-text-box">
                <h2>{title}</h2>
                <Image src="./img/pink-bar.png" />
                <p>{desc}</p>
                {url && (
                  <div className="button-wrapper">
                    <Link to={url} className="bordered-button pink-border">
                      Stake
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default FeaturedItem
