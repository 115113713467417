import { createContext, useRef, useEffect, useState } from "react";
import { fetchNames, fetchLRToken, fetchOnlyNamed } from "src/apis";

const initialState = {};

export const NamesDataContext = createContext(initialState);

const NamesDataProvider = ({ children }) => {
  const [, updateValue] = useState(1);
  const namesRef = useRef(null);
  useEffect(() => {
    //fetch stuff and update state
    fetchNames()
      .then(loadNames)
      .catch((error) => {
        console.log(error);
      });
    setInterval(() => {
      fetchNames()
        .then(loadNames)
        .catch((error) => {
          console.log(error);
        });
    }, 600000);
  }, []);

  function loadNames(names) {
    names.forEach((namedCollection) => {
      Object.keys(namedCollection.nfts).forEach((nft) => {
        namedCollection.nfts[nft].tokenId = nft;
      });
    });
    namesRef.current = names;
    updateValue((prev) => prev + 1);
  }

  function checkNFT(contractAddress, tokenId) {
    if (
      !namesRef.current ||
      Object.keys(namesRef.current) === 0 ||
      !contractAddress ||
      tokenId === undefined
    ) {
      return null;
    }
    let collection = namesRef.current.find((e) => e._id === contractAddress);
    if (collection) {
      let nft = collection.nfts[tokenId];
      if (nft) {
        return nft.name;
      }
      return null;
    }
    return null;
  }

  function updateNFT(contractAddress, tokenId, name) {
    let collection = namesRef.current.find(
      (e) => e._id === contractAddress.toLowerCase()
    );
    fetchLRToken(contractAddress.toLowerCase(), tokenId)
      .then((res) => {
        let imageUrl = "";
        if (res && res.data && res.data.imageURI) {
          imageUrl = res.data.imageURI;
        }
        if (collection) {
          collection.nfts[tokenId] = {
            imageUrl: imageUrl,
            name: name,
            timestamp: Date.now(),
          };
        }
        updateValue((prev) => prev + 1);
      })
      .catch(console.error);

    return null;
  }
  function checkCollection(contractAddress) {
    if (namesRef.current && Object.keys(namesRef.current).length === 0)
      return [];
    let contractNames = namesRef.current.find((e) => e._id === contractAddress);
    if (
      contractNames &&
      contractNames.nfts &&
      Object.keys(contractNames).length
    ) {
      return Object.keys(contractNames.nfts).map((e) => ({
        ...contractNames.nfts[e],
        _id: e,
        contractAddress: contractAddress,
        tokenId: e,
      }));
    }
    return [];
  }
  function searchNames(value = "") {
    let results = [];
    if (namesRef.current && Object.keys(namesRef.current).length === 0) {
      return results;
    }
    for (let contract of namesRef.current) {
      for (let nft of Object.keys(contract.nfts)) {
        if (
          contract.nfts[nft].name.toLowerCase().indexOf(value.toLowerCase()) >
          -1
        ) {
          results.push({
            ...contract.nfts[nft],
            contractAddress: contract._id,
            tokenId: nft,
          });
        }
      }
    }
    return results;
  }


  const fetchNamedData = async (pageNumber) => {
    const { data } = await fetchOnlyNamed(pageNumber);
    return data
  }
  const valuesObject = {
    checkNFT,
    checkCollection,
    searchNames,
    updateNFT,
    fetchNamedData
  };

  return (
    <NamesDataContext.Provider value={valuesObject}>
      {/* {namesRef.current && Object.keys(namesRef.current).length === 0 ? (
        <input
          className="loading-text"
          readOnly
          value="Loading Names..."
        ></input>
      ) : null} */}
      {children}
    </NamesDataContext.Provider>
  );
};

export default NamesDataProvider;
