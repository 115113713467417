import { useEffect } from 'react'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './rnmtokenused.css'

const RnmTokenUsed = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <section className="rnmtokenused-section position-relative">
      <div className="pink-shape-wrapper">
        <Image
          className="pink-shape position-absolute"
          src="./img/pink-shape.png"
        />
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="rnm-token-used-content">
              <div className="nftr-text-box wow fadeInUp">
                <h2>
                  THE <strong>RNM</strong> TOKEN
                </h2>
                <Image className="pink-bar" src="./img/pink-bar.png" />
                <p>
                  Nifter's native token, RNM, can be staked for a share of
                  platform ETH revenue and governance.
                  <br />
                  The RNM (Rename) token is an ERC-20{' '}
                  <a
                    href="https://docs.nftr.name/architecture/tokenomics"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                  >
                    utility-strengthened floor
                  </a>{' '}
                  token that also has utility as a secondary currency for naming
                  NFTs. When it's less costly to name an NFT with RNM than ETH,
                  RNM is burned in the process, exerting deflationary pressure.
                  RNM will be accrued by Hold Farmers, Stakers, and Namers at
                  varying rates, allowing them additional naming or renaming of
                  more NFTs.
                </p>
              </div>
              <div className="rnmtokenused-thumbnail-wrapper">
                <Image
                  className="rnmtokenused-thumbnail wow fadeInRight"
                  src="./img/rnm-token-used.png"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default RnmTokenUsed
