import { Skeleton, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Blockies from "react-blockies";
import { useLookupAddress } from "../hooks";
import { TokenboundClient } from "@tokenbound/sdk";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { getAlchemyImages } from "src/hooks/useTokenBoundCollectibles";
import { Image } from "react-bootstrap";
import { ethers } from "ethers";
import contracts from "src/contracts/external_contracts";

// changed value={address} to address={address}

/*
  ~ What it does? ~

  Displays an address with a blockie image and option to copy address

  ~ How can I use? ~

  <Address
    address={address}
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    fontSize={fontSize}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
  - Provide fontSize={fontSize} to change the size of address text
*/

const { Text } = Typography;

const blockExplorerLink = (address, blockExplorer) =>
  `${blockExplorer || "https://etherscan.io/"}${"address/"}${address}`;


export default function Address(props) {
  const address = props.value || props.address;
  const [tokenBoundNFT, setTokenBoundNFT] = useState({
    contractAddress: "",
    tokenId: "",
    name: "",
    imageUrl: "",
    walletAddress: "",
  });
  const { userSigner } = useContext(Web3OnboardContext);

  const tokenboundClient = new TokenboundClient({
    signer: userSigner,
    chainId: 1,
  });

  useEffect(() => {
    async function getTokenBoundNFT() {
      try {
        const nft = await tokenboundClient.getNFT({
          accountAddress: address,
        });

        if (nft) {
          const images = await getAlchemyImages(nft.tokenContract, nft.tokenId);

          setTokenBoundNFT((prev) => ({
            ...prev,
            contractAddress: nft.tokenContract,
            tokenId: nft.tokenId,
            imageUrl: images?.image2,
            walletAddress: address,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (address) {
      getTokenBoundNFT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const baseProvider = new ethers.providers.InfuraProvider(
    "mainnet",
    "d34a8d5f505a4dfcafacadd4e019e771"
  );

  useEffect(() => {
    const NFTRegistry = new ethers.Contract(
      "0xc662a8Ce36392d4B9489D0B59605a1874711BFc6",
      contracts[1].contracts.NFTRegistry.abi,
      baseProvider
    );

    async function getTokenBoundNFTOwnerName() {
      try {
        if (
          tokenBoundNFT.contractAddress &&
          tokenBoundNFT.tokenId
        ) {
          const tbNFTOwnerName = await NFTRegistry.tokenName(
            tokenBoundNFT.contractAddress,
            tokenBoundNFT.tokenId
          );
          setTokenBoundNFT((prev) => ({ ...prev, name: tbNFTOwnerName }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (tokenBoundNFT?.contractAddress && tokenBoundNFT?.tokenId) {
      getTokenBoundNFTOwnerName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenBoundNFT.contractAddress, tokenBoundNFT.tokenId]);

  const ens = useLookupAddress(props.ensProvider, address);

  if (!address) {
    return (
      <span>
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </span>
    );
  }

  let displayAddress = address.substr(0, 6);

  if (ens && ens.indexOf("0x") < 0) {
    displayAddress = ens;
  } else if (props.size === "short") {
    displayAddress += "..." + address.substr(-4);
  } else if (props.size === "long") {
    displayAddress = address;
  }

  const etherscanLink = blockExplorerLink(address, props.blockExplorer);
  if (props.minimized) {
    return (
      <span style={{ verticalAlign: "middle" }}>
        <a
          style={{ color: "#fff" }}
          target="_blank"
          href={etherscanLink}
          rel="noopener noreferrer"
        >
          <Blockies seed={address.toLowerCase()} size={8} scale={2} />
        </a>
      </span>
    );
  }

  let text;
  if (props.onChange) {
    text = (
      <Text editable={{ onChange: props.onChange }}  >
        <a
          style={{ color: "#ddd", textDecoration: "none" }}
          target="_blank"
          href={etherscanLink}
          rel="noopener noreferrer"
        >
          {displayAddress}
        </a>
      </Text>
    );
  } else {
    text = (
      <Text >
        <a
          style={{ color: "#ddd", fontSize: 35, textDecoration: "none" }}
          target="_blank"
          href={etherscanLink}
          rel="noopener noreferrer"
        >
          {displayAddress}
        </a>
      </Text>
    );
  }

  return (
    <span className="address-image-display">
      <span style={{ verticalAlign: "middle" }}>
        {
          tokenBoundNFT.imageUrl !== "" ?
            <a
              className='text-pink ms-1'
              target='_blank'
              href={`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${tokenBoundNFT.contractAddress}/${tokenBoundNFT.tokenId}`}
              rel='noreferrer'>
              <Image
                className='token-owner-image'
                src={tokenBoundNFT.imageUrl}
              />
            </a> :
            <Blockies seed={address.toLowerCase()} size={8} scale={props.fontSize ? props.fontSize / 7 : 4} />
        }
      </span>
      <span style={{ verticalAlign: "middle", paddingLeft: 5, fontSize: props.fontSize ? props.fontSize : 28, color: "#fff" }}>
        {tokenBoundNFT.name !== "" ?
          <Text >
            <a
              style={{ color: "#ddd", textDecoration: "none" }}
              target="_blank"
              href={etherscanLink}
              rel="noopener noreferrer"
            >
              {`default.${tokenBoundNFT.name}.nftr`}
            </a>
          </Text>
          : text}
      </span>
    </span>
  );
}
