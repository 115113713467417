import { useEffect, useState } from "react";
import { fetchWalletContents } from "src/apis";
import getAlchemyMetadata from "src/apis/getAlchemyMetadata";
import { INFT, TokenBoundNFT } from "src/components/LetsBrowseScreen/RobotDetails/TokenBoundAccount/interface";
import formatIPFS from "src/helpers/formatIPFS";

export function useTokenBoundCollectibles(account: `0x${string}`) {
  const [tokenBoundNFTs, setTokenBoundNFTs] = useState<TokenBoundNFT[]>([]);

  useEffect(() => {
    async function fetchTokenBoundAccountNFTs() {
      try {
        if (account) {
          const { data } = await fetchWalletContents(account);

          const nfts: INFT[] = data.data;
          let tokenBoundNFT;

          let tokenBoundNFTs: any[] = nfts.map(async (item) => {
            const images = await getAlchemyImages(
              item.contractAddress,
              item.tokenId
            );

            if (images) {
              const { image1, image2 } = images;
              tokenBoundNFT = {
                collectionName: item.collectionName,
                contractAddress: item.contractAddress,
                tokenId: item.tokenId,
                image1: image1,
                image2: image2,
              };

            } else {
              tokenBoundNFT = {
                collectionName: item.collectionName,
                contractAddress: item.contractAddress,
                tokenId: item.tokenId,
                image1: null,
                image2: null,
              };
            }

            return tokenBoundNFT;
          });

          tokenBoundNFTs = await Promise.all(tokenBoundNFTs);
          setTokenBoundNFTs(tokenBoundNFTs);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchTokenBoundAccountNFTs();
  }, [account]);

  return tokenBoundNFTs
}

export async function getAlchemyImages(
  contractAddress: string,
  tokenId: number
): Promise<{ image1: string; image2: string } | undefined> {
  try {
    const { data } = await getAlchemyMetadata(contractAddress, tokenId);

    if (data && data.data && data.data.media) {
      const image1 = formatIPFS(data?.data?.media[0].raw);
      const image2 = formatIPFS(data?.data?.media[0].gateway);

      return { image1, image2 };
    }
  } catch (error) {
    console.log(error);
  }
}
