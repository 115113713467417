import { ethers } from "ethers";
export async function approveWeth(tx, writeContracts, callback) {
  try {
    await tx(
      writeContracts.Weth9.approve(
        writeContracts.NFTRegistry.address,
        ethers.BigNumber.from(
          "115792089237316195423570985008687907853269984665640564039457584007169174851493"
        )
      ),
      (update) => {
        console.log("📡 Transaction Update:", update);
        if (update && (update.status === "confirmed" || update.status === 1)) {
          if (callback) callback(update);
        }
      }
    );
  } catch (error) {
    console.log(error);
  }
  return;
}
// export async function checkWethAllowance(tx, readContracts, userAddress, callback){
//     try {
//         await tx(
//           readContracts.Weth9.allowance(
//             userAddress,
//             readContracts.NFTRegistry.address
//           ),
//           (update) => {
//             console.log("📡 Transaction Update:", update);
//             if (update && (update.status === "confirmed" || update.status === 1)) {
//               callback();
//             }
//           }
//         );
//       } catch (error) {
//         console.log(error);
//       }
// }
