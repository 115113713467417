const DB_NAME = process.env.REACT_APP_DB_NAME

export const DBConfig = {
	name: DB_NAME,
	version: 1,
	objectStoresMeta: [
		{
			store: "conversations",
			storeConfig: { keyPath: "_id" },
			storeSchema: [
				{
					name: "_id",
					keypath: "_id",
					options: { unique: true },
				},
				{
					name: "active",
					keypath: "active",
					options: { unique: false },
				},
				{
					name: "status",
					keypath: "status",
					options: { unique: false },
				},
				{
					name: "members",
					keypath: "members",
					options: { unique: false },
				},
				{
					name: "createdAt",
					keypath: "createdAt",
					options: { unique: false },
				},
			],
		},
		{
			store: "messages",
			storeConfig: { keyPath: "_id" },
			storeSchema: [
				{
					name: "_id",
					keypath: "_id",
					options: { unique: true },
				},
				{
					name: "conversationId",
					keypath: "conversationId",
					options: { unique: false },
				},
				{
					name: "sender",
					keypath: "sender",
					options: { unique: false },
				},
				{
					name: "text",
					keypath: "text",
					options: { unique: false },
				},
				{
					name: "members",
					keypath: "members",
					options: { unique: false },
				},
				{
					name: "isRead",
					keypath: "isRead",
					options: { unique: false },
				},
				{
					name: "createdAt",
					keypath: "createdAt",
					options: { unique: false },
				},
			],
		},
	],
};
