import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { WOW } from "wowjs";
import { Col, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import placeholder from "../../assets/images/customplaceholder.png";
import formatIPFS from "../../helpers/formatIPFS";
import "../LetsBrowseScreen/robotCard.css";
import LiveName from "../LiveName";
import axios from "axios";
import { IS_DEV } from "src/constants";
import useGetName from "src/hooks/useGetName";
import { listTag } from "src/assets/images";
import getAlchemyMetadata from "src/apis/getAlchemyMetadata";

const baseURL = process.env.REACT_APP_API_URL_BASE;

const RobotCard = ({ robot, project, filter, contractAddress, count }) => {
	const [tokenDetails, setTokenDetails] = useState({
		collectionName: null,
		imageURI: null,
		image2: null,
	});

	const [activeOffer, setActiveOffer] = useState(null);
	const { name } = useGetName(robot.contractAddress, robot.tokenId, 3000);

	useEffect(() => {
		new WOW({
			mobile: false,
			live: false,
		}).init();
	}, []);

	useEffect(() => {
		async function alchemyMetadata() {
			try {
				const { data } = await getAlchemyMetadata(
					robot.contractAddress,
					robot.tokenId.toString()
				);

				if (data) {
					const imageURI = formatIPFS(data?.data?.media[0].raw);
					const image2 = formatIPFS(data?.data?.media[0].gateway);

					setTokenDetails({
						imageURI: imageURI,
						collectionName: data?.data?.contractMetadata?.name,
						image2: image2,
					});

					setTokenDetails({
						imageURI: imageURI,
						collectionName: data?.data?.contractMetadata?.name,
						image2: image2,
					});
				}
			} catch (error) {
				console.log(
					{ contractAddress: robot.contractAddress, tokenId: robot.tokenId },
					error
				);
			}
		}

		if (!IS_DEV && robot.contractAddress && robot.tokenId) {
			alchemyMetadata();
		}
	}, [robot.contractAddress, robot.tokenId]);

	useEffect(() => {
		async function getUserBids() {
			const { data: offer } = await axios.get(`${baseURL}name/offer/${name}`);

			setActiveOffer(offer);
		}

		if (name) {
			getUserBids();
		}
	}, [name, setActiveOffer]);

	const renderRef = useRef(0);

	let url = tokenDetails.image2 || robot.img || robot.imageUrl || robot.image;

	if (url) {
		formatIPFS(url);
	}
	if (!url) {
		url = placeholder;
	}
	const savedUrl = url.indexOf("lh3") > -1;
	if (savedUrl) {
		url = url + "=w300";
	}

	let id = robot.tokenId;
	if (id !== undefined && id.length > 10) id = id.slice(0, 8) + "...";
	const linkState = {
		robot: { ...robot, project: project, filter: filter },
	};
	let showProject = true;

	let projectToUse =
		tokenDetails.collectionName ||
		robot.project ||
		robot.collectionName ||
		project ||
		"NFTR";

	return (
		<Col
			lg={3}
			md={6}
			sm={6}
			xs={6}
			className='text-center wow fadeInUp '
			data-wow-delay={"0." + Math.round(count / 4) + "s"}>
			<div className='robot-card'>
				<div className='robot-image-border'>
					<Link
						to={{
							pathname: `/robot-details/${contractAddress}/${robot.tokenId}`,
							state: linkState,
						}}>
						<Image
							className='punk'
							src={url}
							onError={function (error) {
								renderRef.current++;
								if (renderRef.current === 1) {
									error.target.src = tokenDetails.imageURI;
								} else if (renderRef.current === 2) {
									error.target.src = placeholder;
								}
							}}
						/>
					</Link>
				</div>
				<div className='robot-text'>
					<div className='name-with-tag'>
						<LiveName
							contractAddress={contractAddress}
							tokenId={robot.tokenId}
							updateInterval={60000}
						/>
						{activeOffer ? (
							<img src={listTag} alt='list tag' className='list-tag' />
						) : null}
					</div>

					{showProject && projectToUse.length < 21 ? (
						<h3 className='text-pink'>{projectToUse}</h3>
					) : showProject && projectToUse.length > 21 ? (
						<ProjectTooltip projectToUse={projectToUse} />
					) : null}
					<h4 className='text-green'>ID {id}</h4>
				</div>
			</div>
		</Col>
	);
};

export default RobotCard;

function ProjectTooltip({ projectToUse }) {
	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			{projectToUse}
		</Tooltip>
	);

	return (
		<OverlayTrigger
			placement='top'
			delay={{ show: 250, hide: 400 }}
			overlay={renderTooltip}>
			<h3 className='text-pink'>{`${projectToUse.substring(0, 21)}...`}</h3>
		</OverlayTrigger>
	);
}
