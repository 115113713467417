import { nftrAxios } from "./axiosInstance";

async function postEvents(event: object): Promise<any> {
  try {
    const result = await nftrAxios.post("/event", event);
    return;
  } catch (error) {
    console.log(error);
  }
}

interface SearchEvent {
  type: string;
  data: {
    address: string;
    name?: string;
    [key: string]: any;
  };
}

export function postSearch(contractAddress: string, name?: string) {
  let searchEvent: SearchEvent = {
    type: "search",
    data: { address: contractAddress, dt: Date.now() },
  };
  if (name) {
    searchEvent.data.name = name;
  }
  return postEvents(searchEvent);
}
