import axios from "axios";

function fetchTokenByName(name: string = "") {
  return axios
    .get(
      `https://api.nftr.name/registry/token_by_name/${name
        .toLowerCase()
        .split('_').join(' ')}`
    )
    .then((res) => res.data);
}

export default fetchTokenByName;
