import React from "react";

const Plus = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='21'
			height='21'
			viewBox='0 0 21 21'
			fill='none'>
			<path
				d='M18.1648 10.1544C18.1645 10.3201 18.0985 10.479 17.9811 10.5961C17.8637 10.7131 17.7047 10.7787 17.5389 10.7785L11.2889 10.7699L11.2802 17.0199C11.28 17.1856 11.2139 17.3445 11.0966 17.4615C10.9792 17.5786 10.8201 17.6442 10.6544 17.644C10.4886 17.6438 10.3297 17.5777 10.2127 17.4603C10.0956 17.3429 10.03 17.1839 10.0302 17.0181L10.0389 10.7681L3.78891 10.7595C3.62315 10.7592 3.46427 10.6932 3.34722 10.5758C3.23018 10.4584 3.16455 10.2994 3.16478 10.1336C3.16501 9.96784 3.23108 9.80896 3.34845 9.69191C3.46582 9.57486 3.62488 9.50924 3.79064 9.50947L10.0406 9.51813L10.0493 3.26813C10.0495 3.10237 10.1156 2.94349 10.233 2.82644C10.3503 2.7094 10.5094 2.64377 10.6752 2.644C10.8409 2.64423 10.9998 2.7103 11.1169 2.82767C11.2339 2.94504 11.2995 3.1041 11.2993 3.26986L11.2906 9.51986L17.5406 9.52852C17.7064 9.52875 17.8653 9.59482 17.9823 9.71219C18.0994 9.82956 18.165 9.98862 18.1648 10.1544Z'
				fill='white'
			/>
		</svg>
	);
};

export default Plus;
