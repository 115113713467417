import { useEffect } from 'react'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import EarnBox from './EarnBox'
import './earn.css'

const EarnBoxList = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <section className="earn-rnm-section">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <div className="nftr-text-box wow fadeInUp">
              <h2>
                EARN <strong>RNM</strong>
              </h2>
              <Image src="./img/pink-bar.png" className="pink-bar" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4} className="wow fadeInDown" data-wow-delay="0.1s">
            <EarnBox
              title="HOLD FARM"
              img="./img/service-1.svg"
              desc="Accumulate RNM just for holding your curated NFT."
            />
          </Col>
          <Col
            className="earn-box-second wow fadeInDown"
            md={4}
            style={{ marginTop: '-60px' }}
            data-wow-delay="0.3s"
          >
            <EarnBox
              title="STAKE"
              img="./img/service-2.svg"
              desc="Stake your RNM for RNM and ETH yield."
            />
          </Col>
          <Col md={4} className="wow fadeInDown" data-wow-delay="0.5s">
            <EarnBox
              title="NAME NFTs"
              img="./img/service-3.svg"
              desc="Get a share of naming rewards yield for every NFT you name."
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EarnBoxList
