import axios, { AxiosRequestConfig } from "axios";

function fetchLRToken(contractAddress: string, tokenId: number | string) {
  const params = new URLSearchParams([
    ["collection", contractAddress],
    ["tokenId", tokenId.toString()],
  ]);

  let config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.looksrare.org/api/v1/tokens",
    headers: {},
    params,
  };

  return axios(config).then((res) => res.data).catch(error => console.log(error));
}

export default fetchLRToken;
