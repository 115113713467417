import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import '../TokenBanner/banner.css'

const TokenBanner = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <>
      <section className="banner h-100">
        <div className="banner-shapes">
          <Image
            className="banner-shape-left banner-shape position-absolute wow fadeIn"
            src="./img/left-banner-shape.png"
            data-wow-delay="0.7s"
          />
          <Image
            className="banner-shape-right banner-shape position-absolute wow fadeIn"
            src="./img/right-banner-shape.png"
            data-wow-delay="0.7s"
          />
        </div>
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg={10}>
              <div className="banner-text text-center">
                <h1 className="text-green wow fadeInUp">
                  <span className="text-pink" style={{ fontSize: 80 }}>
                    RNM
                  </span>
                  <br />
                  OUR NATIVE TOKEN
                </h1>
                <p className="text-white wow fadeInUp">COMING SOON</p>
                <Image className="wow fadeInUp" src="./img/range-bar.png" />
                <p className="text-blue wow fadeInUp">
                  THE FIRST TOKEN <span className="text-white">SUPPORTED</span>
                </p>
                <p
                  className="text-blue wow fadeInUp"
                  style={{ marginTop: -20 }}
                >
                  BY A <span className="text-pink">UTILITY-STRENGTHENED</span>{' '}
                  FLOOR
                </p>
                <div className="banner-button-group">
                  <a
                    href="https://docs.nftr.name/architecture/tokenomics/"
                    className="bordered-button wow fadeInUp"
                    data-wow-delay="0.6s"
                    target="_blank"
                  >
                    LEARN MORE
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default TokenBanner
