import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import "./TokenBoundAccount.css";
import { Core } from "@walletconnect/core";
import { Web3Wallet, Web3WalletTypes } from "@walletconnect/web3wallet";
import { buildApprovedNamespaces } from "@walletconnect/utils";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { hexToUtf8 } from "web3-utils";
import { TokenboundClient } from "@tokenbound/sdk";

const LoginWithNFTModal = ({
	openLoginModal,
	setOpenLoginModal,
	account,
	tokenboundClient,
}: ILoginWithNFTModalProps) => {
	const [uri, setUri] = useState("");
	const { userSigner } = useContext(Web3OnboardContext);

	const core = new Core({
		projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
	});

	async function loginWithNFT() {
		try {
			const web3wallet = await Web3Wallet.init({
				core,
				metadata: {
					name: "Demo app",
					description: "Demo Client as Wallet/Peer",
					url: "www.walletconnect.com",
					icons: [],
				},
			});

			async function onSessionProposal({
				id,
				params,
			}: Web3WalletTypes.SessionProposal) {
				console.log("onSessionProposal");

				try {
					const approvedNamespaces = buildApprovedNamespaces({
						proposal: params,
						supportedNamespaces: {
							eip155: {
								chains: ["eip155:1"],
								methods: [
									"eth_sendTransaction",
									"eth_signTypedData_v4",
									"personal_sign",
								],
								events: ["chainChanged", "accountsChanged"],
								accounts: [`eip155:1:${account}`],
							},
						},
					});

					await web3wallet.approveSession({
						id,
						namespaces: approvedNamespaces,
					});
				} catch (error) {
					console.log(error);
				}
			}

			async function onSessionRequest(event: Web3WalletTypes.SessionRequest) {
				console.log("onSessionRequest", event);

				const { topic, params, id } = event;
				const { request } = params;

				if (request.method === "eth_sendTransaction") {
					try {
						console.log("eth_sendTransaction");

						const value = request.params[0].value
							? request.params[0].value
							: 0n;

						const executedCall = await tokenboundClient.executeCall({
							account: account as `0x${string}`,
							to: request.params[0].to,
							data: request.params[0].data,
							value: BigInt(parseInt(value)),
						});

						console.log(executedCall);
					} catch (error) {
						console.log("onSessionRequest", error);
					}
				}

				if (request.method === "personal_sign") {
					const requestParamsMessage = request.params[0];
					console.log("personal_sign");

					const message = hexToUtf8(requestParamsMessage);
					const signedMessage = await userSigner.signMessage(message);

					const response = { id, result: signedMessage, jsonrpc: "2.0" };

					await web3wallet.respondSessionRequest({ topic, response });
				}
			}

			web3wallet.on("session_proposal", onSessionProposal);
			web3wallet.on("session_request", onSessionRequest);

			await web3wallet.core.pairing.pair({
				uri: uri,
				activatePairing: true,
			});
			setOpenLoginModal(false);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<Modal
				show={openLoginModal}
				onHide={() => {
					setOpenLoginModal(false);
				}}
				className='my-5 LoginWithNFTModal-body'>
				<Modal.Header closeButton>
					<Modal.Title>Log in as your NFT</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='LoginWithNFTModal'>
						<h4>Step One</h4>
						<p>
							<span className='font-bold'>Open WalletConnect</span> on the site
							you want to log in as your NFT with
						</p>
					</div>
					<div className='LoginWithNFTModal'>
						<h4>Step Two</h4>

						<p>
							Click the <span className='font-bold'>copy to clipboard</span>{" "}
							icon in the top right corner of the modal
						</p>
					</div>
					<div className='LoginWithNFTModal'>
						<h4>Step Three</h4>

						<p>
							<span className='font-bold'>Return to nftr.name</span>, paste the
							code into the input below, and click connect
						</p>
					</div>

					<div className='walletconnect-container'>
						<input
							type='text'
							placeholder='WalletConnect URI'
							className='select-token-wrapper deposit'
							onChange={(e) => setUri(e.target.value)}
							value={uri}
						/>
						<button
							className='offer-btn'
							onClick={async () => {
								loginWithNFT();
							}}
							style={{
								padding: "6px 24px",
								width: "208px",
								fontWeight: "300",
								fontSize: "1rem",
							}}>
							Connect
						</button>
					</div>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default LoginWithNFTModal;

interface ILoginWithNFTModalProps {
	openLoginModal: boolean;
	setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
	account: string | null;
	tokenboundClient: TokenboundClient;
}
