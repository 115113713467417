import axios from 'axios'
import { useEffect, useState } from 'react'

function useGetBlockedCountries() {
  const [blocked, setBlocked] = useState(false)
  useEffect(() => {
    const BLOCKED_GTK_MINTING_COUNTRIES = process.env.REACT_APP_BLOCKED_GTK_MINTING_COUNTRIES.split(
      ', ',
    )
    async function getUserCountry() {
      const { data } = await axios.get(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_GEOLOCATION_API_KEY}`,
      )

      const { country_code2 } = data

      if (BLOCKED_GTK_MINTING_COUNTRIES.includes(country_code2)) {
        setBlocked(true)
      }
    }

    getUserCountry()
  }, [])

  return { blocked }
}

export default useGetBlockedCountries
