import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import {
	ethMainnetGasBlockPrices,
	infuraRPC,
	initWeb3Onboard,
} from "../Web3Onboard";
import { useCallback, useEffect, useState } from "react";
import { createContext } from "react";
import {
	ConnectOptions,
	DisconnectOptions,
	WalletState,
} from "src/interfaces/web3Onboard.interface";
import { DEFAULT_NETWORK, IS_DEV } from "src/constants";
import { BigNumberish, ethers } from "ethers";
import { useContractLoader } from "src/hooks";
import { Transactor } from "src/helpers";
import { GasPrice } from "@web3-onboard/gas";
import hardHatContracts from "../contracts/hardhat_contracts.json";
import externalContracts from "../contracts/external_contracts.js";

const targetNetwork = DEFAULT_NETWORK;
const blockExplorer = targetNetwork.blockExplorer;
const localProviderUrl = targetNetwork.rpcUrl;

const initialState = {
	address: undefined,
	readContracts: null,
	writeContracts: null,
	userSigner: null,
	tx: null,
	selectedChainId: undefined,
	blockExplorer: null,
	web3Onboard: null,
	connect: null,
	disconnect: null,
	wallet: null,
	loading: false,
	targetNetwork,
};

export const Web3OnboardContext = createContext<any>(initialState);

const Web3OnboardContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
	const [readContracts, setReadContracts] = useState<any>(null);
	const [writeContracts, setWriteContracts] = useState<any>(null);
	const [connected, setConnected] = useState(false);

	const [{ connectedChain, settingChain }] = useSetChain();
	const [bnGasPrices, setBNGasPrices] = useState<GasPrice[] | undefined>();
	const [web3Onboard, setWeb3Onboard] = useState<any>(null);

	const [rpcInfuraGasPrices, setRPCInfuraGasPrices] = useState<
		IFees | undefined
	>();

	useEffect(() => {
		setWeb3Onboard(initWeb3Onboard);
	}, []);

	const address = wallet?.accounts[0].address;
	const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER
		? process.env.REACT_APP_PROVIDER
		: localProviderUrl;

	const localProvider = new ethers.providers.StaticJsonRpcProvider(
		localProviderUrlFromEnv
	);
	const localChainId =
		localProvider && localProvider._network && localProvider._network.chainId;

	let ethersProvider: any;
	if (wallet) {
		ethersProvider = new ethers.providers.Web3Provider(wallet.provider, "any");
	}

	const signer = ethersProvider?.getSigner(address);

	const etherScanLink = IS_DEV
		? "https://sepolia.etherscan.io/"
		: "https://etherscan.io/";

	const gasPrice = rpcInfuraGasPrices
		? rpcInfuraGasPrices.price
		: ethers.utils.parseUnits("4.1", "gwei");

	const tx = useCallback(() => {
		Transactor(signer, gasPrice, etherScanLink);
	}, [signer, gasPrice, etherScanLink]);

	const chainId = parseInt(connectedChain?.id as string, 16);

	useEffect(() => {
		if (!wallet) return;
		const ethersProvider = new ethers.providers.Web3Provider(
			wallet.provider,
			"any"
		);

		const signer = ethersProvider?.getSigner(address);

		async function loadContracts(config?: any) {
			if (ethersProvider && typeof ethersProvider !== "undefined") {
				try {
					const providerNetwork = await ethersProvider?.getNetwork();

					const _chainId = config?.chainId || providerNetwork?.chainId;

					let contractList: any = {};
					let externalContractList: any = {};
					try {
						contractList = config?.hardhatContracts || hardHatContracts;
					} catch (e) {
						console.log(e);
					}
					try {
						externalContractList =
							config?.externalContracts || externalContracts;
					} catch (e) {
						console.log(e);
					}

					let combinedContracts: any = {};

					if (contractList[_chainId]) {
						for (const hardhatNetwork in contractList[_chainId]) {
							if (
								Object.prototype.hasOwnProperty.call(
									contractList[_chainId],
									hardhatNetwork
								)
							) {
								if (
									!config?.hardhatNetworkName ||
									hardhatNetwork === config?.hardhatNetworkName
								) {
									combinedContracts = {
										...combinedContracts,
										...contractList[_chainId][hardhatNetwork].contracts,
									};
								}
							}
						}
					}

					if (externalContractList[_chainId]) {
						combinedContracts = {
							...combinedContracts,
							...externalContractList[_chainId].contracts,
						};
					}

					const newContracts = Object.keys(combinedContracts).reduce(
						(accumulator: any, contractName: any) => {
							const _address =
								config?.customAddresses &&
								Object.keys(config?.customAddresses).includes(contractName)
									? config?.customAddresses[contractName]
									: combinedContracts[contractName].address;
							accumulator[contractName] = new ethers.Contract(
								_address,
								combinedContracts[contractName].abi,
								signer
							);
							return accumulator;
						},
						{}
					);

					setReadContracts(newContracts);
					setWriteContracts(newContracts);
					// return newContracts;
				} catch (e) {
					console.log("ERROR LOADING CONTRACTS!!", e);
				}
			}
		}

		loadContracts({ chainId: chainId });
	}, [address, chainId, wallet]);

	const valuesObject = {
		address,
		readContracts,
		writeContracts,
		userSigner: signer,
		web3Onboard: web3Onboard,
		tx,
		selectedChainId: chainId,
		blockExplorer,
		connect,
		disconnect,
		wallet,
		loading: false,
		localChainId,
		targetNetwork,
		injectedProvider: ethersProvider,
		connected,
		setConnected,
	};

	return (
		<Web3OnboardContext.Provider value={valuesObject}>
			{children}
		</Web3OnboardContext.Provider>
	);
};

export default Web3OnboardContextProvider;

export interface IWeb3Onboard {
	connect: (
		options?: ConnectOptions | undefined
	) => Promise<WalletState[]> | null;
	disconnect: (wallet: DisconnectOptions) => Promise<WalletState[]> | null;
	wallet: WalletState | null;
	web3Onboard: any;
	loading: boolean;
	blockExplorer: string | null;
	address: string | undefined;
	selectedChainId: string | undefined;
	targetNetwork: any;
}

interface IFees {
	price: string;
	maxPriorityFeePerGas: string;
	maxFeePerGas: string;
}
