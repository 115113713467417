import { useContext, useEffect, useState } from "react";
import { TokenboundClient, TBAccountParams } from "@tokenbound/sdk";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";


export function useTokenBoundAccount(contractAddress: `0x${string}`, tokenId: string) {
  const [account, setAccount] = useState<`0x${string}` | null>(null);
  const [accountDeployed, setAccountDeployed] = useState(false);
  const [loading, setLoading] = useState(true);


  const { userSigner } = useContext(Web3OnboardContext);


  useEffect(() => {
    async function getAccount() {
      const tokenboundClient = new TokenboundClient({
        signer: userSigner,
        chainId: 1,
      });
      try {
        const tokenBoundAccount = tokenboundClient.getAccount({
          tokenContract: contractAddress,
          tokenId: tokenId as TBAccountParams["tokenId"],
        });

        let isDeployed = false;

        if (account) {
          isDeployed = await tokenboundClient.checkAccountDeployment({
            accountAddress: account,
          });
        }

        setAccountDeployed(isDeployed);
        setAccount(tokenBoundAccount);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getAccount();
  }, [account, contractAddress, tokenId, userSigner]);

  return { account, accountDeployed, loading }
}