import React from "react";

const ClipBoard = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			fill='#77e5c4'
			className='bi bi-clipboard'
			viewBox='0 0 16 16'>
			<path d='M7 1a2 2 0 0 0-2 2V2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a2 2 0 0 0-2-2zM5 3a1 1 0 0 1-1-1V1a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H5zM4 4v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1zM4 4a1 1 0 0 0-1 1h10a1 1 0 0 0-1-1H4z' />
		</svg>
	);
};

export default ClipBoard;
