import { useContext, useEffect, useMemo, useState } from "react";
import useClipboard from "react-use-clipboard";
import { TokenboundClient } from "@tokenbound/sdk";
import "./TokenBoundAccount.css";
import Loader from "../../Loader";
import ClipBoard from "./ClipBoard";
import ExternalLinkIcon from "./ExternalLinkIcon";
import Tabs, { Tab } from "src/components/Tabs/Tabs";
import { useTokenBoundAccount } from "src/hooks/useTokenBoundAccount";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import TokenBoundAccountAssets from "./TokenBoundAccountAssets";
import TokenBoundAccountCollectibles from "./TokenBoundAccountCollectibles";
import { Alert } from "react-bootstrap";
import { useTokenBoundAssets } from "src/hooks/useTokenBoundAssets";
import { useTokenBoundCollectibles } from "src/hooks/useTokenBoundCollectibles";
import { ITokenBoundAccount } from "./interface";
import { deployAccount } from "./utils";
import WalletConnect from "src/assets/images/WalletConnect";
import LoginWithNFTModal from "./LoginWithNFTModal";

export const initialToken = {
	address: null,
	logoURI:
		"https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
	symbol: "",
	balance: 0,
	decimal: 0,
};

const TokenBoundAccount = ({
	contractAddress,
	tokenId,
	name,
	owned,
	setDefaultLabel,
}: ITokenBoundAccount) => {
	const { account, accountDeployed, loading } = useTokenBoundAccount(
		contractAddress,
		tokenId
	);

	useEffect(() => {
		localStorage.setItem("service", "TOKEN BOUND ACCOUNT");
	}, []);

	const tokens = useTokenBoundAssets(account as `0x${string}`);
	const tokenBoundNFTs = useTokenBoundCollectibles(account as `0x${string}`);

	const initialValue = account
		? account
		: "0x0000000000000000000000000000000000000000";

	const [, setCopied] = useClipboard(initialValue);
	const [show, setShow] = useState(false);
	const { userSigner } = useContext(Web3OnboardContext);
	const [openLoginModal, setOpenLoginModal] = useState(false);

	const tokenboundClient = useMemo(() => {
		return new TokenboundClient({
			signer: userSigner,
			chainId: 1,
		});
	}, [userSigner]);

	useEffect(() => {
		let timer: any;
		if (show) {
			timer = setTimeout(() => {
				setShow(false);
			}, 3000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [show]);

	return (
		<div className='token-bound-account'>
			<LoginWithNFTModal
				openLoginModal={openLoginModal}
				setOpenLoginModal={setOpenLoginModal}
				account={account}
				tokenboundClient={tokenboundClient}
			/>
			{!loading && account ? (
				<>
					<div className='d-flex  copy-account'>
						<div className='token-bound-address-container'>
							<div
								className='d-flex align-items-center token-bound-address'
								onClick={() => {
									setCopied();
									setShow(true);
								}}>
								<span className='token-address mb-0'>
									{account.substring(0, 4)} ... {account.substring(38)}
								</span>
								<ClipBoard />
							</div>
							<a
								href={`https://etherscan.io/address/${account}`}
								target='_blank'
								rel='noreferrer'
								className='external-link-icon'>
								<div>
									{name ? (
										<span className='nftr-account-address'>
											{`default.${name}.nftr`}
										</span>
									) : (
										<ExternalLinkIcon />
									)}
								</div>
							</a>
						</div>
						{!accountDeployed && owned ? (
							<button
								className='deploy-account-btn'
								onClick={async () => {
									await deployAccount(
										contractAddress,
										tokenId,
										tokenboundClient
									);
								}}>
								Deploy Account
							</button>
						) : accountDeployed && owned ? (
							<button
								className='deploy-account-btn'
								onClick={() => {
									setOpenLoginModal(true);
								}}>
								<WalletConnect />
								Connect as NFT
							</button>
						) : null}
					</div>
					{accountDeployed ? null : (
						<p className='tba-account-status'>
							This account is not deployed yet
						</p>
					)}
					<div className='token-bound-account-tabs'>
						<Tabs>
							<Tab label='Assets'>
								<TokenBoundAccountAssets
									contractAddress={contractAddress}
									tokenId={tokenId}
									owned={owned}
									tokens={tokens}
								/>
							</Tab>
							<Tab label='Collectibles'>
								<TokenBoundAccountCollectibles
									contractAddress={contractAddress}
									tokenId={tokenId}
									owned={owned}
									tokenBoundNFTs={tokenBoundNFTs}
								/>
							</Tab>
						</Tabs>

						{show && (
							<Alert variant={"success"} className='clipboard-alert'>
								Token bound account successfully copied to clipboard
							</Alert>
						)}
					</div>
				</>
			) : (
				<Loader color={"#fc4ba3"} />
			)}
		</div>
	);
};

export default TokenBoundAccount;
