import { nftrAxios } from "./axiosInstance";

function getNameHistory(contractAddress: string, tokenId: number | string) {
    const body = {
        contractAddress,
        tokenId: tokenId.toString(),
    }
    return nftrAxios.post("/name/history", body);
}

export default getNameHistory;
