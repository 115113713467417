import React, { useContext, useEffect, useRef, useState } from 'react'
import { LoadedContractsDataContext, WalletContentsDataContext } from 'src/contexts';
import "./SelectOwnNFT.css"
import formatIPFS from 'src/helpers/formatIPFS';
import getAlchemyMetadata from 'src/apis/getAlchemyMetadata';
import { Spinner } from 'react-bootstrap';
import { ethers } from "ethers"
import { Web3OnboardContext } from 'src/contexts/Web3OnboardContext';



const SelectOwnNFT = ({ selected, setSelected, top, position = "static", backgroundColor = "none", text = "#030a34", context = "OWNED", tokenBoundAccount = false, tokenBoundNFTs = [] }) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchId, setSearchId] = useState();

	const [collectionDetails, setCollectionDetails] = useState([{ name: "", _id: "" }])
	const { userSigner } = useContext(Web3OnboardContext);


	const [showIdDropdown, setShowIdDropDown] = useState(false);
	const [nftImage, setNftImage] = useState({ image1: "", image2: "" })
	const { data: walletContents } = useContext(WalletContentsDataContext);

	const [data,] = useContext(LoadedContractsDataContext);
	const [loading, setLoading] = useState(false)

	const addressRegex = /^0x[a-fA-F0-9]{40}$/;
	const validAddress = addressRegex.test(searchTerm);
	const tokens = tokenBoundAccount ? tokenBoundNFTs : walletContents?.nfts

	const uniqueTokens = context === "ALL" ? data?.contracts : context === "NAMED" ? data.NamedData : tokens?.filter(
		(token, index, self) =>
			index ===
			self.findIndex((t) => t.collectionName === token.collectionName)
	);

	const collectionDropdownRef = useRef(null);
	const tokenIdDropdownRef = useRef(null)

	useEffect(() => {
		function handleClickOutside(event) {
			if (collectionDropdownRef.current && !collectionDropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}

			if (tokenIdDropdownRef.current && !tokenIdDropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);


	useEffect(() => {
		setSelected({
			contractAddress: "",
			tokenId: "",
			collectionName: "",
			nftrName: ""
		})
		setNftImage({ image1: "", image2: "" })
	}, [context, setSelected])

	useEffect(() => {
		async function fetchCollectionName() {
			const abi = [
				"function name() view returns (string)",
			];
			try {
				if (validAddress) {
					const contract = new ethers.Contract(
						searchTerm,
						abi,
						userSigner
					);

					const collectionName = await contract.name();
					setCollectionDetails([{ name: collectionName, _id: searchTerm }])

				}
			} catch (error) {
				console.log(error);
				setCollectionDetails({ name: "", _id: searchTerm })

			}
		}
		fetchCollectionName()
	}, [searchTerm, userSigner, validAddress])

	// NamedData

	const filteredTokens = context === "OWNED" ? uniqueTokens?.filter((token) => validAddress ? token.contractAddress?.toLowerCase() === searchTerm?.toLowerCase() :
		token.collectionName?.toLowerCase().includes(searchTerm.trim()?.toLowerCase())
	) :

		// get collection name and replace
		// @WIP
		context === "ALL" && validAddress ? [...collectionDetails] :
			uniqueTokens?.filter((token) => validAddress ? token._id?.toLowerCase() === searchTerm?.toLowerCase() :
				token.name?.toLowerCase().includes(searchTerm.trim()?.toLowerCase())
			)

	// use effect



	const filteredIds = tokens?.filter(
		(token) =>
			token.contractAddress?.toLowerCase() ===
			selected.contractAddress?.toLowerCase()
	)?.sort((a, b) => a._id - b._id)

	const ids = searchId && filteredIds ? filteredIds.filter(item => item.tokenId.toString() === searchId)?.sort((a, b) => a._id - b._id) : filteredIds


	useEffect(() => {
		async function fetchNFTMetadata() {
			if (!isNotEmptyString(selected.contractAddress) || !isNotEmptyString(selected.tokenId)) {
				setNftImage({ image1: "", image2: "" });
				setLoading(false)
			}
			try {
				setLoading(true)
				if (isNotEmptyString(selected.contractAddress && isNotEmptyString(selected.tokenId))) {
					const { data } = await getAlchemyMetadata(
						selected.contractAddress,
						selected.tokenId
					);
					if (data && data.data && data.data.media) {
						const image1 = formatIPFS(data?.data?.media[0].raw);
						const image2 = formatIPFS(data?.data?.media[0].gateway);

						const images = { image1, image2 }
						setNftImage(images);
						setLoading(false)
					}
				}
			} catch (error) {
				console.log(error);
				setLoading(false)
			}
		}

		if (isNotEmptyString(selected.contractAddress) && isNotEmptyString(selected.tokenId)) {
			fetchNFTMetadata();
		}
	}, [selected?.contractAddress, selected?.tokenId]);

	function isNotEmptyString(str) {
		return str !== "";
	}

	// @REMOVE

	if (context === "ADDRESS") {
		return null
	}

	return (
		<div className={`d-flex select-nft-container ${nftImage.image1 || nftImage.image2 ? "select-own-nft-image" : "select-own-nft"}`}>
			<div className="nft-address">
				<div className="select-nft">
					<div className="d-flex align-items-center">
						<div
							className="select-token-wrapper select-collection"
							onClick={() => {
								setShowDropdown(!showDropdown)
								setShowIdDropDown(false)
							}
							}
						>
							<div className="select-token-container">
								<div className="select-token-content">
									<div className="select-token-details">
										{isNotEmptyString(selected.contractAddress) ? (
											<p className="select-token-text"
												style={{ color: text }}
											>
												{isNotEmptyString(selected.nftrName) ? selected.nftrName : isNotEmptyString(selected.collectionName) ? selected.collectionName : selected.contractAddress}
											</p>
										) :
											<p className="select-token-text text-uppercase"
												style={{ color: text }}
											>
												{context === "NAMED" ? "NAME" : "Collection Name / Address"}
											</p>}
									</div>
								</div>
							</div>
							<svg
								className="dropdown-icon"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 16 16"
								fill="none"
							>
								<path
									d="M11 5.632v1.4L8.2 10 5.4 7.032v-1.4H11z"
									fill={text}
								></path>
							</svg>
						</div>
					</div>
				</div>
				{showDropdown && (
					<div
						ref={collectionDropdownRef}
						className="dropdown-container"
						style={{
							height: "240px",
							// overflow: "auto",
							backgroundColor: backgroundColor,
							width: "360px",
							position: position,
							zIndex: "999",
							top: top
						}}

					>

						<input
							placeholder='Collection Name / Address'
							type="text"
							className="select-token-search"
							value={searchTerm}
							onChange={async (e) => {
								setSearchTerm(e.target.value);
							}}
						/>
						<ul className="token-list" style={{
							height: "75%",
							overflowY: "scroll",
						}}>

							{/* <li
								className="token-list-item"
							>Collection Name / Address</li> */}
							{filteredTokens
								?.map((token, index) => (
									<li
										className="token-list-item"
										key={index}
										onClick={() => {
											setNftImage("")
											setSearchTerm("");
											setShowDropdown(false);
											setSelected((prev) => ({
												...prev,
												contractAddress: context === "ALL" ? token._id : context === "NAMED" ? token.contractAddress : token.contractAddress,
												collectionName: context === "ALL" ? token.name : context === "NAMED" ? null : token.collectionName,
												tokenId: context === "NAMED" ? token.tokenId : "",
												nftrName: context === "NAMED" ? token.name : "",
											}));
										}}
									>
										{/* <span>{isNotEmptyString(token.collectionName) ? token.collectionName : token.contractAddress}</span> */}

										<span>

											{
												context === "OWNED" && isNotEmptyString(token.collectionName) ? token.collectionName : context === "ALL" && isNotEmptyString(token.name) ? token.name :
													context === "ALL" && isNotEmptyString(token._id) ? token._id :
														context === "NAMED" ? token.name :
															isNotEmptyString(token.contractAddress) ? token.contractAddress : token._id
											}
										</span>
									</li>
								))}
						</ul>
					</div>
				)}
			</div>

			{
				context === "NAMED" ? null : <div className="nft-id">

					{
						context === "ALL" ?
							<input
								placeholder='ID'
								id='select-token-search'
								type="text"
								className="select-token-search"
								value={selected.tokenId}
								onChange={async (e) => {
									setSelected((prev) => ({
										...prev,
										tokenId: e.target.value,
									}));
								}}
								style={{
									marginRight: "12px",
									marginLeft: "12px",
									width: "100px",
									color: "#fc4ba3"
								}}
							/> : <div className="select-nft-id">
								<div className="d-flex align-items-center">
									<div
										className="select-token-wrapper"
										onClick={() => {
											setShowIdDropDown(!showIdDropdown)
											setShowDropdown(false)
										}
										}
										style={{
											width: "100px",
										}}
									>
										<div className="select-token-container">
											<div className="select-token-content">
												<div className="select-token-details">
													{isNotEmptyString(selected.tokenId) ? (
														<p className="select-token-text"
															style={{ color: text }}

														>
															{selected.tokenId}
														</p>
													) : <p className="select-token-text"
														style={{ color: text }}

													>
														ID
													</p>}
												</div>
											</div>
										</div>
										<svg
											className="dropdown-icon"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 16 16"
											fill="none"
										>
											<path
												d="M11 5.632v1.4L8.2 10 5.4 7.032v-1.4H11z"
												fill={text}
											></path>
										</svg>
									</div>
								</div>
							</div>
					}



					{showIdDropdown && (
						<div
							ref={tokenIdDropdownRef}
							className="dropdown-container"
							style={{
								width: "120px",
								// marginLeft: "24px",
								paddingLeft: "0px",
								paddingRight: "0px",
								overflow: "hidden",
								height: "240px",
								backgroundColor: backgroundColor,
								position: position,
								zIndex: "999",
								top: top
							}}
						>
							<input
								placeholder='id'
								type="text"
								className="select-token-search"
								value={searchId}
								onChange={async (e) => {
									setSearchId(e.target.value);
								}}
								style={{
									width: "100px",
								}}
							/>
							<ul className="token-list" style={{
								height: "100%",
								overflowY: "scroll",
							}}>
								{ids?.map((token, index) => (
									<li
										className="token-list-item"
										key={index}
										onClick={() => {
											setNftImage("")
											setSelected((prev) => ({
												...prev,
												tokenId: token.tokenId,
											}));
											setShowIdDropDown(false);
										}}
										style={{
											paddingLeft: "0px",
											paddingRight: "0px",
											textAlign: "center",
										}}
									>
										<span>{token.tokenId}</span>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			}



			{
				loading ? <Spinner
					animation="border"
					role="status"
				></Spinner> :

					selected.contractAddress && selected.tokenId && isNotEmptyString(nftImage.image1) ?
						<img src={nftImage.image1} alt="select nft" className='nft-image'
							onError={function (error) {
								error.target.src = nftImage.image2
							}}
						/>
						: null

			}
		</div >

	)
}

export default SelectOwnNFT
