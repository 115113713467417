import { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Spinner, Table } from "react-bootstrap";
import "./NameService.css";
import BuyNameModal from "./BuyNameModal";
import formatIPFS from "src/helpers/formatIPFS";
import placeholder from "../../../assets/images/customplaceholder.png";
import "./NameService.css";
import { EtherscanEnum, etherscanLink } from "src/helpers/etherscan";
import { SocketContext } from "src/contexts/SocketContext";
import getAlchemyMetadata from "src/apis/getAlchemyMetadata";

const baseURL = process.env.REACT_APP_API_URL_BASE as string;

const NameBids = ({
	setOpenCreateBidModal,
	setOpenCreateOfferModal,
	name,
	address,
	owned,
	NameMarketplace,
	approval,
	approveTransfer,
	contractAddress,
	tokenId,
	setActiveOffer,
	activeOffer,
	activeBid,
	setActiveBid,
	setUpdateNameInput,
	awaitingApproval,
	ownNFT,
	setName,
	updateName,
}: any) => {
	const [bidderImage, setBidderImage] = useState("");
	const [openBuyNameModal, setOpenBuyNameModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [offerLoading, setOfferLoading] = useState(false);

	const hasNFT = ownNFT();

	const [selected, setSelected] = useState({
		contractAddress: "",
		tokenId: "",
		collectionName: "",
	});

	useEffect(() => {
		async function getUserBids() {
			try {
				const { data: bid } = await axios.get(`${baseURL}name/bid/${name}`);
				const { data: offer } = await axios.get(`${baseURL}name/offer/${name}`);

				setActiveBid(bid);
				setActiveOffer(offer);
			} catch (error) {
				console.log(error);
			}
		}

		if (name) {
			getUserBids();
		}
	}, [name, setActiveBid, setActiveOffer]);
	const { socket } = useContext(SocketContext);

	useEffect(() => {
		socket.on("UpdateBids", async () => {
			const { data: bid } = await axios.get(`${baseURL}name/bid/${name}`);
			setActiveBid(bid);
		});

		socket.on("UpdateOffers", async () => {
			const { data: offer } = await axios.get(`${baseURL}name/offer/${name}`);
			setActiveOffer(offer);
		});
	}, [name, setActiveBid, setActiveOffer, socket]);

	useEffect(() => {
		async function fetchNFTMetadata() {
			try {
				if (activeBid) {
					const { data } = await getAlchemyMetadata(
						activeBid.collectionTo,
						activeBid?.tokenTo
					);
					if (data) {
						const imageURI = formatIPFS(data?.data?.media[0].raw);
						setBidderImage(imageURI);
					}
				}
			} catch (error) {
				console.log(error);
			}
		}
		fetchNFTMetadata();
	}, [activeBid]);

	async function cancelBid() {
		try {
			setIsLoading(true);

			const res = await NameMarketplace.withdrawBidForName(name, {
				gasLimit: 100000,
			});
			await res.wait();
			setIsLoading(false);

			setActiveBid(null);
			setTimeout(() => {
				socket.emit("BidCreated");
			}, 5000);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	async function acceptBidForName() {
		try {
			setIsLoading(true);

			const feePerc = await NameMarketplace.feePerc();
			const minPrice = activeBid && activeBid?.value * 10 ** 18;

			const res = await NameMarketplace.acceptBidForName(
				name,
				minPrice?.toString(),
				feePerc.toString(),
				{
					gasLimit: 2000000,
				}
			);

			await res.wait();

			setActiveBid(null);
			setActiveOffer(null);

			socket.emit("NameTransfer");
			socket.emit("NameSold", activeBid);

			setIsLoading(false);

			setUpdateNameInput(true);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	async function nameNoLongerForSale() {
		try {
			setOfferLoading(true);
			const res = await NameMarketplace.nameNoLongerForSale(name);
			await res.wait();
			setActiveOffer(null);
			await axios.post(`${baseURL}name/marketplace/event`, {
				event: "NameNoLongerForSale",
				blockNumber: res.blockNumber,
				transactionHash: res.transactionHash,
			});

			await axios.put(`${baseURL}name/offer`, {
				name,
				collectionFrom: contractAddress,
				tokenFrom: tokenId,
			});

			setOfferLoading(false);

			setTimeout(() => {
				socket.emit("OfferCreated");
			}, 5000);
		} catch (error) {
			setOfferLoading(false);
			console.log(error);
		}
	}

	const allow =
		activeOffer &&
		activeOffer.onlyTo === "0x0000000000000000000000000000000000000000"
			? true
			: activeOffer &&
			  activeOffer.onlyTo !== "0x0000000000000000000000000000000000000000" &&
			  activeOffer.onlyTo?.toLowerCase() === address.toLowerCase()
			? true
			: false;

	const imageUrl =
		activeBid &&
		`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${activeBid.collectionTo}/${activeBid.tokenTo}`;

	return (
		<div>
			<BuyNameModal
				openBuyNameModal={openBuyNameModal}
				setOpenBuyNameModal={setOpenBuyNameModal}
				selected={selected}
				setSelected={setSelected}
				name={name}
				activeOffer={activeOffer}
				NameMarketplace={NameMarketplace}
				address={address}
				setUpdateNameInput={setUpdateNameInput}
			/>

			{activeBid ? (
				<div>
					<h3 className='text-center bid-header'>NAME BIDS</h3>

					<Table className='namehistory-table'>
						<thead className='namehistory-thead'>
							<tr className='namehistory-header-tr'>
								<th className='namehistory-th'>Date</th>
								<th className='namehistory-th'>From</th>
								<th className='namehistory-th'>Bid Amount</th>
								<th className='namehistory-th'>Bidder</th>
								{activeBid.bidder === address.toLowerCase() || owned ? (
									<th className='namehistory-th'>Action</th>
								) : null}
							</tr>
						</thead>
						<tbody className='namehistory-tbody'>
							<tr className='namehistory-body-tr'>
								<td className='namehistory-td' style={{ textAlign: "center" }}>
									{moment(activeBid.timestamp).format("l")}
								</td>
								<td className='namehistory-td' style={{ textAlign: "center" }}>
									{imageUrl ? (
										<a href={imageUrl} target='_blank' rel='noreferrer'>
											{bidderImage ? (
												<img
													src={bidderImage}
													onError={(error: any) => {
														error.target.src = placeholder;
													}}
													alt='bidderImage'
													style={{
														width: "25px",
														height: "25px",
														borderRadius: "12px",
													}}
												/>
											) : null}
										</a>
									) : null}
								</td>

								<td className='namehistory-td' style={{ textAlign: "center" }}>
									{Number(activeBid.value).toFixed(2)} Ξ
								</td>
								<td className='namehistory-td' style={{ textAlign: "center" }}>
									<a
										href={etherscanLink(
											activeBid.bidder,
											EtherscanEnum.ADDRESS
										)}
										target='_blank'
										rel='noopener noreferrer'
										style={{
											textDecoration: "none",
											color: "#3fecc6",
										}}>
										{activeBid.bidder.slice(0, 6)}
									</a>
								</td>
								{activeBid.bidder === address.toLowerCase() ? (
									<td
										className='namehistory-td cancel-offer'
										style={{ textAlign: "center" }}>
										<button className='cancel-bid' onClick={cancelBid}>
											{isLoading ? (
												<Spinner
													style={{
														height: "1.5rem",
														width: "1.5rem",
													}}
													animation='border'
													role='status'></Spinner>
											) : (
												"CANCEL BID"
											)}
										</button>
									</td>
								) : owned ? (
									approval ? (
										<td
											className='namehistory-td'
											style={{ textAlign: "center" }}>
											<button className='cancel-bid' onClick={acceptBidForName}>
												{isLoading ? (
													<Spinner
														style={{
															height: "1.5rem",
															width: "1.5rem",
														}}
														animation='border'
														role='status'></Spinner>
												) : (
													"SELL NAME"
												)}
											</button>
										</td>
									) : (
										<td
											className='namehistory-td'
											style={{ textAlign: "center" }}>
											<button className='cancel-bid' onClick={approveTransfer}>
												{awaitingApproval ? (
													<Spinner animation='border' role='status'></Spinner>
												) : (
													"APPROVE"
												)}
											</button>
										</td>
									)
								) : null}
							</tr>
						</tbody>
					</Table>
				</div>
			) : null}

			<div className='bid-button-group'>
				{name && !owned && hasNFT ? (
					<button
						className='bid-button'
						onClick={() => setOpenCreateBidModal(true)}>
						Create Bid
					</button>
				) : null}
			</div>

			{activeOffer && (allow || owned) ? (
				<div>
					<h3 className='text-center bid-header'>NAME OFFERS</h3>
					<Table className='namehistory-table'>
						<thead className='namehistory-thead'>
							<tr className='namehistory-header-tr'>
								<th className='namehistory-th'>Date</th>
								<th className='namehistory-th'>Offer Amount</th>
								<th className='namehistory-th'>Action</th>
							</tr>
						</thead>
						<tbody className='namehistory-tbody'>
							<tr className='namehistory-body-tr'>
								<td className='namehistory-td' style={{ textAlign: "center" }}>
									{moment(activeOffer.timestamp).format("l")}
								</td>

								<td
									className='namehistory-td'
									style={{ textAlign: "center", color: "#fc4ba3" }}>
									{Number(activeOffer.value).toFixed(2)} Ξ
								</td>
								{owned ? (
									<td className='namehistory-td cancel-offer'>
										<button
											className='cancel-bid'
											onClick={nameNoLongerForSale}>
											{offerLoading ? (
												<Spinner
													style={{
														height: "1.5rem",
														width: "1.5rem",
													}}
													animation='border'
													role='status'></Spinner>
											) : (
												" CANCEL OFFER"
											)}
										</button>
									</td>
								) : (
									<td
										className='namehistory-td'
										style={{ textAlign: "center", height: "42px" }}>
										<button
											className='cancel-bid'
											onClick={() => setOpenBuyNameModal(true)}>
											BUY NAME
										</button>
									</td>
								)}
							</tr>
						</tbody>
					</Table>
				</div>
			) : null}

			<div className='bid-button-group'>
				{name && owned ? (
					<button
						className='bid-button'
						onClick={() => setOpenCreateOfferModal(true)}>
						Offer Name
					</button>
				) : null}
			</div>
		</div>
	);
};

export default NameBids;

export interface IBids {
	collectionFrom: string;
	tokenFrom: number;
	collectionTo: string;
	tokenTo: number;
	name: string;
	value: number;
	bidder: string;
	status: "active" | "accepted" | "withdrawn";
	timestamp: number;
	blockNumber: number;
	transactionHash: string;
}

export interface INameOffer {
	collectionFrom: string;
	tokenFrom: string;
	name: string;
	value: number;
	onlyTo?: string;
	status: "active" | "accepted" | "withdrawn" | "expired";
	timestamp: number;
	blockNumber: number;
	transactionHash: string;
}
