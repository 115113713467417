import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Project from './Project'
import './Projects.css'

const Projects = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <section className="project-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="nftr-text-box section-tite text-center wow fadeInUp">
              <h2>
                HOLD FARMING <strong>NFT PROJECTS</strong>
              </h2>
              <Image src="./img/pink-bar.png" />
              <p>
                Bluechip NFT projects selected by our governance will
                participate in RNM hold farming. Projects with the most named
                NFTs will compete for additional spots in the Curated project
                list.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="g-5">
          <Col
            xl={3}
            lg={6}
            md={6}
            className="wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="nft-name-box">
              <div
                className="nft-name-box-bg"
                style={{
                  backgroundImage: `url(./img/project-bg.png)`,
                }}
              >
                <div className="colored-bg">
                  <Image src="./img/svg/man.svg" />
                  <h4>BAYC</h4>
                </div>
              </div>
              <div className="nft-name-box-content">
                <Link to="/lets-browse" className="bordered-button pink-border">
                  Name Your Nft
                </Link>
                <p>TO HOLD FARM</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={6}
            md={6}
            className="wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <Project
              title={
                <React.Fragment>
                  New Curations <strong>Coming</strong> Every Month
                </React.Fragment>
              }
              url="https://snapshot.org/#/nifter.eth"
            />
          </Col>
          <Col
            xl={3}
            lg={6}
            md={6}
            className="wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <Project
              title={
                <React.Fragment>
                  New Curations <strong>Coming</strong> Every Month
                </React.Fragment>
              }
              url="https://snapshot.org/#/nifter.eth"
            />
          </Col>
          <Col
            xl={3}
            lg={6}
            md={6}
            className="wow fadeInUp"
            data-wow-delay="0.7s"
          >
            <Project
              title={
                <React.Fragment>
                  New Curations <strong>Coming</strong> Every Month
                </React.Fragment>
              }
              url="https://snapshot.org/#/nifter.eth"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Projects
