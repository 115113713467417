import { IS_DEV } from "src/constants";
import { nftrAxios } from "./axiosInstance";

async function fetchOnlyNamed(
    page: number,
    contractAddress?: string
) {
    const body = {
        page: page,
        contractAddress: contractAddress,
        chainId: IS_DEV ? 11155111 : 1
    };
    return nftrAxios.post("/fetch-only-named", body).then((res) => res.data);
}

export default fetchOnlyNamed;
