import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';

type SocketConnectionStatus = boolean;

const baseURL = process.env.REACT_APP_API_URL_BASE as string;

const useSocketConnectionStatus = (url: string): SocketConnectionStatus => {
  const [isConnected, setIsConnected] = useState<SocketConnectionStatus>(false);

  useEffect(() => {
    const socket = io(baseURL);

    const handleConnect = () => {
      setIsConnected(true);
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      socket.close();
    };
  }, [url]);

  return isConnected;
};

export default useSocketConnectionStatus;
