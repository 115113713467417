import { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { keccak256 } from "web3-utils";
import "./RobotDetails.css";
import useGetName from "src/hooks/useGetName";
import { WalletContentsDataContext } from "src/contexts";
import { ethers } from "ethers";

import {
	NAME_MARKETPLACE_ADDRESS,
	NFTREGISTRY_ADDRESS,
	NFTRegistryABI,
	NameMarketplaceABI,
} from "src/contracts/external_contracts";
import Notify from "bnc-notify";
import { BLOCKNATIVE_DAPPID, IS_DEV } from "src/constants";
import NameTransfer from "./NameTransfer";
import NameHistory from "./NameHistory";
import NameBids, { IBids, INameOffer } from "./NameBids";
import CreateBidModal from "./CreateBidModal";
import CreateOfferModal from "./CreateOfferModal";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { SocketContext } from "src/contexts/SocketContext";

interface IProps {
	contractAddress: string;
	tokenId: string | number;
	providedName: string;
	namingTweet: any;
	owned: boolean;
	name: string;
	setUpdateNameInput: () => void;
}

const notify = Notify({
	dappId: BLOCKNATIVE_DAPPID,
	system: "ethereum",
	networkId: IS_DEV ? 5 : 1,
});

function NameService({
	contractAddress,
	tokenId,
	namingTweet,
	owned,
	name,
	setUpdateNameInput,
}: IProps) {
	//@ts-ignore
	const { userSigner, address } = useContext(Web3OnboardContext);

	const [openCreateBidModal, setOpenCreateBidModal] = useState(false);
	const [openCreateOfferModal, setOpenCreateOfferModal] = useState(false);

	const [, forceUpdate] = useState<any>({});
	const [selected, setSelected] = useState({
		contractAddress: "",
		tokenId: "",
		collectionName: "",
	});

	const [approval, setApproval] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [currentBid, setCurrentBid] = useState<Number | null>(null);
	const [NFTRegistry, setNFTRegistry] = useState<ethers.Contract>();
	const [NameMarketplace, setNameMarketplace] = useState<ethers.Contract>();
	const [activeOffer, setActiveOffer] = useState<INameOffer | null>(null);
	const [activeBid, setActiveBid] = useState<IBids | null>(null);

	const { data: walletContents } = useContext<any>(WalletContentsDataContext);

	const ownNFT = (): boolean => {
		if (walletContents && walletContents?.nfts?.length > 0) return true;
		return false;
	};

	const handleClose = () => setOpenCreateBidModal(false);

	const handleCloseOfferModal = () => setOpenCreateOfferModal(false);

	useEffect(() => {
		if (!userSigner) return;

		const NFTRegistry = new ethers.Contract(
			NFTREGISTRY_ADDRESS,
			NFTRegistryABI,
			userSigner
		);

		const NameMarketplace = new ethers.Contract(
			NAME_MARKETPLACE_ADDRESS,
			NameMarketplaceABI,
			userSigner
		);
		setNFTRegistry(NFTRegistry);
		setNameMarketplace(NameMarketplace);
	}, [userSigner]);

	useEffect(() => {
		if (!userSigner) return;

		async function checkAllowance() {
			try {
				const approval = await NFTRegistry?.allowances(
					address,
					contractAddress,
					tokenId.toString()
				);
				setApproval(approval);
			} catch (error) {
				console.log(error);
			}
		}

		checkAllowance();
	}, [contractAddress, tokenId, NFTRegistry, address, userSigner]);

	useEffect(() => {
		async function getActiveBids() {
			try {
				if (name) {
					const nameHash = keccak256(name.toLowerCase());
					const bid = await NameMarketplace?.nameBids(nameHash);
					if (bid) {
						const bidPrice = bid[5].toString() / 10 ** 18;
						setCurrentBid(bidPrice);
					}
				}
			} catch (error) {
				console.log(error);
			}
		}

		getActiveBids();
	}, [NameMarketplace, name]);

	const { socket } = useContext(SocketContext);
	const {
		name: nftName,
		setName,
		updateName,
	} = useGetName(selected.contractAddress, selected.tokenId, 5000);

	useEffect(() => {
		socket.on("UpdateTransfers", () => {
			forceUpdate({});
		});
	}, [forceUpdate, socket]);

	async function approveTransfer() {
		try {
			setIsLoading(true);
			const allowance = await NFTRegistry?.allowTransfer(
				contractAddress,
				tokenId.toString()
			);
			await allowance.wait();
			setApproval(true);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	}

	return (
		<>
			<CreateBidModal
				name={name}
				openCreateBidModal={openCreateBidModal}
				selected={selected}
				NameMarketplace={NameMarketplace}
				nftName={nftName}
				setSelected={setSelected}
				setOpenCreateBidModal={setOpenCreateBidModal}
				handleClose={handleClose}
				currentBid={currentBid}
				setCurrentBid={setCurrentBid}
				setActiveBid={setActiveBid}
				activeBid={activeBid}
				address={address}
			/>
			<CreateOfferModal
				name={name}
				NameMarketplace={NameMarketplace}
				currentBid={currentBid}
				handleCloseOfferModal={handleCloseOfferModal}
				openCreateOfferModal={openCreateOfferModal}
				approval={approval}
				approveTransfer={approveTransfer}
				setActiveOffer={setActiveOffer}
				awaitingApproval={isLoading}
			/>

			<Accordion defaultActiveKey='1' className='mt-5'>
				{owned === true && name ? (
					<Accordion.Item eventKey='0'>
						<Accordion.Header as='div' className='fs-4 text-white naming'>
							<span></span>
							TRANSFER NAME
						</Accordion.Header>
						<Accordion.Body className='fs-4 text-white'>
							<NameTransfer
								owned={owned}
								selected={selected}
								nftName={nftName}
								approval={approval}
								setSelected={setSelected}
								NameMarketplace={NameMarketplace}
								NFTRegistry={NFTRegistry}
								approveTransfer={approveTransfer}
								contractAddress={contractAddress}
								tokenId={tokenId}
								name={name}
								isLoading={isLoading}
								setUpdateNameInput={setUpdateNameInput}
								activeOffer={activeOffer}
								setActiveOffer={setActiveOffer}
								updateName={updateName}
								address={address}
							/>
						</Accordion.Body>
					</Accordion.Item>
				) : null}
				{address && name ? (
					<Accordion.Item eventKey='1'>
						<Accordion.Header as='div' className='fs-4 text-white naming'>
							<span></span>
							BUY / SELL NAME
						</Accordion.Header>
						<Accordion.Body className='fs-4 text-white'>
							<NameBids
								setOpenCreateBidModal={setOpenCreateBidModal}
								setOpenCreateOfferModal={setOpenCreateOfferModal}
								name={name}
								address={address}
								owned={owned}
								NameMarketplace={NameMarketplace}
								approveTransfer={approveTransfer}
								approval={approval}
								contractAddress={contractAddress}
								tokenId={tokenId}
								setActiveOffer={setActiveOffer}
								activeOffer={activeOffer}
								activeBid={activeBid}
								setActiveBid={setActiveBid}
								setUpdateNameInput={setUpdateNameInput}
								awaitingApproval={isLoading}
								setName={setName}
								updateName={updateName}
								ownNFT={ownNFT}
							/>
						</Accordion.Body>
					</Accordion.Item>
				) : null}
				<Accordion.Item eventKey='2'>
					<Accordion.Header as='div' className='fs-4 text-white naming'>
						<span></span>
						NAMING HISTORY
					</Accordion.Header>
					<Accordion.Body>
						<NameHistory
							namingTweet={namingTweet}
							contractAddress={contractAddress}
							tokenId={tokenId}
						/>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	);
}

export default NameService;
