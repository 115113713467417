import image from "../../assets/images/customplaceholder.png";
import { useEffect, useState } from "react";
import axios from "axios";
import placeholder from "src/assets/images/customplaceholder.png";
import retrieveContract from "src/apis/retrieveContract";

interface IProps {
	imageUrl: string | null;
	name: string;
	contract?: any;
	contractAddress?: string;
}

const Label = ({ imageUrl, name, contract, contractAddress }: IProps) => {
	const [collectionBanner, setCollectionBanner] = useState(null);

	useEffect(() => {
		async function getBannerImage() {
			try {
				if (contractAddress) {
					const { data } = await retrieveContract(contractAddress);
					setCollectionBanner(data.data.image_url);
				} else if (contract) {
					const { data } = await axios.get(
						`https://api.opensea.io/api/v1/asset_contract/${contract?._id}`
					);

					setCollectionBanner(data.collection.banner_image_url);
				}
			} catch (error) {
				console.log("collectionBanner", error);
			}
		}

		if (contractAddress) {
			getBannerImage();
		}
		if (!imageUrl && contract && contract._id && !contract.imageUrl) {
			getBannerImage();
		}
	}, [contract, contractAddress, imageUrl]);

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyItems: "center",
				alignContent: "center",
				width: "100%",
			}}>
			<div
				style={{
					width: "8%",
					display: "flex",
					alignItems: "center",
					justifyItems: "center",
					alignContent: "center",
				}}>
				{imageUrl !== null ? (
					<img
						style={{
							maxWidth: "60px",
							maxHeight: "60px",
							marginTop: "-10px",
						}}
						src={imageUrl || image}
						alt='collection-icon'></img>
				) : (
					<img alt='collection-icon' src={collectionBanner || placeholder} />
				)}
			</div>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyItems: "center",
					justifyContent: "center",
					alignContent: "center",
					alignItems: "center",
				}}>
				<div className={"react-select-label"}>{name}</div>
			</div>
		</div>
	);
};

export default Label;
