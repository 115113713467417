import { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import WithPromises from "./AsyncSelect.tsx";
import "../LetsBrowseScreen/letsBrowseSearch.css";

const LetsBrowseSearch = ({ contractId, updateProject, searchNames }) => {
	const [searchFilter, setSearchFilter] = useState("project");
	useEffect(() => {
		const filters = document.querySelectorAll(".find-filter");
		for (const filter of filters) {
			filter.addEventListener("click", (e) => {
				for (const node of filters) {
					node.classList.remove("active");
				}
				e.target.classList.add("active");
			});
		}
	}, []);
	return (
		<section className='lets-browse-search'>
			<div className='shapes'>
				<Image
					className='left-shape'
					src='./img/lets-browse-page/lets-browse-left-shape.png'
				/>
				<Image
					className='right-shape'
					src='./img/lets-browse-page/lets-browse-right-shape.png'
				/>
			</div>
			<Container>
				<Row>
					<Col xl={12} className='text-center'>
						<div className='lets-browse-search-text'>
							<h1 className='text-green fadeInUp' style={{ marginTop: "50px" }}>
								Let's Browse
							</h1>
							<p className='text-blue  fadeInUp'>
								FIND BY
								<span className='find-by-filters'>
									<span
										className='find-filter active'
										onClick={() => setSearchFilter("project")}>
										PROJECT NAME
									</span>
									|
									<span
										className='find-filter'
										onClick={() => setSearchFilter("name")}>
										NFT NAME
									</span>
								</span>
							</p>

							<div
								style={{
									display: "flex",
									justifyContent: "center",
									paddingBottom: "25px",
									color: "white",
								}}>
								<div style={{ minHeight: "24px" }}>
									{contractId ? (
										<a
											href={"https://etherscan.io/address/" + contractId}
											className={"letsbrowse-contractaddress-link"}
											target='_blank'
											style={{
												textDecoration: "inherit",
												color: "inherit",
											}}
											rel='noreferrer'>
											{" "}
											{contractId}
										</a>
									) : (
										""
									)}
								</div>
							</div>

							{searchFilter === "project" ? (
								<>
									<WithPromises
										key='project'
										searchType='project'
										updateProject={updateProject}
									/>
								</>
							) : (
								<>
									<WithPromises
										key='name'
										searchType='name'
										updateProject={updateProject}
										searchNames={searchNames}
									/>
								</>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default LetsBrowseSearch;
