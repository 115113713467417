import { createContext, useState, useEffect, useCallback, useContext } from "react";
import {
  fetchContractAddresses,
  fetchNFTImages,
  fetchBlockchainData,
  fetchOnlyNamed,
} from "src/apis";
import { defaultCollection } from "../components/LetsBrowseScreen/curatedCollections";
import { Web3OnboardContext } from "./Web3OnboardContext";
import { SocketContext } from "./SocketContext";
import useIsMounted from "src/hooks/useIsMounted";


const initialState = {
  contracts: [],
  loaded: false,
  selectedContract: null,
  NFTData: [],
  NamedData: [],
  loading: false,
  errors: [],
  length: 0,
  allCollections: [],
  filter: "DEFAULT"
};

export const LoadedContractsDataContext = createContext(initialState);

const LoadedContractsDataProvider = ({ children }) => {
  const [data, setData] = useState({ ...initialState });
  const { address, wallet } = useContext(Web3OnboardContext);

  useEffect(() => {
    async function fetchNamedData() {
      const { data } = await fetchOnlyNamed(1)

      setData((prev) => ({ ...prev, loading: false, NamedData: data.nfts, length: data.total }))
    }
    fetchNamedData()
  }, [])

  const { socket } = useContext(SocketContext);
  const mounted = useIsMounted()

  useEffect(() => {
    async function fetchNamedData() {
      const { data } = await fetchOnlyNamed(1)
      setData((prev) => ({ ...prev, loading: false, NamedData: data.nfts, length: data.total }))
    }

    socket.on("UpdateTransfers", async () => {
      fetchNamedData()
    });
  }, [socket]);

  useEffect(() => {
    let promise;
    if (mounted) {
      if (address || wallet) {
        setData((prev) => ({
          ...prev,
          selectedContract: null,
          NFTData: [],
          filter: "WALLET"
        }));
      } else {
        promise = fetchNFTImages(defaultCollection._id, 1);
        promise
          .then((response) => {
            setData((prev) => ({
              ...prev,
              selectedContract: defaultCollection,
              NFTData: response.data,
              filter: "DEFAULT"
            }));
          })
          .catch((error) => {
            console.log("error occured in fetching addresses", error);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, wallet])


  useEffect(() => {
    if (mounted) {
      fetchContractAddresses().then((contracts) => {
        setData((prev) => ({
          ...prev,
          selectedContract: null,
          NFTData: [],
          loaded: true,
          contracts: contracts,
        }));

      });
    }
  }, [mounted]);

  const updateSelectedContract = (contract) => {
    setData((prev) => ({ ...prev, selectedContract: contract }));
  };

  const updateSelectedContractAndFetchImages = async (contract) => {
    try {
      const contractAddress = contract?.value || contract?._id;
      if (
        contract.imageUrl === null &&
        contract.collectionLength === null &&
        contract.startingIndex === null
      ) {
        setData((prev) => ({ ...prev, loading: true }));
        fetchBlockchainData(contractAddress, 1)
          .then(async ({ data }) => {

            const NFTs = data.data

            setData((prev) => ({
              ...prev,
              NFTData: NFTs.map((e) => ({
                ...e,
                contractAddress: contractAddress,
              })),
              loading: false,
              selectedContract: contract,
              length: data.length
            }));
          })
          .catch((error) => {
            setData((prev) => ({
              ...prev,
              loading: false,
              errors: prev.errors.concat(error),
            }));
          });
      } else {
        const { data: nftImages } = await fetchNFTImages(contractAddress, 1);
        setData((prev) => ({
          ...prev,
          NFTData: nftImages.map((e) => ({
            ...e,
            contractAddress: contractAddress,
          })),
          selectedContract: contract,
          loading: false,
        }));
      }
    } catch (error) {
      setData((prev) => ({
        ...prev,
        loading: false,
        errors: prev.errors.concat(error),
      }));
    }
  };

  const updateImagesWithSameContract = async (pageNumber, tokenId) => {
    try {
      const contractAddress =
        data.selectedContract?.value || data.selectedContract?._id;
      if (data.selectedContract?.collectionLength === null) {
        setData((prev) => ({ ...prev, loading: true }));
        fetchBlockchainData(
          contractAddress,
          pageNumber,
          tokenId ? [tokenId] : undefined
        )
          .then(async ({ data }) => {


            const NFTs = data.data

            // console.log({ fetchBlockchainData: data });
            // const innerData = data.data ? data.data : data;
            // const LRResponse = await fetchLRToken(
            //   contractAddress,
            //   innerData[0].tokenId
            // );

            // console.log({ LRResponse });
            // const NFTs = mapBlockchainData(
            //   innerData,
            //   LRResponse.data.collection.name
            // );

            updateNFTData(NFTs);
          })
          .catch((error) => {
            setData((prev) => ({
              ...prev,
              loading: false,
              errors: prev.errors.concat(error),
            }));

          });
      } else {

        setData((prev) => ({
          ...prev,
          loading: true,
        }));

        const { data: nftImages } = await fetchNFTImages(
          contractAddress,
          pageNumber,
          tokenId ? [+tokenId] : undefined
        );

        updateNFTData(
          nftImages.map((e) => ({
            ...e,
            contractAddress: contractAddress,
          }))
        );

      }
    } catch (error) {
      console.log(error);
      setData((prev) => ({
        ...prev,
        loading: false,
        errors: prev.errors.concat(error),
      }));
    }
  };

  const updateNFTData = (nftImages) =>
    setData((prev) => ({ ...prev, NFTData: nftImages, loading: false }));

  const resetContract = useCallback(() => {
    setData((prev) => ({ ...prev, selectedContract: null, NFTData: [] }));
  }, [])

  const actions = {
    updateSelectedContract,
    updateNFTData,
    updateSelectedContractAndFetchImages,
    updateImagesWithSameContract,
    resetContract,
  };

  return (
    <LoadedContractsDataContext.Provider value={[data, actions]}>
      {children}
    </LoadedContractsDataContext.Provider>
  );
};

export default LoadedContractsDataProvider;