import axios from "axios";
import { ReactNode, createContext, useEffect, useState } from "react";
import { IS_DEV } from "src/constants";
import useSWR from "swr";

export const ListedNamesContext = createContext<any>([]);

const baseURL = process.env.REACT_APP_API_URL_BASE;

const ListedNamesContextProvider = ({ children }: { children: ReactNode }) => {
  const [listed, setListed] = useState([]);
  useEffect(() => {
    async function getOnlyListed() {
      const { data } = await axios.post(`${baseURL}name/offer`, {
        chainId: IS_DEV ? 11155111 : 1,
      });

      setListed(data);
    }

    getOnlyListed();
  }, []);

  const valuesObject = { listed };
  return (
    <ListedNamesContext.Provider value={valuesObject}>
      {children}
    </ListedNamesContext.Provider>
  );
};

export default ListedNamesContextProvider;
