import { nftrAxios } from "./axiosInstance";
import { IS_DEV } from "../constants";

function getNFTTokenIdOwners(address: string, tokenId: number | string) {
    const body = {
        address,
        tokenId: tokenId.toString(),
        chain: IS_DEV ? 11155111 : 1
    }
    return nftrAxios.post("/token-owner", body);
}

export default getNFTTokenIdOwners;