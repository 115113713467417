import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { WOW } from 'wowjs'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './BecomeProvider.css'

const BecomeProvider = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <section className="become-provider-section">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <div className="nftr-text-box become-provider-content wow fadeInUp">
              <h2>
                CLAIM RNM IN OUR <strong>AIRDROP</strong>
              </h2>
              <Image src="./img/pink-bar.png" />
              <p>
                NFTR community participants will claim RNM upon tokenomics
                launch.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="custom-container">
        <Row>
          <Col md={12} className="text-center wow fadeInUp">
            <div className="become-provider-image">
              <Image src="./img/become-provider.png" />
              <Link to="/earn" className="bordered-button pink-border">
                VERIFY
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BecomeProvider
