import { useEffect, useRef, useState, useContext, useMemo } from "react";
import axios from "axios";
import useSWR from "swr";
import { WalletContentsDataContext } from "../../../contexts/WalletContentsContext";
import { WOW } from "wowjs";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import placeholder from "../../../assets/images/customplaceholder.png";
import formatIPFS from "../../../helpers/formatIPFS";

import LiveName from "../../LiveName";
import useGetName from "../../../hooks/useGetName";
import {
	openseaLogo,
	looksrareLogo,
	x2y2Logo,
	gemLogo,
	twitter,
	uniswap,
	remove,
	Add,
	Message,
	Edit,
} from "src/assets/images";
import { useLookupAddress } from "src/hooks";
import usePollAsync from "src/hooks/usePollAsync";
import TwitterModal from "./TwitterModal";
import DisplayTooltip from "./DisplayTooltip";
import { removeTwitter, signMessage } from "src/helpers/social";
import { IS_DEV } from "src/constants";
import ServicesTabs from "./ServicesTabs";
import getNFTTokenIdOwners from "src/apis/getNFTTokenIdOwners";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { etherscanLink } from "src/helpers/etherscan";
import { ethers } from "ethers";
import { SocketContext } from "src/contexts/SocketContext";
import getAlchemyMetadata from "src/apis/getAlchemyMetadata";
import ConnectEmailModal from "./ConnectEmailModal";
import { TokenboundClient } from "@tokenbound/sdk";
import { getAlchemyImages } from "src/hooks/useTokenBoundCollectibles";
import contracts from "src/contracts/external_contracts";
import CustomTooltip from "src/components/CustomTooltip/CustomTooltip";
import { useIndexedDB } from "react-indexed-db-hook";
import { Helmet } from "react-helmet";

const defaultLRData = {
	imageURI: "",
	name: "",
	collectionName: "",
	attributes: [],
	image2: "",
};

const baseURL = process.env.REACT_APP_API_URL_BASE;

const RobotDetails = ({ contractAddress, tokenId }) => {
	const [owner, setOwner] = useState("");
	const [nameUpdated, setNameUpdated] = useState(false);
	const [updatedName, setUpdatedName] = useState(null);
	const [messages, setMessages] = useState([]);
	const [conversations, setConversations] = useState([]);
	const { data: walletContents } = useContext(WalletContentsDataContext);
	const { injectedProvider, address, userSigner } =
		useContext(Web3OnboardContext);
	const [tokenPrices, setTokenPrices] = useState({
		opensea: null,
		looksrare: null,
		x2y2: null,
	});

	const [updateNameInput, setUpdateNameInput] = useState(false);
	const [tokenBoundNFT, setTokenBoundNFT] = useState({
		contractAddress: "",
		tokenId: "",
		name: "",
		imageUrl: "",
		walletAddress: "",
	});

	const [, setEvents] = useState([]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const baseProvider = new ethers.providers.InfuraProvider(
		"mainnet",
		"d34a8d5f505a4dfcafacadd4e019e771"
	);

	const ens = useLookupAddress(baseProvider, owner);

	let displayAddress = owner.substring(0, 6);
	const truncatedTBAAddress = tokenBoundNFT.walletAddress.substring(0, 6);

	if (ens && ens.indexOf("0x") < 0) {
		displayAddress = ens;
	}
	const renderRef = useRef(0);

	const contractAddressToUse = contractAddress?.toLowerCase();
	const tokenIdToUse = tokenId;

	const [LRData, setLRData] = useState(defaultLRData);
	const [tweetId, setTweetId] = useState(null);
	let owned = false;
	let robotFromStorage;
	let robot = robotFromStorage;
	let collectionName = LRData?.collectionName;

	const { socket } = useContext(SocketContext);

	const { name, updateName } = useGetName(contractAddress, tokenIdToUse, 5000);
	const [email, setEmail] = useState(null);

	const fetcher = (url) => fetch(url).then((res) => res.json());

	const { data: userSocial } = useSWR(
		contractAddressToUse && tokenIdToUse && address
			? `${baseURL}user/social?userAddress=${address}&contractAddress=${contractAddressToUse}&tokenId=${tokenIdToUse}`
			: null,
		fetcher
	);

	useEffect(() => {
		if (!injectedProvider) return;

		if (userSocial?.twitterUsername && !userSocial?.twitterVerified) {
			// signMessage(userSigner, userSocial)
			// 	.then((signature) => {
			// 		axios.post(`${baseURL}twitter/verify`, {
			// 			twitterUsername: userSocial.twitterUsername,
			// 			signature,
			// 		});
			// 	})
			// 	.catch(async (error) => {
			// 		await axios.post(`${baseURL}twitter/remove`, {
			// 			contractAddress: contractAddressToUse,
			// 			tokenId: tokenIdToUse,
			// 			twitterUsername: userSocial.twitterUsername,
			// 		});
			// 	});

			socket.emit("sendSignMessage", {
				contractAddress: contractAddressToUse,
				tokenId: tokenIdToUse,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSocial?.twitterVerified]);

	useEffect(() => {
		async function fetchNFTOwner() {
			try {
				const { data } = await getNFTTokenIdOwners(
					contractAddressToUse,
					tokenIdToUse.toString()
				);
				setOwner(data.data);
			} catch (error) {
				console.log(error);
			}
		}

		if (contractAddressToUse && tokenIdToUse) {
			fetchNFTOwner();
		}
	}, [contractAddressToUse, tokenIdToUse]);

	const tokenboundClient = new TokenboundClient({
		signer: userSigner,
		chainId: 1,
	});

	useEffect(() => {
		async function getTokenBoundNFT() {
			try {
				const nft = await tokenboundClient.getNFT({
					accountAddress: owner,
				});

				if (nft) {
					const images = await getAlchemyImages(nft.tokenContract, nft.tokenId);

					setTokenBoundNFT((prev) => ({
						...prev,
						contractAddress: nft.tokenContract,
						tokenId: nft.tokenId,
						imageUrl: images?.image2,
						walletAddress: owner,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		}

		if (owner) {
			getTokenBoundNFT();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [owner]);

	useEffect(() => {
		async function alchemyMetadata() {
			try {
				const { data } = await getAlchemyMetadata(
					contractAddressToUse,
					tokenIdToUse.toString()
				);

				if (data) {
					const imageURI = formatIPFS(data?.data?.media[0].raw);
					const image2 = formatIPFS(data?.data?.media[0].gateway);

					setLRData({
						imageURI: imageURI,
						name: data?.data?.contractMetadata?.name,
						collectionName: data?.data?.contractMetadata?.name,
						attributes: data?.data?.metadata?.attributes,
						image2: image2,
					});
				}
			} catch (error) {
				console.log(error);
			}
		}

		if (!IS_DEV && contractAddressToUse && tokenIdToUse) {
			alchemyMetadata();
		}
	}, [contractAddressToUse, tokenIdToUse]);

	useEffect(() => {
		new WOW({
			mobile: false,
			live: false,
		}).init();
	}, []);

	useEffect(() => {
		socket.on("signMessage", (data) => {
			const { contractAddress, tokenId } = data;
			if (
				contractAddress.toLowerCase() === contractAddressToUse.toLowerCase() &&
				+tokenIdToUse === +tokenId
			) {
				signMessage(userSigner, userSocial)
					.then((signature) => {
						axios.post(`${baseURL}twitter/verify`, {
							twitterUsername: userSocial.twitterUsername,
							signature,
						});
					})
					.catch(async (error) => {
						await axios.post(`${baseURL}twitter/remove`, {
							contractAddress: contractAddressToUse,
							tokenId: tokenIdToUse,
							twitterUsername: userSocial.twitterUsername,
						});
					});
			}
		});
	}, [contractAddressToUse, socket, tokenIdToUse, userSigner, userSocial]);

	// useEffect(() => {
	//   async function getTokenEvents() {
	//     try {
	//       const { data } = await axios.post(`${baseURL}token-events`, {
	//         contractAddress: contractAddressToUse,
	//         tokenId: tokenIdToUse,
	//       });

	//       const { openseaPrice, looksrarePrice, x2y2Price, events } = data;
	//       setTokenPrices((prev) => ({
	//         ...prev,
	//         opensea: openseaPrice,
	//         looksrare: looksrarePrice,
	//         x2y2: x2y2Price,
	//       }));

	//       setEvents(events);
	//     } catch (error) {
	//       console.log(error);
	//     }
	//   }

	//   getTokenEvents();
	// }, [contractAddressToUse, tokenIdToUse]);

	useEffect(() => {
		socket.on("UpdateRobotDetails", () => {
			updateName();
		});
	}, [updateName, setUpdateNameInput, socket]);

	if (owner?.toLowerCase() === address?.toLowerCase()) {
		owned = true;
	}

	let url = LRData.imageURI || robot?.imageUrl || robot?.image;
	const currentTweetId = useRef(null);

	let enabled = false;

	if (owned && updatedName && contractAddressToUse && tokenIdToUse) {
		enabled = true;
	}

	if (nameUpdated === false) {
		enabled = false;
	}

	// eslint-disable-next-line no-unused-vars
	// if (url && url.toLowerCase().indexOf("ipfs")) url = formatIPFS(url);

	if (!url) {
		url = placeholder;
	}

	if (url && url.length && url.indexOf("lh3") > -1) {
		url = url + "=w600";
	}

	async function handleRemoveTwitter() {
		try {
			const signature = await signMessage(userSigner, userSocial, true);

			if (signature) {
				await removeTwitter(
					contractAddressToUse,
					tokenIdToUse,
					userSocial.twitterUsername
				);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// fetch token conversations
	// add them to an array

	const [showDropdown, setShowDropdown] = useState(false);
	const [searchCollectionTerm, setSearchCollectionTerm] = useState("");
	const [selectedToken, setSelectedToken] = useState(null);

	let filteredNFTs = [];

	if (walletContents && walletContents?.nfts) {
		filteredNFTs = walletContents?.nfts.filter(
			(token) =>
				token.collectionName
					?.toLowerCase()
					.includes(searchCollectionTerm.toLowerCase()) ||
				token?.tokenId.toString().includes(searchCollectionTerm.toLowerCase())
		);
	}

	const prices = [
		tokenPrices.opensea,
		tokenPrices.looksrare,
		tokenPrices.x2y2,
	].filter((item) => item !== null);

	const minPrice = Math.min(...prices);

	const { data: namingTweet } = useSWR(
		contractAddressToUse && tokenIdToUse && name
			? `${baseURL}twitter/tweet?name=${name}&contractAddress=${contractAddressToUse}&tokenId=${tokenIdToUse}`
			: null,
		fetcher
	);
	const twitterConnectionUrl = `${baseURL}auth/twitter?userAddress=${address}&contractAddress=${contractAddressToUse}&tokenId=${tokenIdToUse}`;

	const connectTwitter = () => {
		if (address) window.location.href = twitterConnectionUrl;
	};

	const findNamingTweet = async () => {
		try {
			const { data } = await axios.post(`${baseURL}twitter/search/tweet`, {
				collectionName,
				tokenId: tokenIdToUse,
				contractAddress: contractAddressToUse,
				name: updatedName,
			});
			const { tweetId } = data;
			if (tweetId) {
				currentTweetId.current = tweetId;
				setTweetId(tweetId);
				setNameUpdated(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const update = usePollAsync(findNamingTweet, 10000, enabled);

	const [openEmailModal, setOpenEmailModal] = useState(false);
	const [title, setTitle] = useState("NFTR");
	const pageTitle = name ? `NFTR | ${name}` : "NFTR";

	useEffect(() => {
		// Update the page title when the component mounts
		document.title = pageTitle;
		const title = name ? `NFTR | ${name}` : "NFTR";
		setTitle(title);

		// Optionally, you can reset the title when the component unmounts
		return () => {
			document.title = "NFTR";
		};
	}, [pageTitle]);

	// @TODO
	useEffect(() => {
		const NFTRegistry = new ethers.Contract(
			"0xc662a8Ce36392d4B9489D0B59605a1874711BFc6",
			contracts[1].contracts.NFTRegistry.abi,
			baseProvider
		);

		async function getTokenBoundNFTOwnerName() {
			try {
				if (
					tokenBoundNFT &&
					tokenBoundNFT.contractAddress &&
					tokenBoundNFT.tokenId
				) {
					const tbNFTOwnerName = await NFTRegistry.tokenName(
						tokenBoundNFT?.contractAddress,
						tokenBoundNFT?.tokenId
					);
					setTokenBoundNFT((prev) => ({ ...prev, name: tbNFTOwnerName }));
				}
			} catch (error) {
				console.log(error);
			}
		}

		if (tokenBoundNFT?.contractAddress && tokenBoundNFT?.tokenId) {
			getTokenBoundNFTOwnerName();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenBoundNFT.contractAddress, tokenBoundNFT.tokenId]);

	useEffect(() => {
		async function getEmail() {
			try {
				const { data: walletEmail } = await axios.get(
					`${baseURL}wallet/email/${address}`
				);
				if (!userSocial?.emailVerified && walletEmail) {
					const email = walletEmail?.email;
					setEmail(email);
				} else {
					setEmail(userSocial?.email);
				}
			} catch (error) {
				console.log("getEmailError", error);
			}
		}

		if (address) {
			getEmail();
		}
	}, [address, userSocial?.email, userSocial?.emailVerified]);

	useEffect(() => {
		async function getEmail() {
			try {
				const { data } = await axios.get(`${baseURL}wallet/email/${address}`);
				if (!userSocial?.emailVerified && data) {
					const email = data?.email;
					setEmail(email);
				} else {
					setEmail(userSocial?.email);
				}
			} catch (error) {
				console.log("getEmailError", error);
			}
		}

		if (address) {
			socket.on("updateEmail", (data) => {
				if (data?.toLowerCase() === address?.toLowerCase()) {
					getEmail();
				}
			});
		}
	}, [address, socket, userSocial?.email, userSocial?.emailVerified]);

	const {
		add: addConversation,
		getByID: getConversationByID,
		clear: clearConversations,
	} = useIndexedDB("conversations");

	const {
		add: addMessage,
		getByID: getMessageByID,
		clear: clearMessages,
	} = useIndexedDB("messages");

	useEffect(() => {
		async function fetchConversations() {
			try {
				const senderId = contractAddressToUse + tokenIdToUse;

				const { data: userConversations } = await axios.get(
					`${baseURL}conversations/${senderId}`
				);

				const conversations = userConversations.map((item) => ({
					_id: item._id,
					active: item.active,
					status: item.status,
					members: item.members,
					createdAt: item.createdAt,
				}));

				setConversations(conversations);
			} catch (error) {
				console.log({ fetchConversations: error });
			}
		}

		if (contractAddressToUse && tokenIdToUse) {
			fetchConversations();
		}
	}, [contractAddressToUse, tokenIdToUse, address]);

	// useEffect(() => {
	// 	const DB_NAME = process.env.REACT_APP_DB_NAME;
	// 	const request = window.indexedDB.open(DB_NAME, 1);

	// 	request.onsuccess = async (event) => {
	// 		const collections = event.target.result.objectStoreNames;
	// 		const conversations = collections.contains("conversations");
	// 		const messages = collections.contains("messages");

	// 		if (conversations) {
	// 			await clearConversations();
	// 		}
	// 		if (messages) {
	// 			await clearMessages();
	// 		}
	// 	};
	// }, [clearConversations, clearMessages]);

	// store conversations
	useEffect(() => {
		async function storeConversations() {
			try {
				const DB_NAME = process.env.REACT_APP_DB_NAME;
				const request = window.indexedDB.open(DB_NAME, 1);

				request.onsuccess = async (event) => {
					const collections = event.target.result.objectStoreNames;
					const conversations = collections.contains("conversations");

					if (conversations) {
						await clearConversations();
					}
				};
				for (const conversation of conversations) {
					const exists = await getConversationByID(conversation._id);
					if (exists) {
						continue;
					}
					await addConversation(conversation);
				}
			} catch (error) {
				console.log({ storeConversations: error });
			}
		}
		storeConversations();
	}, [addConversation, clearConversations, conversations, getConversationByID]);

	useEffect(() => {
		async function fetchMessages() {
			try {
				for (const conversation of conversations) {
					const { data } = await axios.get(
						`${baseURL}messages/${conversation._id}`
					);
					const messages = data.map((item) => ({
						_id: item._id,
						conversationId: item.conversationId,
						sender: item.sender,
						text: item.text,
						members: item.members,
						isRead: item.isRead,
						createdAt: item.createdAt,
					}));

					setMessages(messages);
				}
			} catch (error) {
				console.log({ fetchMessages: error });
			}
		}

		fetchMessages();
	}, [conversations, address]);

	useEffect(() => {
		async function storeMessages() {
			const DB_NAME = process.env.REACT_APP_DB_NAME;
			const request = window.indexedDB.open(DB_NAME, 1);

			request.onsuccess = async (event) => {
				const collections = event.target.result.objectStoreNames;
				const messages = collections.contains("messages");

				if (messages) {
					await clearMessages();
				}
			};
			try {
				for (const message of messages) {
					const exist = await getMessageByID(message._id);
					if (exist) {
						continue;
					}
					await addMessage(message);
				}
			} catch (error) {
				console.log({ storeMessages: error });
			}
		}

		storeMessages();
	}, [addMessage, clearMessages, getMessageByID, messages]);

	return (
		<section className='robot-details' style={{ marginTop: "80px" }}>
			<Helmet>
				<meta property='og:image' content={LRData.imageURI} />
				<meta property='og:title' content={title} />
				{/* <meta property='og:description' content={description} /> */}
			</Helmet>
			<ConnectEmailModal
				openEmailModal={openEmailModal}
				setOpenEmailModal={setOpenEmailModal}
				name={name}
				contractAddress={contractAddress}
				tokenId={tokenId}
			/>
			<div className='robot-details-shape position-relative'>
				<Image
					className='position-absolute robot-details-shape'
					src='./img/robot-details-shape.png'
				/>
			</div>
			<Container>
				<Row className='justify-content-center mb-4'>
					<Col lg={12}>
						<div className='robot-details-content wow fadeIn'>
							<Row className='robot-details-buttons justify-content-between align-items-baseline mx-0'>
								<div style={{ width: "auto", padding: "0" }}>
									<Link className='navigation' to='/lets-browse'>
										<i className='fas fa-angle-left'></i> Back
									</Link>
								</div>
								<div style={{ width: "auto", margin: "0", padding: "0" }}>
									{tokenBoundNFT.contractAddress.length &&
									tokenBoundNFT.tokenId.length ? (
										<div className='token-bound-nft-details'>
											<p className='text-pink p-0 mb-0 fs-4 d-flex token-owner-address'>
												owned by{" "}
												{tokenBoundNFT.name ? (
													<a
														className='text-pink ms-1'
														target='_blank'
														href={`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${tokenBoundNFT.contractAddress}/${tokenBoundNFT.tokenId}`}
														rel='noreferrer'>
														{tokenBoundNFT.name}
													</a>
												) : (
													<DisplayTooltip
														owner={tokenBoundNFT.walletAddress}
														displayAddress={tokenBoundNFT.walletAddress}
														tokenBoundNFT={tokenBoundNFT}
													/>
												)}
											</p>

											<Image
												className='token-owner-image'
												src={tokenBoundNFT.imageUrl}
											/>
										</div>
									) : (
										<p className='text-pink p-0 mb-0 fs-4 d-flex'>
											owned by{" "}
											{owned ? (
												<span>&nbsp;you</span>
											) : displayAddress.length < 15 ? (
												<a
													className='text-pink ms-1'
													target='_blank'
													href={etherscanLink(owner, "address")}
													rel='noreferrer'>
													{displayAddress}
												</a>
											) : (
												<DisplayTooltip
													owner={owner}
													displayAddress={displayAddress}
												/>
											)}
										</p>
									)}
								</div>
							</Row>

							<Row className='align-items-start'>
								<Col lg={5}>
									<div className='robot-details-wrapper'>
										<div className='robot-details-thumbnail'>
											<Image
												className='punk'
												src={url}
												onError={function (error) {
													renderRef.current++;
													if (renderRef.current === 1) {
														error.target.src = LRData.image2;
													} else if (renderRef.current === 2) {
														error.target.src = placeholder;
													}
												}}
											/>
										</div>
										<div style={{ textAlign: "start" }}>
											<h3>
												<LiveName
													updateInterval={5000}
													contractAddress={contractAddress}
													tokenId={tokenIdToUse}
												/>
											</h3>
											{collectionName ? (
												<h3
													className='text-pink robot-details-collection-name'
													style={{ fontWeight: "lighter" }}>
													{" "}
													{contractAddress ? (
														<a
															href={etherscanLink(contractAddress, "address")}
															rel='noreferrer'
															target='_blank'
															style={{
																textDecoration: "inherit",
																color: "inherit",
															}}>
															{collectionName}
														</a>
													) : (
														collectionName
													)}
												</h3>
											) : null}
											<h4 className='text-green robot-details-token-id'>
												{" "}
												{contractAddress && tokenIdToUse ? (
													<a
														href={
															etherscanLink(contractAddress, "address") +
															"?a=" +
															tokenIdToUse
														}
														rel='noreferrer'
														target='_blank'
														style={{
															textDecoration: "inherit",
															color: "inherit",
														}}>
														ID {tokenIdToUse}
													</a>
												) : (
													"ID " + tokenIdToUse
												)}
											</h4>

											{contractAddress && tokenIdToUse ? (
												<Row className='marketplace'>
													<Col>
														<Tooltip
															content={
																tokenPrices.opensea === null
																	? null
																	: +tokenPrices.opensea === minPrice
																	? `Best price: ${tokenPrices.opensea} Ξ`
																	: `${tokenPrices.opensea} Ξ`
															}
															direction='bottom'
															id='opensea-tooltip'
															style={
																tokenPrices.opensea === null
																	? null
																	: +tokenPrices.opensea === minPrice
																	? { border: "2px solid #00F5B2" }
																	: tokenPrices.opensea
																	? { border: "2px solid #fc4ba3" }
																	: null
															}>
															<a
																href={`https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`}
																target='_blank'
																rel='noreferrer'>
																<img
																	className='marketplace-logo'
																	src={openseaLogo}
																	alt='Opensea logo'
																/>
															</a>
														</Tooltip>
													</Col>
													<Col>
														<Tooltip
															content={
																tokenPrices.looksrare === null
																	? null
																	: +tokenPrices.looksrare === minPrice
																	? `Best price: ${tokenPrices.looksrare} Ξ`
																	: `${tokenPrices.looksrare} Ξ`
															}
															direction='bottom'
															style={
																tokenPrices.looksrare === null
																	? null
																	: +tokenPrices.looksrare === minPrice
																	? { border: "2px solid #00F5B2" }
																	: tokenPrices.looksrare
																	? { border: "2px solid #fc4ba3" }
																	: null
															}>
															<a
																href={`https://looksrare.org/collections/${contractAddress}/${tokenId}`}
																target='_blank'
																rel='noreferrer'
																className='marketplace-link'>
																<img
																	className='marketplace-logo'
																	src={looksrareLogo}
																	alt='Looksrare logo'
																/>
															</a>
														</Tooltip>
													</Col>
													<Col>
														<Tooltip
															direction='bottom'
															content={
																tokenPrices.x2y2 === null
																	? null
																	: +tokenPrices.x2y2 === minPrice
																	? `Best price: ${tokenPrices.x2y2} Ξ`
																	: `${tokenPrices.x2y2} Ξ`
															}
															style={
																tokenPrices.x2y2 === null
																	? null
																	: +tokenPrices.x2y2 === minPrice
																	? { border: "2px solid #00F5B2" }
																	: tokenPrices.x2y2
																	? { border: "2px solid #fc4ba3" }
																	: null
															}>
															<a
																href={`https://x2y2.io/eth/${contractAddress}/${tokenId}`}
																target='_blank'
																rel='noreferrer'
																className='marketplace-link'>
																<img
																	className='marketplace-logo'
																	src={x2y2Logo}
																	alt='x2y2 logo'
																/>
															</a>
														</Tooltip>
													</Col>
													<Col>
														<Tooltip direction='bottom'>
															<a
																href={`https://www.gem.xyz/asset/${contractAddress}/${tokenId}`}
																target='_blank'
																rel='noreferrer'
																className='marketplace-link'>
																<img
																	className='marketplace-logo'
																	src={gemLogo}
																	alt='gem logo'
																/>
															</a>
														</Tooltip>
													</Col>
													<Col>
														<Tooltip direction='bottom'>
															<a
																href={`https://app.uniswap.org/#/nfts/asset/${contractAddress}/${tokenId}`}
																target='_blank'
																rel='noreferrer'
																className='marketplace-link'>
																<img
																	className='marketplace-logo'
																	src={uniswap}
																	alt='uniswap marketplace logo'
																/>
															</a>
														</Tooltip>
													</Col>
												</Row>
											) : (
												<Row>
													<div>Opensea</div>
													<div>Looksrare</div>
													<a
														className='text-green'
														href='https://google.com'
														target='_blank'
														rel='noreferrer'>
														View on Opensea
													</a>
												</Row>
											)}
										</div>
									</div>
									<div className='mt-4'>
										<div className='twitter robot-details-twitter'>
											{(userSocial && userSocial.twitterUsername) || owned ? (
												<img
													style={{
														width: "30px",
													}}
													src={twitter}
													alt='Twitter Logo'
												/>
											) : null}
											{userSocial &&
											userSocial.twitterVerified &&
											userSocial.twitterUsername ? (
												<a
													href={`https://twitter.com/${userSocial.twitterUsername}`}
													target='_blank'
													rel='noreferrer'
													style={{
														textDecoration: "none",
													}}
													className='text-white robot-details-twitter'>
													@{userSocial.twitterUsername}
												</a>
											) : null}

											{owned && !userSocial?.twitterUsername ? (
												<span onClick={connectTwitter}>
													<CustomTooltip content='Bind your NFT to your twitter account by verifying. This allows users to message your NFT via Twitter'>
														<Add />
													</CustomTooltip>
												</span>
											) : null}
											{owned &&
											userSocial &&
											userSocial.twitterVerified &&
											userSocial.twitterUsername ? (
												<span
													onClick={handleRemoveTwitter}
													style={{
														cursor: "pointer",
														marginLeft: "12px",
													}}>
													<img
														style={{ width: "25px", marginTop: "-10px" }}
														src={remove}
														alt='remove twitter '
													/>
												</span>
											) : null}
										</div>
										{email && owned ? (
											<div className='email-edit' style={{ marginTop: "24px" }}>
												<Message />
												<p>{email}</p>
												<span onClick={() => setOpenEmailModal(true)}>
													<Edit />
												</span>
											</div>
										) : (
											<div className='email flex align-items-center mt-4'>
												{(userSocial && userSocial?.email) || owned ? (
													<Message />
												) : null}
												{userSocial &&
												userSocial?.email &&
												userSocial?.emailVerified ? (
													<a
														href={`mailto: ${userSocial?.email}?subject="NFTR CONNECTION"`}
														target='_blank'
														rel='noreferrer'
														style={{
															textDecoration: "none",
														}}
														className='text-white fs-4'>
														{userSocial?.email}
													</a>
												) : null}

												{owned && !userSocial?.emailVerified ? (
													<span onClick={() => setOpenEmailModal(true)}>
														<CustomTooltip content='Bind your NFT to your email address by verifying. This allows users to message your NFT via email'>
															<Add />
														</CustomTooltip>
													</span>
												) : null}

												{owned && userSocial?.emailVerified ? (
													<span
														onClick={() => {}}
														style={{
															cursor: "pointer",
															marginLeft: "12px",
														}}>
														<img
															style={{ width: "25px", marginTop: "-10px" }}
															src={remove}
															alt='remove email'
														/>
													</span>
												) : null}
											</div>
										)}

										{showDropdown && (
											<div className='dropdown-container'>
												<p className='text-white'>Select NFT to message from</p>
												<input
													type='text'
													className='select-token-search'
													value={searchCollectionTerm}
													onChange={(e) =>
														setSearchCollectionTerm(e.target.value)
													}
												/>
												<ul className='token-list'>
													{filteredNFTs.map((token, index) => (
														<>
															<li
																className='token-list-item'
																onClick={() => {
																	setSelectedToken(token);
																	setSearchCollectionTerm("");
																	setShowDropdown(false);
																}}>
																<span>
																	{token.collectionName} #{token.tokenId}
																</span>
															</li>
														</>
													))}
												</ul>
											</div>
										)}
									</div>
								</Col>
								<Col lg={7}>
									{/* move this inside services tabs */}
									<ServicesTabs
										tokenIdToUse={tokenIdToUse}
										contractAddress={contractAddress}
										name={name}
										namingTweet={namingTweet}
										owned={owned}
										selectedToken={selectedToken}
										setSelectedToken={setSelectedToken}
										updateName={updateName}
										setNameUpdated={setNameUpdated}
										setUpdatedName={setUpdatedName}
										senderImage={url}
										updateNameInput={updateNameInput}
										setUpdateNameInput={setUpdateNameInput}
									/>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				{currentTweetId.current ? (
					<div>
						<TwitterModal
							currentTweetId={currentTweetId}
							tweetId={currentTweetId.current}></TwitterModal>
					</div>
				) : null}
			</Container>
		</section>
	);
};

export default RobotDetails;

export const Tooltip = (props) => {
	let timeout;
	const [active, setActive] = useState(false);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, props.delay || 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<div
			className='Tooltip-Wrapper'
			id={props.id}
			// When to show the tooltip
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
			// style={{ marginLeft: "24px" }}
		>
			{/* Wrapping */}
			{props.children}
			{active && props.content && (
				<div
					className={`Tooltip-Tip ${props.direction || "top"}`}
					style={{
						padding: "8px",
						marginLeft: props.marginLeft,
						width: "100px",
					}}>
					{props.content}
				</div>
			)}
		</div>
	);
};
