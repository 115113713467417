import { useContext, useEffect, useRef, useState } from "react";
import { LoadedContractsDataContext } from "src/contexts";
import ActiveButton from "../ActiveButton";
import InactiveButton from "../InactiveButton";
import placeholder from "../../../assets/images/customplaceholder.png";
import { SelectMessagingRecipient } from "src/components/LetsBrowseScreen/RobotDetails/TokenBoundAccount/TBATransferModal";

const SearchByName = ({
	active,
	setActive,
	selected,
	setSelected,
}: ISearchByNameProps) => {
	const collectionDropdownRef = useRef<any>(null);
	const [openDropDown, setOpenDropDown] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [data] = useContext<any>(LoadedContractsDataContext);

	const names = data.NamedData;

	const filteredCollections = names.filter((item: any) =>
		item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
	);

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				collectionDropdownRef.current &&
				!collectionDropdownRef.current.contains(event.target)
			) {
				setOpenDropDown(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	return (
		<div ref={collectionDropdownRef}>
			<div
				className='custom-radio-button'
				onClick={() => {
					setActive("NAMES");
					setSelected(null);
					setSearchTerm("");
				}}>
				{active === "NAMES" ? <ActiveButton /> : <InactiveButton />}
				<div>
					<h4>Name</h4>
					<p>Search by NFTR name</p>
				</div>
			</div>
			{active === "NAMES" ? (
				<div className='select-recipient-container'>
					<input
						type='text'
						className='select-recipient-input'
						placeholder='NAME'
						onFocus={() => {
							setOpenDropDown(true);
							setSearchTerm("");
							setSelected(null);
						}}
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>

					{selected && selected.imageUrl ? (
						<img src={selected.imageUrl} alt='NFT' />
					) : selected && !selected.imageUrl ? (
						<img src={placeholder} alt='NFT' />
					) : null}
				</div>
			) : null}

			{openDropDown && active === "NAMES" ? (
				<div className='search-result'>
					<ul>
						{filteredCollections.map((item: any, index: number) => (
							<li
								key={index}
								onClick={() => {
									setSearchTerm(item.name);
									setSelected(item);
									setOpenDropDown(false);
								}}>
								<img
									src={
										item.imageUrl && item.imageUrl.includes("http")
											? item.imageUrl
											: placeholder
									}
									alt='placeholder'
								/>
								<span>{item.name}</span>
							</li>
						))}
					</ul>
				</div>
			) : null}
		</div>
	);
};

export default SearchByName;

interface ISearchByNameProps {
	active: string;
	setActive: React.Dispatch<React.SetStateAction<string>>;
	selected: ISelectNamedNFT | SelectMessagingRecipient | null;
	setSelected: React.Dispatch<
		React.SetStateAction<ISelectNamedNFT | SelectMessagingRecipient | null>
	>;
}

export interface ISelectNamedNFT {
	contractAddress: string;
	imageUrl: string;
	name: string;
	timestamp: number;
	tokenId: string;
	_id: string;
}
