import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  errorMessage: string | undefined;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log("Error is detected", error, errorInfo);
    debugger;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <h1>{this.props.errorMessage || "Something Went Wrong"}</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
