import React, { Suspense } from "react";

const ContractsScreen = () => {
  const ContractsScreenComponent = React.lazy(() =>
    import("src/components/ContractsScreen")
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ContractsScreenComponent></ContractsScreenComponent>
    </Suspense>
  );
};

export default ContractsScreen;
