import { useEffect } from "react";
import { WOW } from "wowjs";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./whynamenft.css";

const WhyNameNft = () => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);
  return (
    <section className="nft-section position-relative">
      <Container>
        <Row>
          <Col md={12}>
            <div className="nftr-text-box wow fadeInRight">
              <h2>
                NFT <strong>MESSAGING</strong>
              </h2>
              <Image className="pink-bar" src="./img/pink-bar.png" />
              <p>
                As NFTs can now be easily reached by its name <br />
                messaging between NFTs becomes simple. <br />
                NFT to NFT messaging will be encrypted and <br />
                stored off-chain on IPFS.
                <br />
                You will be able to message an NFT without
                <br />
                having to track down the owner's socials.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Image className="hand-thumbnail wow fadeIn" src="./img/hand.png" />
    </section>
  );
};

export default WhyNameNft;
