import { nftrAxios } from "./axiosInstance";

function getAlchemyMetadata(address: string, tokenId: number | string) {
    const body = {
        address,
        tokenId: tokenId.toString(),
    }
    return nftrAxios.post("/alchemy-metadata", body);
}

export default getAlchemyMetadata;
