import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FeaturedItem from './FeaturedItem'
import FeaturedItem2 from './FeaturedItem2'
import './Featured.css'

const Featured = () => {
  return (
    <section className="featured-section">
      <Container>
        <Row>
          <Col md={12}>
            <div className="featured-items">
              <FeaturedItem2
                inverted="false"
                img="./img/svg/featured-one.svg"
                title={
                  <React.Fragment>
                    HOLD <strong>FARM</strong>
                  </React.Fragment>
                }
                desc="Curated projects are hand-picked NFT projects that get perks. Curated projects include Bored Ape Yacht Club and CryptoPunks. Naming an NFT from a Curated Project will be free and will activate Hold Farming rewards (RNM yield). Hold Farming rewards curated NFT owners for naming and holding their NFTs."
                url="/lets-browse"
              />
              <FeaturedItem
                inverted="true"
                img="./img/featured-img-2.png"
                title={
                  <React.Fragment>
                    STAKE <strong>RNM</strong>
                  </React.Fragment>
                }
                desc="By Staking RNM, you receive a pro-rata share of the staking yield paid in RNM, as well as a pro-rata share of platform ETH (naming) revenue."
                url="/earn"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Featured
