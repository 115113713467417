import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import Slider from "react-slick";
import RobotCard from "./RobotCard";
import curatedCollections from "./curatedCollections";
import IdSearch from "./IdSearch";
import {
	LoadedContractsDataContext,
	WalletContentsDataContext,
} from "src/contexts";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import useIsMounted from "src/hooks/useIsMounted";

const DEFAULT_MAX_ID = 9999;
const RobotsList = ({
	robotData,
	contract,
	updateFilter,
	walletConnected,
	filter,
	nextPage,
	lastPage,
	walletSearch,
	updateQuickCollection,
	updateImagesWithSameContract,
	updateSelectedContractAndFetchImages,
	setPageNumber,
	pageNumberRef,
	pageNumber,
	collectionSize,
	walletTokenIdLookup,
	setFilter,
}) => {
	const [data, actions] = useContext(LoadedContractsDataContext);
	const [message, setMessage] = useState("");

	const { address } = useContext(Web3OnboardContext);
	const { isLoading } = useContext(WalletContentsDataContext);

	let lastFilter = sessionStorage.getItem("lastFilter");

	const mounted = useIsMounted();

	useEffect(() => {
		if (mounted) {
			if (lastFilter) {
				setFilter(lastFilter);
				return;
			} else {
				if (address) {
					setFilter("WALLET");
					return;
				} else {
					setFilter("DEFAULT");
					return;
				}
			}
		}
	}, [address, lastFilter, mounted, setFilter]);

	useEffect(() => {
		if (mounted) {
			if (filter === "DEFAULT" && !data.selectedContract) {
				updateQuickCollection("cryptopunks");
			}
		}
	}, [data, filter, mounted, updateQuickCollection]);

	useEffect(() => {
		let message;
		if (filter === "WALLET" && robotData.length === 0) {
			message = null;
		}

		if (contract && filter === "WALLET" && robotData.length === 0) {
			message = "unselect collection to see your NFTs";
		}

		if (!contract && filter === "DEFAULT") {
			message = "Select a collection or search for a collections to see NFTs";
		}

		setMessage(message);
	}, [contract, filter, robotData.length]);

	useEffect(() => {
		const curated_filters = document.querySelectorAll(".robot-slide-item");
		for (const filter of curated_filters) {
			filter.addEventListener("click", (e) => {
				for (const node of curated_filters) {
					node.classList.remove("active");
					node.parentElement.classList.remove("active");
				}
				e.target.classList.add("active");
				e.target.parentElement.classList.add("active");
			});
		}
	}, []);

	const slickSettings = {
		dots: false,
		infinite: true,
		arrows: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: (
			<span>
				<Image src='./img/angle-left.png' />
			</span>
		),
		nextArrow: (
			<span>
				<Image src='./img/angle-right.png' />
			</span>
		),
		responsive: [
			{
				breakpoint: 1680,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					arrows: true,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					arrows: false,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					arrows: false,
				},
			},
		],
	};

	const collectionLength =
		contract?.collectionLength || collectionSize || data.length;

	const hidePagination = !data.selectedContract && filter === "DEFAULT";

	let maxId =
		filter === "DEFAULT"
			? collectionLength
			: filter === "LISTED"
			? robotData.length
			: filter === "WALLET"
			? collectionSize
			: filter === "WALLET" && contract
			? robotData.length
			: filter === "NAMED" && walletTokenIdLookup.current
			? robotData.length
			: filter === "NAMED"
			? collectionSize
			: contract
			? Number(contract.startingIndex) - 1 + contract.collectionLength
			: DEFAULT_MAX_ID;

	const Items = React.memo(function Items() {
		return (
			<>
				{robotData && robotData.length
					? robotData.map((robot, i) => (
							<RobotCard
								key={i}
								robot={robot}
								filter={filter}
								contractAddress={robot.contractAddress || contract?._id}
								project={
									contract?.openseaMetadata?.name ||
									contract?.label?.props?.name
								}
								count={i}
								maxId={maxId}
							/>
					  ))
					: null}
			</>
		);
	});

	const PaginatedItems = React.memo(
		function () {
			const pageNumbers = [];
			let lastPageNumber = +sessionStorage.getItem("lastPageNumber") || 1;

			for (let i = 1; i <= Math.ceil(maxId / 16); i++) {
				pageNumbers.push(i);
			}

			const firstThreePages = pageNumbers.slice(0, 3);

			const currentPage =
				pageNumbers.length === 1
					? 1
					: pageNumberRef.current > 3 &&
					  pageNumberRef.current < Math.max(...pageNumbers) - 2
					? pageNumberRef.current
					: null;

			const lastThreePages =
				pageNumbers.length < 5
					? pageNumbers.slice(pageNumbers.length - 1, pageNumbers.length)
					: pageNumbers.length < 6
					? pageNumbers.slice(pageNumbers.length - 2, pageNumbers.length)
					: pageNumbers.length >= 6
					? pageNumbers.slice(pageNumbers.length - 3, pageNumbers.length)
					: pageNumbers.slice(pageNumbers.length - 3, pageNumbers.length);

			return (
				<Container>
					{robotData.length > 15 &&
					pageNumbers.length > 1 &&
					!hidePagination ? (
						<Row className='justify-content-center'>
							<Col className='text-center'>
								{pageNumbers.length > 1 && (
									<div className='pagination-robots d-flex align-items-center justify-content-center mt-0  '>
										{pageNumbers.length > 1 && (
											<button
												onClick={() => {
													lastPage();
												}}
												className='pagination-prev'>
												{lastPageNumber > 1 ? (
													<Image
														className='change-page'
														src='./img/pagination-prev.png'
													/>
												) : null}
											</button>
										)}
										{firstThreePages.map((number) => (
											<span
												role='button'
												className={
													number === lastPageNumber ? "three-dots" : ""
												}
												onClick={() => {
													setPageNumber(number);
													pageNumberRef.current = number;
													sessionStorage.setItem(
														"lastPageNumber",
														pageNumberRef.current
													);
													actions.updateImagesWithSameContract(Number(number));
												}}
												key={number}>
												{number}
											</span>
										))}

										{pageNumbers.length > 6 && <span>⋯</span>}
										{currentPage > 3 && (
											<span className='three-dots'>{lastPageNumber}</span>
										)}
										{pageNumbers.length > 6 && currentPage && <span>⋯</span>}
										{pageNumbers.length >= 4 &&
											lastThreePages.map((number) => (
												<span
													className={
														number === lastPageNumber ? "three-dots" : ""
													}
													role='button'
													onClick={() => {
														setPageNumber(number);
														pageNumberRef.current = number;
														sessionStorage.setItem(
															"lastPageNumber",
															pageNumberRef.current
														);
														actions.updateImagesWithSameContract(
															Number(number)
														);
													}}
													key={number}>
													{number}
												</span>
											))}

										{pageNumbers.length > 1 && (
											<button
												onClick={() => {
													if (
														pageNumberRef.current < Math.max(...pageNumbers)
													) {
														nextPage();
													}
												}}
												className='pagination-next'>
												{pageNumberRef.current < Math.max(...pageNumbers) ? (
													<Image
														className='change-page'
														src='./img/pagination-next.png'
													/>
												) : null}
											</button>
										)}
									</div>
								)}
							</Col>
						</Row>
					) : null}

					{
						address && filter === "WALLET" && isLoading ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Spinner
									style={{
										color: "#fc4ba3",
										width: "100px",
										height: "100px",
									}}
									size='xl'
									variant='#fc4ba3'
									color='#fc4ba3'
									animation='border'
									role='status'></Spinner>
							</div>
						) : (
							<Row className='g-4 align-items-start mt-5 mb-5'>
								<Items />
							</Row>
						)

						// check if wallet is connected and wallet content is loading
					}

					{robotData.length > 1 && (
						<Row className='justify-content-center'>
							<Col className='text-center'>
								{pageNumbers.length > 1 && (
									<div className='pagination-robots d-flex align-items-center justify-content-center mt-0  '>
										{pageNumbers.length > 1 && (
											<button
												onClick={() => {
													lastPage();
												}}
												className='pagination-prev'>
												{lastPageNumber > 1 ? (
													<Image
														className='change-page'
														src='./img/pagination-prev.png'
													/>
												) : null}
											</button>
										)}
										{firstThreePages.map((number) => (
											<span
												role='button'
												className={
													number === lastPageNumber ? "three-dots" : ""
												}
												onClick={() => {
													setPageNumber(number);
													pageNumberRef.current = number;
													sessionStorage.setItem(
														"lastPageNumber",
														pageNumberRef.current
													);
													actions.updateImagesWithSameContract(Number(number));
												}}
												key={number}>
												{number}
											</span>
										))}

										{pageNumbers.length > 6 && <span>⋯</span>}
										{currentPage > 3 && (
											<span className='three-dots'>{lastPageNumber}</span>
										)}
										{pageNumbers.length > 6 && currentPage && <span>⋯</span>}
										{pageNumbers.length >= 4 &&
											lastThreePages.map((number) => (
												<span
													className={
														number === lastPageNumber ? "three-dots" : ""
													}
													role='button'
													onClick={() => {
														setPageNumber(number);
														pageNumberRef.current = number;
														sessionStorage.setItem(
															"lastPageNumber",
															pageNumberRef.current
														);
														actions.updateImagesWithSameContract(
															Number(number)
														);
													}}
													key={number}>
													{number}
												</span>
											))}

										{pageNumbers.length > 1 ? (
											<button
												onClick={() => {
													if (
														pageNumberRef.current < Math.max(...pageNumbers)
													) {
														nextPage();
													}
												}}
												className='pagination-next'>
												{pageNumberRef.current < Math.max(...pageNumbers) ? (
													<Image
														className='change-page'
														src='./img/pagination-next.png'
													/>
												) : null}
											</button>
										) : null}
									</div>
								)}
							</Col>
						</Row>
					)}
				</Container>
			);
		},
		[robotData, pageNumberRef]
	);

	const WalletSpan = (
		<span
			className={`see-all-filter${filter === "WALLET" ? " active" : ""}`}
			onClick={() => {
				updateFilter("WALLET");
				sessionStorage.setItem("lastFilter", "WALLET");
			}}>
			ONLY MINE
		</span>
	);

	return (
		<>
			<section className='robot-lists' style={{ paddingTop: "-30px" }}>
				<Container>
					<Row>
						<Col lg={12} className='text-center'>
							<div>
								<span className='quick-select'>
									QUICK SELECT COLLECTIONS
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<div className='robot-lists-slider '>
							<Slider {...slickSettings}>
								<div
									className={`robot-slide-item ${
										contract && contract._id === curatedCollections["BAYC"]._id
											? "active"
											: ""
									}`}
									onClick={() => updateQuickCollection("BAYC")}>
									<span>BAYC</span>
								</div>
								<div
									className={`robot-slide-item ${
										contract &&
										contract._id === curatedCollections["cryptopunks"]._id
											? "active"
											: ""
									}`}
									onClick={() => updateQuickCollection("cryptopunks")}>
									<span>Cryptopunks</span>
								</div>
								<div
									className={`robot-slide-item`}
									onClick={() => updateQuickCollection("")}>
									<span>To Be Announced</span>
								</div>
								<div
									className='robot-slide-item'
									onClick={() => updateQuickCollection("")}>
									<span>To Be Announced</span>
								</div>
								<div
									className='robot-slide-item'
									onClick={() => updateQuickCollection("")}>
									<span>To Be Announced</span>
								</div>
							</Slider>
						</div>
					</Row>
				</Container>
			</section>

			<section className='robot-lists-section'>
				<Container>
					<Row>
						<Col lg={12} className='text-center'>
							<div className='robot-filter'>
								<span
									className={`see-all-filter${
										filter === "DEFAULT" ? " active" : ""
									}`}
									onClick={() => {
										updateFilter("DEFAULT");
										sessionStorage.setItem("lastFilter", "DEFAULT");
									}}>
									SEE ALL
								</span>
								|
								<span
									className={`see-all-filter${
										filter === "NAMED" ? " active" : ""
									}`}
									onClick={() => {
										updateFilter("NAMED");
										sessionStorage.setItem("lastFilter", "NAMED");
									}}>
									{" "}
									ONLY NAMED{" | "}
								</span>
								{/* listed */}
								<span
									className={`see-all-filter${
										filter === "LISTED" ? " active" : ""
									}`}
									onClick={() => {
										updateFilter("LISTED");
										sessionStorage.setItem("lastFilter", "LISTED");
									}}>
									{" "}
									LISTED NAMES{" | "}
								</span>
								{WalletSpan} |
								<>
									<span className='see-all-filter'>
										<IdSearch
											updateNFTs={
												filter === "WALLET" || filter === "NAMED"
													? walletSearch
													: updateImagesWithSameContract
											}
											updateSelectedContractAndFetchImages={
												updateSelectedContractAndFetchImages
											}
											contract={contract}
											walletTokenIdLookup={walletTokenIdLookup}
										/>
									</span>
								</>
							</div>
						</Col>
					</Row>
					<Row>
						<p className='text-white text-center fs-3'>{message}</p>
						<PaginatedItems itemsPerPage={16} />
					</Row>
				</Container>
			</section>
		</>
	);
};

export default RobotsList;
