import { useEffect } from 'react'
import { WOW } from 'wowjs'
import { Container, Row, Col } from 'react-bootstrap'
import './cta.css'

const Cta = ({ title, url, classN }) => {
  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init()
  }, [])
  return (
    <section className={classN}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <div className="cta-text wow fadeInUp">
              <a
                className="cta-button"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {title}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Cta
