import { useState } from "react";
import "./SelectReceipient.css";
import SearchByName from "./SearchNFTOptions/SearchByName";
import SearchByCollection from "./SearchNFTOptions/SearchByCollection";
import SearchByWallet from "./SearchNFTOptions/SearchByWallet";

const SearchNFTOptions = ({
	setSelected,
	selected,
	address = "",
	setAddress,
}: ISearchNFTOptionsProps) => {
	const [active, setActive] = useState("");

	return (
		<div className='select-method-container'>
			<SearchByName
				active={active}
				selected={selected}
				setActive={setActive}
				setSelected={setSelected}
			/>
			<SearchByCollection
				active={active}
				selected={selected}
				setActive={setActive}
				setSelected={setSelected}
			/>

			{setAddress ? (
				<SearchByWallet
					active={active}
					setActive={setActive}
					address={address}
					setAddress={setAddress}
					setSelected={setSelected}
				/>
			) : null}
		</div>
	);
};

export default SearchNFTOptions;

interface ISearchNFTOptionsProps {
	selected: any | undefined;
	setSelected: React.Dispatch<React.SetStateAction<any | undefined>>;
	setAddress?: React.Dispatch<React.SetStateAction<string>>;
	address?: string;
}
