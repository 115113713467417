const specialNames = [
  {
    id: "0",
    name: "0",
  },
  {
    id: "1",
    name: "1",
  },
  {
    id: "2",
    name: "2",
  },
  {
    id: "3",
    name: "3",
  },
  {
    id: "4",
    name: "4",
  },
  {
    id: "5",
    name: "5",
  },
  {
    id: "6",
    name: "6",
  },
  {
    id: "7",
    name: "7",
  },
  {
    id: "8",
    name: "8",
  },
  {
    id: "9",
    name: "9",
  },
  {
    id: "10",
    name: "10",
  },
  {
    id: "11",
    name: "69",
  },
  {
    id: "12",
    name: "123",
  },
  {
    id: "13",
    name: "420",
  },
  {
    id: "14",
    name: "666",
  },
  {
    id: "15",
    name: "42069",
  },
  {
    id: "16",
    name: "69420",
  },
  {
    id: "17",
    name: "007",
  },
  {
    id: "18",
    name: "0x",
  },
  {
    id: "19",
    name: "0x0",
  },
  {
    id: "20",
    name: "2pac",
  },
  {
    id: "21",
    name: "50cent",
  },
  {
    id: "22",
    name: "a",
  },
  {
    id: "23",
    name: "aaron rodgers",
  },
  {
    id: "24",
    name: "abra",
  },
  {
    id: "25",
    name: "abraham lincoln",
  },
  {
    id: "26",
    name: "ace ventura",
  },
  {
    id: "27",
    name: "adele",
  },
  {
    id: "28",
    name: "adidas",
  },
  {
    id: "29",
    name: "adolf hitler",
  },
  {
    id: "30",
    name: "agent 47",
  },
  {
    id: "31",
    name: "air jordan",
  },
  {
    id: "32",
    name: "akshay kumar",
  },
  {
    id: "33",
    name: "aladdin",
  },
  {
    id: "34",
    name: "alakazam",
  },
  {
    id: "35",
    name: "albert einstein",
  },
  {
    id: "36",
    name: "albus dumbledore",
  },
  {
    id: "37",
    name: "alexa",
  },
  {
    id: "38",
    name: "alibaba",
  },
  {
    id: "39",
    name: "alien",
  },
  {
    id: "40",
    name: "alpha",
  },
  {
    id: "41",
    name: "amazon",
  },
  {
    id: "42",
    name: "america",
  },
  {
    id: "43",
    name: "american psycho",
  },
  {
    id: "44",
    name: "amsterdam",
  },
  {
    id: "45",
    name: "anakin skywalker",
  },
  {
    id: "46",
    name: "andy warhol",
  },
  {
    id: "47",
    name: "angel",
  },
  {
    id: "48",
    name: "angelina jolie",
  },
  {
    id: "49",
    name: "angels",
  },
  {
    id: "50",
    name: "anne frank",
  },
  {
    id: "51",
    name: "anthony davis",
  },
  {
    id: "52",
    name: "ape",
  },
  {
    id: "53",
    name: "aphrodite",
  },
  {
    id: "54",
    name: "apollo",
  },
  {
    id: "55",
    name: "apple",
  },
  {
    id: "56",
    name: "aquaman",
  },
  {
    id: "57",
    name: "arcanine",
  },
  {
    id: "58",
    name: "ares",
  },
  {
    id: "59",
    name: "ariana grande",
  },
  {
    id: "60",
    name: "arnold schwarzenegger",
  },
  {
    id: "61",
    name: "art",
  },
  {
    id: "62",
    name: "art block",
  },
  {
    id: "63",
    name: "art blocks",
  },
  {
    id: "64",
    name: "art collector",
  },
  {
    id: "65",
    name: "artemis",
  },
  {
    id: "66",
    name: "articuno",
  },
  {
    id: "67",
    name: "artificial intelligence",
  },
  {
    id: "68",
    name: "artist",
  },
  {
    id: "69",
    name: "ashton kutcher",
  },
  {
    id: "70",
    name: "ass",
  },
  {
    id: "71",
    name: "athena",
  },
  {
    id: "72",
    name: "audrey hepburn",
  },
  {
    id: "73",
    name: "avatar",
  },
  {
    id: "74",
    name: "b",
  },
  {
    id: "75",
    name: "babe ruth",
  },
  {
    id: "76",
    name: "baby yoda",
  },
  {
    id: "77",
    name: "bancor",
  },
  {
    id: "78",
    name: "bangladesh",
  },
  {
    id: "79",
    name: "banksy",
  },
  {
    id: "80",
    name: "barack",
  },
  {
    id: "81",
    name: "barack obama",
  },
  {
    id: "82",
    name: "barbie",
  },
  {
    id: "83",
    name: "barcelona",
  },
  {
    id: "84",
    name: "barry silbert",
  },
  {
    id: "85",
    name: "bart simpson",
  },
  {
    id: "86",
    name: "baseball",
  },
  {
    id: "87",
    name: "basketball",
  },
  {
    id: "88",
    name: "batman",
  },
  {
    id: "89",
    name: "bayc",
  },
  {
    id: "90",
    name: "bayern munich",
  },
  {
    id: "91",
    name: "beanie",
  },
  {
    id: "92",
    name: "beautiful",
  },
  {
    id: "93",
    name: "beeple",
  },
  {
    id: "94",
    name: "beer",
  },
  {
    id: "95",
    name: "beethoven",
  },
  {
    id: "96",
    name: "beijing",
  },
  {
    id: "97",
    name: "ben affleck",
  },
  {
    id: "98",
    name: "bernie sanders",
  },
  {
    id: "99",
    name: "beyonce",
  },
  {
    id: "100",
    name: "bezos",
  },
  {
    id: "101",
    name: "biden",
  },
  {
    id: "102",
    name: "biggie smalls",
  },
  {
    id: "103",
    name: "bill gates",
  },
  {
    id: "104",
    name: "billie eilish",
  },
  {
    id: "105",
    name: "billionaire",
  },
  {
    id: "106",
    name: "bitcoin",
  },
  {
    id: "107",
    name: "black",
  },
  {
    id: "108",
    name: "black lives matter",
  },
  {
    id: "109",
    name: "black mamba",
  },
  {
    id: "110",
    name: "black panther",
  },
  {
    id: "111",
    name: "black widow",
  },
  {
    id: "112",
    name: "blade",
  },
  {
    id: "113",
    name: "blastoise",
  },
  {
    id: "114",
    name: "blockchain",
  },
  {
    id: "115",
    name: "blue",
  },
  {
    id: "116",
    name: "bnb",
  },
  {
    id: "117",
    name: "bob dylan",
  },
  {
    id: "118",
    name: "bob mariey",
  },
  {
    id: "119",
    name: "bored ape",
  },
  {
    id: "120",
    name: "bored ape yacht club",
  },
  {
    id: "121",
    name: "bored apes",
  },
  {
    id: "122",
    name: "boston celtics",
  },
  {
    id: "123",
    name: "boston red sox",
  },
  {
    id: "124",
    name: "bot",
  },
  {
    id: "125",
    name: "bowser",
  },
  {
    id: "126",
    name: "brad pitt",
  },
  {
    id: "127",
    name: "brazil",
  },
  {
    id: "128",
    name: "britney spears",
  },
  {
    id: "129",
    name: "bruce lee",
  },
  {
    id: "130",
    name: "bruno mars",
  },
  {
    id: "131",
    name: "bryce harper",
  },
  {
    id: "132",
    name: "btc",
  },
  {
    id: "133",
    name: "bts",
  },
  {
    id: "134",
    name: "buddha",
  },
  {
    id: "135",
    name: "budweiser",
  },
  {
    id: "136",
    name: "bulbasaur",
  },
  {
    id: "137",
    name: "bull",
  },
  {
    id: "138",
    name: "bulls",
  },
  {
    id: "139",
    name: "burger king",
  },
  {
    id: "140",
    name: "byo",
  },
  {
    id: "141",
    name: "byopill",
  },
  {
    id: "142",
    name: "c",
  },
  {
    id: "143",
    name: "c3po",
  },
  {
    id: "144",
    name: "cairo",
  },
  {
    id: "145",
    name: "california",
  },
  {
    id: "146",
    name: "cameron winklevoss",
  },
  {
    id: "147",
    name: "captain america",
  },
  {
    id: "148",
    name: "cara delevingne",
  },
  {
    id: "149",
    name: "cardano",
  },
  {
    id: "150",
    name: "cardi b",
  },
  {
    id: "151",
    name: "carole baskin",
  },
  {
    id: "152",
    name: "carpe diem",
  },
  {
    id: "153",
    name: "carrie",
  },
  {
    id: "154",
    name: "cash",
  },
  {
    id: "155",
    name: "casper",
  },
  {
    id: "156",
    name: "cat",
  },
  {
    id: "157",
    name: "celtics",
  },
  {
    id: "158",
    name: "ceo",
  },
  {
    id: "159",
    name: "chainlink",
  },
  {
    id: "160",
    name: "changpeng zhao",
  },
  {
    id: "161",
    name: "charizard",
  },
  {
    id: "162",
    name: "charli damelio",
  },
  {
    id: "163",
    name: "charlie chaplin",
  },
  {
    id: "164",
    name: "charmander",
  },
  {
    id: "165",
    name: "charmeleon",
  },
  {
    id: "166",
    name: "che guevara",
  },
  {
    id: "167",
    name: "chewbacca",
  },
  {
    id: "168",
    name: "chicago cubs",
  },
  {
    id: "169",
    name: "china",
  },
  {
    id: "170",
    name: "chosen one",
  },
  {
    id: "171",
    name: "chris brown",
  },
  {
    id: "172",
    name: "christ",
  },
  {
    id: "173",
    name: "christopher columbus",
  },
  {
    id: "174",
    name: "cisco",
  },
  {
    id: "175",
    name: "clefable",
  },
  {
    id: "176",
    name: "clefairy",
  },
  {
    id: "177",
    name: "cleopatra",
  },
  {
    id: "178",
    name: "clone x",
  },
  {
    id: "179",
    name: "cloud",
  },
  {
    id: "180",
    name: "cloud strife",
  },
  {
    id: "181",
    name: "cobie",
  },
  {
    id: "182",
    name: "coca cola",
  },
  {
    id: "183",
    name: "coin",
  },
  {
    id: "184",
    name: "coinbase",
  },
  {
    id: "185",
    name: "collector",
  },
  {
    id: "186",
    name: "columbus",
  },
  {
    id: "187",
    name: "comic",
  },
  {
    id: "188",
    name: "conor mcgregor",
  },
  {
    id: "189",
    name: "cool cat",
  },
  {
    id: "190",
    name: "cool cats",
  },
  {
    id: "191",
    name: "cope",
  },
  {
    id: "192",
    name: "cortana",
  },
  {
    id: "193",
    name: "crash",
  },
  {
    id: "194",
    name: "crazy",
  },
  {
    id: "195",
    name: "cristiano ronaldo",
  },
  {
    id: "196",
    name: "crypto",
  },
  {
    id: "197",
    name: "cryptoadz",
  },
  {
    id: "198",
    name: "cryptocurrency",
  },
  {
    id: "199",
    name: "cryptopunk",
  },
  {
    id: "200",
    name: "cryptopunks",
  },
  {
    id: "201",
    name: "cubone",
  },
  {
    id: "202",
    name: "cupid",
  },
  {
    id: "203",
    name: "cyberkong",
  },
  {
    id: "204",
    name: "cyberkongz",
  },
  {
    id: "205",
    name: "d",
  },
  {
    id: "206",
    name: "da vinci",
  },
  {
    id: "207",
    name: "dababy",
  },
  {
    id: "208",
    name: "dak prescott",
  },
  {
    id: "209",
    name: "dallas cowboys",
  },
  {
    id: "210",
    name: "damien lillard",
  },
  {
    id: "211",
    name: "damn",
  },
  {
    id: "212",
    name: "danny",
  },
  {
    id: "213",
    name: "dannypunk",
  },
  {
    id: "214",
    name: "dao",
  },
  {
    id: "215",
    name: "darth maul",
  },
  {
    id: "216",
    name: "darth vader",
  },
  {
    id: "217",
    name: "data",
  },
  {
    id: "218",
    name: "dave chappelle",
  },
  {
    id: "219",
    name: "david beckham",
  },
  {
    id: "220",
    name: "david dobrik",
  },
  {
    id: "221",
    name: "davinci",
  },
  {
    id: "222",
    name: "deadfella",
  },
  {
    id: "223",
    name: "deadfellaz",
  },
  {
    id: "224",
    name: "deepika padukone",
  },
  {
    id: "225",
    name: "deez nuts",
  },
  {
    id: "226",
    name: "defi",
  },
  {
    id: "227",
    name: "degen",
  },
  {
    id: "228",
    name: "degenerate",
  },
  {
    id: "229",
    name: "delhi",
  },
  {
    id: "230",
    name: "demi lovato",
  },
  {
    id: "231",
    name: "devil",
  },
  {
    id: "232",
    name: "devin booker",
  },
  {
    id: "233",
    name: "dez bryant",
  },
  {
    id: "234",
    name: "dhaka",
  },
  {
    id: "235",
    name: "diamond hands",
  },
  {
    id: "236",
    name: "dick",
  },
  {
    id: "237",
    name: "diddy kong",
  },
  {
    id: "238",
    name: "diego maradona",
  },
  {
    id: "239",
    name: "diglett",
  },
  {
    id: "240",
    name: "disney",
  },
  {
    id: "241",
    name: "ditto",
  },
  {
    id: "242",
    name: "dodgers",
  },
  {
    id: "243",
    name: "dodrio",
  },
  {
    id: "244",
    name: "doduo",
  },
  {
    id: "245",
    name: "dog",
  },
  {
    id: "246",
    name: "doge",
  },
  {
    id: "247",
    name: "dogecoin",
  },
  {
    id: "248",
    name: "donald trump",
  },
  {
    id: "249",
    name: "donkey kong",
  },
  {
    id: "250",
    name: "doodle",
  },
  {
    id: "251",
    name: "doodles",
  },
  {
    id: "252",
    name: "dorothy",
  },
  {
    id: "253",
    name: "dr congo",
  },
  {
    id: "254",
    name: "dr dre",
  },
  {
    id: "255",
    name: "dr evil",
  },
  {
    id: "256",
    name: "dracula",
  },
  {
    id: "257",
    name: "dragon",
  },
  {
    id: "258",
    name: "drake",
  },
  {
    id: "259",
    name: "dre",
  },
  {
    id: "260",
    name: "drowsee",
  },
  {
    id: "261",
    name: "dua lipa",
  },
  {
    id: "262",
    name: "dubai",
  },
  {
    id: "263",
    name: "dude",
  },
  {
    id: "264",
    name: "dugtrio",
  },
  {
    id: "265",
    name: "duke nukem",
  },
  {
    id: "266",
    name: "dwayne johnson",
  },
  {
    id: "267",
    name: "dydx",
  },
  {
    id: "268",
    name: "e",
  },
  {
    id: "269",
    name: "earth",
  },
  {
    id: "270",
    name: "ed sheeran",
  },
  {
    id: "271",
    name: "edward scissorhands",
  },
  {
    id: "272",
    name: "edward snowden",
  },
  {
    id: "273",
    name: "eevee",
  },
  {
    id: "274",
    name: "egirl",
  },
  {
    id: "275",
    name: "egypt",
  },
  {
    id: "276",
    name: "eight",
  },
  {
    id: "277",
    name: "el diablo",
  },
  {
    id: "278",
    name: "electrode",
  },
  {
    id: "279",
    name: "ellen degeneres",
  },
  {
    id: "280",
    name: "elon",
  },
  {
    id: "281",
    name: "elon musk",
  },
  {
    id: "282",
    name: "elvis",
  },
  {
    id: "283",
    name: "elvis presley",
  },
  {
    id: "284",
    name: "emily ratajkowski",
  },
  {
    id: "285",
    name: "eminem",
  },
  {
    id: "286",
    name: "ens",
  },
  {
    id: "287",
    name: "erc20",
  },
  {
    id: "288",
    name: "erling haaland",
  },
  {
    id: "289",
    name: "esports",
  },
  {
    id: "290",
    name: "et",
  },
  {
    id: "291",
    name: "eth",
  },
  {
    id: "292",
    name: "ether",
  },
  {
    id: "293",
    name: "ethereum",
  },
  {
    id: "294",
    name: "ethiopia",
  },
  {
    id: "295",
    name: "exeggcute",
  },
  {
    id: "296",
    name: "exeggutor",
  },
  {
    id: "297",
    name: "ezio",
  },
  {
    id: "298",
    name: "f",
  },
  {
    id: "299",
    name: "facebook",
  },
  {
    id: "300",
    name: "farmer",
  },
  {
    id: "301",
    name: "fear",
  },
  {
    id: "302",
    name: "few",
  },
  {
    id: "303",
    name: "fiat",
  },
  {
    id: "304",
    name: "fidenza",
  },
  {
    id: "305",
    name: "five",
  },
  {
    id: "306",
    name: "flame",
  },
  {
    id: "307",
    name: "flareon",
  },
  {
    id: "308",
    name: "fluf world",
  },
  {
    id: "309",
    name: "fomo",
  },
  {
    id: "310",
    name: "football",
  },
  {
    id: "311",
    name: "forrest gump",
  },
  {
    id: "312",
    name: "fortnite",
  },
  {
    id: "313",
    name: "four",
  },
  {
    id: "314",
    name: "fox mccloud",
  },
  {
    id: "315",
    name: "france",
  },
  {
    id: "316",
    name: "frankenstein",
  },
  {
    id: "317",
    name: "freddy krueger",
  },
  {
    id: "318",
    name: "free",
  },
  {
    id: "319",
    name: "fren",
  },
  {
    id: "320",
    name: "fuck",
  },
  {
    id: "321",
    name: "future of france",
  },
  {
    id: "322",
    name: "g",
  },
  {
    id: "323",
    name: "game",
  },
  {
    id: "324",
    name: "gamestop",
  },
  {
    id: "325",
    name: "gandalf",
  },
  {
    id: "326",
    name: "gandhi",
  },
  {
    id: "327",
    name: "gangster",
  },
  {
    id: "328",
    name: "gastly",
  },
  {
    id: "329",
    name: "gay",
  },
  {
    id: "330",
    name: "gengar",
  },
  {
    id: "331",
    name: "genghis khan",
  },
  {
    id: "332",
    name: "geodude",
  },
  {
    id: "333",
    name: "george clooney",
  },
  {
    id: "334",
    name: "george washington",
  },
  {
    id: "335",
    name: "geralt",
  },
  {
    id: "336",
    name: "geralt of rivia",
  },
  {
    id: "337",
    name: "germany",
  },
  {
    id: "338",
    name: "ghost",
  },
  {
    id: "339",
    name: "ghostface",
  },
  {
    id: "340",
    name: "giannis",
  },
  {
    id: "341",
    name: "giannis antetokounmpo",
  },
  {
    id: "342",
    name: "giant",
  },
  {
    id: "343",
    name: "giants",
  },
  {
    id: "344",
    name: "gif",
  },
  {
    id: "345",
    name: "gm",
  },
  {
    id: "346",
    name: "gmi",
  },
  {
    id: "347",
    name: "gn",
  },
  {
    id: "348",
    name: "god",
  },
  {
    id: "349",
    name: "godzilla",
  },
  {
    id: "350",
    name: "goku",
  },
  {
    id: "351",
    name: "gold",
  },
  {
    id: "352",
    name: "golden state warriors",
  },
  {
    id: "353",
    name: "goldfinger",
  },
  {
    id: "354",
    name: "goliath",
  },
  {
    id: "355",
    name: "gollum",
  },
  {
    id: "356",
    name: "google",
  },
  {
    id: "357",
    name: "gordon freeman",
  },
  {
    id: "358",
    name: "gorilla",
  },
  {
    id: "359",
    name: "graveler",
  },
  {
    id: "360",
    name: "greatest of all time",
  },
  {
    id: "361",
    name: "green",
  },
  {
    id: "362",
    name: "grimer",
  },
  {
    id: "363",
    name: "grimes",
  },
  {
    id: "364",
    name: "growlithe",
  },
  {
    id: "365",
    name: "gta",
  },
  {
    id: "366",
    name: "gwei",
  },
  {
    id: "367",
    name: "gyarados",
  },
  {
    id: "368",
    name: "h",
  },
  {
    id: "369",
    name: "haaland",
  },
  {
    id: "370",
    name: "hacker",
  },
  {
    id: "371",
    name: "hailey bieber",
  },
  {
    id: "372",
    name: "halo",
  },
  {
    id: "373",
    name: "halsey",
  },
  {
    id: "374",
    name: "han solo",
  },
  {
    id: "375",
    name: "hannibal",
  },
  {
    id: "376",
    name: "happy",
  },
  {
    id: "377",
    name: "harambe",
  },
  {
    id: "378",
    name: "harley quinn",
  },
  {
    id: "379",
    name: "harry potter",
  },
  {
    id: "380",
    name: "harry styles",
  },
  {
    id: "381",
    name: "hashmask",
  },
  {
    id: "382",
    name: "hashmasks",
  },
  {
    id: "383",
    name: "haunter",
  },
  {
    id: "384",
    name: "have fun staying poor",
  },
  {
    id: "385",
    name: "hawkeye",
  },
  {
    id: "386",
    name: "heisenberg",
  },
  {
    id: "387",
    name: "hello",
  },
  {
    id: "388",
    name: "help",
  },
  {
    id: "389",
    name: "hercules",
  },
  {
    id: "390",
    name: "hermione",
  },
  {
    id: "391",
    name: "hermione granger",
  },
  {
    id: "392",
    name: "hi",
  },
  {
    id: "393",
    name: "hitier",
  },
  {
    id: "394",
    name: "hitman",
  },
  {
    id: "395",
    name: "hitmonchan",
  },
  {
    id: "396",
    name: "hitmonlee",
  },
  {
    id: "397",
    name: "hodl",
  },
  {
    id: "398",
    name: "hodler",
  },
  {
    id: "399",
    name: "hollywood",
  },
  {
    id: "400",
    name: "holy ghost",
  },
  {
    id: "401",
    name: "holy spirit",
  },
  {
    id: "402",
    name: "homer",
  },
  {
    id: "403",
    name: "homer simpson",
  },
  {
    id: "404",
    name: "hot",
  },
  {
    id: "405",
    name: "houdini",
  },
  {
    id: "406",
    name: "hulk",
  },
  {
    id: "407",
    name: "hustler",
  },
  {
    id: "408",
    name: "hype",
  },
  {
    id: "409",
    name: "i",
  },
  {
    id: "410",
    name: "i love you",
  },
  {
    id: "411",
    name: "ibm",
  },
  {
    id: "412",
    name: "immortal",
  },
  {
    id: "413",
    name: "india",
  },
  {
    id: "414",
    name: "indiana jones",
  },
  {
    id: "415",
    name: "indonesia",
  },
  {
    id: "416",
    name: "infinity",
  },
  {
    id: "417",
    name: "instagram",
  },
  {
    id: "418",
    name: "intel",
  },
  {
    id: "419",
    name: "internet",
  },
  {
    id: "420",
    name: "iphone",
  },
  {
    id: "421",
    name: "iran",
  },
  {
    id: "422",
    name: "irobot",
  },
  {
    id: "423",
    name: "iron man",
  },
  {
    id: "424",
    name: "island boy",
  },
  {
    id: "425",
    name: "it",
  },
  {
    id: "426",
    name: "italy",
  },
  {
    id: "427",
    name: "ivysaur",
  },
  {
    id: "428",
    name: "iykyk",
  },
  {
    id: "429",
    name: "j",
  },
  {
    id: "430",
    name: "ja morant",
  },
  {
    id: "431",
    name: "jack dorsey",
  },
  {
    id: "432",
    name: "jack sparrow",
  },
  {
    id: "433",
    name: "jack torrance",
  },
  {
    id: "434",
    name: "jackie chan",
  },
  {
    id: "435",
    name: "jadu",
  },
  {
    id: "436",
    name: "jake paul",
  },
  {
    id: "437",
    name: "james bond",
  },
  {
    id: "438",
    name: "james harden",
  },
  {
    id: "439",
    name: "japan",
  },
  {
    id: "440",
    name: "jay z",
  },
  {
    id: "441",
    name: "jayson tatum",
  },
  {
    id: "442",
    name: "jedi",
  },
  {
    id: "443",
    name: "jeff bezos",
  },
  {
    id: "444",
    name: "jennifer lawrence",
  },
  {
    id: "445",
    name: "jennifer lopez",
  },
  {
    id: "446",
    name: "jesse pinkman",
  },
  {
    id: "447",
    name: "jesus",
  },
  {
    id: "448",
    name: "jigglypuff",
  },
  {
    id: "449",
    name: "jlo",
  },
  {
    id: "450",
    name: "joan of arc",
  },
  {
    id: "451",
    name: "joe biden",
  },
  {
    id: "452",
    name: "joe montana",
  },
  {
    id: "453",
    name: "john kennedy",
  },
  {
    id: "454",
    name: "john legend",
  },
  {
    id: "455",
    name: "john lennon",
  },
  {
    id: "456",
    name: "john marston",
  },
  {
    id: "457",
    name: "john travolta",
  },
  {
    id: "458",
    name: "johnny depp",
  },
  {
    id: "459",
    name: "joker",
  },
  {
    id: "460",
    name: "jolteon",
  },
  {
    id: "461",
    name: "jpeg",
  },
  {
    id: "462",
    name: "julia roberts",
  },
  {
    id: "463",
    name: "julius caesar",
  },
  {
    id: "464",
    name: "jungkook",
  },
  {
    id: "465",
    name: "jupiter",
  },
  {
    id: "466",
    name: "justin bieber",
  },
  {
    id: "467",
    name: "justin sun",
  },
  {
    id: "468",
    name: "justin timberlake",
  },
  {
    id: "469",
    name: "k",
  },
  {
    id: "470",
    name: "kadabra",
  },
  {
    id: "471",
    name: "kanye west",
  },
  {
    id: "472",
    name: "kate moss",
  },
  {
    id: "473",
    name: "katy perry",
  },
  {
    id: "474",
    name: "keanu reeves",
  },
  {
    id: "475",
    name: "kek",
  },
  {
    id: "476",
    name: "kendall jenner",
  },
  {
    id: "477",
    name: "kevin de bruyne",
  },
  {
    id: "478",
    name: "kevin durant",
  },
  {
    id: "479",
    name: "kevin hart",
  },
  {
    id: "480",
    name: "kevin mccallister",
  },
  {
    id: "481",
    name: "kfc",
  },
  {
    id: "482",
    name: "khloe kardashian",
  },
  {
    id: "483",
    name: "kim jong un",
  },
  {
    id: "484",
    name: "kim kardashian",
  },
  {
    id: "485",
    name: "king",
  },
  {
    id: "486",
    name: "king kong",
  },
  {
    id: "487",
    name: "king tut",
  },
  {
    id: "488",
    name: "kirby",
  },
  {
    id: "489",
    name: "kitana",
  },
  {
    id: "490",
    name: "knicks",
  },
  {
    id: "491",
    name: "knight",
  },
  {
    id: "492",
    name: "kobe",
  },
  {
    id: "493",
    name: "kobe bryant",
  },
  {
    id: "494",
    name: "koffing",
  },
  {
    id: "495",
    name: "kong",
  },
  {
    id: "496",
    name: "kourtney kardashian",
  },
  {
    id: "497",
    name: "kratos",
  },
  {
    id: "498",
    name: "ksi",
  },
  {
    id: "499",
    name: "kyler murray",
  },
  {
    id: "500",
    name: "kylian mbappe",
  },
  {
    id: "501",
    name: "kylie jenner",
  },
  {
    id: "502",
    name: "l",
  },
  {
    id: "503",
    name: "lady gaga",
  },
  {
    id: "504",
    name: "lakers",
  },
  {
    id: "505",
    name: "lamar jackson",
  },
  {
    id: "506",
    name: "lara croft",
  },
  {
    id: "507",
    name: "lebron",
  },
  {
    id: "508",
    name: "lebron james",
  },
  {
    id: "509",
    name: "leonardo da vinci",
  },
  {
    id: "510",
    name: "leonardo dicaprio",
  },
  {
    id: "511",
    name: "lfg",
  },
  {
    id: "512",
    name: "life",
  },
  {
    id: "513",
    name: "lil baby",
  },
  {
    id: "514",
    name: "lil nas x",
  },
  {
    id: "515",
    name: "lindsay lohan",
  },
  {
    id: "516",
    name: "link",
  },
  {
    id: "517",
    name: "link marine",
  },
  {
    id: "518",
    name: "lion king",
  },
  {
    id: "519",
    name: "lionel messi",
  },
  {
    id: "520",
    name: "liquidator",
  },
  {
    id: "521",
    name: "lisa manoban",
  },
  {
    id: "522",
    name: "liverpool",
  },
  {
    id: "523",
    name: "logan paul",
  },
  {
    id: "524",
    name: "london",
  },
  {
    id: "525",
    name: "looks rare",
  },
  {
    id: "526",
    name: "looksrare",
  },
  {
    id: "527",
    name: "loomdart",
  },
  {
    id: "528",
    name: "loot",
  },
  {
    id: "529",
    name: "lord",
  },
  {
    id: "530",
    name: "los angeles",
  },
  {
    id: "531",
    name: "los angeles dodgers",
  },
  {
    id: "532",
    name: "los angeles lakers",
  },
  {
    id: "533",
    name: "los angeles rams",
  },
  {
    id: "534",
    name: "love",
  },
  {
    id: "535",
    name: "luigi",
  },
  {
    id: "536",
    name: "luka doncic",
  },
  {
    id: "537",
    name: "luke skywalker",
  },
  {
    id: "538",
    name: "m",
  },
  {
    id: "539",
    name: "machamp",
  },
  {
    id: "540",
    name: "machoke",
  },
  {
    id: "541",
    name: "machop",
  },
  {
    id: "542",
    name: "madonna",
  },
  {
    id: "543",
    name: "madrid",
  },
  {
    id: "544",
    name: "magic",
  },
  {
    id: "545",
    name: "magic johnson",
  },
  {
    id: "546",
    name: "magnemite",
  },
  {
    id: "547",
    name: "magneton",
  },
  {
    id: "548",
    name: "malcolm x",
  },
  {
    id: "549",
    name: "man",
  },
  {
    id: "550",
    name: "manchester city",
  },
  {
    id: "551",
    name: "manchester united",
  },
  {
    id: "552",
    name: "mandalorian",
  },
  {
    id: "553",
    name: "mao",
  },
  {
    id: "554",
    name: "marco polo",
  },
  {
    id: "555",
    name: "marcus fenix",
  },
  {
    id: "556",
    name: "mariah carey",
  },
  {
    id: "557",
    name: "marie antoinette",
  },
  {
    id: "558",
    name: "marijuana",
  },
  {
    id: "559",
    name: "marilyn monroe",
  },
  {
    id: "560",
    name: "mario",
  },
  {
    id: "561",
    name: "mark cuban",
  },
  {
    id: "562",
    name: "mark zuckerberg",
  },
  {
    id: "563",
    name: "mars",
  },
  {
    id: "564",
    name: "marshall mathers",
  },
  {
    id: "565",
    name: "marshmello",
  },
  {
    id: "566",
    name: "martin luther king jr",
  },
  {
    id: "567",
    name: "marvel",
  },
  {
    id: "568",
    name: "master chief",
  },
  {
    id: "569",
    name: "mastercard",
  },
  {
    id: "570",
    name: "max payne",
  },
  {
    id: "571",
    name: "mayc",
  },
  {
    id: "572",
    name: "mbappe",
  },
  {
    id: "573",
    name: "mcdonalds",
  },
  {
    id: "574",
    name: "me",
  },
  {
    id: "575",
    name: "meebit",
  },
  {
    id: "576",
    name: "meebits",
  },
  {
    id: "577",
    name: "megaman",
  },
  {
    id: "578",
    name: "megatron",
  },
  {
    id: "579",
    name: "meme",
  },
  {
    id: "580",
    name: "meowth",
  },
  {
    id: "581",
    name: "mercury",
  },
  {
    id: "582",
    name: "messi",
  },
  {
    id: "583",
    name: "meta",
  },
  {
    id: "584",
    name: "metasaur",
  },
  {
    id: "585",
    name: "metaverse",
  },
  {
    id: "586",
    name: "mew",
  },
  {
    id: "587",
    name: "mewtwo",
  },
  {
    id: "588",
    name: "mexico",
  },
  {
    id: "589",
    name: "mexico city",
  },
  {
    id: "590",
    name: "michael",
  },
  {
    id: "591",
    name: "michael jackson",
  },
  {
    id: "592",
    name: "michael jordan",
  },
  {
    id: "593",
    name: "michael myers",
  },
  {
    id: "594",
    name: "michael saylor",
  },
  {
    id: "595",
    name: "michelangelo",
  },
  {
    id: "596",
    name: "mickey mouse",
  },
  {
    id: "597",
    name: "microsoft",
  },
  {
    id: "598",
    name: "mike",
  },
  {
    id: "599",
    name: "mike trout",
  },
  {
    id: "600",
    name: "miley cyrus",
  },
  {
    id: "601",
    name: "millionaire",
  },
  {
    id: "602",
    name: "mlb",
  },
  {
    id: "603",
    name: "modern art",
  },
  {
    id: "604",
    name: "mohamed salah",
  },
  {
    id: "605",
    name: "moltres",
  },
  {
    id: "606",
    name: "mona lisa",
  },
  {
    id: "607",
    name: "money",
  },
  {
    id: "608",
    name: "monkey",
  },
  {
    id: "609",
    name: "monster",
  },
  {
    id: "610",
    name: "moon",
  },
  {
    id: "611",
    name: "moon boy",
  },
  {
    id: "612",
    name: "moscow",
  },
  {
    id: "613",
    name: "moses",
  },
  {
    id: "614",
    name: "mother theresa",
  },
  {
    id: "615",
    name: "mr mime",
  },
  {
    id: "616",
    name: "mr roboto",
  },
  {
    id: "617",
    name: "muk",
  },
  {
    id: "618",
    name: "mumbai",
  },
  {
    id: "619",
    name: "music",
  },
  {
    id: "620",
    name: "mutant",
  },
  {
    id: "621",
    name: "mutant ape",
  },
  {
    id: "622",
    name: "mutant ape yacht club",
  },
  {
    id: "623",
    name: "mutant apes",
  },
  {
    id: "624",
    name: "n",
  },
  {
    id: "625",
    name: "nameless",
  },
  {
    id: "626",
    name: "napoleon",
  },
  {
    id: "627",
    name: "nas",
  },
  {
    id: "628",
    name: "nathan drake",
  },
  {
    id: "629",
    name: "naval",
  },
  {
    id: "630",
    name: "nayib bukele",
  },
  {
    id: "631",
    name: "nba",
  },
  {
    id: "632",
    name: "nelson mandela",
  },
  {
    id: "633",
    name: "neo",
  },
  {
    id: "634",
    name: "neptune",
  },
  {
    id: "635",
    name: "nerd",
  },
  {
    id: "636",
    name: "nero",
  },
  {
    id: "637",
    name: "netflix",
  },
  {
    id: "638",
    name: "new england patriots",
  },
  {
    id: "639",
    name: "new york city",
  },
  {
    id: "640",
    name: "new york giants",
  },
  {
    id: "641",
    name: "new york jets",
  },
  {
    id: "642",
    name: "new york knicks",
  },
  {
    id: "643",
    name: "new york yankees",
  },
  {
    id: "644",
    name: "neymar",
  },
  {
    id: "645",
    name: "nfl",
  },
  {
    id: "646",
    name: "nft",
  },
  {
    id: "647",
    name: "ngmi",
  },
  {
    id: "648",
    name: "nhl",
  },
  {
    id: "649",
    name: "nicki minaj",
  },
  {
    id: "650",
    name: "nidoran",
  },
  {
    id: "651",
    name: "nidorina",
  },
  {
    id: "652",
    name: "nidorino",
  },
  {
    id: "653",
    name: "nigeria",
  },
  {
    id: "654",
    name: "night",
  },
  {
    id: "655",
    name: "night king",
  },
  {
    id: "656",
    name: "nightmare",
  },
  {
    id: "657",
    name: "nike",
  },
  {
    id: "658",
    name: "nine",
  },
  {
    id: "659",
    name: "ninetales",
  },
  {
    id: "660",
    name: "ninja",
  },
  {
    id: "661",
    name: "no",
  },
  {
    id: "662",
    name: "no name",
  },
  {
    id: "663",
    name: "nobody",
  },
  {
    id: "664",
    name: "noname",
  },
  {
    id: "665",
    name: "noob",
  },
  {
    id: "666",
    name: "not for sale",
  },
  {
    id: "667",
    name: "null",
  },
  {
    id: "668",
    name: "nulladdress",
  },
  {
    id: "669",
    name: "o",
  },
  {
    id: "670",
    name: "obama",
  },
  {
    id: "671",
    name: "oddish",
  },
  {
    id: "672",
    name: "og",
  },
  {
    id: "673",
    name: "oklg",
  },
  {
    id: "674",
    name: "omega",
  },
  {
    id: "675",
    name: "one",
  },
  {
    id: "676",
    name: "onix",
  },
  {
    id: "677",
    name: "oprah winfrey",
  },
  {
    id: "678",
    name: "oracle",
  },
  {
    id: "679",
    name: "orange",
  },
  {
    id: "680",
    name: "osaka",
  },
  {
    id: "681",
    name: "p",
  },
  {
    id: "682",
    name: "pablo picasso",
  },
  {
    id: "683",
    name: "pacman",
  },
  {
    id: "684",
    name: "pakistan",
  },
  {
    id: "685",
    name: "paris",
  },
  {
    id: "686",
    name: "paris hilton",
  },
  {
    id: "687",
    name: "patrick mahomes",
  },
  {
    id: "688",
    name: "patriots",
  },
  {
    id: "689",
    name: "paul mccartney",
  },
  {
    id: "690",
    name: "peach",
  },
  {
    id: "691",
    name: "pele",
  },
  {
    id: "692",
    name: "people",
  },
  {
    id: "693",
    name: "pepe",
  },
  {
    id: "694",
    name: "permabull",
  },
  {
    id: "695",
    name: "pewdiepie",
  },
  {
    id: "696",
    name: "peyton manning",
  },
  {
    id: "697",
    name: "philadelphia eagles",
  },
  {
    id: "698",
    name: "philippines",
  },
  {
    id: "699",
    name: "picasso",
  },
  {
    id: "700",
    name: "pidgeot",
  },
  {
    id: "701",
    name: "pidgeotto",
  },
  {
    id: "702",
    name: "pikachu",
  },
  {
    id: "703",
    name: "pink",
  },
  {
    id: "704",
    name: "pixar",
  },
  {
    id: "705",
    name: "pizza",
  },
  {
    id: "706",
    name: "platinum",
  },
  {
    id: "707",
    name: "plato",
  },
  {
    id: "708",
    name: "player one",
  },
  {
    id: "709",
    name: "pluto",
  },
  {
    id: "710",
    name: "pocahontas",
  },
  {
    id: "711",
    name: "pokemon",
  },
  {
    id: "712",
    name: "poliwag",
  },
  {
    id: "713",
    name: "poliwhirl",
  },
  {
    id: "714",
    name: "poliwrath",
  },
  {
    id: "715",
    name: "ponyta",
  },
  {
    id: "716",
    name: "ponzi",
  },
  {
    id: "717",
    name: "pope francis",
  },
  {
    id: "718",
    name: "pornhub",
  },
  {
    id: "719",
    name: "portrait",
  },
  {
    id: "720",
    name: "porygon",
  },
  {
    id: "721",
    name: "poseidon",
  },
  {
    id: "722",
    name: "post malone",
  },
  {
    id: "723",
    name: "power",
  },
  {
    id: "724",
    name: "president",
  },
  {
    id: "725",
    name: "prime",
  },
  {
    id: "726",
    name: "prince",
  },
  {
    id: "727",
    name: "princess",
  },
  {
    id: "728",
    name: "princess diana",
  },
  {
    id: "729",
    name: "princess leia",
  },
  {
    id: "730",
    name: "princess peach",
  },
  {
    id: "731",
    name: "princess zelda",
  },
  {
    id: "732",
    name: "priyanka chopra",
  },
  {
    id: "733",
    name: "probably nothing",
  },
  {
    id: "734",
    name: "puma",
  },
  {
    id: "735",
    name: "punk",
  },
  {
    id: "736",
    name: "purple",
  },
  {
    id: "737",
    name: "q",
  },
  {
    id: "738",
    name: "queen",
  },
  {
    id: "739",
    name: "queen victoria",
  },
  {
    id: "740",
    name: "quentin tarantino",
  },
  {
    id: "741",
    name: "r",
  },
  {
    id: "742",
    name: "r2d2",
  },
  {
    id: "743",
    name: "rafael nadal",
  },
  {
    id: "744",
    name: "raichu",
  },
  {
    id: "745",
    name: "rambo",
  },
  {
    id: "746",
    name: "rare",
  },
  {
    id: "747",
    name: "rare pepe",
  },
  {
    id: "748",
    name: "raticate",
  },
  {
    id: "749",
    name: "rattata",
  },
  {
    id: "750",
    name: "rayman",
  },
  {
    id: "751",
    name: "real",
  },
  {
    id: "752",
    name: "real madrid",
  },
  {
    id: "753",
    name: "red",
  },
  {
    id: "754",
    name: "red bull",
  },
  {
    id: "755",
    name: "rekt",
  },
  {
    id: "756",
    name: "respect the pump",
  },
  {
    id: "757",
    name: "rich",
  },
  {
    id: "758",
    name: "rich the kid",
  },
  {
    id: "759",
    name: "right",
  },
  {
    id: "760",
    name: "rihanna",
  },
  {
    id: "761",
    name: "ringer",
  },
  {
    id: "762",
    name: "ringers",
  },
  {
    id: "763",
    name: "robert downey jr",
  },
  {
    id: "764",
    name: "robot",
  },
  {
    id: "765",
    name: "rocky",
  },
  {
    id: "766",
    name: "roger federer",
  },
  {
    id: "767",
    name: "rome",
  },
  {
    id: "768",
    name: "romeo",
  },
  {
    id: "769",
    name: "ronaldo",
  },
  {
    id: "770",
    name: "rtfkt",
  },
  {
    id: "771",
    name: "rug",
  },
  {
    id: "772",
    name: "rugged",
  },
  {
    id: "773",
    name: "rugpull",
  },
  {
    id: "774",
    name: "russ",
  },
  {
    id: "775",
    name: "russell wilson",
  },
  {
    id: "776",
    name: "russia",
  },
  {
    id: "777",
    name: "ryan cohen",
  },
  {
    id: "778",
    name: "s",
  },
  {
    id: "779",
    name: "salman khan",
  },
  {
    id: "780",
    name: "sam bankman fried",
  },
  {
    id: "781",
    name: "sam fisher",
  },
  {
    id: "782",
    name: "samsung",
  },
  {
    id: "783",
    name: "samus aran",
  },
  {
    id: "784",
    name: "san francisco 49ers",
  },
  {
    id: "785",
    name: "sandbox",
  },
  {
    id: "786",
    name: "santa",
  },
  {
    id: "787",
    name: "santa claus",
  },
  {
    id: "788",
    name: "sao paulo",
  },
  {
    id: "789",
    name: "satoshi",
  },
  {
    id: "790",
    name: "satoshi nakamoto",
  },
  {
    id: "791",
    name: "saturn",
  },
  {
    id: "792",
    name: "scorpion",
  },
  {
    id: "793",
    name: "seedphrase",
  },
  {
    id: "794",
    name: "selena gomez",
  },
  {
    id: "795",
    name: "self",
  },
  {
    id: "796",
    name: "self portrait",
  },
  {
    id: "797",
    name: "ser",
  },
  {
    id: "798",
    name: "serena williams",
  },
  {
    id: "799",
    name: "sergey",
  },
  {
    id: "800",
    name: "sergey nazarov",
  },
  {
    id: "801",
    name: "seven",
  },
  {
    id: "802",
    name: "sex god",
  },
  {
    id: "803",
    name: "shakespeare",
  },
  {
    id: "804",
    name: "shakira",
  },
  {
    id: "805",
    name: "shanghai",
  },
  {
    id: "806",
    name: "shaq",
  },
  {
    id: "807",
    name: "shaquille oneal",
  },
  {
    id: "808",
    name: "shaun white",
  },
  {
    id: "809",
    name: "shawn mendes",
  },
  {
    id: "810",
    name: "shiba inu",
  },
  {
    id: "811",
    name: "shit",
  },
  {
    id: "812",
    name: "shitcoin",
  },
  {
    id: "813",
    name: "silver",
  },
  {
    id: "814",
    name: "simp",
  },
  {
    id: "815",
    name: "singapore",
  },
  {
    id: "816",
    name: "siri",
  },
  {
    id: "817",
    name: "sith",
  },
  {
    id: "818",
    name: "six",
  },
  {
    id: "819",
    name: "skeleton",
  },
  {
    id: "820",
    name: "skull",
  },
  {
    id: "821",
    name: "slim shady",
  },
  {
    id: "822",
    name: "snoop dogg",
  },
  {
    id: "823",
    name: "snorlax",
  },
  {
    id: "824",
    name: "snow",
  },
  {
    id: "825",
    name: "soccer",
  },
  {
    id: "826",
    name: "socrates",
  },
  {
    id: "827",
    name: "soldier",
  },
  {
    id: "828",
    name: "solid snake",
  },
  {
    id: "829",
    name: "sonic",
  },
  {
    id: "830",
    name: "sophie turner",
  },
  {
    id: "831",
    name: "sora",
  },
  {
    id: "832",
    name: "soul",
  },
  {
    id: "833",
    name: "space",
  },
  {
    id: "834",
    name: "spiderman",
  },
  {
    id: "835",
    name: "spike lee",
  },
  {
    id: "836",
    name: "spongebob",
  },
  {
    id: "837",
    name: "spyro",
  },
  {
    id: "838",
    name: "squid game",
  },
  {
    id: "839",
    name: "squiggle",
  },
  {
    id: "840",
    name: "squirtle",
  },
  {
    id: "841",
    name: "stalin",
  },
  {
    id: "842",
    name: "star",
  },
  {
    id: "843",
    name: "stay poor",
  },
  {
    id: "844",
    name: "steph curry",
  },
  {
    id: "845",
    name: "steve aoki",
  },
  {
    id: "846",
    name: "steve jobs",
  },
  {
    id: "847",
    name: "steven spielberg",
  },
  {
    id: "848",
    name: "stfu",
  },
  {
    id: "849",
    name: "stoner",
  },
  {
    id: "850",
    name: "sun",
  },
  {
    id: "851",
    name: "super mario",
  },
  {
    id: "852",
    name: "superhero",
  },
  {
    id: "853",
    name: "superman",
  },
  {
    id: "854",
    name: "sushiswap",
  },
  {
    id: "855",
    name: "sydney sweeney",
  },
  {
    id: "856",
    name: "t",
  },
  {
    id: "857",
    name: "taylor swift",
  },
  {
    id: "858",
    name: "technology",
  },
  {
    id: "859",
    name: "ten",
  },
  {
    id: "860",
    name: "tencent",
  },
  {
    id: "861",
    name: "terminator",
  },
  {
    id: "862",
    name: "tesla",
  },
  {
    id: "863",
    name: "thailand",
  },
  {
    id: "864",
    name: "thanos",
  },
  {
    id: "865",
    name: "the batman",
  },
  {
    id: "866",
    name: "the chainsmokers",
  },
  {
    id: "867",
    name: "the chosen one",
  },
  {
    id: "868",
    name: "the dark knight",
  },
  {
    id: "869",
    name: "the end",
  },
  {
    id: "870",
    name: "the future of finance",
  },
  {
    id: "871",
    name: "the future of france",
  },
  {
    id: "872",
    name: "the godfather",
  },
  {
    id: "873",
    name: "the internet",
  },
  {
    id: "874",
    name: "the matrix",
  },
  {
    id: "875",
    name: "the one",
  },
  {
    id: "876",
    name: "the rock",
  },
  {
    id: "877",
    name: "the sandbox",
  },
  {
    id: "878",
    name: "the weeknd",
  },
  {
    id: "879",
    name: "the witcher",
  },
  {
    id: "880",
    name: "this is fine",
  },
  {
    id: "881",
    name: "thor",
  },
  {
    id: "882",
    name: "three",
  },
  {
    id: "883",
    name: "tiger king",
  },
  {
    id: "884",
    name: "tiger woods",
  },
  {
    id: "885",
    name: "tiktok",
  },
  {
    id: "886",
    name: "tim cook",
  },
  {
    id: "887",
    name: "timbaland",
  },
  {
    id: "888",
    name: "timelord",
  },
  {
    id: "889",
    name: "to the moon",
  },
  {
    id: "890",
    name: "token",
  },
  {
    id: "891",
    name: "tokyo",
  },
  {
    id: "892",
    name: "tom brady",
  },
  {
    id: "893",
    name: "tom cruise",
  },
  {
    id: "894",
    name: "tom hanks",
  },
  {
    id: "895",
    name: "tom holland",
  },
  {
    id: "896",
    name: "tony hawk",
  },
  {
    id: "897",
    name: "tony stark",
  },
  {
    id: "898",
    name: "trillionaire",
  },
  {
    id: "899",
    name: "trump",
  },
  {
    id: "900",
    name: "tupac",
  },
  {
    id: "901",
    name: "turkey",
  },
  {
    id: "902",
    name: "twitter",
  },
  {
    id: "903",
    name: "two",
  },
  {
    id: "904",
    name: "tyler winklevoss",
  },
  {
    id: "905",
    name: "u",
  },
  {
    id: "906",
    name: "uber",
  },
  {
    id: "907",
    name: "unavailable",
  },
  {
    id: "908",
    name: "undefeated",
  },
  {
    id: "909",
    name: "unicorn",
  },
  {
    id: "910",
    name: "uniswap",
  },
  {
    id: "911",
    name: "united kingdom",
  },
  {
    id: "912",
    name: "united states",
  },
  {
    id: "913",
    name: "up only",
  },
  {
    id: "914",
    name: "uranus",
  },
  {
    id: "915",
    name: "us",
  },
  {
    id: "916",
    name: "usa",
  },
  {
    id: "917",
    name: "usain bolt",
  },
  {
    id: "918",
    name: "v",
  },
  {
    id: "919",
    name: "vader",
  },
  {
    id: "920",
    name: "van gogh",
  },
  {
    id: "921",
    name: "vaporeon",
  },
  {
    id: "922",
    name: "vbuterin",
  },
  {
    id: "923",
    name: "venom",
  },
  {
    id: "924",
    name: "venomoth",
  },
  {
    id: "925",
    name: "venus",
  },
  {
    id: "926",
    name: "venusaur",
  },
  {
    id: "927",
    name: "vibe",
  },
  {
    id: "928",
    name: "vietnam",
  },
  {
    id: "929",
    name: "vin diesel",
  },
  {
    id: "930",
    name: "viral",
  },
  {
    id: "931",
    name: "virat kohli",
  },
  {
    id: "932",
    name: "virgin",
  },
  {
    id: "933",
    name: "visa",
  },
  {
    id: "934",
    name: "vitalik",
  },
  {
    id: "935",
    name: "vitalik buterin",
  },
  {
    id: "936",
    name: "vito corleone",
  },
  {
    id: "937",
    name: "vladimir putin",
  },
  {
    id: "938",
    name: "voldemort",
  },
  {
    id: "939",
    name: "voltorb",
  },
  {
    id: "940",
    name: "vox",
  },
  {
    id: "941",
    name: "w",
  },
  {
    id: "942",
    name: "wagmi",
  },
  {
    id: "943",
    name: "walmart",
  },
  {
    id: "944",
    name: "walt disney",
  },
  {
    id: "945",
    name: "walter white",
  },
  {
    id: "946",
    name: "war",
  },
  {
    id: "947",
    name: "warren buffett",
  },
  {
    id: "948",
    name: "wartortle",
  },
  {
    id: "949",
    name: "washington",
  },
  {
    id: "950",
    name: "wasted",
  },
  {
    id: "951",
    name: "water",
  },
  {
    id: "952",
    name: "weed",
  },
  {
    id: "953",
    name: "weezing",
  },
  {
    id: "954",
    name: "wen moon",
  },
  {
    id: "955",
    name: "whale",
  },
  {
    id: "956",
    name: "what",
  },
  {
    id: "957",
    name: "whindersson nunes batista",
  },
  {
    id: "958",
    name: "white",
  },
  {
    id: "959",
    name: "whitney houston",
  },
  {
    id: "960",
    name: "who",
  },
  {
    id: "961",
    name: "why",
  },
  {
    id: "962",
    name: "wigglytuff",
  },
  {
    id: "963",
    name: "will smith",
  },
  {
    id: "964",
    name: "william",
  },
  {
    id: "965",
    name: "willy wonka",
  },
  {
    id: "966",
    name: "win",
  },
  {
    id: "967",
    name: "winter",
  },
  {
    id: "968",
    name: "witch",
  },
  {
    id: "969",
    name: "witcher",
  },
  {
    id: "970",
    name: "wiz khalifa",
  },
  {
    id: "971",
    name: "wolf",
  },
  {
    id: "972",
    name: "woman",
  },
  {
    id: "973",
    name: "wonder woman",
  },
  {
    id: "974",
    name: "world",
  },
  {
    id: "975",
    name: "wtf",
  },
  {
    id: "976",
    name: "x",
  },
  {
    id: "977",
    name: "xaea 12",
  },
  {
    id: "978",
    name: "xbt",
  },
  {
    id: "979",
    name: "xi jinping",
  },
  {
    id: "980",
    name: "xo",
  },
  {
    id: "981",
    name: "xoxo",
  },
  {
    id: "982",
    name: "xxx",
  },
  {
    id: "983",
    name: "y",
  },
  {
    id: "984",
    name: "yacht party",
  },
  {
    id: "985",
    name: "yeezy",
  },
  {
    id: "986",
    name: "yellow",
  },
  {
    id: "987",
    name: "yes",
  },
  {
    id: "988",
    name: "yoda",
  },
  {
    id: "989",
    name: "yoshi",
  },
  {
    id: "990",
    name: "you",
  },
  {
    id: "991",
    name: "z",
  },
  {
    id: "992",
    name: "zapdos",
  },
  {
    id: "993",
    name: "zendaya",
  },
  {
    id: "994",
    name: "zero",
  },
  {
    id: "995",
    name: "zeus",
  },
  {
    id: "996",
    name: "zombie",
  },
  {
    id: "997",
    name: "zoom",
  },
  {
    id: "998",
    name: "zorro",
  },
  {
    id: "999",
    name: "zuckerberg",
  },
];

function isSpecialName(str: string) {
  if (specialNames.find((e) => e.name === str.toLowerCase())) {
    return true;
  }
  return false;
}
export default isSpecialName;
