import { useEffect, useState, useContext } from "react";
import { WOW } from "wowjs";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useContractReader } from "../../hooks";
import useGetBlockedCountries from "src/hooks/useGetBlockedCountries";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
const { ethers } = require("ethers");

const GoldenTicketRatio = () => {
  const { readContracts, writeContracts, tx } = useContext(Web3OnboardContext);
  const { blocked } = useGetBlockedCountries();

  useEffect(() => {
    new WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);

  const [numTicketsMint, setNumTicketsMint] = useState(1);
  const [numTicketsBurn, setNumTicketsBurn] = useState(1);

  const [mintPrice, updateValueMint] = useContractReader(
    readContracts,
    "GoldenTicket",
    "currentMintPrice",
    [numTicketsMint]
  );

  useEffect(() => {
    updateValueMint();
  }, [numTicketsMint]);

  const [burnPrice, updateValueBurn] = useContractReader(
    readContracts,
    "GoldenTicket",
    "currentBurnPrice",
    [numTicketsBurn]
  );

  useEffect(() => {
    updateValueBurn();
  }, [numTicketsBurn]);

  const [totalSupply] = useContractReader(
    readContracts,
    "GoldenTicket",
    "totalSupply"
  );

  const mintTicket = async () => {
    /* notice how you pass a call back for tx updates too */
    const result = tx(
      writeContracts.GoldenTicket.mintTickets(numTicketsMint, {
        value: mintPrice.toString(),
      }),
      (update) => {
        console.log("📡 Transaction Update:", update);
        if (update && (update.status === "confirmed" || update.status === 1)) {
          console.log(" 🍾 Transaction " + update.hash + " finished!");
          console.log(
            " ⛽️ " +
              update.gasUsed +
              "/" +
              (update.gasLimit || update.gas) +
              " @ " +
              parseFloat(update.gasPrice) / 1000000000 +
              " gwei"
          );
        }
      }
    );
    console.log("awaiting metamask/web3 confirm result...", result);
    console.log(await result);
  };

  const burnTicket = async () => {
    /* notice how you pass a call back for tx updates too */
    const result = tx(
      writeContracts.GoldenTicket.burnTickets(1, numTicketsBurn),
      (update) => {
        console.log("📡 Transaction Update:", update);
        if (update && (update.status === "confirmed" || update.status === 1)) {
          console.log(" 🍾 Transaction " + update.hash + " finished!");
          console.log(
            " ⛽️ " +
              update.gasUsed +
              "/" +
              (update.gasLimit || update.gas) +
              " @ " +
              parseFloat(update.gasPrice) / 1000000000 +
              " gwei"
          );
        }
      }
    );
    console.log("awaiting metamask/web3 confirm result...", result);
    console.log(await result);
  };

  function onChange(e) {
    const re = /^[1-9]+[0-9]*$/;

    if (re.test(e.target.value) && e.target.value < 1001) {
      setNumTicketsMint(e.target.value);
    } else {
      setNumTicketsMint(1);
    }
  }

  function onChangeBurn(e) {
    const re = /^[1-9]+[0-9]*$/;

    if (re.test(e.target.value) && e.target.value < 1001) {
      setNumTicketsBurn(e.target.value);
    } else {
      setNumTicketsBurn(1);
    }
  }

  function validate(e) {
    const re = /^[1-9]+[0-9]*$/;
    if (e.target.value == "") {
    } else if (!re.test(e.target.value) || e.target.value > 1000) {
      e.target.value = "1";
    }
  }

  return (
    <section className="golden-ticket-ratio">
      <Container>
        <Row>
          <Col lg={12} className="text-center wow fadeInUp">
            <div className="golden-ticket-ratio-content">
              <h5>Bonding Curve</h5>
              <Image src="./img/golden-ticket-ratio.png" />
              <p>
                Since Golden Ticket liquidity lives on a bonding curve, the
                value of the Golden Tickets increases as more Golden Tickets are
                minted off the curve. The bonding curve also provides instant
                and allocated exit liquidity allowing ticket holders to burn
                their ticket to get ETH in return. The amount of ETH obtained
                when a ticket is burned equals 90% of the most recent ticket's
                mint price. This allows for continuous Golden Ticket price
                discovery. Every time a new Golden Ticket is minted, 10% of the
                ETH submitted is kept by the Golden Ticket contract as a mint
                fee. The other 90% of the mint cost is accumulated in the
                bonding curve (contract reserve) as exit liquidity. The first
                Golden Ticket has a price of 0.1001Ξ and the last one (1000th)
                has a price of 100.1Ξ. Enter how many tickets you would like to
                mint or burn, below.
              </p>
            </div>
          </Col>
        </Row>

        {blocked ? (
          <p className="text-center fs-4 text-danger">
            Minting is not available in your country
          </p>
        ) : null}

        <Row className="g-xl-5">
          <Col lg={6} className="text-center wow fadeInLeft">
            <div className="golden-ticket-box">
              <div className="robot-details-text">
                <input
                  style={{
                    width: "45%",
                    textAlign: "center",
                    marginLeft: -40,
                    marginBottom: 20,
                  }}
                  onChange={onChange}
                  onKeyUp={validate}
                  placeholder="#"
                  id="num-tickets-mint"
                />
              </div>
              <h4>
                {mintPrice ? ethers.utils.formatEther(mintPrice) : "..."} ETH
              </h4>
              <button
                style={{ cursor: "pointer" }}
                onClick={mintTicket}
                className="bordered-button blue-ticket-btn"
                disabled={blocked ? true : false}
              >
                MINT
              </button>
              <p>
                {totalSupply
                  ? `Currently, there are ${totalSupply}/1,000 Golden Tickets minted. Above is the cost of minting ${numTicketsMint} ticket${
                      numTicketsMint > 1 ? "s" : ""
                    }.`
                  : "Above is the cost of minting the next Golden Ticket."}
              </p>
            </div>
          </Col>
          <Col lg={6} className="text-center wow fadeInRight">
            <div className="golden-ticket-box">
              <div className="robot-details-text">
                <input
                  style={{
                    width: "45%",
                    textAlign: "center",
                    marginLeft: -40,
                    marginBottom: 20,
                  }}
                  onChange={onChangeBurn}
                  onKeyUp={validate}
                  placeholder="#"
                  id="num-tickets-burn"
                />
              </div>
              <h4>
                {burnPrice ? ethers.utils.formatEther(burnPrice) : "..."} ETH
              </h4>
              <button
                type="button"
                style={{ cursor: "pointer" }}
                onClick={burnTicket}
                className="bordered-button yello-ticket-btn"
                disabled={blocked ? true : false}
              >
                BURN
              </button>
              <p>
                {totalSupply
                  ? `Currently, there are ${totalSupply}/1,000 Golden Tickets minted.`
                  : ""}{" "}
                {`Above is how much ETH you will receive if you burn ${numTicketsBurn} Golden Ticket${
                  numTicketsBurn > 1 ? "s" : ""
                }.`}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GoldenTicketRatio;
