import jwt from "jsonwebtoken"

interface DecodedToken {
  data: any;
  valid: boolean;
}

function decodeJwtToken(token: string, address: string): DecodedToken {
  try {
    const decoded = jwt.decode(token);

    if (decoded) {
      const { exp, ...data } = decoded as { exp: number, data: any };


      const publicAddress: string = data.data.publicAddress
      console.log({ publicAddress });

      const present = new Date().getTime() / 1000
      const expired = exp > present && publicAddress.toLowerCase() === address.toLowerCase()

      if (data && expired) {
        return { data, valid: expired };
      } else {
        return { data: null, valid: false };
      }
    }
    else {
      return { data: null, valid: false };
    }
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return { data: null, valid: false };
  }

}

export default decodeJwtToken;
