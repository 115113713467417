import React from "react";

const SendMessage = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='21'
			viewBox='0 0 20 21'
			fill='none'>
			<path
				d='M18.1249 10.7414C18.1255 10.9641 18.0665 11.1829 17.9542 11.3752C17.8418 11.5675 17.6801 11.7263 17.4858 11.8352L4.36787 19.3359C4.17939 19.4428 3.96656 19.4993 3.7499 19.5C3.55009 19.4996 3.35329 19.4513 3.176 19.3592C2.9987 19.267 2.84608 19.1337 2.73093 18.9704C2.61577 18.8071 2.54143 18.6186 2.51415 18.4207C2.48687 18.2228 2.50743 18.0212 2.57412 17.8328L4.71083 11.5867C4.73189 11.5249 4.77177 11.4713 4.82487 11.4333C4.87798 11.3953 4.94164 11.3749 5.00693 11.375H10.6249C10.7106 11.3752 10.7954 11.3578 10.874 11.3238C10.9527 11.2898 11.0235 11.2401 11.0821 11.1776C11.1408 11.1151 11.1859 11.0412 11.2148 10.9605C11.2436 10.8798 11.2556 10.7941 11.2499 10.7086C11.2357 10.5479 11.1614 10.3985 11.0417 10.2902C10.922 10.182 10.7659 10.123 10.6046 10.125H5.0124C4.94721 10.1251 4.88361 10.1049 4.83052 10.067C4.77743 10.0292 4.73749 9.97572 4.7163 9.91407L2.57255 3.66407C2.48907 3.42458 2.48041 3.16536 2.54774 2.92083C2.61506 2.67631 2.75517 2.45804 2.94947 2.29503C3.14376 2.13201 3.38305 2.03195 3.63556 2.00815C3.88806 1.98434 4.14183 2.0379 4.36318 2.16173L17.4882 9.65313C17.6812 9.76183 17.8419 9.91989 17.9538 10.1111C18.0657 10.3023 18.1247 10.5199 18.1249 10.7414Z'
				fill='#fff'
			/>
		</svg>
	);
};

export default SendMessage;
