import { useState } from 'react'
import { Col, Row, Accordion, Tabs, Tab, Nav } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import DiamondImage from './images/diamond.svg'
import IconGreen from './images/logo-green.svg'
import Switch from '../../Switch/Switch'
import FormGroupInput from '../../FormGroupInput/FormGroupInput'
import NftrIcon from './images/nftr-icon.png'

const NamingRewards = () => {
  const [error, setError] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)

  const rnmNumber = 50000

  // handle RNM form
  const handleRnm = (e) => {
    // handle error state
    if (parseInt(e.target.value) < rnmNumber) {
      setError(true)
    } else {
      setError(false)
    }

    // handle button state
    if (
      parseInt(e.target.value) < rnmNumber ||
      parseInt(e.target.value.length) === 0
    ) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }
  }

  return (
    <div>
      <Row>
        <Col lg={5}>
          <div className="tab-left-content">
            <h4>NAMING REWARDS</h4>
            <p className="para-medium">EARN RNM as NAMING REWARDS</p>
            <p className="para-small">
              When you name NFTs or buy naming credits in bulk. <br /> Each
              naming credit counts as a naming.
            </p>
            <p className="para-large">Rewards distributed once daily</p>
          </div>
        </Col>
        <Col lg={7}>
          <div className="tab-right-content">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordion-header-left">
                    <h4>
                      <span>Your NAMING REWARDS</span>{' '}
                      <span className="accordion-header-right">0 RNM</span>
                    </h4>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="accordion-naming">
                  <div className="blue-box d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Naming Rewards</h4>
                  </div>
                  <div className="blue-box-padding blue-box d-flex justify-content-between align-items-center mt-30 mb-30 border-top border-bottom">
                    <div className="blue-box-left">
                      <h4>Rewards</h4>
                      <p>RNM: 0 ($0.00)</p>
                    </div>
                    <div className="blue-box-right">
                      <button className="collect-btn">Collect</button>
                    </div>
                  </div>
                  <div className="blue-box border-bottom details-box">
                    <h4>Details</h4>
                    <p>
                      You can collect rewards in the 22 hours between 03:00 PM
                      and 05:00 PM every day. Don't worry, your rewards won't
                      disappear; they are just unavailable for those two hours.
                    </p>
                    <div className="statistics-row">
                      <span>Naming Rewards to Collect:</span>
                      <span>0($0.00)</span>
                    </div>
                  </div>
                  <div className="blue-box">
                    <div className="statistics-row">
                      <span>Next Distribution:</span>
                      <span>15 hours 19 minutes</span>
                    </div>
                    <div className="statistics-row">
                      <span>Next Pause:</span>
                      <span>13 hours 19 minutes</span>
                    </div>
                  </div>
                </Accordion.Body>

                <Accordion.Body className="mt-30">
                  <div className="statistics-row accordion-naming-bulk-name">
                    <span>Bulk Name</span>
                  </div>
                  <p className="text-white">
                    Buy naming credits in bulk to use later to name NFTs. You
                    will participate in naming rewards; one credit equals one
                    naming.
                  </p>
                  <a href="" className="inline-btn mb-30 d-inline-block">
                    Learn More
                  </a>
                  <FormGroupInput placeholderText="0" btnText="Buy" />
                  <div className="statistics-row mt-30">
                    <span>Naming credits bought in the current period: </span>
                    <span>0 ($0.00)</span>
                  </div>
                  <div className="statistics-row mt-20">
                    <span>Unused naming credits</span>
                    <span>0</span>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
      </Row>
      <ReactTooltip />
    </div>
  )
}

export default NamingRewards
