import React from "react";

const WalletConnect = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 38 23'
			className='w-6 h-6 mr-2 walletconnect-icon'>
			<path
				fill='currentColor'
				d='M7.58 4.433c6.038-5.91 15.83-5.91 21.868 0l.726.71a.744.744 0 0 1 0 1.07L27.69 8.646a.393.393 0 0 1-.547 0l-1-.979c-4.212-4.121-11.042-4.121-15.255 0L9.816 8.716a.393.393 0 0 1-.547 0L6.783 6.283a.745.745 0 0 1 0-1.07l.798-.78Zm27.01 5.03L36.8 11.63a.745.745 0 0 1 0 1.07l-9.976 9.764a.785.785 0 0 1-1.093 0l-7.08-6.93a.196.196 0 0 0-.274 0l-7.08 6.93a.785.785 0 0 1-1.095 0L.227 12.7a.745.745 0 0 1 0-1.07L2.44 9.463a.785.785 0 0 1 1.093 0l7.08 6.93a.196.196 0 0 0 .274 0l7.08-6.93a.785.785 0 0 1 1.094 0l7.08 6.93a.196.196 0 0 0 .275 0l7.08-6.93a.785.785 0 0 1 1.093 0Z'></path>
		</svg>
	);
};

export default WalletConnect;
