import { nftrAxios } from "./axiosInstance";
//import fetchLRToken from "./fetchLRToken";

function fetchBlockchainData(
  contractAddress: string,
  page?: number,
  tokenIds?: number[]
) {
  if (tokenIds) {
    return nftrAxios
      .post("/fetch-blockchain-contents", {
        address: contractAddress,
        tokenIds: tokenIds,
        page: page,
      })
      .then((res) => res.data);
  } else if (page) {
    return nftrAxios.post("/fetch-blockchain-contents", {
      address: contractAddress,
      page: page,
    });
  } else {
    throw new Error("invalid params");
  }
}

export default fetchBlockchainData;
