import { useState } from 'react'
import { Col, Row, Accordion, Tabs, Tab, Nav } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import DiamondImage from './images/diamond.svg'
import IconGreen from './images/logo-green.svg'
import Switch from '../../Switch/Switch'
import FormGroupInput from '../../FormGroupInput/FormGroupInput'

const SpendGtk = () => {
  const [error, setError] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)

  const rnmNumber = 50000

  // handle RNM form
  const handleRnm = (e) => {
    // handle error state
    if (parseInt(e.target.value) < rnmNumber) {
      setError(true)
    } else {
      setError(false)
    }

    // handle button state
    if (
      parseInt(e.target.value) < rnmNumber ||
      parseInt(e.target.value.length) === 0
    ) {
      setBtnDisable(true)
    } else {
      setBtnDisable(false)
    }
  }
  return (
    <div>
      <Row>
        <Col lg={5}>
          <div className="tab-left-content">
            <h4>SPEND GTK</h4>
            <p className="para-medium">
              SPEND GTKs. EARN <span className="pink-text">10%</span> of WETH
              REV. EARN RNM
            </p>
            <p className="para-small">Total GTKs staked or spent: 0 ($0.00)</p>
            <p className="para-small">TOTAL GTKs SPENT: 0 ($0.00)</p>
            <p className="para-large">
              APY 0%
              <span
                style={{ marginLeft: '8px' }}
                data-tip="APR calculated from current WETH revenue dollar value per staked/spent GTK + RNM yield per spent GTK vs. current GTK mint dollar value"
              >
                <i className="far fa-question-circle"></i>
              </span>
            </p>
          </div>
        </Col>
        <Col lg={7}>
          <div className="tab-right-content">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordion-header-left">
                    <h4>
                      <span>Your Spent GTKs</span>{' '}
                      <span className="accordion-header-right">0 SPENT</span>
                    </h4>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tab-content-dark spend-gtk">
                    <div className="tab-content">
                      <div className="form-group">
                        <input
                          onChange={handleRnm}
                          type="number"
                          placeholder="GTK Balance"
                          className={`${error === true ? 'show-error' : ''}`}
                        />
                        <button disabled={btnDisable}>Max</button>
                        <button disabled={btnDisable}>Spend Name</button>
                      </div>
                      {error && (
                        <div className="notification" role="alert">
                          {error === true
                            ? 'There aren’t enough GTKs in the wallet balance to be able to spend.'
                            : ''}
                        </div>
                      )}
                    </div>
                    <div className="statistics">
                      <div className="statistics-row">
                        <span>GTKs in wallet:</span>
                        <span>0</span>
                      </div>
                      <span className="bar"></span>
                      <div className="statistics-row">
                        <span>
                          Collect 0 WETH rewards?{' '}
                          <span
                            style={{ marginLeft: '8px' }}
                            data-tip="Hello from the tooltip"
                          >
                            <i className="far fa-question-circle"></i>
                          </span>
                        </span>
                        <Switch />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>

                <Accordion.Body className="mt-30">
                  <div className="statistics-row mb-30">
                    <h6>WETH rewards distributed every block</h6>
                    <span>
                      What's this{' '}
                      <span data-tip="Hello from the tooltip">
                        <i className="far fa-question-circle"></i>
                      </span>
                    </span>
                  </div>
                  <FormGroupInput
                    placeholderText={['Quantity ', <span>($0.00)</span>]}
                  />
                  <div className="statistics-row mt-30">
                    <span>LAST COLLECTED:</span>
                    <span>00:00, Jan 1, 1979</span>
                  </div>
                  <div className="statistics-row mt-20">
                    <span>COLLECTED TO DATE:</span>
                    <span>
                      0 <span>($0.00)</span>
                    </span>
                  </div>
                  <span className="bar mt-30 mb-20"></span>
                  <div className="statistics-row mb-30">
                    <h6>Collect RNM Rewards</h6>
                    <span>
                      What's this{' '}
                      <span data-tip="Hello from the tooltip">
                        <i className="far fa-question-circle"></i>
                      </span>
                    </span>
                  </div>
                  <FormGroupInput
                    placeholderText={['Quantity ', <span>($0.00)</span>]}
                  />
                  <div className="statistics-row mt-40">
                    <h3>
                      <img src={IconGreen} alt="" /> RNM
                    </h3>
                  </div>
                  <div className="statistics-row">
                    <p>Earned to date:</p>
                    <p>
                      <span>
                        0 <span>($0.00)</span>
                      </span>
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
      </Row>
      <ReactTooltip />
    </div>
  )
}

export default SpendGtk
