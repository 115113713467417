import React, { useState, ReactNode, ReactElement } from "react";

import "./CustomTooltip.css";

interface TooltipProps {
	content: ReactNode;
	children: ReactElement;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
	const [isVisible, setIsVisible] = useState(false);

	const showTooltip = () => {
		setIsVisible(true);
	};

	const hideTooltip = () => {
		setIsVisible(false);
	};

	return (
		<div
			className='custom-tooltip-container'
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}>
			{children}
			{isVisible && (
				<div className='custom-tooltip'>
					<div className='custom-tooltip-content'>{content}</div>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
