import axios from "axios";
import { formatEther } from "ethers/lib/utils";
import { useContext, useEffect, useState } from "react";
import { initialToken } from "src/components/LetsBrowseScreen/RobotDetails/TokenBoundAccount/TokenBoundAccount";
import { IToken } from "src/components/LetsBrowseScreen/RobotDetails/TokenBoundAccount/interface";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
import { ERC20TOKENS } from "src/tokenlist";

const baseURL = process.env.REACT_APP_API_URL_BASE;

export function useTokenBoundAssets(account: `0x${string}`) {
  const [etherBalance, setEtherBalance] = useState<IToken>(initialToken);
  const [tokens, setTokens] = useState<IToken[]>([]);
  const { injectedProvider } = useContext(Web3OnboardContext);

  useEffect(() => {
    if (!injectedProvider) return;

    async function getEtherBalance() {
      try {
        const balanceWei = await injectedProvider.getBalance(account);
        const balanceEther = +formatEther(balanceWei);

        setEtherBalance({
          address: "0x0000000000000000000000000000000000000000",
          logoURI: ERC20TOKENS[0].logoURI,
          symbol: "ETH",
          balance: balanceEther,
          decimal: 0,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }

    if (account) {
      getEtherBalance();
    }
  }, [account, injectedProvider, tokens]);

  useEffect(() => {
    async function getAccountBalances() {
      try {
        const { data: tokens } = await axios.get(
          `${baseURL}token/balances/${account}`
        );
        const tokenBalances = tokens.tokenBalances;

        const balances: IToken[] = tokenBalances.map((item: any) => {
          const balance = parseInt(item.tokenBalance) / 10 ** 18;

          const image = ERC20TOKENS.find(
            (token) =>
              token.address.toLowerCase() === item.contractAddress.toLowerCase()
          );

          return {
            address: item.contractAddress,
            logoURI: image?.logoURI,
            symbol: image?.symbol,
            decimal: image?.decimals,
            balance,
          };
        });
        setTokens(balances);
      } catch (error) {
        console.log(error);
      }
    }

    if (account) {
      getAccountBalances();
    }
  }, [account, injectedProvider]);

  let combinedTokens: IToken[] =
    etherBalance.balance > 0 ? [etherBalance, ...tokens] : tokens;

  combinedTokens = combinedTokens.filter((item) => item.balance > 0);

  return combinedTokens

}