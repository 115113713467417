// UI
import {
  Switch,
  HashRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";

import HomeScreen from "./screens/HomeScreen";
import TokenScreen from "./screens/TokenScreen";
import LetsBrowseScreen from "./screens/LetsBrowseScreen";
import GoldenTicketScreen from "./screens/GoldenTicketScreen";
import InviteScreen from "./screens/InviteScreen";
import RobotDetailsScreen from "./screens/RobotDetailsScreen";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import HeaderTwo from "./components/HeaderTwo/HeaderTwo";
import EarnYield from "./screens/EarnYield";
import ContractsScreen from "./screens/ContractsScreen";
import Account from "./components/Account";
import PageNotFoundComponent from "./components/PageNotFoundScreen/PageNotFoundComponent";
import EmailConfirmationScreen from "./screens/EmailConfirmationScreen";
import useIsMounted from "./hooks/useIsMounted";
import { useContext, useEffect } from "react";
import { Web3OnboardContext } from "./contexts/Web3OnboardContext";
// EmailConfirmationScreen

function Routes() {

  const mounted = useIsMounted();
  const { address } = useContext(Web3OnboardContext);

  useEffect(() => {

    if (address && mounted) {
      // fetch messages
      // add messages to indexedDB
    }
  }, [])


  const urlParts = window.location.host.split(".");
  let subdomain = "";
  let CustomRoute = null;
  if (
    urlParts[0] &&
    urlParts[1] &&
    urlParts[0] !== "www" &&
    (urlParts[1] === "localhost:3001" ||
      (urlParts[1] === "nftr" && urlParts[2] && urlParts[2] === "name"))
  ) {
    subdomain = urlParts[0];
    CustomRoute = (
      <Route path="/" exact>
        <RobotDetailsScreen subdomain={subdomain} />
      </Route>
    );
  }


  return (
    <>
      <Router basename="/">
        <ScrollToTop>
          <Switch>
            {CustomRoute}
            <Route component={HomeScreen} path="/" exact />
            <Route component={TokenScreen} path="/token" exact />
            <Route>
              <HeaderTwo />
              <Switch>
                <Route path="/lets-browse">
                  <LetsBrowseScreen />
                </Route>
                <Route path="/golden-ticket">
                  <GoldenTicketScreen />
                </Route>
                <Route path="/invite">
                  <InviteScreen />
                </Route>
                <Route path="/robot-details/:contractAddress/:tokenId">
                  <RobotDetailsScreen />
                </Route>
                <Route path="/earn">
                  <EarnYield />
                </Route>

                <Route path="/email-confirmation">
                  <EmailConfirmationScreen />
                </Route>
                <Route path={process.env.REACT_APP_CONTRACTS_PAGE}>
                  <ContractsScreen />
                </Route>
                {/* DEFAULT ROUTE, sends us back to the home if someone puts in a bad url  */}
                <Route path="*">
                  {/* <Redirect to={{ pathname: "/" }}></Redirect> */}
                  <PageNotFoundComponent />
                </Route>
              </Switch>
              <Account className="account-class" />
            </Route>
          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    </>
  );
}

export default Routes;
