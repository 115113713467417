
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios';
import Message from './Message';
import "./NFTMessaging.css"
import { SocketContext } from 'src/contexts/SocketContext';
import formatIPFS from 'src/helpers/formatIPFS';
import getAlchemyMetadata from 'src/apis/getAlchemyMetadata';
import getNFTTokenIdOwners from "src/apis/getNFTTokenIdOwners"
import { Image } from 'react-bootstrap';
import { LoadedContractsDataContext } from 'src/contexts';
import { ethers } from 'ethers';
import contracts from 'src/contracts/external_contracts';
import { Web3OnboardContext } from 'src/contexts/Web3OnboardContext';
import { format } from "timeago.js";
import placeholder from "../../../assets/images/customplaceholder.png"
import TBATransferModal from './TokenBoundAccount/TBATransferModal';
import { Plus, SendMessage } from 'src/assets/images';
import Hambuger from 'src/assets/images/Hambuger';
import { ObjectId } from 'bson';
import { useMessageStore } from 'src/store/message.store';
import useSocketConnectionStatus from 'src/hooks/useSocketConnectionStatus';

const baseURL = process.env.REACT_APP_API_URL_BASE;

const NFTMessaging = ({ contractAddress, tokenIdToUse, owned }) => {
	const [data,] = useContext(LoadedContractsDataContext)

	const names = data.NamedData

	const [arrivalMessage, setArrivalMessage] = useState(null);
	const [arrivalConversation, setArrivalConversation] = useState(null);
	const [newMessage, setNewMessage] = useState("");
	const [activeConversationsImages, setActiveConversationsImages] = useState([])
	const [notifications, setNotifications] = useState([]);
	const [showMobileMenu, setShowMobileMenu] = useState(true)
	const [conversation, setConversation] = useState(null)
	const [senderImage, setSenderImage] = useState({ image1: "", image2: "" })
	const [recipientImage, setRecipientImage] = useState({ image1: "", image2: "" })
	const [messages, setMessages] = useState([])
	const [recipientAddress, setRecipientAddress] = useState("")

	const { selected, allUserMessages, setNftToMessage, setUserMessages } = useMessageStore(
		(state) => ({
			selected: state.selected,
			allUserMessages: state.allUserMessages,
			setNftToMessage: state.actions.setNftToMessage,
			setUserMessages: state.actions.setUserMessages,
		})
	);

	const toggleMenu = () => setShowMobileMenu(!showMobileMenu)

	const { address } = useContext(Web3OnboardContext);
	const { socket } = useContext(SocketContext);
	const senderId = contractAddress + tokenIdToUse
	const receiverId = selected ? `${selected.contractAddress}${selected.tokenId}` : null
	let authToken = JSON.parse(localStorage.getItem("authToken"))

	const [userConversations, setUserConversations] = useState([])

	authToken = authToken && address ? authToken[address] : ""

	const headers = useMemo(() => {
		return {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authToken}`,
		};
	}, [authToken])
	const [latestConversation, setLatestConversation] = useState(null)

	const isConnected = useSocketConnectionStatus()


	useEffect(() => {
		if (senderId) {
			async function fetchUserConversations() {
				try {
					const { data: conversation } = await axios.get(`${baseURL}conversations/${senderId}`)
					setUserConversations(conversation)
				} catch (error) {
					console.log({ error });
				}
			}
			fetchUserConversations()
		}
	}, [senderId])

	useEffect(() => {
		setUserMessages(userConversations)
	}, [setUserMessages, userConversations])

	// INCOMING MESSAGE
	useEffect(() => {
		socket.on("getMessage", async ({ conversation, message }) => {
			console.log("INCOMING MESSAGE");
			try {
				setArrivalMessage(message);
				setArrivalConversation(conversation);
				setLatestConversation({ conversation, message })

				const conversationExists = userConversations.find((item) => item._id === conversation._id)

				if (!conversationExists) {
					setUserConversations((prev) => [conversation, ...prev])
				}

			} catch (error) {
				console.log(error);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, isConnected]);


	// ARRIVAL MESSAGE
	useEffect(() => {
		if (!arrivalMessage || !conversation || !conversation.members) {
			return;
		}

		if (arrivalMessage.sender.toLowerCase() !== senderId.toLowerCase() && conversation.members.includes(arrivalMessage.sender)) {
			if (arrivalMessage.conversationId === conversation._id) {
				setMessages(prevMessages => [...prevMessages, arrivalMessage]);
			}
		}
	}, [arrivalMessage, conversation, senderId]);

	useEffect(() => {
		const receiverId = `${selected.contractAddress}${selected.tokenId}`.toLowerCase();

		if (!selected.contractAddress || !selected.tokenId) {
			return;
		}

		async function fetchConversation() {
			try {
				const { data: conversation } = await axios.get(`${baseURL}conversations/find/${senderId}/${receiverId}`);
				const { data: messages } = await axios.get(`${baseURL}messages/${conversation._id}`);
				setConversation(conversation);
				setMessages(messages)

				const { contractAddress, tokenId } = selected

				const { data: metadata } = await getAlchemyMetadata(
					contractAddress,
					tokenId
				);
				const image1 = formatIPFS(metadata?.data?.media[0].raw);
				const image2 = formatIPFS(metadata?.data?.media[0].gateway);

				const images = { image1, image2 }
				setRecipientImage(images)

				const { data } = await getNFTTokenIdOwners(
					contractAddress,
					tokenId
				);

				setRecipientAddress(data.data)
			} catch (error) {
				console.log(error);
			}
		}

		fetchConversation();
	}, [selected, selected.contractAddress, selected.tokenId, senderId]);



	useEffect(() => {
		async function getSenderImage() {
			try {
				const contractAddress = senderId.substring(0, 42);
				const tokenId = senderId.substring(42)

				const { data: imageData } = await getAlchemyMetadata(
					contractAddress,
					tokenId
				);

				if (imageData && imageData.data && imageData.data.media) {
					const image1 = formatIPFS(imageData?.data?.media[0].raw);
					const image2 = formatIPFS(imageData?.data?.media[0].gateway);

					const images = { image1, image2 }
					setSenderImage(images)
				}
			} catch (error) {
				console.log(error);
			}
		}
		getSenderImage()
	}, [senderId])



	useEffect(() => {
		socket.emit("addUser", senderId);
		socket.on("getUsers", () => {
		});
	}, [senderId, socket, isConnected]);

	useEffect(() => {
		async function updatedConversationStatus() {
			try {
				if (conversation && conversation._id) {
					const conversationId = conversation._id
					await axios.patch(`${baseURL}conversations/${conversationId}`, { status: "open" }, { headers: headers })
				}
			} catch (error) {
				console.log(error);
			}
		}

		if (conversation && conversation._id) {
			updatedConversationStatus()
		}
	}, [conversation, arrivalMessage, headers])


	// OUTGOING MESSAGES
	const handleSubmit = async (e) => {
		e.preventDefault();
		socket.emit("addUser", receiverId);

		if (newMessage.trim() === "") return

		const newId = new ObjectId()
		const message = {
			_id: newId,
			sender: senderId,
			text: newMessage,
			conversationId: conversation._id,
			receiverId: receiverId,
			recipientAddress: recipientAddress,
			senderAddress: address
		};
		const conversationId = conversation._id
		try {
			setNewMessage("");
			const indexedDBMessage = {
				_id: newId.toString(),
				conversationId: conversation._id,
				sender: senderId,
				text: newMessage,
				members: [recipientAddress, address],
				isRead: false,
				createdAt: new Date().toISOString()
			}

			setMessages(prevMessages => [...prevMessages, indexedDBMessage]);

			socket.emit("sendMessage", {
				senderId,
				receiverId,
				conversationId,
				messageId: newId,
				message: indexedDBMessage,
			});
			await axios.post(`${baseURL}messages`, message, { headers: headers });

			const conversationExists = userConversations.find((item) => item._id === conversation._id)

			if (!conversationExists) {
				setUserConversations((prev) => [conversation, ...prev])
			}

			if (conversation && recipientAddress && receiverId) {
				socket.emit("sendNotification", {
					conversationId: conversation._id,
					recipient: recipientAddress,
					receiverId,
					senderId,
					senderImage,
					recipientImage,
					status: false
				});
			}
			setLatestConversation({ conversation, message: indexedDBMessage })
			await axios.patch(`${baseURL}conversations/${conversationId}`, { status: "closed" }, { headers: headers })
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		setConversation(senderId)
		setNftToMessage(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [senderId]);

	const [nftName, setNftName] = useState("")

	useEffect(() => {
		if (selected.contractAddress === '' || selected.tokenId === '') {
			return
		}

		async function updateNFTName() {
			try {
				const network = "mainnet"
				const address = "0xc662a8Ce36392d4B9489D0B59605a1874711BFc6"
				const baseProvider = new ethers.providers.InfuraProvider(
					network,
					"d34a8d5f505a4dfcafacadd4e019e771"
				);
				const NFTRegistry = new ethers.Contract(address, contracts[1].contracts.NFTRegistry.abi, baseProvider)

				if (selected) {
					const name = await NFTRegistry.tokenName(selected.contractAddress, selected.tokenId)
					if (name) {
						setNftName(name)
					} else {
						const NFTContract = new ethers.Contract(
							selected.contractAddress,
							["function name() external view returns (string _name)"],
							baseProvider
						);
						const collectionName = await NFTContract.name();
						const name = `${collectionName} #${selected.tokenId}`
						setNftName(name)
					}
				}
			} catch (error) {
				console.log(error);
			}
		}

		updateNFTName()
	}, [selected])

	const getMetadata = useCallback(async (item) => {
		const receiverId = item.members.find((item) => item.toLowerCase() !== senderId.toLowerCase())
		const contractAddress = receiverId.substring(0, 42);
		const tokenId = receiverId.substring(42)
		try {
			const { data } = await getAlchemyMetadata(
				contractAddress,
				tokenId
			);

			if (data && data.data && data.data.media) {
				const image1 = formatIPFS(data?.data?.media[0].raw);
				const image2 = formatIPFS(data?.data?.media[0].gateway);

				return { image1, image2 }
			}
		} catch (error) {
			console.log(error);
		}

	}, [senderId])

	async function getName(item) {
		const contractAddress = item?.substring(0, 42);
		const tokenId = item?.substring(42)

		const network = "mainnet"
		const address = "0xc662a8Ce36392d4B9489D0B59605a1874711BFc6"
		const baseProvider = new ethers.providers.InfuraProvider(
			network,
			"d34a8d5f505a4dfcafacadd4e019e771"
		);

		const NFTRegistry = new ethers.Contract(address, contracts[1].contracts.NFTRegistry.abi, baseProvider)

		let NFTContract;

		if (contractAddress) {
			NFTContract = new ethers.Contract(
				contractAddress,
				["function name() external view returns (string _name)"],
				baseProvider
			);
		}

		try {
			const name = await NFTRegistry.tokenName(contractAddress, tokenId)

			if (name) {
				return name
			} else {
				const name = await NFTContract.name();
				return `${name} #${tokenId}`
			}

		} catch (error) {
			console.log(error);
		}

	}

	const getLastMessage = async (receiverId) => {
		try {

			console.log({ userConversations, allUserMessages });

			let conversations = userConversations
			let messages = allUserMessages

			if (arrivalConversation) {
				conversations = [...userConversations, arrivalConversation]
			}

			if (arrivalMessage) {
				messages = [...allUserMessages, arrivalMessage]
			}
			const foundConversation = conversations.find(item => item.members.some(item => item.toLowerCase() === receiverId.toLowerCase()))
			const foundConversationId = foundConversation._id

			console.log({ foundConversationId });

			const conversationMessages = messages.filter(item => item.conversationId === foundConversationId)

			console.log({ conversationMessages });
			const lastMessage = conversationMessages
				.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]

			console.log({ conversationMessages, lastMessage });

			return lastMessage

		} catch (error) {
			console.log({ getLastMessage: error });
		}
	}

	useEffect(() => {

		if (!userConversations.length) return

		async function recentConversations() {
			console.log("called recentConversations");
			function removeDuplicates(array, property) {
				const seen = {};
				return array.filter(obj => {
					const value = obj[property];
					return seen.hasOwnProperty(value) ? false : (seen[value] = true);
				});
			}

			const conversations = removeDuplicates(userConversations, '_id');
			let images = conversations.map(async item => {

				const receiverId = item.members.find(item => item.toLowerCase() !== senderId.toLowerCase())
				const data = await getMetadata(item)
				const name = await getName(receiverId)
				const message = await getLastMessage(receiverId)

				const active = {
					_id: item._id,
					contractAddress: receiverId.substring(0, 42),
					tokenId: receiverId.substring(42),
					image1: data?.image1,
					image2: data?.image2,
					nftrName: name,
					message: message
				}

				return active
			})
			images = await Promise.all(images)
			setActiveConversationsImages(images)
		}
		recentConversations()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userConversations, senderId, allUserMessages])

	useEffect(() => {
		async function updateActiveConversationList() {
			// Early return if necessary values are not present
			if (!latestConversation?.conversation || !senderId) {
				return;
			}

			const receiverId = latestConversation.conversation.members.find(item => item?.toLowerCase() !== senderId?.toLowerCase());
			if (!receiverId) {
				return;
			}

			try {
				const newArray = [...activeConversationsImages];
				const index = newArray.findIndex(item => item._id === latestConversation.conversation._id);

				const message = await getLastMessage(receiverId);
				const data = await getMetadata(latestConversation.conversation);
				const name = await getName(receiverId);

				const newItem = {
					_id: latestConversation.conversation._id,
					contractAddress: receiverId.substring(0, 42),
					tokenId: receiverId.substring(42),
					image1: data?.image1,
					image2: data?.image2,
					nftrName: name,
					message: message
				};

				if (index !== -1) {
					newArray.splice(index, 1); // Remove existing item
				}

				newArray.unshift(newItem); // Add new item to the beginning

				setActiveConversationsImages(newArray);
			} catch (error) {
				console.log(error);
			}
		}

		updateActiveConversationList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestConversation?.conversation, senderId, activeConversationsImages]);


	const containerRef = useRef();
	const mobileRef = useRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				mobileRef.current &&
				!mobileRef.current.contains(event.target)
			) {
				setShowMobileMenu(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);


	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight;
		}
	}, [messages]);

	useEffect(() => {
		async function getNotifications() {
			try {
				const { data: notifications } = await axios.get(`${baseURL}token/notifications/${senderId}`, { headers: headers })
				setNotifications(notifications)
			} catch (error) {
				console.log(error);
			}
		}

		getNotifications()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [senderId, arrivalMessage, conversation])

	useEffect(() => {
		socket.on("getNotification", async (data) => {
			try {
				const { data: notifications } = await axios.get(`${baseURL}token/notifications/${senderId}`, { headers: headers })
				setNotifications(notifications)
			} catch (error) {
				console.log(error);
			}
		})
	}, [headers, senderId, socket])

	function showNotification(item) {
		const senderId = item?.contractAddress + item?.tokenId
		if (typeof senderId === "string" && item) {
			const isUnread = notifications?.find(element => element?.senderId?.toLowerCase() === senderId?.toLowerCase())
			return isUnread ? true : false

		}
	}
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	console.log({ selected });

	return (
		<>
			{
				isModalOpen &&
				<TBATransferModal
					isOpen={isModalOpen}
					onClose={closeModal}
					account={address}
					accountDeployed={true}
					action='MESSAGING'
					nftToMessage={selected}
					senderId={senderId}
				/>
			}
			{
				owned ?
					(
						<div className={`messaging-container`}>
							<div className={`active-conversation  ${!selected?.contractAddress || !selected?.tokenId ? "rounded-full" : ""}  ${showMobileMenu ? "show-mobile-ac" : "hide-mobile-ac"}`}>
								<div className="center-btn">
									<button className='start-chat' onClick={() => {
										openModal()
									}}>
										<Plus />
										<span>Start New Chat</span>
									</button>
								</div>
								<div className='recent-conversations'>
									{
										activeConversationsImages?.sort((a, b) => new Date(b?.message?.createdAt).getTime() - new Date(a?.message?.createdAt).getTime())
											.map((item, index) => {
												return (
													<div className={`recent-convo ${item?.contractAddress === selected?.contractAddress && item?.tokenId === selected?.tokenId ? "selected-convo" : ""}`} onClick={async () => {
														const selected = {
															contractAddress: item?.contractAddress,
															tokenId: item?.tokenId,
															name: item?.nftrName,
															imageUrl: item?.image1,
															timestamp: "",
															_id: item?.contractAddress
														}
														setNftToMessage(selected)
														await setConversation(senderId);

														setTimeout(() => {
															const data = { recipient: address, senderId: receiverId, conversationId: conversation?._id, receiverId: senderId }
															socket.emit("removeNotification", data);
														}, 3000)
													}}
														key={index}
													>
														{showNotification(item) ? <span className='unread-icon'></span> : null}
														<div className='recent-convo-content'>
															<div key={index} className='convo-image-name'
															>
																<Image
																	key={index}
																	className={`recent-convo-image conversation-list ${showNotification(item) ? "unread-conversation" : null}`}
																	src={item?.image2}
																	onError={(error) => {
																		error.target.src = placeholder;
																	}}
																	alt=""
																	style={{
																		cursor: "pointer"
																	}}
																/>
																<div>
																	<p className='recent-convo-name'>
																		{
																			item?.nftrName?.length > 10 ?
																				item?.nftrName?.slice(0, 10) + "..."
																				:
																				item?.nftrName ?
																					item?.nftrName :

																					null}</p>
																	<p className='last-message'>
																		{item?.message?.text}
																	</p>
																</div>
															</div>
															<p className='last-message-timestamp'>{`${format(item?.message?.createdAt).split(" ")[0]} ${format(item?.message?.createdAt).split(" ")[1][0]}`}</p>
														</div>
													</div>
												)
											})
									}
								</div>
							</div>

							<div className={`active-conversation ${!selected?.contractAddress || !selected?.tokenId ? "rounded-full" : ""}`}>
								<div className="center-btn">
									<button className='start-chat' onClick={() => {
										openModal()
									}}>
										<Plus />
										<span>Start New Chat</span>
									</button>
								</div>
								<div className='recent-conversations'>
									{
										activeConversationsImages?.map((item, index) => {
											return (
												<div className={`recent-convo ${item?.contractAddress === selected?.contractAddress && item?.tokenId === selected?.tokenId ? "selected-convo" : showNotification(item) ? "unread-convo" : ""}`} onClick={async () => {
													const selected = {
														contractAddress: item?.contractAddress,
														tokenId: item?.tokenId,
														name: item?.nftrName,
														imageUrl: item?.image1,
														timestamp: "",
														_id: item?.contractAddress
													}
													setNftToMessage(selected)
													await setConversation(senderId);

													setTimeout(() => {
														const data = { recipient: address, senderId: receiverId, conversationId: conversation?._id, receiverId: senderId }
														socket.emit("removeNotification", data);
													}, 3000)
												}}
													key={index}
												>
													<div className='recent-convo-content'>
														<div key={index} className='convo-image-name'
														>
															<Image
																key={index}
																className={`recent-convo-image conversation-list ${showNotification(item) ? "unread-conversation" : null}`}
																src={item?.image2}
																onError={(error) => {
																	error.target.src = placeholder;
																}}
																alt=""
																style={{
																	cursor: "pointer"
																}}
															/>
															<div>
																<p className='recent-convo-name'>
																	{
																		item?.nftrName?.length > 10 ?
																			item?.nftrName?.slice(0, 10) + "..."
																			:
																			item?.nftrName ?
																				item?.nftrName :

																				null}</p>
																<p className='last-message'>
																	{item?.message?.text}
																</p>
															</div>
														</div>
														<p className='last-message-timestamp'>{`${format(item?.message?.createdAt).split(" ")[0]} ${format(item?.message?.createdAt).split(" ")[1][0]}`}</p>
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
							<div className="message-box">
								<div className='d-flex justify-content-between'>
									{
										selected?.contractAddress && selected?.tokenId ? (
											<div className="chat-container">
												<div className="chat-history" ref={containerRef}>

													{/* fixed header */}

													<div className='chat-history-header'>
														<div className="flex item-center">
															{
																recipientImage &&
																<a href={`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${selected.contractAddress}/${selected.tokenId}`} target='_blank' rel='noreferrer'>

																	<Image
																		className="messageImg"
																		src={recipientImage?.image1}
																		alt="recipientImage"
																		onError={function (error) {
																			error.target.src = placeholder;
																		}} />
																</a>
															}
															<span>{nftName}</span>
														</div>

														{/* messaging menu icon */}

														<div className="menu-icon" onClick={toggleMenu}>
															<Hambuger />
														</div>
													</div>
													<div>
														{messages.map((m, i) => (
															<div key={i}>
																<Message
																	key={i}
																	message={m}
																	own={m.sender === senderId}
																	senderImage={senderImage}
																	recipientImage={recipientImage}
																	selected={selected}
																	names={names}
																	recipientAddress={recipientAddress}
																	senderAddress={address}
																/>
															</div>
														))}
													</div>

												</div>
												<div className="chat-input">
													<textarea
														placeholder="write something..."
														onChange={(e) => setNewMessage(e.target.value)}
														value={newMessage}
													></textarea>
													<button onClick={handleSubmit} className='send-messaging'>
														<SendMessage />
													</button>
												</div>
											</div>
										) : null
									}
								</div>
							</div>
						</div >) : null
			}
		</>
	)
}

export default NFTMessaging