import React from "react";

const Hambuger = () => {
	return (
		<svg
			width='32px'
			height='32px'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='SVGRepo_bgCarrier' stroke-width='0'></g>
			<g
				id='SVGRepo_tracerCarrier'
				stroke-linecap='round'
				stroke-linejoin='round'></g>
			<g id='SVGRepo_iconCarrier'>
				{" "}
				<path
					d='M20 7L4 7'
					stroke='#1C274C'
					stroke-width='1.5'
					stroke-linecap='round'></path>{" "}
				<path
					opacity='0.5'
					d='M20 12L4 12'
					stroke='#1C274C'
					stroke-width='1.5'
					stroke-linecap='round'></path>{" "}
				<path
					d='M20 17L4 17'
					stroke='#1C274C'
					stroke-width='1.5'
					stroke-linecap='round'></path>{" "}
			</g>
		</svg>
	);
};

export default Hambuger;
