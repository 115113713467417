import { useContext, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import SelectOwnNFT from "./SelectOwnNFT";
import { ethers } from "ethers";
import { SocketContext } from "src/contexts/SocketContext";

const CreateBidModal = ({
	openCreateBidModal,
	handleClose,
	name,
	selected,
	setSelected,
	NameMarketplace,
	nftName,
	currentBid,
	setCurrentBid,
	activeBid,
	setActiveBid,
	address,
}: any) => {
	const [offerAmount, setOfferAmount] = useState("0.01");
	const [isLoading, setIsLoading] = useState(false);
	const [, forceUpdate] = useState<any>({});

	const { socket } = useContext(SocketContext);

	const bidExist =
		activeBid?.collectionTo === selected?.contractAddress &&
		activeBid?.tokenTo === +selected?.tokenId;

	useEffect(() => {
		if (typeof currentBid === "number" && currentBid > 0)
			setOfferAmount((currentBid + 0.01).toFixed(3).toString());
	}, [currentBid]);

	async function createBid() {
		try {
			setIsLoading(true);
			const res = await NameMarketplace.enterBidForName(
				name,
				selected.contractAddress,
				selected.tokenId.toString(),
				{
					value: ethers.utils.parseEther(offerAmount),
					gasLimit: 300000,
				}
			);
			setIsLoading(false);
			handleClose();

			await res.wait();

			setCurrentBid(+offerAmount);
			socket.emit("BidCreated");

			setActiveBid({
				collectionFrom: "",
				tokenFrom: "",
				collectionTo: selected.contractAddress,
				name: name,
				tokenTo: +selected.tokenId,
				bidder: address.toLowerCase(),
				value: offerAmount,
				status: "active",
				timestamp: Date.now(),
				blockNumber: 0,
				transactionHash: "",
			});
			setSelected({
				contractAddress: "",
				tokenId: "",
				collectionName: "",
			});
			forceUpdate({});
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	}

	return (
		<>
			<Modal show={openCreateBidModal} onHide={handleClose} className='my-5'>
				<Modal.Header closeButton>
					<Modal.Title>MAKE A BID</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: "0px" }}>
					<div style={{ width: "100%" }}>
						<p
							className='text-center'
							style={{ marginTop: "12px", marginBottom: "0" }}>
							You're about to create a bid for{" "}
							<span className='text-pink'>{name}</span>
						</p>

						{typeof currentBid === "number" && currentBid > 0 ? (
							<p style={{ marginTop: "6px", marginBottom: "0" }}>
								Current bid price is{" "}
								<span className='text-pink'>{+currentBid} ETH</span>
							</p>
						) : (
							<p style={{ marginTop: "6px", marginBottom: "0" }}>
								Minimum bid price is <span className='text-pink'>0.01 ETH</span>
							</p>
						)}

						<p className='input-label'>Offer Amount</p>
						<div className='bid-input'>
							<input
								step='any'
								type='number'
								min={
									typeof currentBid === "number" && currentBid > 0
										? currentBid
										: "0.01"
								}
								value={offerAmount}
								onChange={(e) => {
									setOfferAmount(e.target.value);
								}}
								className='select-token-wrapper deposit'
							/>
							<label className='text-pink input-label'>ETH</label>
						</div>

						<p className='input-label'>Recipient NFT </p>
						<SelectOwnNFT
							selected={selected}
							setSelected={setSelected}
							top='240px'
							backgroundColor='#3fecc6'
							position='absolute'
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{nftName ? (
						<p style={{ color: "#ffffff" }}>Cannot bid with a named NFT</p>
					) : selected.contractAddress &&
					  selected.tokenId.toString() &&
					  offerAmount <= currentBid ? (
						<p style={{ color: "#ffffff" }}>
							You have to place a bid higher than the current bid
						</p>
					) : null}
					<button
						onClick={createBid}
						className='offer-btn'
						style={{
							padding: "6px 24px",
							width: "208px",
							fontWeight: "300",
							fontSize: "1rem",
						}}
						disabled={
							nftName ||
							+offerAmount < 0.01 ||
							(bidExist && +offerAmount <= +currentBid) ||
							!selected.contractAddress ||
							selected.tokenId.length === 0
						}>
						{isLoading ? (
							<Spinner animation='border' role='status'></Spinner>
						) : (
							"CREATE BID"
						)}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CreateBidModal;
