import GoldenTicket from "../components/GoldenTicketScreen/GoldenTicket";
import GoldenTicketRatio from "../components/GoldenTicketScreen/GoldenTicketRatio";

const GoldenTicketScreen = () => {
  return (
    <>
      <GoldenTicket />
      <GoldenTicketRatio />
    </>
  );
};

export default GoldenTicketScreen;
