import SelectOwnNFT from './SelectOwnNFT'
import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import useGetName from 'src/hooks/useGetName';
import TransferWarningModal from './TransferWarningModal';
import { SocketContext } from 'src/contexts/SocketContext';

const NameTransfer = ({ selected, setSelected, nftName, approval, owned, approveTransfer, NameMarketplace, contractAddress, tokenId, name, isLoading: waitingApproval, setUpdateNameInput, activeOffer, setActiveOffer, updateName, NFTRegistry, address }) => {

	const [transferWarningOpen, setTransferWarningOpen] = useState(false)
	const [allowTransfer, setAllowTransfer] = useState(false)

	const { setName } = useGetName(contractAddress, tokenId, 5000)
	const [isLoading, setIsLoading] = useState(false);
	const { socket } = useContext(SocketContext)


	useEffect(() => {
		async function checkAllowance() {
			if (selected.contractAddress &&
				selected.tokenId) {
				const allow = await NFTRegistry?.allowances(
					address,
					selected.contractAddress,
					selected.tokenId.toString()
				);
				setAllowTransfer(allow)
			}
		}
		checkAllowance()
	}, [NFTRegistry, address, selected.contractAddress, selected.tokenId])

	async function transferName() {

		if (activeOffer && selected.contractAddress && selected.tokenId && !allowTransfer) {
			setTransferWarningOpen(true)
		} else {
			try {
				let result;
				if (selected.contractAddress && selected.tokenId.toString()) {
					setIsLoading(true)
					result = await NameMarketplace?.transferName(
						contractAddress,
						tokenId.toString(),
						selected.contractAddress,
						selected.tokenId.toString()
					);
				}
				await result.wait()
				setName("")
				setIsLoading(false)
				setUpdateNameInput(true)
				updateName()
				socket.emit("OfferCreated");
				socket.emit("NameTransfer");
				socket.emit("BidCreated");
				socket.emit("OfferCreated");

			} catch (error) {
				setIsLoading(false)
				console.log(error);

			}
		}

	}

	return (
		<>
			<TransferWarningModal
				transferWarningOpen={transferWarningOpen}
				setTransferWarningOpen={setTransferWarningOpen}
				NameMarketplace={NameMarketplace}
				name={name}
				setAllowTransfer={setAllowTransfer}
				setActiveOffer={setActiveOffer}
				selected={selected}
				NFTRegistry={NFTRegistry}
			/>
			<p style={{
				fontStyle: "italic"
			}} className='fs-6 mt-4'>Transfer <span className='text-pink'>{name}</span> for free to another NFT in your wallet</p>
			<label className="text-pink fs-6">Recipient NFT</label>
			<SelectOwnNFT
				selected={selected}
				setSelected={setSelected} position="absolute"
				backgroundColor="#3fecc6"
				text="#fc4ba3 "
			/>
			{
				nftName && selected.contractAddress && selected.tokenId ? (
					<p className="fs-6" style={{ color: "red" }}>cannot transfer name to a named NFT</p>
				) : null
			}

			<div className="d-flex align-items-center justify-content-center">
				{approval ? (
					<button
						disabled={!owned ? true : nftName ? true : false}
						className="transfer-button"
						onClick={transferName}
					>
						{isLoading ? (
							<Spinner
								animation="border"
								role="status"
								style={{
									width: "24px",
									height: "24px"
								}}
							>
							</Spinner>
						) : (
							"TRANSFER"
						)}
					</button>
				) : (
					<button
						disabled={!owned ? true : nftName ? true : false}
						className="transfer-button"
						onClick={approveTransfer}
						style={{
							width: "170px",
						}}
					>
						{waitingApproval ? (
							<Spinner
								animation="border"
								role="status"
								style={{
									width: "24px",
									height: "24px"
								}}
							>
							</Spinner>) : (
							"APPROVE"
						)}
					</button>
				)}
			</div>
		</>
	)
}

export default NameTransfer