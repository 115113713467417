import { useState, useEffect } from "react";
import ErrorBoundary from "src/components/ErrorBoundary";
import RobotDetails from "../components/LetsBrowseScreen/RobotDetails/RobotDetails";
import { useParams } from "react-router-dom";
import { fetchTokenByName } from "src/apis";
import Loader from "src/components/LetsBrowseScreen/Loader";

const RobotDetailsScreen = ({ subdomain }) => {
	const { contractAddress, tokenId } = useParams();
	const [contractAddressToUse, setContractAddressToUse] =
		useState(contractAddress);
	const [tokenIdToUse, setTokenIdToUse] = useState(tokenId);
	const [isError, setIsError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (subdomain) {
			setLoading(true);
			fetchTokenByName(subdomain).then((res) => {
				console.log({ fetchTokenByName: res });
				if (res.error) {
					setLoading(false);
					setIsError(res.error);
					window.location.href = process.env.REACT_APP_BASE_PAGE;
				} else {
					setLoading(false);
					window.location.href = `${process.env.REACT_APP_BASE_PAGE}robot-details/${res.tokenContract}/${res.tokenId}`;
				}
			});
		}
	}, [subdomain]);

	const shouldRender =
		contractAddressToUse !== undefined && tokenIdToUse !== undefined; //https://dev--suspicious-babbage-5fa6bd.netlify.app/#/8ea75a85-e75f-4771-b975-449398fb03a7

	return (
		<>
			<ErrorBoundary errorMessage='Error Loading NFT Details'>
				{shouldRender ? (
					<RobotDetails
						contractAddress={contractAddressToUse}
						tokenId={tokenIdToUse}
						loading={loading}
					/>
				) : (
					<Loader color={"#fc4ba3"} />
				)}
				{isError ? (
					<button
						onClick={() => {
							window.location.href = window.location.host.split(".")[1];
						}}>
						No NFT Found
					</button>
				) : null}
			</ErrorBoundary>
		</>
	);
};

export default RobotDetailsScreen;
