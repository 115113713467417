import Header from '../components/Header/Header'
import Banner from '../components/Banner/Banner'
import RnmToken from '../components/RnmToken/RnmToken'
import WhyNameNft from '../components/WhyNameNFT/WhyNameNft'
import NFTBanking from '../components/NFTBanking/NFTBanking'
import EarnBoxList from '../components/Earn/EarnBoxList'
import Featured from '../components/Featured/Featured'
import BecomeProvider from '../components/BecomeProvider/BecomeProvider'
import Projects from '../components/Projects/Projects'
import Cta from '../components/Cta/Cta'
import Footer from '../components/CallToAction/CallToAction'
import { useState, useEffect } from 'react'

const HomeScreen = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 500
    let heightToShowFrom = 4500
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    /*	
		if (winScroll < heightToHideFrom || winScroll > heightToShowFrom) { 
			 setIsVisible(false);
		} else {
			 setIsVisible(true);
		} 
		*/
  }

  return (
    <>
      {isVisible && <Footer />}
      <Header />
      <Banner />
      <RnmToken />
      <WhyNameNft />
      <NFTBanking />
      <Cta
        title="FOLLOW US ON MEDIUM"
        url="https://medium.com/nft-registry"
        classN="cta-section-2"
      />
    </>
  )
}

export default HomeScreen
