//import HeaderTwo from "../components/HeaderTwo/HeaderTwo";
import InviteComponent from "../components/InviteScreen/InviteComponent";

const InviteScreen = ({ address }) => {
  return (
    <>
      <InviteComponent address={address} />
    </>
  );
};

export default InviteScreen;
