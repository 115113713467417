import { useState } from "react";

const FormGroupInput = ({ placeholderText, btnText }) => {
  const [showPh, setShowPh] = useState(true);

  const handlePlaceholder = (e) => {
    if (e.target.value.length > 0) {
      setShowPh(false);
    } else {
      setShowPh(true);
    }
  };

  return (
    <div className="form-group-input">
      <div className="placeholder-wrapper">
        {showPh === true && (
          <p className="placeholder-input">{placeholderText}</p>
        )}
      </div>
      <input type="submit" value={btnText ? btnText : "Collect"} />
    </div>
  );
};

export default FormGroupInput;
