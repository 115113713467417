import { Link, NavLink } from "react-router-dom";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import "../Header/header.css";

const Header = () => {
  return (
    <header className="header-one">
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Link to="" className="navbar-brand">
            <Image src="./img/logo.png" />
          </Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <div className="specialnavlink">
                <NavLink
                  to="/"
                  exact={true}
                  className="text-white nav-link"
                  activeClassName="active"
                >
                  HOME
                </NavLink>
              </div>
              <a
                target="_blank"
                href="https://docs.nftr.name"
                className="text-white nav-link"
                rel="noreferrer"
              >
                Docs
              </a>
              <Nav.Link
                href="https://discord.gg/zKR763rCfp"
                target="_blank"
                className="text-white nav-link"
              >
                COMMUNITY
              </Nav.Link>
              <Nav.Link
                href="https://medium.com/nft-registry"
                target="_blank"
                className="text-white"
              >
                BLOG
              </Nav.Link>
              <Nav.Link
                href="https://twitter.com/NFT_Registry"
                target="_blank"
                className="text-white"
              >
                NEWS
              </Nav.Link>
            </Nav>
            <div className="text-end">
              <Link to="/lets-browse" className="bordered-button pink-border">
                Launch dapp
              </Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
