import { Container, Row, Col, Image } from 'react-bootstrap'
import './Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer>
      <div className="copyright-section text-center">
        <Image className="copyright-image" src="./img/svg/copyright-bg.svg" />
        <p>&copy; {year} NFTR</p>
      </div>
      <div className="footer-menu text-center">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="https://docs.nftr.name" target="_blank">
              Docs
            </a>
          </li>
          <li>
            <Link to="/token" className="footer-menu.li">
              TOKEN
            </Link>
          </li>
          <li>
            <a href="https://discord.gg/zKR763rCfp" target="_blank">
              Community
            </a>
          </li>
          <li>
            <a href="https://medium.com/nft-registry" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <a href="https://twitter.com/nft_registry" target="_blank">
              News
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
