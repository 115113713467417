import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from 'react-router-dom'
import EarnBg from './earn-bg.png'

import RnmStaking from './Tabs/RnmStaking'
import StakeGtk from './Tabs/StakeGtk'
import SpendGtk from './Tabs/SpendGtk'
import NamingRewards from './Tabs/NamingRewards.js'
import { Col, Container, Row } from 'react-bootstrap'

const EarnYieldTab = () => {
  const history = useHistory()

  const pathName = history.location.pathname

  return (
    <div
      className="earn-yield-tab-section"
      style={{ backgroundImage: `URL(${EarnBg})` }}
    >
      <Container>
        <Row>
          <Col>
            <div className="tab-nav">
              <ul>
                <li>
                  <NavLink
                    className={
                      pathName === '/earn/' || pathName === '/earn'
                        ? 'active'
                        : ''
                    }
                    to="/earn/rnm-staking"
                  >
                    RNM STAKING
                  </NavLink>
                </li>
                <li>
                  <span data-tip="Staking and yield functionality will be enabled upon NFTR phase 2 (RNM) launch">
                    <NavLink to="/earn/stake-gtk">STAKE GTKs</NavLink>
                  </span>
                </li>
                <li>
                  <span data-tip="Staking and yield functionality will be enabled upon NFTR phase 2 (RNM) launch">
                    <NavLink to="/earn/spend-gtk">SPEND GTKs</NavLink>
                  </span>
                </li>
                <li>
                  <span data-tip="Staking and yield functionality will be enabled upon NFTR phase 2 (RNM) launch">
                    <NavLink to="/earn/naming-rewards">NAMING REWARDS</NavLink>
                  </span>
                </li>
              </ul>
            </div>
            <div className="tab-content-wrapper">
              <Switch>
                <Route path="/earn" exact component={RnmStaking}></Route>
                <Route path="/earn/rnm-staking" component={RnmStaking}></Route>
                <Route path="/earn/stake-gtk" component={StakeGtk}></Route>
                <Route path="/earn/spend-gtk" component={SpendGtk}></Route>
                <Route
                  path="/earn/naming-rewards"
                  component={NamingRewards}
                ></Route>
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EarnYieldTab
