import { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import { NETWORK } from "../../constants";
import "../HeaderTwo/HeaderTwo.css";
import { Alert, Button } from "antd";
import { WalletBalancesContext } from "src/contexts/WalletBalancesContext";
import axios from "axios";
// import { ethers } from "ethers";
// import { NameMarketplaceABI } from "src/contracts/external_contracts";
import { Edit, Message, MessageCenter } from "src/assets/images";
import { Web3OnboardContext } from "src/contexts/Web3OnboardContext";
// import { SocketContext } from "src/contexts/SocketContext";
import { Add } from "src/assets/images";
import ConnectEmailModal from "../LetsBrowseScreen/RobotDetails/ConnectEmailModal";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { SocketContext } from "src/contexts/SocketContext";
import placeholder from "../../assets/images/customplaceholder.png";

const baseURL = process.env.REACT_APP_API_URL_BASE;

function HeaderTwo() {
	const {
		targetNetwork,
		selectedChainId,
		injectedProvider,
		address,
		userSigner,
	} = useContext(Web3OnboardContext);

	const [arrivalMessage, setArrivalMessage] = useState(1);
	const [notifications, setNotifications] = useState([]);
	// const [marketplaceBalace, setMarketPlaceBalance] = useState(0);
	const [openEmailModal, setOpenEmailModal] = useState(false);
	const [email, setEmail] = useState(null);

	const { socket } = useContext(SocketContext);

	useEffect(() => {
		socket.on("getNotification", (data) => {
			setArrivalMessage(data);
		});
	}, [socket]);

	useEffect(() => {
		async function getEmail() {
			try {
				const { data } = await axios.get(`${baseURL}wallet/email/${address}`);
				if (data) {
					const email = data.email;
					setEmail(email);
				}
			} catch (error) {
				console.log("getEmailError", error);
			}
		}

		if (address) {
			socket.on("updateEmail", (data) => {
				if (data?.toLowerCase() === address?.toLowerCase()) {
					getEmail();
				}
			});
		}
	}, [address, socket]);

	useEffect(() => {
		async function fetchNotifications() {
			try {
				const { data: notification } = await axios.get(
					`${baseURL}wallet/notifications/${address}`
				);
				setNotifications(notification);
			} catch (error) {
				console.log(error);
			}
		}

		if (arrivalMessage && address) {
			fetchNotifications();
		}
	}, [address, arrivalMessage]);

	useEffect(() => {
		socket.on("getNotification", async (data) => {
			try {
				const { data: notifications } = await axios.get(
					`${baseURL}wallet/notifications/${address}`
				);
				setNotifications(notifications);
			} catch (error) {
				console.log(error);
			}
		});
	}, [address, socket]);

	// useEffect(() => {
	// 	if (!userSigner) return;

	// 	const NameMarketplace = new ethers.Contract(
	// 		"0xe752c849208CD1F2AF487103BD3a03B050BEBe1A",
	// 		NameMarketplaceABI,
	// 		userSigner
	// 	);

	// 	async function pendingWithdrawals() {
	// 		try {
	// 			const result = await NameMarketplace.pendingWithdrawals(address);
	// 			setMarketPlaceBalance(result.toString() / 10 ** 18);
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	}
	// 	pendingWithdrawals();
	// }, [address, userSigner]);

	const { eth, weth, gtk, namingCredits } = useContext(WalletBalancesContext);

	useEffect(() => {
		async function getEmail() {
			try {
				const { data } = await axios.get(`${baseURL}wallet/email/${address}`);
				if (data) {
					const email = data.email;
					setEmail(email);
				}
			} catch (error) {
				console.log("getEmailError", error);
			}
		}

		if (address) {
			getEmail();
		}
	}, [address]);

	const ethBalance = eth.decimal;
	const wethBalance = weth.decimal;
	const goldenTicketBalance = gtk.decimal;
	const namingCreditsBalance = namingCredits.decimal;
	const networkSelected = NETWORK(selectedChainId);

	const displayMessage = useRef(null);

	if (selectedChainId === targetNetwork.chainId) {
		displayMessage.current = null;
	} else if (selectedChainId !== targetNetwork.chainId) {
		displayMessage.current = (
			<div
				style={{
					zIndex: 2,
					position: "absolute",
					right: 0,
					top: 0,
					padding: 16,
					color: "white",
				}}
				className={`${!selectedChainId ? `d-none` : null}`}>
				<Alert
					message='⚠️ Wrong Network'
					description={
						<div>
							You have <b>{networkSelected && networkSelected.name}</b> selected
							and you need to be on{" "}
							<Button
								onClick={async () => {
									const ethereum = window.ethereum;
									const data = [
										{
											chainId: "0x" + targetNetwork.chainId.toString(16),
										},
									];
									const tx = await ethereum
										.request({
											method: "wallet_switchEthereumChain",
											params: data,
										})
										.catch(console.error);
								}}>
								<b>{targetNetwork.name}</b>
							</Button>
						</div>
					}
					type='error'
					closable={false}
				/>
			</div>
		);
	}

	// async function withdraw() {
	// 	const NameMarketplace = new ethers.Contract(
	// 		"0xBf1B1ba9CD5803781E97b3C966c9EC1E1677a730",
	// 		NameMarketplaceABI,
	// 		userSigner
	// 	);

	// 	try {
	// 		const result = await NameMarketplace.withdraw();
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }

	const uniqueNotifications = removeDuplicateReceiverIds(notifications);

	console.log({ uniqueNotifications });
	return (
		<header className='header-two' style={{ zIndex: 1, position: "relative" }}>
			<ConnectEmailModal
				openEmailModal={openEmailModal}
				setOpenEmailModal={setOpenEmailModal}
			/>
			<Navbar collapseOnSelect expand='lg'>
				<Container>
					<Link to='/' className='navbar-brand'>
						<Image src='./img/logo.png' />
					</Link>

					<Navbar.Toggle aria-controls='responsive-navbar-nav' />
					<Navbar.Collapse id='responsive-navbar-nav'>
						<Nav className='mx-auto'>
							<div className='specialnavlink'>
								<NavLink
									to='/lets-browse'
									activeClassName='active'
									className='text-white nav-link'>
									Browse
								</NavLink>
							</div>
							<div className='specialnavlink'>
								<NavLink
									to='/golden-ticket'
									className='text-white nav-link text-golden'>
									Golden Ticket
								</NavLink>
							</div>
						</Nav>

						<div className='mobile-flex-center'>
							<div className='text-center home-button'>
								<Link to='/' className='bordered-button pink-border'>
									Home
								</Link>
								<div className='waller-wrapper' style={{ marginTop: "6px" }}>
									<div
										className='wallet'
										id='wallet'
										style={{ marginBottom: "50px" }}>
										{uniqueNotifications.length > 0 ? (
											<div className='noitification'>
												<div className='message-notification-container'>
													{/* <Image
														style={{ maxWidth: "35px" }}
														src='./img/chat.png'
													/> */}

													<MessageCenter />
													<span className='message-notification-count'>
														{uniqueNotifications.length > 0
															? uniqueNotifications.length
															: null}
													</span>
												</div>

												{uniqueNotifications.map((item, index) => {
													const contractAddress = item?.receiverId.substring(
														0,
														42
													);
													const tokenId = item?.receiverId.substring(42);
													return (
														<a
															reloadDocument={true}
															forceRefresh={true}
															href={`${process.env.REACT_APP_NFTR_ORIGIN}/#/robot-details/${contractAddress}/${tokenId}`}
															onClick={() => {
																socket.emit("removeNotification", item);
																setTimeout(() => {
																	window.location.reload();
																}, 500);
															}}>
															<Image
																key={index}
																src={item.recipientImage?.image1}
																alt={item?.receiverId}
																id='notification-img'
																style={{
																	width: "52px",
																	height: "52px",
																}}
																onError={(error) => {
																	error.target.src = placeholder;
																}}
															/>
														</a>
													);
												})}
											</div>
										) : null}
										{eth.original && (
											<h4 className='my-wallet'>
												MY WALLET{" "}
												<Image
													style={{ maxWidth: "35px" }}
													src='./img/wallet.png'
												/>
											</h4>
										)}
										{userSigner && gtk.original ? (
											<p className='golden-ticket'>
												{goldenTicketBalance != null
													? goldenTicketBalance.toString()
													: "..."}{" "}
												<span className='text-golden'>GOLDEN TICKETS</span>
											</p>
										) : null}

										<div className='balances'>
											{ethBalance === 0 ? null : (
												<p>
													{userSigner && (
														<>
															{ethBalance.toFixed(4)}{" "}
															<span className='text-green'>ETH</span>
														</>
													)}
												</p>
											)}
											{wethBalance === null ? null : (
												<p>
													{userSigner && (
														<>
															{wethBalance.toFixed(4)}{" "}
															<span className='text-green'>WETH</span>
														</>
													)}
												</p>
											)}
										</div>
										{!userSigner || namingCreditsBalance === 0 ? null : (
											<p>
												{namingCreditsBalance.toFixed(0)}{" "}
												<span className='text-green'>NAMING CREDITS</span>
											</p>
										)}

										{userSigner ? (
											<div className='wallet-email'>
												{email ? (
													<div className='email-edit'>
														<Message />
														<p>{email}</p>

														<span onClick={() => setOpenEmailModal(true)}>
															<Edit />
														</span>
													</div>
												) : (
													<>
														<Message />
														<span onClick={() => setOpenEmailModal(true)}>
															<CustomTooltip content='Bind your NFT to your email address by verifying. This allows users to message your NFT via email'>
																<Add />
															</CustomTooltip>
														</span>
													</>
												)}
											</div>
										) : null}

										{/* {marketplaceBalace === 0 ? null : (
										<div className='d-flex justify-content-end'>
											<p style={{ marginRight: "12px" }}>
												{marketplaceBalace}{" "}
												<span className='text-green'>ETH</span>
											</p>
											<button
												onClick={withdraw}
												style={{
													background: "none",
													border: "none",
													outline: "none",
												}}>
												<img src={withdrawal} alt='withdraw icon' />
											</button>
										</div>
									)} */}
									</div>
								</div>
							</div>
						</div>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			{injectedProvider && displayMessage.current}
		</header>
	);
}

export default HeaderTwo;

function removeDuplicateReceiverIds(notifications) {
	const notificationArray = notifications.map((item) => ({
		...item,
		receiverId: item.receiverId.toLowerCase(),
	}));
	const uniqueReceiverIds = new Set();
	const uniqueNotifications = [];

	for (const notification of notificationArray) {
		if (!uniqueReceiverIds.has(notification.receiverId)) {
			uniqueReceiverIds.add(notification.receiverId);
			uniqueNotifications.push(notification);
		}
	}

	return uniqueNotifications;
}
