import React from 'react'
import '../CallToAction/call-to-action.css'
import gtkImage from './GOLDEN-TICKET-POPUP.png'

const CallToAction = () => (
  <footer className="footer">
    <a href="/#/golden-ticket" target="_blank" rel="noreferrer">
      <img src={gtkImage} alt="golden-ticket-minting-link" />
    </a>
  </footer>
)

export default CallToAction
