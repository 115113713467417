import axios from "axios"

const baseURL = process.env.REACT_APP_API_URL_BASE;

const removeTwitter = async (contractAddressToUse: string, tokenIdToUse: string, twitterUsername: string) => {
    await axios.post(`${baseURL}twitter/remove`, {
        contractAddress: contractAddressToUse,
        tokenId: tokenIdToUse,
        twitterUsername
    });
};

async function signMessage(userSigner: any, userSocial: any, disconnect: boolean = false) {
    const message = `NFTR wants you to ${disconnect ? "disconnect" : "connect"} with your Twitter account to your NFT\n\nusername: ${userSocial.twitterUsername}\n\ncontract address: ${userSocial.contractAddress}\ntoken id: ${userSocial.tokenId}\n\nURI: https://nftr.name/\nChain ID: 1\nNonce: ${userSocial.authToken}\n`;
    const signature = await userSigner.signMessage(message);
    return signature;
}

export { removeTwitter, signMessage }